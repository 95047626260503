import formatNumber from 'number-handler'
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChartType } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { environment } from '../../../../../../../environments/environment';
import { valuesys } from '../../../../../../../options';
import { etablissement, ordre_paiement } from '../../../../../model/db';
import { categorie } from '../../../../../model/pension';
import { AuthServiceService } from '../../../../../service/auth-service.service';
import { HelperService } from '../../../../../service/helper.service';
import { Translatable } from '../../../../../service/Translatable';

@Component({
  selector: 'app-tableau-bord',
  templateUrl: './tableau-bord.component.html',
  styleUrls: ['./tableau-bord.component.scss']
})
export class TableauBordComponent extends  Translatable implements OnInit {


  formatNumber : any = formatNumber; 

  dtOptions: DataTables.Settings = {};
  titleModal:string ;
  title:string ;
  public categories: categorie [] = [];
  
  etabId:any ;
  public module_code = window['module'];
  public superAdmin : number ;
  registerForm : FormGroup;
  submitted    = false;

  searchInput : any= "";
  filtreSelect : any= 10;
  where : any= "";
  search : any= "";
  child : any= "";
  tri : any= "";
  order : any= "";
  endPOint : any= "";
  public searchColumn: string;
  public column: string;
  public childCol: string;

   //--paginate
   public paginateListe : any = [];
   public nextPage : any = [];
   public prevPage : any = [];
   public lastPage : any = [];
   public firstPage : any = [];
   public currentPage : any = [];
   public total : any = [];
   public path : any = [];
   public lastNumPage : any = [];
   public infoData : any = [];
   public cPage_less_2 : number;
   public path_cPage_less_2 : any;
   public cPage_less_1 : number;
   public path_cPage_less_1 : any;
   public cPage_more_1 : number;
   public path_cPage_more_1 : any;
   public cPage_more_2 : number;  
   public path_cPage_more_2 : any;
  //--end-paginate

  public etablissements:etablissement [] = [];

  filtre_etablissement_id : any;
  filtre_categorie_concernees_id: any;
   

  public ordre_paiements :ordre_paiement[] = [] ;
  dateDebut: Date;
  dateFin: Date;
  submitFiltreDate: boolean = false;

  // Chart Pour genre
  doughnutChartLabels: Label[] = [];
  doughnutChartData: MultiDataSet = [];
  doughnutChartType: ChartType = 'doughnut';
  public chartOptions: any = {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 80
  };
  public chartColors: Array<any> = [
      { // first color
        backgroundColor: ['#834090', '#cb2a7c','#bf96e8','#99185f','#d2418a','#ef5fa7']
      }
  ];

  
  // Chart Pour nationalité 
  doughnutChartLabelsNationalite: Label[] = [];
  doughnutChartDataNationalite: MultiDataSet = [];
  doughnutChartTypeNationalite: ChartType = 'doughnut';
  public chartOptionsNationalite: any = {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 80
  };
  public chartColorsNationalite: Array<any> = [
      { // first color
        backgroundColor: ['#834090', '#cb2a7c','#bf96e8','#99185f','#d2418a','#ef5fa7']
      }
  ];

  public detail_dashboard :any = [] ;
  public nombre_etudiant_inscrit_par_mention :any = [] ;
  public recapitulatif_preinscris_a_valider_par_etablissement :any = [] ;
  public recapitulatif_preinscription_a_valider_par_genre :any = [] ;
  public recapitulatifs :any = [] ;
  public recapitulatif_etudiant_inscris_par_code_redoublement :any = [] ;
  public recapitulatif_etudiant_inscris_nationalite :any = [] ;
  
  dtOptions1: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  dtOptions3: DataTables.Settings = {};
  dtOptions4: DataTables.Settings = {};
  dtOptions5: DataTables.Settings = {};
  dtOptions6: DataTables.Settings = {};
  dtOptions7: DataTables.Settings = {};

  constructor(private helper: HelperService,             
              private http: HttpClient,
              private auth : AuthServiceService,  
              private formBuilder: FormBuilder,
               
    ) {super()}

  async ngOnInit() {
    let where = "";
    let userinfo = this.auth.account()
    this.etabId = userinfo.info.etablissement_id;
    this.superAdmin = userinfo.info.admin;
    if(this.superAdmin == 0 && this.etabId != 1) where = "?where=etablissement.id|e|"+this.etabId;

    let resp:any = await this.http.get<any>(environment.pension_detail_dashboard_listecarte_by_cat_id+where, valuesys.httpAuthOptions()).toPromise();
    this.recapitulatifs = resp.data;

    console.log(this.ordre_paiements,"xxxxx")
    
    this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);
    if (this.superAdmin == 0 && this.etabId != 1) {
      const idEtablissement = this.auth.account().info.etablissement_id;
      let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+idEtablissement, valuesys.httpAuthOptions()).toPromise();
      if(res.code == 200) {
          this.categories = res.data
      }
    }
    // this.categories = await this.helper.getSelectList(environment.pension_categories,['name']);

   
    
    if(this.superAdmin == 0) {
      this.filtre_etablissement_id = this.etabId;
    }

    this.table_nbr_pre_inscri_valid_mention();

    
   
    }


  async table_nbr_pre_inscri_valid_mention(){
    let where = "";
    if(this.superAdmin == 0 && this.etabId != 1) where = "&where=etablissement.id|e|"+this.etabId;
  
    this.endPOint = environment.pension_detail_dashboard_listecarte+"?page=1"+where;

    let resp = await this.http.get<any>(this.endPOint,valuesys.httpAuthOptions()).toPromise();
    this.ordre_paiements = resp.data.data;
    console.log("Dashboard :",  this.recapitulatifs)

  }

 

  async getSearchInput(){
    let where = "";
    let date = ""
    if(this.dateFin && this.dateDebut) date = `&date_debut=${this.dateDebut}&date_fin=${this.dateFin}`;
    if(this.superAdmin == 0) where += "&where=etablissement.id|e|"+this.etabId
    this.endPOint = environment.pension_detail_dashboard_listecarte+"?page=1"+where+date;
    this.getUrlDatatable(this.endPOint )
  }

  async getFiltreSelect(){   
    let where = "";
    let date = ""
    if(this.dateFin && this.dateDebut) date = `&date_debut=${this.dateDebut}&date_fin=${this.dateFin}`;
    if(this.superAdmin == 0) where += "&where=etablissement.id|e|"+this.etabId
    this.endPOint = environment.pension_detail_dashboard_listecarte+"?page=1"+where+date;
    this.getUrlDatatable(this.endPOint )
  }


  async getSearch(){
    this.getUrlDatatable(this.endPOint )
  }
  async triTable(col, order){
    this.tri = col;
    this.order = order;
    this.getUrlDatatable(this.endPOint )
  }

  async getUrlDatatable(url,col = "", refElement?: any, child?: any, searchMulti?:any){


          this.where = "";

          if(refElement){
          this.searchColumn = refElement.value
          this.column = col
          }

       
          if(col != "" || this.searchColumn ){
            if(this.searchColumn !="" && this.searchColumn !="undefined"){
              if(this.superAdmin==1)
              this.search = this.column+"|l|"+this.searchColumn;
              else
              this.search = ","+this.column+"|l|"+this.searchColumn;
            }else if(this.searchColumn == "") {
              this.search = "";
            }
          } 

          else {

            if(this.searchInput !="" && this.searchInput !="undefined"){
              this.where = ""
              this.search = "&where_or=etablissement.name|l|%"+this.searchInput+ "%"
            }else if(this.searchInput == "") {
              this.search = "";
            }
          }
    

    let filtre : any= "";
    if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
      filtre = "&per_page=" + this.filtreSelect;
    }

    let triage : any= "";
    if(this.tri !="" && this.tri !="undefined"){
      triage = "&__order__="+ this.order+","+ this.tri;
    }
    console.log("search", this.search)

    let toogle = await this.http.get<any>(url + this.where + this.search  + filtre + triage,valuesys.httpAuthOptions()).toPromise();
   
    this.ordre_paiements = toogle.data.data;

    console.log("Togle data :",  this.ordre_paiements)
  }



  async addSelectForm(){

    

      let filtre_etablissement = '';
    if(this.filtre_etablissement_id != '' && this.filtre_etablissement_id != undefined){
      filtre_etablissement = ",concernees.etablissement_id|e|" + this.filtre_etablissement_id ;
    }
    let filtre_categorie = '';
    if(this.filtre_categorie_concernees_id != '' && this.filtre_categorie_concernees_id != undefined){
      filtre_categorie = ",concernees.categorie_concerne_id|e|" + this.filtre_categorie_concernees_id ;
    }

    let resp = await this.http.get<any>(environment.pension_detail_dashboard_listecarte_by_cat_id+"?where=1|e|1" + filtre_etablissement + filtre_categorie, valuesys.httpAuthOptions()).toPromise();

    this.recapitulatifs = resp.data;
    this.submitted = true;

  }
  filtreByDate(){
    console.log("date debut",typeof this.dateDebut ,"date fin",this.dateFin)
    let where = "";
    this.submitFiltreDate = true;
    if(!this.dateFin || !this.dateDebut) return
    let date = `&date_debut=${this.dateDebut}&date_fin=${this.dateFin}`;
    if(this.superAdmin == 0) where += "&where=etablissement.id|e|"+this.etabId
  
    this.endPOint = environment.pension_detail_dashboard_listecarte+"?page=1"+date+where;
    this.getUrlDatatable(this.endPOint )

  }

  async exportToExcel() {

    this.helper.exportExcel(this.printPaiement(this.ordre_paiements),'Listes des ordres des paiments').then(
      (response: any)=>{
        console.log('respons beee',response)
            let a = document.createElement("a"); 
            a.href = response.data;
            a.download = `ordre_paiements.xlsx`;
            a.click(); 
      },
      (error:any)=>{
        console.log(error)
      }
    );
  }

  printPaiement(ordre_paiement:any[]){
    return  ordre_paiement.map((ordre_paiement)=>{
      let t = {}
      t[this.__('dashboard.etab')] = ordre_paiement.Minister
      t[this.__('dashboard.nb_beneficiaire')] = ordre_paiement.Nombre_concerne
      t[this.__('dashboard.nb_cart')] = ordre_paiement.Nombre_carte
      t[this.__('dashboard.nb_ordre_p')] = ordre_paiement.Nombre_ordre_paiement
      t[this.__('dashboard.nb_paiement')] = ordre_paiement.Nombre_paiement
      t["Paiement à faire"] = ordre_paiement.Nombre_paiement_a_faire
      t["Paiement par E-poketra "] = ordre_paiement.Nombre_paiement_par_E_Poketra 
      t["Paiement par Paositra Money"] = ordre_paiement.Nombre_paiement_par_paositra
      return t ;
    })  || [];

  }
  async exportPdf() {
    await this.helper.exportPdf(this.printPaiement(this.ordre_paiements),'Listes des ordres des paiments','','ordre_paiements');
  }
  
  async exportToExcelRecap() {

    this.helper.exportExcel(this.printPaiementRecap(this.ordre_paiements),this.__('dashboard.recapitulatif_preinscription_a_valider_par_genre')).then(
      (response: any)=>{
        console.log('respons beee',response)
            let a = document.createElement("a"); 
            a.href = response.data;
            a.download = `recapitulatif_beneficiaires.xlsx`;
            a.click(); 
      },
      (error:any)=>{
        console.log(error)
      }
    );
  }
  async exportPdfRecap() {
    await this.helper.exportPdf(this.printPaiementRecap(this.ordre_paiements),this.__('dashboard.recapitulatif_preinscription_a_valider_par_genre'),'','recapitulatif_beneficiaires');
  }
  printPaiementRecap(recap:any[]){
    return  recap.map((r)=>{
      let t = {}
      t[this.__('dashboard.etablissement')] = r.etablissement_name
      t["Nombre"] = r.nombre_concernees
      t["Avec cartes"] = r.concernees_avec_carte
      t["Sans cartes"] = r.concernees_sans_carte
      t["avec cartes active"] = r.concernees_avec_carte_active
      t["avec cartes inactive"] = r.concernees_avec_carte_active
  
      return t ;
    })  || [];

  }
  async getCategorieByIdEtab(id,element) {
    this.categories = [];
    element.clearModel();
    let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+id, valuesys.httpAuthOptions()).toPromise();
    if(res.code == 200) {
        this.categories = res.data;
    }
  }

}
 