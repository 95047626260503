import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import { environment } from '../../../../../../../environments/environment';
import { valuesys } from '../../../../../../../options';
import {  etablissement} from '../../../../../model/db';
import {  carte } from '../../../../../model/pension';
import { AuthServiceService } from '../../../../../service/auth-service.service';
import { HelperService } from '../../../../../service/helper.service';
import { Translatable } from '../../../../../service/Translatable';
import { RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-carte',
  templateUrl: './carte.component.html',
  styleUrls: ['./carte.component.scss'],
  
})
export class CarteComponent extends Translatable implements OnInit{


  etablissement_id : number;
  niveau_id: number;
  situation_cart_id: string;

  niveaux = [
    {
      id: 0,
      niveau: "ministere"
    },
    {
      id:1,
      niveau: "concerne"
    }, 
  ]

  situation_cartes = [
    {
      id : "0",
      sit: "Non personnalisée"
    },
    {
      id : 3,
      sit: "Personnalisée"
    },
    {
      id : 4,
      sit: "Enrôlé"
    }     
  ]

  dtOptions: DataTables.Settings = {};
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  title:string ;
  public tabUE = Array();
  public carteArray :carte[] = [] ;

  documents: any [] = [];

  registerForm !: FormGroup;
  registerFormImport!: FormGroup

  submitted    = false;
  currentEtabName:any=''
  currentTypeEtabName:any=''
  etabId:any=''
  endPOint:any=""

  filtre_etablissement_id:any=''

  filtreBase:boolean=false
  filtreBaseParametres:any=''
  superAdmin:any=""
  search:any=""
  filtreSelect:any= 10;
  tri : any= "";
  order : any= "";
  child : any= "";
  facID:any=""
  disableEtabInput:boolean=false
  disableExcelInput:boolean=false
  tabcarteshow:boolean=false
  totalparcourue:number = 0
  totalinvalid:number = 0
  totalvalid:number = 0

  public searchColumn: string;
  public column: string;
  public childCol: string;
  public paginateListe : any = [];
  public nextPage : any = [];
  public prevPage : any = [];
  public lastPage : any = [];
  public firstPage : any = [];
  public currentPage : any = [];
  public total : any = [];
  public path : any = [];
  public lastNumPage : any = [];
  public infoData : any = [];
  public cPage_less_2 : number;
  public path_cPage_less_2 : any;
  public cPage_less_1 : number;
  public path_cPage_less_1 : any;
  public cPage_more_1 : number;
  public path_cPage_more_1 : any;
  public cPage_more_2 : number;  
  public path_cPage_more_2 : any;


  public cartes :carte[] = [] ;
  public carte:carte = new carte();
  public carte_error : carte ;

  public c_envoye:any = {}
  public etab_c_id:any 
  public cat_c_id:any 
  public prov_c_id:any 
  public carte_verif:any[] = []


  searchInput : any= "";

  searchCol : any= "";

  public tab_concernee: any [] = [];

  savedDocument: any [] = [];

  documentLogo: any = [];

  public etablissements:etablissement [] = [];
  numSerieFilter: string = '';

 public ligne_parcourues: string = '';
 public ligne_traitees: string = '';
 public ligne_echouees: string = '';
 public reference_matricule_echec: [] = [];
sourcePath = "";
activeEtabId: number;
  constructor(             
            private modalService_: NgbModal,   
            private auth : AuthServiceService,  
            private toastr: ToastrService, 
            private formBuilder: FormBuilder,         
            private helper: HelperService,
            private router: Router,
            private http: HttpClient) { 
    super()
  }

  async ngOnInit() {
  this.auth.initAutority(["GCA","DCA","AFC","ACD","ENC"]);
  this.registerForm = this.formBuilder.group({
      etablissement_id:['', Validators.required],
      numero_serie:['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      Validators.minLength(10), Validators.maxLength(10)]]
  }); 
  this.registerFormImport = this.formBuilder.group({
    etab_id:['', Validators.required],
    fichier:['', Validators.required]
  }); 


    this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);

    let userinfo = this.auth.account()
    this.etabId  = userinfo.info.etablissement_id; 
    this.activeEtabId  = userinfo.info.etablissement_id; 

    this.superAdmin = userinfo.info.admin; 
   
    if(this.superAdmin==1 || this.activeEtabId == 1){
      this.endPOint = environment.pension_cartes+"?page=1"
    } else{
      this.filtre_etablissement_id=this.etabId
      this.etablissement_id = this.etabId;
      this.endPOint = environment.pension_cartes+"?page=1&where=etablissement.id|e|"+ this.filtre_etablissement_id ;
      //this.getCurrentEtablissement(this.etabId)
    }    
    this.getUrlDatatable(this.endPOint)

    
  }

  get f() { return this.registerForm.controls; }

  get f_import(){return this.registerFormImport.controls}

  getCurrentEtablissement(etabId: any) {
    throw new Error('Method not implemented.');
  }

  async getSearchInput(){
    this.getUrlDatatable(environment.pension_cartes+"?page=1"+this.child)
  }

  async getFiltreSelect(){
    this.getUrlDatatable(this.endPOint)
  }

  async getUrlDatatable(url,col="", refElement?: any, child?: any, searchMulti?:any){
// BEGIN
    let id_etab = this.etablissement_id;
    let id_niv = this.niveau_id;
    let id_sit = this.situation_cart_id;
    
    if(!id_etab && !id_niv && !id_sit) {
      if(this.superAdmin==1){
        this.endPOint = environment.pension_cartes+"?page=1";
      }else{
        this.etablissement_id = this.etabId
        this.filtre_etablissement_id=this.etabId
        this.endPOint = environment.pension_cartes+"?page=1&where=carte.etablissement_id|e|"+this.etabId;
      }    
  
      // this.getUrlDatatable(this.endPOint)
      // return
    }

    console.log(this.numSerieFilter,'xxxxxxx')
    let path = '';
    if(this.numSerieFilter.length == 0) path = "&where=";
    console.log(path,'pattthhhhhhhhh',this.numSerieFilter.length)
    
    
    if(id_etab) {
      console.log("test Etablissement rah mis :", id_etab)
      path +="carte.etablissement_id|e|"+id_etab+","
    }

    if(id_niv || id_niv == 0) {
    console.log("test Niveau rah mis", id_niv)
      path +="carte.niveau|e|"+id_niv+","
    }
    if(id_sit) {
      console.log("test Situation rah mis", id_sit)
      path +="carte.situation|e|"+id_sit+","
    }




    // END TEST
 
    if(refElement){
     this.searchColumn = refElement.value
     this.column = col
     this.childCol = child
    }
     if(searchMulti){
        if(this.superAdmin==1){
          if(this.filtre_etablissement_id!="")
          this.search = this.search+",etablissement.id|e|"+ this.filtre_etablissement_id ;
        }

      this.filtreBase=true

     }else{
        console.log("COL :" +col)
        console.log("Search column :" +this.searchColumn)
        if(col != "" || this.searchColumn ){
          if(this.searchColumn !="" && this.searchColumn !="undefined"){
            if(this.superAdmin==1)
            this.search = "&where="+this.column+"|l|"+this.searchColumn+"";
            else
            this.search = ","+this.column+"|l|"+this.searchColumn+"";
          }else if(this.searchColumn == "") {
            this.search = "";
          }
        }else{

          if(col != "" || this.searchColumn ){
            console.log(col,"col",this.searchCol,"search collllllllll")
            if(this.searchColumn !="" && this.searchColumn !="undefined"){
              this.searchCol = "&where="+this.childCol+"."+this.column+"|l|"+this.searchColumn+"";
            }else if(this.searchColumn == "") {
              console.log("search col vide")
              this.searchCol = "";
            }
          }else{
            console.log("xxxxxx 1111")
            if(this.searchInput !="" && this.searchInput !="undefined"){

              this.searchCol = "&where_or=carte.numero_serie|l|"+this.searchInput+",etablissement.name|l|"+this.searchInput+",carte.niveau|l|"+this.searchInput+"";

            }else if(this.searchInput == "") {
              console.log("xxxxxx 222")

              this.searchCol = "";
            }
          }
        }
  
      }

    let filtre : any= "";
    if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
      filtre = "&per_page=" + this.filtreSelect;
    }

    let triage : any= "";
    if(this.tri !="" && this.tri !="undefined"){
      triage = "&__order__="+ this.order+","+ this.tri;
    }
    // console.log("search", this.search)
    let toogle = await this.http.get<any>(url+this.search+ this.searchCol +triage + filtre + path ,valuesys.httpAuthOptions()).toPromise();
    //console.log("*****VARIABLES FILTRES***"+  this.search)
    this.carteArray = toogle.data.data;
    //console.log("*****insrcription_boursiers***", this.inscriptions)
   

    this.path = environment.pension_cartes;
    this.currentPage = toogle.data.current_page;
    this.firstPage =this.path+"?page=1";
    this.prevPage = this.path+"?page="+(this.currentPage-1) ;
    this.nextPage = this.path+"?page="+(this.currentPage+1);
    this.lastPage = this.path +"?page="+ toogle.data.last_page ;
    this.lastNumPage = toogle.data.last_page;
    this.total = toogle.data.total;


    console.log("=======>",this.search, this.searchCol ,triage ,filtre ,path)
    this.cPage_less_2 = 0
    this.cPage_less_1 = 0
    if(this.currentPage > 1 ){
      
      if(this.currentPage > 2 ){
        this.cPage_less_2 = this.currentPage - 2
        this.path_cPage_less_2 = this.path +"?page="+this.cPage_less_2;
      }
    
    this.cPage_less_1 = this.currentPage - 1 
    this.path_cPage_less_1 = this.path +"?page="+this.cPage_less_1;
    }

    
    this.cPage_more_1 = 0
    this.cPage_more_2 = 0
    if(this.currentPage < this.lastNumPage){
      this.cPage_more_1 = this.currentPage + 1 
      this.path_cPage_more_1 = this.path +"?page="+this.cPage_more_1;

      if(this.currentPage < this.lastNumPage - 1 ){
        this.cPage_more_2 = this.currentPage + 2 
        this.path_cPage_more_2 = this.path +"?page="+this.cPage_more_2;
      }

    }

    let info = this.__('datatable.info').replace('_START_', toogle.data.from)
    let info2 =info.replace('_END_', toogle.data.to)
    this.infoData = info2.replace('_TOTAL_', toogle.data.total)

  }

  async openModal(template:TemplateRef<any>,currenUser:carte =null,elem,mode:any) {
    this.tab_concernee = [];
    this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);

    this.carte_error = new carte()

    if(currenUser == null){
      this.isAdd = true ;
      this.carte = new carte() ;
      if(mode=='import')
      this.titleModal = this.__('carte.ajout_import') ;
      if(mode=='simple')
      this.titleModal = this.__('carte.ajout') ;
    }else {
      this.isAdd = false ;
      this.titleModal = this.__('carte.modifier') ;
      this.carte = currenUser ;
    } 
    //modalDialogClass: 'antonony modal-dialog', backdrop:"static" 
    this.modalService_.open(template, { size: 'xl', backdrop:"static"  });

  } 


  async toggleStateActive(carte:carte,btn:any){

    btn.disabled = true;



    Swal.fire({
      title: this.__('msg.confirm')+" de vouloir" ,
      text: "désactiver la carte n° "+carte.numero_serie+"?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: "Confirmer",
      cancelButtonText: "Annuler"

  }).then(async (result) => {

      if (result.value) {
        let response = await this.http.post<any>(environment.pension_desactiv_carte,{
          "numero_serie":carte.numero_serie
        },valuesys.httpAuthOptions()).toPromise();          
          if(response.code == 200){
            Swal.fire({
              icon: 'success',
              title: "Donnée enregistrée",
              confirmButtonColor: '#44A1A0',
              text: "Désactivation avec  succès",
            }).then((resultSuccess) => {
                if(resultSuccess)
                {
                  this.carteArray = this.carteArray.map(function(val){
                    if(val.numero_serie == carte.numero_serie) {
                      return {
                        ...val,
                        active: 0
                      }
                    }
                    return val
                  })
                }
            })  
          }else {
              this.toastr.error(response.data,this.__("msg.error"));
          }
      }
      btn.disabled = false;
  })
    
  }

  async toggleStateDesactive(carte:carte,btn:any){
    console.log(this.carteArray,"mmmmm")
    
    btn.disabled = true;

    Swal.fire({
      title: this.__('msg.confirm')+" de vouloir" ,
      text: "activer la carte n° "+carte.numero_serie+"?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: "Confirmer",
      cancelButtonText: "Annuler"

  }).then(async (result) => {

      if (result.value) {
        let response = await this.http.post<any>(environment.pension_active_carte,{
          "numero_serie":carte.numero_serie
        },valuesys.httpAuthOptions()).toPromise();         
          if(response.code == 200){
            Swal.fire({
              icon: 'success',
              title: "Donnée enregistrée",
              confirmButtonColor: '#44A1A0',
              text: "Activation avec  succès",
            }).then((resultSuccess) => {
                if(resultSuccess)
                {
                  this.carteArray = this.carteArray.map(function(val){
                    if(val.numero_serie == carte.numero_serie) {
                      return {
                        ...val,
                        active: 1
                      }
                    }
                    return val
                  })
                }
            })  
          }else {
              this.toastr.error(response.data,this.__("msg.error"));
          }
      }
      btn.disabled = false;
  })

  }

  async toggleRetirerCarte(carte:carte,btn:any){

    btn.disabled = true;
        
    let toogle = await this.http.post<any>(environment.pension_retirer_carte,{
      "numero_serie":carte.numero_serie
    },valuesys.httpAuthOptions()).toPromise();

    console.log("Activation carte :", toogle)

    if(toogle.code == 200){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      carte.state = toogle.data.state
    }else {
      this.toastr.error(toogle.data,this.__("msg.error"));
    }
    btn.disabled = false;
  }
 
 
  //--fermeture du modal
  close(){
    this.modalService_.dismissAll()
    this.isValid = false ;
    this.carte = new carte()
    this.titleModal = this.__('label_user_edit') ;
    this.carte_error = new carte();
    this.registerForm.get('etablissement_id').setValue('');    
    this.registerFormImport.get('fichier').setValue('');  
    this.carte_verif = []
    this.tabcarteshow = false
    this.disableEtabInput=false
    this.disableExcelInput=false
    this.submitted = false
  }


  delete(deletedcarte:carte,elm){
    console.log("ID", deletedcarte.numero_serie);
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.pension_cartes+"/" + deletedcarte.numero_serie,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          Swal.fire(
            {
              icon: 'success',
              title: this.__('msg.deleted'),
              confirmButtonColor: '#44A1A0',
              text: sup.msg,
            }
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          {
            icon: 'error',
            title: this.__('msg.canceled'),
            confirmButtonColor: '#44A1A0',
            text: this.__('msg.canceled_delete'),
          }
        )
      }
    })
  }

  async addtab_carte(){

    this.carte_error = new carte()

    this.isValid = true ;
    this.submitted=true

    if (this.registerForm.invalid) {
      console.log ("Invalide form control")
      return;
    }

    //console.log ("Valide form control")

    this.etab_c_id = this.carte.etablissement_id

    let niv = this.carte.niveau;
    let Cx = {"numero_serie":this.carte.numero_serie};
    
    
    this.tab_concernee.push(Cx);
    console.log("RESPONSE_XXXXXX : "+JSON.stringify(this.tab_concernee)) 

    //this.registerForm.get('numero_serie').setValue('');

    //this.registerForm.get('niveau').setValue('');

    this.submitted=false

  }

    //--Add or Update
    async addCarteForm(){
     
      console.log("carte : "+JSON.stringify(this.carte)) 

    /*   this.carte_error = new carte()
      this.isValid = true ; */
      //this.submitted=true
      
     
      //--- Add grade
      if(this.isAdd ){

        let carte_a_envoyer = {
          "etablissement_id":this.etab_c_id,
          "carte":this.tab_concernee
        }

        if(JSON.stringify(carte_a_envoyer) === '{}'){
          return;
        }

        console.log("carte test : "+JSON.stringify(carte_a_envoyer))  

        let res:any =  await this.http.post<any>(environment.pension_cartes,carte_a_envoyer,valuesys.httpAuthOptions()).toPromise();
        
          //--success
          if(res.code === 201){
            this.toastr.success(res.msg,this.__("msg.success")) ;      
            this.close()
            this.getUrlDatatable(this.endPOint)
          }else if(res.code === 400){
            for (let item in res.data){
              this.carte_error[item] = res.data[item][0] ;
            }
            this.carte_error = <carte> this.helper.errorForm(res.data);
            this.toastr.warning(res.msg,this.__("msg.warning")) ;
          }
          //--error
          else {
            let doublon = res.data.indexOf("Duplicate");
            if(doublon >-1){
              this.toastr.error(this.__("msg.error_doublon"),this.__("msg.error")) ;
            }else{ 
              this.toastr.error(res.msg,this.__("msg.error")) ;
            }
          }
      //--- Update grade
      }else {    
        
        this.carte_error = new carte()
        this.isValid = true ;
        this.submitted=true;

        if (this.registerForm.invalid) {
          return;
        }

        if(this.carte.niveau == "0"){
          this.carte.niveau = "minister";
        } else {
          this.carte.niveau = "concerne";
        }

        console.log("Update", this.carte);

          let res =  await this.http.put<any>(environment.pension_cartes + '/'+this.carte.numero_serie,this.carte,valuesys.httpAuthOptions()).toPromise();
  
          if(res.code === 201){
            
            
            this.toastr.success(res.msg,this.__("msg.success")) ;
            this.close()
            this.getUrlDatatable(this.endPOint)

          }else if(res.code === 400){
            this.carte_error = <carte> this.helper.errorForm(res.data);
            this.toastr.warning(res.msg,this.__("msg.warning")) ;
          }
          //--error
          else {
            let doublon = res.data.indexOf("Duplicate");
            if(doublon >-1){
              this.toastr.error(this.__("msg.error_doublon"),this.__("msg.error")) ;
            }else{ 
              this.toastr.error(res.msg,this.__("msg.error")) ;
            }
          }
      }
      this.isValid = false ;
    }

    async addExcelForm(){

      this.submitted = true;
  
      // stop here if form is invalid
      if (this.registerForm.invalid) {
          return;
      }
      //--add Logo
      this.carte.documents = this.documentLogo.name;

      this.carte_error = new carte()
      this.isValid = true ;
      //--- Add User
      if(this.isAdd ){
        let res:any =  await this.http.post<any>(environment.pension_cartes,this.carte,valuesys.httpAuthOptions()).toPromise();
        console.log(res);
          //--success
          if(res.code === 201){
            this.toastr.success(res.msg,this.__("msg.success")) ;      
            this.close()
            this.getUrlDatatable(this.endPOint)
          }else if(res.code === 400){
            for (let item in res.data){
              this.carte_error[item] = res.data[item][0] ;
            }
            this.carte_error = <carte> this.helper.errorForm(res.data);
            this.toastr.warning(res.msg,this.__("msg.warning")) ;
          }else{ 
            this.toastr.error(res.msg,this.__("msg.error")) ;
          }
      }
     
      this.isValid = false ;
    }

    handleInputChange(e) {

      console.log(e);
      var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      if (!files) {
        return;
      }
      for (var i = 0; i < files.length; i++) {
        if (files[i].size > 1000*1000 * 100) {
          this.toastr.error("taille image trop grande", "Error");
          $("#document").val("");
          return;
        }
        var reader = new FileReader();
        if (!files[i].type.match('application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          //files[i].name=""
          this.registerFormImport.get('fichier').setValue('');
          this.toastr.error(this.__('import_excel.text_invalid'), this.__("msg.error"));
          return;
        }
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(files[i]);
      }
    }
  
  
    _handleReaderLoaded(e) {
      let reader = e.target;
      let d :any = [];
      d.name = reader.result
      if (this.documents.length>0){
        this.documents=[]
        this.documents.push(d)
      }
      if(this.documents.length==0)
      this.documents.push(d)

      console.log("DOCUMENTS : "+this.documents[0].name)
      //this.partenaire.logo = reader.result;
    }


    async AddImportExcel(){

      this.submitted = true;
      // stop here if form is invalid
      if (this.registerFormImport.invalid) {
          return;
      }

      this.savedDocument = this.documents.filter((_) => _.id == null);

      this.etab_c_id = this.carte.etablissement_id

      let objectcarte = {
        "etablissement_id":this.etab_c_id,
        "fileXls":this.savedDocument[0].name
      };
      console.log("object", objectcarte)

      let res =  await this.http.post<any>( environment.pension_improt_excel_carte,objectcarte,valuesys.httpAuthOptions()).toPromise();
           console.log("Response after post :", res);
           //--success
           if(res.code === 201){
      
            this.tabcarteshow = true
            //  this.toastr.success(res.msg,this.__("msg.success")) ;
             this.totalparcourue = res.data.ligne_parcourue
             this.totalvalid  = res.data.total_valid
             this.totalinvalid = this.totalparcourue - this.totalvalid
            this.carte_verif = res.data.insered
            console.log("CARTE VERIF : "+ JSON.stringify(this.carte_verif))
              //this.reference_matricule_echec = res.data.reference_matricule_echec;
  
             //this.close()
           //--Warning : Donnée invalide, Session expiré
           }else if(res.code === 400){
            this.carte_error = <carte> this.helper.errorForm(res.data);
             this.toastr.warning(res.msg,this.__("msg.warning")) ;
           }
           //--error
           else {
             this.toastr.error(res.data,this.__("msg.error")) ;
          } 
  
          // this.close();
          // this.getUrlDatatable(this.endPOint)

          this.disableEtabInput=true          
          this.disableExcelInput=true
          this.submitted = false
         
      
    }
    async validerAddCarte(){
      let objectcarte = {
        "etablissement_id":this.etab_c_id,
        "fileXls":this.savedDocument[0].name
      };
      console.log("object a inserer : ", objectcarte)

      let res =  await this.http.post<any>( environment.pension_cartes+'/insertDataFile',objectcarte,valuesys.httpAuthOptions()).toPromise();

      if(res.code==201){        
        if(res.data.total_insert>0){
          this.toastr.success(res.msg+" : "+res.data.total_insert+" inserée(s)",this.__("msg.success"));
          this.close();
          this.getUrlDatatable(this.endPOint)
        }
        else{
          this.toastr.error(this.__("msg.error_verify_excel_benef"),this.__("msg.error"))
        }

      }
      else{
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }

    async reimporterExcel(){
      this.carte_verif=[]
      this.savedDocument=[]
      this.tabcarteshow=false
      this.disableEtabInput=false
      this.disableExcelInput=false
      this.registerFormImport.get('fichier').setValue('')
    }
  
    async openModalDetails(template: TemplateRef<any>, currentcarte: carte = null, elm) {
      elm.disabled = true
      this.titleModal = this.__('label_details');

      this.carte = currentcarte;
      console.log("xxex",this.carte)

      this.modalService_.open(template, { size: 'xl', backdrop:"static"  });
      elm.disabled = false
    }
  

  
    goAffectation(idCart)
    {
        this.router.navigate(['/dashboard/CAR/plateforme/affectation-carte'],{queryParams : {idCart : idCart}});
    }



    async exportCarteToExcel() {

      this.helper.exportExcel(this.printPaiement(this.carteArray),'Listes cartes').then(
        (response: any)=>{
          console.log('respons beee',response)
              let a = document.createElement("a"); 
              a.href = response.data;
              a.download = `listes_cartes.xlsx`;
              a.click(); 
        },
        (error:any)=>{
          console.log(error)
        }
      );
    }
  
    printPaiement(cartes:any[]){
      return  cartes.map((carte)=>{
        let t = {}
        t['N° de serie'] = carte.numero_serie
        t['Ministère'] = carte.name
        t['Niveau'] = carte.niveau
        t['Situation Carte'] = this.situationCarte(carte.situation);
        t['Affectation'] = carte.affected == 0 ? 'non affectée': "affectée à "+carte?.nom+" "+ carte?.prenom

        t['Status'] = carte.active == 1 ? 'Active': 'Inactive' 
        return t ;
      })  || [];
  
    }
    async exportPdf() {
      await this.helper.exportPdf(this.printPaiement(this.carteArray),'Listes cartes','','liste_cartes');
    }
    

    async addSelectForm(){
      this.numSerieFilter = '';
      this.isValid = true ;
      let id_etab = this.etablissement_id;
      let id_niv = this.niveau_id;
      let id_sit = this.situation_cart_id;
      
      if(!id_etab && !id_niv && !id_sit) {
        console.log('!id_etab && !id_cat && id_sit != ""')
        if(this.superAdmin==1){
          this.endPOint = environment.pension_cartes+"?page=1";
        }else{
          this.etablissement_id = this.etabId
          this.filtre_etablissement_id=this.etabId
          this.endPOint = environment.pension_cartes+"?page=1&where=carte.etablissement_id|e|"+this.etabId;
        }    
    
        this.getUrlDatatable(this.endPOint)
        return
      }
  
      let path = "";
      
      if(id_etab) {
        console.log("test Etablissement rah mis :", id_etab)
        path +="carte.etablissement_id|e|"+id_etab+","
      }

      if(id_niv || id_niv == 0) {
      console.log("test Niveau rah mis", id_niv)
        path +="carte.niveau|e|"+id_niv+","
      }
      if(id_sit) {
        console.log("test Situation rah mis", id_sit)
        path +="carte.situation|e|"+id_sit+","
      }

      this.endPOint = environment.pension_cartes+"?page=1&where="+path
      this.getUrlDatatable(this.endPOint)
      this.submitted = true;
  
    }
  
    async downloadCanva(element: any) {
      this.http.get<any>(environment.base_url_crud+'carte/CanvaExportCarte').subscribe(
          response => {
              this.sourcePath = response;
              setTimeout(() => {
                  element.click()
              }, 1000);  
          },
          (error) => {
              this.toastr.error(error.message, this.__('msg.error'));

          }
      )
      
  }   
  situationCarte(situation: number): string {
    switch (situation) {
      case 0:
        return this.__('carte.situation_0');
      case 1:
        return this.__('carte.situation_1');
      case 2:
        return this.__('carte.situation_2');
      case 3:
        return this.__('carte.situation_3');
      case 4:
          return this.__('carte.situation_4');
      default:
        break;
    }
  }
    
}


