import { Inject, Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {DataTablesResponse} from "../model/DataTablesResponse";
import * as $ from "jquery";
import {Translatable} from "./Translatable";
import {environment} from "../../../environments/environment";
import {valuesys} from "../../../options";
import { DOCUMENT } from "@angular/common";

import axios from 'axios';

declare var window

@Injectable({
  providedIn: 'root'
})
export class HelperService extends Translatable{

  constructor(private http: HttpClient,@Inject(DOCUMENT) private document: Document) {
    super();
  }

  private lang_ = Translatable.getLang();
  window = this.document.defaultView;
  public  dataTableOption(){
    return {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [[10, 20, 50,100,200,300,500, -1], ["10", "20", "50","100","200","300","500", "Tout"]],
      serverSide: true,
      processing: true,
      responsive: true,
      language:{
        url : '../../../assets/i18n/'+this.lang_+'/datatable.json'
      },
      character: 'utf-8',
      searchDelay:3000,
      search:{
        caseInsensitive: true,

     },
      column : {

      },

      /*"columnDefs": [ 
        {
          "targets": [ 0 ],
          "visible": false,
          "searchable": false
        },
      ],*/

   
          initComplete: function () {
        this.api().columns().every(function () {
          var column = this;
          var input = document.createElement("input");
           $(input).addClass('inputSecahProcess');
          // console.log("AAAA",))
           let col = $(column.header())
         // console.log("AAAA",col)
          console.log("AAAA",JSON.stringify(col.html().indexOf('inputSecahProcess')))
          if(JSON.stringify(col.html()).indexOf('inputSecahProcess') == -1){
            $(input).appendTo($(column.header()))
              .on('keyup change', function (e) {
                if (e.keyCode == 13 ) {
                  column.search($(this).val(), false, true, true).draw();
                }
                
              })
              .on('click', function (e) {
                e.stopPropagation();
              });
          }else {
            col.find('input')
            .on('keyup change', function (e) {
              if (e.keyCode == 13) {
                column.search($(this).val(), false, true, true).draw();
              }
              
            })
              .on('click', function (e) {
                e.stopPropagation();
              })
          }
        });
      }
    }
  }
  public  dataTableOptionOffline(){
    return {
      pagingType: 'full_numbers',
      pageLength: 10,
      language:{
        url : 'assets/i18n/'+this.lang_+'/datatable.json'
      }
    }
  }
  public ajax (datae:any){
    let ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          'http://localhost/api/person?'+$.param(dataTablesParameters), {}
        ).subscribe(resp => {
        datae = resp.data;
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data: []
        });
      });
    };
    return ajax ;
  }

  downloadPdf(source, fileName) {
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }

 async exportPdf(entities: Array<Object>,title:string,url='',filename?: string,isCustomTemplate?: boolean, nb_bnf?: string,mgp?: string,mge?: string) {
   
    try {
      title = title  ||  "Liste";
      let res = await this.http.post(url || environment.exportPdf , {
        data: entities,
        'title' : title,
        filename: filename,
        display_or:isCustomTemplate,
        nb_bnf,
        mgp,
        mge,
      }, valuesys.httpAuthOptions()).toPromise();
    
      this.downloadPdf(res['data'],filename);

      //window['download'](res['data'], title+ '.pdf', 'application/pdf');

      

      return res
      console.log('respdf')
    } catch (e) {
      console.log(e)
    }
  }
  async exportExcel(entities: Array<Object>,title:string,url='') {
    try {
      title = title  ||  "Liste";
      let res:any = await this.http.post(url || environment.exportExcel , {data: entities,'title' : title}, valuesys.httpAuthOptions()).toPromise();
      return res;
    } catch (e) {
      console.log(e)
    }
  }

  errorForm(data) : Object{
    let msg = ''
    let model = new Object();
    for (let item in data){
      msg += data[item][0] + "\n";
      model[item] = data[item][0] ;
    }
    return model ;
  }

  public async getSelectList(url:string,text: string[] | string,id:string="id"){
    let res:any = await this.http.get<any>(url,valuesys.httpAuthOptions()).toPromise();
    let data  = res.data ;
    let options=[]

    let data_ = data.map(function (item,index) {
      let option =item
      if(typeof text === typeof []){
        let txt =''
        for (var i = 0; i < text.length; i++){
          txt += item[text[i]] + " ";
        }
        option.text =txt;
        option.id =item[id];
      }else {
        // @ts-ignore
       // option = {text:item[text],id: item[id]};
        option.text =item[text];
        option.id =item[id];
      }

      options.push(option);
    });
    return options ; 
  }

  public  encodeParams(paramsQuery_:string){
    return encodeURIComponent(paramsQuery_)
  }

  /**
   * download file
   */
  dowloadFile(fileLink,fileName,fileExtension){
    axios({
      url: fileLink, //your url
      method: 'GET',
      responseType: 'blob', // important
  }).then((response:any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = window.document.createElement('a');
      link.href = url;
      const file = `${fileName}.${fileExtension}`
      link.setAttribute('download', file); //or any other extension
      window.document.body.appendChild(link);
      link.click();
  });

  }

  async guard() {
  }
  createLow(lows:any[]) {
  }






}
