import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import { environment } from '../../../../../../../environments/environment';
import { valuesys } from '../../../../../../../options';
import { etablissement, parcours, province } from '../../../../../model/db';
import { categorie, concerner } from '../../../../../model/pension';
import { AuthServiceService } from '../../../../../service/auth-service.service';
import { HelperService } from '../../../../../service/helper.service';
import { Translatable } from '../../../../../service/Translatable';

@Component({
  selector: 'app-creation-compte',
  templateUrl: './creation-compte.component.html',
  styleUrls: ['./creation-compte.component.scss']
})
export class CreationCompteComponent extends Translatable implements OnInit  {



  etablissement_id : number;
  categorie_concernees_id: number;
  f_matricule : any;

  title:string ;
  public concernerArray :parcours[] = [] ;
  public concernerSuccess :parcours[] = [] ;
  public concernerEchec :parcours[] = [] ;


  etabId:any=''
  endPOint:any=""

  filtre_etablissement_id:any='';
  tabBeneficiaireCheck : any = [];

  filtreBase:boolean=false
  filtreBaseParametres:any=''
  superAdmin:any=""
  search:any=""
  filtreSelect:any= 10;
  tri : any= "";
  order : any= "";
  child : any= "";


  public searchColumn: string;
  public column: string;
  public childCol: string;
  public paginateListe : any = [];
  public nextPage : any = [];
  public prevPage : any = [];
  public lastPage : any = [];
  public firstPage : any = [];
  public currentPage : any = [];
  public total : any = [];
  public path : any = [];
  public lastNumPage : any = [];
  public infoData : any = [];
  public cPage_less_2 : number;
  public path_cPage_less_2 : any;
  public cPage_less_1 : number;
  public path_cPage_less_1 : any;
  public cPage_more_1 : number;
  public path_cPage_more_1 : any;
  public cPage_more_2 : number;  
  public path_cPage_more_2 : any;



  public concerners :concerner[] = [] ;
  public concerner:concerner = new concerner();
  public concerner_error : concerner ;
  public c_envoye:any = {}
  public etab_c_id:any 
  public cat_c_id:any 
  public beneficiaire_verif:any[] = []


  etab_id : number;
  cat_id: number;

  searchInput : any= "";

  searchCol : any= "";

  public tab_concernee: any [] = [];

  endPOintFiltre:any = ''

  /*
    FORM VALIDATOR
  */
  registerForm : FormGroup;
  submitted    = false;
  activeEtabId: number;
  public etablissements:etablissement [] = [];
  public provinces: province [] = [];
  public categories: categorie [] = [];
  public isCheckedElmBeneficiaire : boolean;

 
  constructor(
            private auth : AuthServiceService,  
            private toastr: ToastrService,         
            private helper: HelperService,
            private formBuilder: FormBuilder,
            private http: HttpClient) { 
    super()
  }

  

  // GET REGISTER FORM VALIDATE
  get f() { return this.registerForm.controls; }

  async ngOnInit() {
    this.auth.initAutority('CRC');


    this.registerForm = this.formBuilder.group({
      etablissement_id:            ['', Validators.required],
      categorie_id:          ['', Validators.required],
      matricule:          [''],   
    });






    this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);
    this.categories = await this.helper.getSelectList(environment.pension_categories,['name']);

    let userinfo = this.auth.account()
    console.log("XXXXXXXX   XXXXXXX", userinfo);

    this.etabId  = userinfo.info.etablissement_id; 
    this.activeEtabId  = userinfo.info.etablissement_id; 
    this.superAdmin = userinfo.info.admin; 

    if(this.superAdmin==1){
      this.endPOint = environment.pension_concerners+"/getConcerneNoCompte?page=1";
    }else{
      this.etablissement_id = this.etabId
      this.filtre_etablissement_id=this.etabId
      this.endPOint = environment.pension_concerners+"/getConcerneNoCompte?page=1&where=concernees.etablissement_id|e|"+this.etabId;
      let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+this.etabId, valuesys.httpAuthOptions()).toPromise();
      if(res.code == 200) {
          this.categories = res.data
      }
    }    

    //this.getUrlDatatable(this.endPOint)
    
    
  }


  async getSearchInput(){
    this.getUrlDatatable(this.endPOint)
  }

  async getFiltreSelect(){
    this.getUrlDatatable(this.endPOint)
  }

  async getUrlDatatable(url,col="", refElement?: any, child?: any, searchMulti?:any){
 
    if(refElement){
     this.searchColumn = refElement.value
     this.column = col
     this.childCol = child
    }
     if(searchMulti){
        if(this.superAdmin==1){
          //this.search = "&where=type_etablissement.id|e|"+ this.filtre_type_etablissement_id ;
          if(this.filtre_etablissement_id!="")
          this.search = this.search+",etablissement.id|e|"+ this.filtre_etablissement_id ;
        }

      this.filtreBase=true

     }else{
        console.log("COL :" +col)
        console.log("Search column :" +this.searchColumn)
        if(col != "" || this.searchColumn ){
          if(col != "" && col !="undefined"){
            this.search = ""
          }
          else if(this.searchColumn !="" && this.searchColumn !="undefined"){
            if(this.superAdmin==1)
            this.search = "&where="+this.column+"|l|"+this.searchColumn;
            else
            this.search = ","+this.column+"|l|"+this.searchColumn;
          }else if(this.searchColumn == "") {
            this.search = "";
          }
          
        }else{

          if(col != "" || this.searchColumn ){
            if(this.searchColumn !="" && this.searchColumn !="undefined"){
              this.searchCol = "&where="+this.childCol+"."+this.column+"|l|"+this.searchColumn+"";
            }else if(this.searchColumn == "") {
              this.searchCol = "";
            }
          }else{
            if(this.searchInput !="" && this.searchInput !="undefined"){
              this.searchCol = "&where_or=concernees.matricule |l|"+this.searchInput+",concernees.nom|l|"+this.searchInput+",concernees.prenom|l|"+this.searchInput+",categorie_concernees.name|l|"+this.searchInput+"";
            }else if(this.searchInput == "") {
              this.searchCol = "";
            }
          }
        }
  
      }

    let filtre : any= "";
    if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
      filtre = "&per_page=" + this.filtreSelect;
    }

    let triage : any= "";
    if(this.tri !="" && this.tri !="undefined"){
      triage = "&__order__="+ this.order+","+ this.tri;
    } 
    let toogle = await this.http.get<any>(url+this.search+ this.searchCol +triage + filtre + col,valuesys.httpAuthOptions()).toPromise();
    this.concernerArray = toogle.data.data;
   

    this.path = this.endPOintFiltre;
    this.currentPage = toogle.data.current_page;
    this.firstPage =this.path+"&page=1";
    this.prevPage = this.path+"&page="+(this.currentPage-1) ;
    this.nextPage = this.path+"&page="+(this.currentPage+1);
    this.lastPage = this.path +"&page="+ toogle.data.last_page ;
    this.lastNumPage = toogle.data.last_page;
    this.total = toogle.data.total;


    console.log("*****VARIABLES End point ***"+  this.firstPage)
    

    this.cPage_less_2 = 0
    this.cPage_less_1 = 0
    if(this.currentPage > 1 ){
      
      if(this.currentPage > 2 ){
        this.cPage_less_2 = this.currentPage - 2
        this.path_cPage_less_2 = this.path +"&page="+this.cPage_less_2;
      }
    
    this.cPage_less_1 = this.currentPage - 1 
    this.path_cPage_less_1 = this.path +"&page="+this.cPage_less_1;
    }

    
    this.cPage_more_1 = 0
    this.cPage_more_2 = 0
    if(this.currentPage < this.lastNumPage){
      this.cPage_more_1 = this.currentPage + 1 
      this.path_cPage_more_1 = this.path +"&page="+this.cPage_more_1;

      if(this.currentPage < this.lastNumPage - 1 ){
        this.cPage_more_2 = this.currentPage + 2 
        this.path_cPage_more_2 = this.path +"&page="+this.cPage_more_2;
      }

    }

    let info = this.__('datatable.info').replace('_START_', toogle.data.from)
    let info2 =info.replace('_END_', toogle.data.to)
    this.infoData = info2.replace('_TOTAL_', toogle.data.total)

  }


  async cocherBeneficiaire(currentBeneficiaire:any, elm) {
    console.log("etudiant", currentBeneficiaire.id);

     if(elm.checked ==true){
        let obejtsData = {
          "concerne_id":currentBeneficiaire.id,
          "etablissement_id":currentBeneficiaire.etablissement_id
        }
        this.tabBeneficiaireCheck.push(obejtsData);

       //this.tabBeneficiaireCheck.push(currentBeneficiaire.id);
     }else{
       this.tabBeneficiaireCheck = this.tabBeneficiaireCheck.filter(e => e.concerne_id !== currentBeneficiaire.id); 
     }
     console.log("liste béneficiaire cocher", this.tabBeneficiaireCheck);
   }

   async cocherToutBeneficiaire(currentBeneficiaire:any = null, elm){
    console.log(currentBeneficiaire,"xxxxxxxxxx", currentBeneficiaire.length)
      if(elm.checked ==true){
        this.isCheckedElmBeneficiaire = true;
        for (let i = 0; i < currentBeneficiaire.length; i++) {
          var a = this.tabBeneficiaireCheck.indexOf(currentBeneficiaire[i].id);
          if(a == -1 ){
            let obejtsData = {
              "concerne_id":currentBeneficiaire[i].id,
              "etablissement_id":currentBeneficiaire[i].etablissement_id
            }
            this.tabBeneficiaireCheck.push(obejtsData);
          }else console.log("xxxxxxx doublant xxxxxx", currentBeneficiaire[i].id)
        }
      }else{
          this.isCheckedElmBeneficiaire = false;
          for (let i = 0; i < currentBeneficiaire.length; i++) {
            this.tabBeneficiaireCheck = this.tabBeneficiaireCheck.filter(e => e.concerne_id !== currentBeneficiaire[i].id);
        }
      }
    console.log("liste tous les béneficiaire cocher", this.tabBeneficiaireCheck);

  }


  filtreBeneficiaire(){

    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }


      let filtre_matricule = '';
    if(this.f_matricule != '' && this.f_matricule != undefined){
      filtre_matricule = ",concernees.matricule|e|" + this.f_matricule ;
    } 

    this.endPOintFiltre = environment.pension_concerners+"/getConcerneNoCompte?where=concernees.categorie_concerne_id|e|"+ this.categorie_concernees_id  + filtre_matricule + ",concernees.etablissement_id|e|"+this.etablissement_id;
    
    this.getUrlDatatable(this.endPOintFiltre,"&page=1")
    
  }


  //--Add or Update
  async createCompteBeneficiaire(){

    Swal.fire({
      title: this.__('msg.confirm'),
      text: "Voulez vous créer des comptes pour ces bénéficiaires sélectionnées? ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: this.__('msg.confirmed_valid_valider'),
      cancelButtonText: this.__('msg.cancel_valid_valider')
    }).then(async (result) => {
      if (result.value) {

          let objectConcerner = {
            "demandes":this.tabBeneficiaireCheck,
          };

          let res:any =  await this.http.post<any>(environment.pension_cartes + "/planifier_creation_compte",objectConcerner,valuesys.httpAuthOptions()).toPromise();
          //--success
          if(res.code === 201){
            if(res.error == false){
              this.toastr.success(res.msg,this.__("msg.success")) ;
            }

            this.concernerSuccess = res.data;
            this.concernerEchec = [];
          //--Warning : Donnée invalide, Session expiré
          } else {
            this.toastr.error(res.msg,this.__("msg.error")) ;
          }

      }
    })

   

  
  }

  annuler(){
    this.concernerEchec = [];
    this.concernerSuccess = [];
    this.tabBeneficiaireCheck = [];
    this.getUrlDatatable(this.endPOint);
  }
  async getCategorieByIdEtab(id,element) {
    this.categories = [];
    element.clearModel();
    let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+id, valuesys.httpAuthOptions()).toPromise();
    if(res.code == 200) {
        this.categories = res.data;
        // if(this.categories.length == 0) element.clearModel();
    }
}
}
