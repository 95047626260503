import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(){

      let urlReturn = window.localStorage.getItem('return')
      localStorage.clear();
      window.localStorage.setItem('return',urlReturn);
      return   this.route.navigate(['/login']);
  }

}
