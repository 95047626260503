import { Component, OnInit } from '@angular/core';
import { Translatable } from '../../../../../service/Translatable';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.scss']
})
export class CashFlowComponent extends Translatable implements OnInit {

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
