import { Component, OnInit, TemplateRef, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import {AuthServiceService} from "../../../../../service/auth-service.service";
import {HttpClient} from "@angular/common/http";
import { Translatable } from '../../../../../service/Translatable';
import {ordre_paiement,documentPj, detail_ordre_paiement,nb_etudiant_par_niveau, bourse_par_code, bourse_par_nb_etudiant, total_bourse_nb_mois_avec_prime, total_bourse_par_niveau,total_bourse_avec_prime, paginate, etablissement, objetTest, niveau, mention, grade, domaine, type_etablissement, etudiant, Postdocument} from "../../../../../model/db";

import {environment} from "../../../../../../../environments/environment";
import {DataTablesResponse, HttpApiResponse} from "../../../../../model/DataTablesResponse";
import {valuesys} from "../../../../../../../options";
import {HelperService} from "../../../../../service/helper.service";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import formatNumber from 'number-handler'
import { DomSanitizer } from '@angular/platform-browser';


declare var require: any;

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { categorie } from '../../../../../model/pension';
import { constante } from '../../../../../service/constante';
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

 @Component({
  selector: 'app-ordre-paiement',
  templateUrl: './ordre-paiement.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./ordre-paiement.component.scss']
})
export class OrdrePaiementComponent extends Translatable implements OnInit {

  @ViewChild('pdfTable') pdf: ElementRef<HTMLDivElement>;

  titleModal:string ;
  isAdd : boolean = true ;
  isListTrue: boolean = false;
  isValid : boolean = false ;
  endPointSearchEtudiant:string ;
  stepperEtape1:boolean=true
  stepperEtape2:boolean=false
  stepperEtape3:boolean=false
  documentsPJ: documentPj [] = [];
  public Postdocument:Postdocument = new Postdocument();


  public etudiants_boursier :etudiant[] = [] ;
  public etudiants_checked_boursier :etudiant[] = [] ;
  public etudiants_boursier_in_data :etudiant[] = [] ;
  public ordre_paiements :ordre_paiement[] = [] ;
  public objetTest :objetTest = new objetTest() ;
  public paginate :paginate = new paginate() ;
  public paginateChecked :paginate = new paginate() ;
  public detail_ordre_paiements :detail_ordre_paiement[] = [] ;
  public ordre_paiement:ordre_paiement = new ordre_paiement();
  public ordre_paiement_error : ordre_paiement ;
  public etablissements:etablissement [] = [];
  public type_etablissements:type_etablissement [] = [];
  public niveaux:niveau [] = [];



  public ligne_parcourues: string = '';
  public ligne_traitees: string = '';
  public ligne_echouees: string = '';

  public etab_c_id:any 
  public categorie_concerne_id: any
  documents: any [] = [];
  savedDocument: any [] = [];

  public categories: categorie [] = [];
  
  public elmInput: any;

  f_date_debut : any= "";
  f_date_fin : any= "";
  etabId:any ;
  userId:any ;
  documentLogo: any = [];
  public module_code = window['module'];
  public superAdmin : number ;
  registerForm : FormGroup;
  submitted    = false;
  searchInput : any= "";
  textSwal : any= "";
  filtreSelect : any= 10;
  e_filtreSelect : any= 10;
  detail_e_filtreSelect : any= 10;

  e_searchInput : any= "";
  filtre_etablissement_id : any = "";

  search_nom : any;
  search_prenom : any;
  search_matricule : any;
  public tabEtudiantCheck : any = [];
  public tabAddEtudiantCheck : any = [];
  public tabAddEtudiant : any = [];
  public tabDelEtudiantCheck : any = [];
  totalDateinfullPage : any;
  where : any= "";
  whereInscrit : any= "";
  tabExportEtud : any= [];
  search : any= "";
  child : any= "";
  annee_scolaire_encour : any = "";
  tri : any= "numero_ordre";  
  order : any= "desc";
  endPOint : any= "";
  public searchColumn: string;
  public column: string;
  public childCol: string;
  public isCheckedElmEtudiant : boolean;
  childWhere : any ="&where=1|e|1";
  totalBourse : any;
  filtreStatus: any = 'tout';
  nbMois : number = 10;

  //--paginate
   public paginateListe : any = [];
   public nextPage : any = [];
   public prevPage : any = [];
   public lastPage : any = [];
   public firstPage : any = [];
   public currentPage : any = [];
   public total : any = [];
   public path : any = [];
   public lastNumPage : any = [];
   public infoData : any = [];
   public cPage_less_2 : number;
   public path_cPage_less_2 : any;
   public cPage_less_1 : number;
   public path_cPage_less_1 : any;
   public cPage_more_1 : number;
   public path_cPage_more_1 : any;
   public cPage_more_2 : number;  
   public path_cPage_more_2 : any;
  //--end-paginate
   

  e_where : any= "";
  e_tabExportEtud : any= [];
  e_search : any= "";
  e_child : any= "";
  e_annee_scolaire_encour : any = "";
  e_tri : any= "";
  e_order : any= "";
  e_endPOint : any= "";
  public e_searchColumn: string;
  public e_column: string;
  public e_childCol: string;
  cocheTout : boolean = false
  selectedAll: any;
  filtreBase:boolean=false
  formatNumber : any = formatNumber;
  detail_searchInput : any = '';
  detail_search : any;
  e_detail_searchInput : any = '';
  e_detail_search : any;
  totalBourse1mois :number =  0;
  totalBourseNbmois :number =  0;
  endPointGetInfoDetail = "/getInformationDetail/";
  endPointGetInfoDetailByConcerneArray = "/getInformationDetailByConcerneArray"
  endPointGetInfoDetailByEtudiantArray = "/getInformationDetailByEtudiantArray"
  montantGlobal : any
  sourcePath = ""

   //--paginate
   public e_paginateListe : any = [];
   public e_nextPage : any = [];
   public e_prevPage : any = [];
   public e_lastPage : any = [];
   public e_firstPage : any = [];
   public e_currentPage : any = [];
   public e_total : any = [];
   public e_path : any = [];
   public e_lastNumPage : any = [];
   public e_infoData : any = [];
   public e_cPage_less_2 : number;
   public e_path_cPage_less_2 : any;
   public e_cPage_less_1 : number;
   public e_path_cPage_less_1 : any;
   public e_cPage_more_1 : number;
   public e_path_cPage_more_1 : any;
   public e_cPage_more_2 : number;  
   public e_path_cPage_more_2 : any;
   tabAllData: any[];
   listMount = [];
   show_equipement: boolean = false;
   type_etablissement_etab_id: number ;
   tabMois: any;
   endpointListBenef: string;
   searchCol: string;
   filtreBaseOP: boolean = false;
   activeEtabId: number;
  //--end-paginate
   

  public nb_etudiant_par_niveau:nb_etudiant_par_niveau = new nb_etudiant_par_niveau();
  public bourse_par_code:bourse_par_code = new bourse_par_code();
  public bourse_par_nb_etudiant:bourse_par_nb_etudiant = new bourse_par_nb_etudiant();
  public total_bourse_par_niveau:total_bourse_par_niveau = new total_bourse_par_niveau();
  public total_bourse_avec_prime:total_bourse_avec_prime = new total_bourse_avec_prime();
  public total_bourse_nb_mois_avec_prime:total_bourse_nb_mois_avec_prime = new total_bourse_nb_mois_avec_prime();
  countResume  : number = 0;
   importListOP: boolean = false;


  constructor( 
    private modalService: NgbModal,   
    private auth : AuthServiceService,  
    private toastr: ToastrService,          
    private helper: HelperService,
    private http: HttpClient, 
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder) {    
    super()
  }

  get f() { return this.registerForm.controls; }

  
   
  public downloadAsPDF() {
    const elementPdf= document.getElementById('pdfTable');
    console.log(elementPdf);
     const pdfTable = elementPdf;
     var html = htmlToPdfmake(pdfTable.innerHTML, { pageSize: 'A3', pageOrientation: 'landscape' });
     const documentDefinition = { content: html, pageSize: 'A3', pageOrientation: 'landscape', 
     defaultStyle: {
        fontSize: 11,
      } };
     pdfMake.createPdf(documentDefinition).download(); 
     
  }

  async ngOnInit() {

    this.registerForm = this.formBuilder.group({
      date_debut_paiement:              ['', Validators.required],
      date_fin_paiement:             ['', Validators.required],
      libelle:             ['', Validators.required],
      mois  :['', Validators.required],
      nombre_mois : ['', Validators.required],
      equipement : [''],
      etat_equipement : [''],
      etablissement_id:             ['', Validators.required],
      categorie_concernees_id: ['', Validators.required],
      id_ordre_ministere: ['', Validators.required],
      niveau_id: [''],
      
  }); 

    this.auth.initAutority("ORP");
    let userinfo = this.auth.account()
    console.log("xxxx", userinfo)
    this.etabId  = userinfo.info.etablissement_id;    
    this.activeEtabId  = userinfo.info.etablissement_id;    
    this.superAdmin = userinfo.info.admin;
    this.userId = userinfo.info.id;
    this.listMount = await this.getYearCurrent(new Date().getFullYear());
    this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);

    let test = this.etablissements.filter(_=>_.id == this.etabId);
    this.type_etablissement_etab_id = test[0].type_etablissement
    this.where = "&where=1|e|1";
    if(this.superAdmin == 0 && this.etabId != 1){
      this.filtre_etablissement_id = this.etabId;
      this.where = "&where=,etablissement.id|e|"+ this.filtre_etablissement_id;
    } else if (this.etabId == 1) {
      this.filtre_etablissement_id = this.etabId;
    }
    

    this.endPOint = environment.ordre_paiement+"?page=1";    

    this.getUrlDatatable(this.endPOint)
    }

    async getCreerFunction(id:number){
      let ordrePaiement_id = id;
      Swal.fire({
        title: this.__('msg.confirm'),
        text: this.__('msg.warning_valide_brouille'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#44A1A0',
        cancelButtonColor: '#FF7467',
        reverseButtons : true,
        confirmButtonText: this.__('msg.confirmed_valid_brouillon'),
        cancelButtonText: this.__('msg.cancel_valid_brouillon')
      }).then(async (result) => {
        if (result.value) {
           let res = await this.http.put<any>(environment.pension_val_creer+"/"+ordrePaiement_id+"/creation","", valuesys.httpAuthOptions()).toPromise();
          if(res.code == 201){
            Swal.fire(
              {
                icon: 'success',
                title: this.__('msg.valid_brouillon'),
                confirmButtonColor: '#44A1A0',
                text: res.msg,
              }
            ) 

          this.close()
          this.getUrlDatatable(this.endPOint)
          }else {
            this.toastr.error(res.msg,this.__("msg.error"));
          }
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            {
              icon: 'error',
              title: this.__('msg.cancel_valid'),
              confirmButtonColor: '#44A1A0',
              text: this.__('msg.valid_brouillon_annuler'),
            }
          )
          this.close()
          this.getUrlDatatable(this.endPOint)
        }
      })

    }

    async getValRejFunction(id: number){
      let ordrePaiement_id = id;

      Swal.fire({
        title: this.__('msg.confirm'),
        text: this.__('msg.warning_valide_rejet'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#44A1A0',
        cancelButtonColor: '#FF7467',
        reverseButtons : true,
        confirmButtonText: this.__('msg.confirmed_valid_rejet'),
        cancelButtonText: this.__('msg.cancel_valid_rejet')
      }).then(async (result) => {
        if (result.value) {
          let res = await this.http.put<any>(environment.pension_val_rejete+"/"+ordrePaiement_id+"/valRej",{"statut": 2}, valuesys.httpAuthOptions()).toPromise();
          // this.rerender();
          if(res.code == 201){
            Swal.fire(
              {
                icon: 'success',
                title: this.__('msg.valid_rejet'),
                confirmButtonColor: '#44A1A0',
                text: res.msg,
              }
            )

          this.close()
          this.getUrlDatatable(this.endPOint)
          }else {
            this.toastr.error(res.msg,this.__("msg.error"));
          }
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            {
              icon: 'error',
              title: this.__('msg.cancel_rejet'),
              confirmButtonColor: '#44A1A0',
              text: this.__('msg.valid_rejet_annuler'),
            }
          )
          this.close()
          this.getUrlDatatable(this.endPOint)
        }
      })

    }

    async getValiderFunction(id: number){
      let ordrePaiement_id = id;

      Swal.fire({
        title: this.__('msg.confirm'),
        text: this.__('msg.warning_valide_valider'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#44A1A0',
        cancelButtonColor: '#FF7467',
        reverseButtons : true,
        confirmButtonText: this.__('msg.confirmed_valid_valider'),
        cancelButtonText: this.__('msg.cancel_valid_valider')
      }).then(async (result) => {
        if (result.value) {
          let res = await this.http.put<any>(environment.pension_val_rejete+"/"+ordrePaiement_id+"/valRej",{"statut": 1}, valuesys.httpAuthOptions()).toPromise();
          // this.rerender();
          if(res.code == 201){
            Swal.fire(
              {
                icon: 'success',
                title: this.__('msg.valid_valider'),
                confirmButtonColor: '#44A1A0',
                text: res.msg,
              }
            )

          this.close()
          this.getUrlDatatable(this.endPOint)
          }else {
            this.toastr.error(res.msg,this.__("msg.error"));
          }
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            {
              icon: 'error',
              title: this.__('msg.cancel_valider'),
              confirmButtonColor: '#44A1A0',
              text: this.__('msg.valid_valider_annuler'),
            }
          )
          this.close()
          this.getUrlDatatable(this.endPOint)
        }
      })

    }


  async getSearchInput(){ this.getUrlDatatable(this.endPOint) }

  async getFiltreSelect(){ this.getUrlDatatable(this.endPOint) }

  async getSearch(){ this.getUrlDatatable(this.endPOint) }

  async triTable(col, order){
    this.tri = col; this.order = order;
    this.getUrlDatatable(this.endPOint)
  }

  async e_getSearchInput(){ this.getListDatatableBeneficiary(this.endPointSearchEtudiant+"?page=1") }

  async e_getFiltreSelect(){ 
    let url = "";

    let whereNiveau = "";
    if(this.ordre_paiement.niveau_id){
      whereNiveau = ",bourse_etudiant.niveau_id|e|"+this.ordre_paiement.niveau_id
    }

    if(this.type_etablissement_etab_id == 1){
      url = environment.etudiant_ready_for_bill+"?page=1&where=,concernees.categorie_concerne_id|e|"+this.ordre_paiement.categorie_concernees_id + ",concernees.etablissement_id|e|"+this.ordre_paiement.etablissement_id + whereNiveau;
    }else{
      url = environment.concerne_ready_for_bill+"?page=1&where=,concernees.categorie_concerne_id|e|"+this.ordre_paiement.categorie_concernees_id + ",concernees.etablissement_id|e|"+this.ordre_paiement.etablissement_id;
    }
    this.getListDatatableBeneficiary(url) 
  }


  async getUrlDatatable(url,col = "", refElement?: any, child?: any, searchMulti?:any){



        if(refElement){
          this.searchColumn = refElement.value
          this.column = col
          this.childCol = child
        }

        if(searchMulti){
          let filtre_etab = "";
          if(this.filtre_etablissement_id !="" && this.superAdmin == 1){
            filtre_etab = ",etablissement.id|e|"+ this.filtre_etablissement_id ;
          }
            this.search =  filtre_etab;

        
          this.filtreBaseOP=true
        } 
        else {
          if(col != "" || this.searchColumn ){
            if(this.searchColumn !="" && this.searchColumn !="undefined"){
              this.searchCol = ","+this.column+"|l|"+this.searchColumn;
            }else if(this.searchColumn == "") {
              this.searchCol = "";
            }
          } else{
            if(this.searchInput !="" && this.searchInput !="undefined"){
              this.searchCol = "&where_or=ordre_paiement.numero_ordre|l|%"+this.searchInput+ "%,etablissement.name|l|%"+this.searchInput+"%,ordre_paiement.libelle|l|%"+this.searchInput+"%,ordre_paiement.montant_global|l|%"+this.searchInput+"%,ordre_paiement.nombre_concernees|l|%"+this.searchInput+"%,categorie_concernees.name|l|%"+this.searchInput+"%,ordre_paiement.date_debut_paiement|l|%"+this.searchInput+"%"
            }else if(this.searchInput == "") {
              this.searchCol = "";
            }
          } 

         
    }

    let filtre : any= "";
    if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
      filtre = "&per_page=" + this.filtreSelect;
    }

    let triage : any= "";
    if(this.tri !="" && this.tri !="undefined"){
      triage = "&__order__="+ this.order+","+ this.tri;
    }

  
    let toogle = await this.http.get<any>(url + this.where + this.search + this.searchCol  + filtre + triage,valuesys.httpAuthOptions()).toPromise();
   
    this.ordre_paiements = toogle.data.data;
    console.log("url", this.ordre_paiements)
    this.lastPage = this.path +"?page="+ toogle.data.last_page ;

    this.path =   environment.ordre_paiement;
    this.currentPage = toogle.data.current_page;

    this.firstPage =this.path+"?page=1";
    this.prevPage = this.path+"?page="+(this.currentPage-1) ;
    this.nextPage = this.path+"?page="+(this.currentPage+1);
    if(this.lastPage == 0){
      this.currentPage = 0;
    }

    this.lastNumPage = toogle.data.last_page;

    this.total = toogle.data.total;

    this.cPage_less_2 = 0
    this.cPage_less_1 = 0
    if(this.currentPage > 1 ){
      
      if(this.currentPage > 2 ){
        this.cPage_less_2 = this.currentPage - 2
        this.path_cPage_less_2 = this.path +"?page="+this.cPage_less_2;
      }
    
    this.cPage_less_1 = this.currentPage - 1 
    this.path_cPage_less_1 = this.path +"?page="+this.cPage_less_1;
    }

    
    this.cPage_more_1 = 0
    this.cPage_more_2 = 0
    if(this.currentPage < this.lastNumPage){
      this.cPage_more_1 = this.currentPage + 1 
      this.path_cPage_more_1 = this.path +"?page="+this.cPage_more_1;

      if(this.currentPage < this.lastNumPage - 1 ){
        this.cPage_more_2 = this.currentPage + 2 
        this.path_cPage_more_2 = this.path +"?page="+this.cPage_more_2;
      }

    }

    let info = this.__('datatable.info').replace('_START_', toogle.data.from)
    let info2 =info.replace('_END_', toogle.data.to)
    this.infoData = info2.replace('_TOTAL_', toogle.data.total)

  }

  async openModal(template:TemplateRef<any>,currentPaiement:ordre_paiement =null,elem) {
    // this.categories = await this.helper.getSelectList(environment.pension_categories,['name']);
    if (this.superAdmin == 0) {
      let userinfo = this.auth.account();
      if(userinfo.etablissement.type_etablissement == 1) {
        this.show_equipement = true;
      }
      const idEtablissement = this.auth.account().info.etablissement_id;
      let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+idEtablissement, valuesys.httpAuthOptions()).toPromise();
      if(res.code == 200) {
          this.categories = res.data
      }
  }

    this.niveaux = await this.helper.getSelectList(environment.niveau,['name']);
    this.ordre_paiement_error = new ordre_paiement()
    this.etudiants_boursier = [];
    this.f_date_debut=''
    this.f_date_fin =''
    this.childWhere= "&where=1|e|1"

    this.tabEtudiantCheck  = [];
    this.tabAddEtudiantCheck = [];
    this.tabAddEtudiant  = [];
    this.tabDelEtudiantCheck  = [];

      
    if(currentPaiement == null){
      
      this.isAdd = true ;
      this.ordre_paiement = new ordre_paiement() ;
      this.titleModal = this.__('ordre_paiement.add') ;

      if(this.superAdmin == 0) {
        this.ordre_paiement.etablissement_id = this.etabId

        let res = await this.http.get<any>(environment.etablissement + "?where=id|e|" + this.etabId,valuesys.httpAuthOptions()).toPromise();
      }

    }else {
      
      this.isAdd = false ;
      this.titleModal = this.__('ordre_paiement.edit') ;
      this.ordre_paiement = currentPaiement ;
      this.ordre_paiement.etat_equipement = '0' ;
      if(this.ordre_paiement.equipement != null){
        this.ordre_paiement.etat_equipement = '1' ;
      }
      let resC: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+this.ordre_paiement.etablissement_id, valuesys.httpAuthOptions()).toPromise();
      if(resC.code == 200) {
          this.categories = resC.data;
      }
      let resM: any = await this.http.get<any>(environment.mois_ordre_paiement+"?where=ordre_paiement_id|e|"+this.ordre_paiement.id, valuesys.httpAuthOptions()).toPromise();
      console.log(resM, "res")

      this.tabMois = resM.data;

      let tabM = [];

      for (let i = 0; i < this.tabMois.length; i++) {
        tabM.push(this.tabMois[i].mois)
      }



      this.ordre_paiement.mois = tabM;
      console.log(this.ordre_paiement.mois);



      let res = await this.http.get<any>(environment.etablissement + "?where=id|e|" + this.ordre_paiement.etablissement_id,valuesys.httpAuthOptions()).toPromise();
    } 

    this.modalService.open(template, { windowClass:'mymodal-xl', backdrop:"static" });
  } 

  //--fermeture du modal
  close(){
    this.modalService.dismissAll()
    this.isValid = false ;
    this.ordre_paiement = new ordre_paiement()
    this.titleModal = this.__('label_user_edit') ;
    this.ordre_paiement_error = new ordre_paiement();
    this.stepperEtape1=true
    this.stepperEtape2=false
    this.stepperEtape3=false
  }
  async annulerFiltreBaseOP(){
    this.filtre_etablissement_id="";
    this.getUrlDatatable(this.endPOint,'','','', true)
    this.filtreBaseOP=false
  }
 

  previousToStep1(){
    console.log("BACK TO 1")
    this.stepperEtape1=true
    this.stepperEtape2=false
    this.stepperEtape3=false;
    this.registerForm = this.formBuilder.group({
      date_debut_paiement:              [this.ordre_paiement.date_debut_paiement, Validators.required],
      date_fin_paiement:             [this.ordre_paiement.date_fin_paiement, Validators.required],
      libelle:             [this.ordre_paiement.libelle, Validators.required],
      etablissement_id:             [this.ordre_paiement.etablissement_id, Validators.required],
      mois:             [this.ordre_paiement.mois, Validators.required],
      categorie_concernees_id: [this.ordre_paiement.categorie_concernees_id, Validators.required],
      id_ordre_ministere: [this.ordre_paiement.id_ordre_ministere, Validators.required],
      nombre_mois : [this.ordre_paiement.nombre_mois, Validators.required],
      etat_equipement : [this.ordre_paiement.etat_equipement],
      niveau_id: [this.ordre_paiement.niveau_id],

    }); 
   
  }
  previousToStep2(){
    this.stepperEtape1=false
    this.stepperEtape2=true
    this.stepperEtape3=false
  }

  async addTest(clickNext: HTMLInputElement){
    this.submitted = true;
    

    this.ordre_paiement.moisItems = [];
    for (let i = 0; i < this.ordre_paiement.mois.length; i++) {
      this.ordre_paiement.moisItems.push({selectedMois:this.ordre_paiement.mois[i]});
    }

    console.log(this.ordre_paiement);

    if(this.type_etablissement_etab_id == 0 ){
      this.registerForm = this.formBuilder.group({
        date_debut_paiement:              [this.ordre_paiement.date_debut_paiement, Validators.required],
        date_fin_paiement:             [this.ordre_paiement.date_fin_paiement, Validators.required],
        libelle:             [this.ordre_paiement.libelle, Validators.required],
        etablissement_id:             [this.ordre_paiement.etablissement_id, Validators.required],
        mois:             [this.ordre_paiement.mois, Validators.required],
        categorie_concernees_id: [this.ordre_paiement.categorie_concernees_id, Validators.required],
        id_ordre_ministere: [this.ordre_paiement.id_ordre_ministere, Validators.required],
        nombre_mois : [this.ordre_paiement.nombre_mois, Validators.required],
        
      }); 
    }else{
      if(this.ordre_paiement.etat_equipement == '0'){
      
        this.ordre_paiement.equipement = null;
        this.registerForm = this.formBuilder.group({
          date_debut_paiement:              [this.ordre_paiement.date_debut_paiement, Validators.required],
          date_fin_paiement:             [this.ordre_paiement.date_fin_paiement, Validators.required],
          libelle:             [this.ordre_paiement.libelle, Validators.required],
          etablissement_id:             [this.ordre_paiement.etablissement_id, Validators.required],
          mois:             [this.ordre_paiement.mois, Validators.required],
          categorie_concernees_id: [this.ordre_paiement.categorie_concernees_id, Validators.required],
          id_ordre_ministere: [this.ordre_paiement.id_ordre_ministere, Validators.required],
          nombre_mois : [this.ordre_paiement.nombre_mois, Validators.required],
          etat_equipement : [this.ordre_paiement.etat_equipement],
          niveau_id: [this.ordre_paiement.niveau_id],

        }); 
  
      }else{
  
        this.registerForm = this.formBuilder.group({
          date_debut_paiement:              [this.ordre_paiement.date_debut_paiement, Validators.required],
          date_fin_paiement:             [this.ordre_paiement.date_fin_paiement, Validators.required],
          libelle:             [this.ordre_paiement.libelle, Validators.required],
          etablissement_id:             [this.ordre_paiement.etablissement_id, Validators.required],
          mois:             [this.ordre_paiement.mois, Validators.required],
          categorie_concernees_id: [this.ordre_paiement.categorie_concernees_id, Validators.required],
          id_ordre_ministere: [this.ordre_paiement.id_ordre_ministere, Validators.required],
          nombre_mois : [this.ordre_paiement.nombre_mois, Validators.required],
          etat_equipement : [this.ordre_paiement.etat_equipement],
          equipement : [this.ordre_paiement.equipement],
          niveau_id: [this.ordre_paiement.niveau_id],

        }); 
  
      }
  
    }

   
  if( this.ordre_paiement.date_debut_paiement > this.ordre_paiement.date_fin_paiement ){
      console.log("-",this.ordre_paiement.date_debut_paiement )
      console.log("-x-", this.ordre_paiement.date_debut_paiement)
      
      this.toastr.warning(this.__('msg.dateDebut_dateFin_error'),this.__("msg.warning"));
      return;
    }

    let res = await this.http.get<any>(environment.ordre_paiement+"?page=1&where=ordre_paiement.id_ordre_ministere|e|"+ this.ordre_paiement.id_ordre_ministere ,valuesys.httpAuthOptions()).toPromise();
    console.log("wwww", res)

    if(this.isAdd && res.data.data.length != 0) {
        this.toastr.warning(this.__('msg.id_ordre_existant'),this.__("msg.warning"));
        return;
    }
    
  

  
    console.log("xxxx", this.ordre_paiement)
    // stop here if form is invalid
    if (this.registerForm.invalid  ) {        
        return;
    }
    this.stepperEtape2=true
    this.stepperEtape1=false
    this.stepperEtape3=false
    let whereNiveau = "";
    if(this.ordre_paiement.niveau_id){
      whereNiveau = ",bourse_etudiant.niveau_id|e|"+this.ordre_paiement.niveau_id
    }
    
    if(this.type_etablissement_etab_id == 1){
      this.endpointListBenef = environment.etudiant_ready_for_bill+"?page=1&where=,concernees.categorie_concerne_id|e|"+this.ordre_paiement.categorie_concernees_id + ",concernees.etablissement_id|e|"+this.ordre_paiement.etablissement_id + whereNiveau;
    }else{
      this.endpointListBenef = environment.concerne_ready_for_bill+"?page=1&where=,concernees.categorie_concerne_id|e|"+this.ordre_paiement.categorie_concernees_id + ",concernees.etablissement_id|e|"+this.ordre_paiement.etablissement_id;

    }


    this.getListDatatableBeneficiary(this.endpointListBenef);

    clickNext.click()
  }

  clickNext(clickNext: HTMLInputElement) {
    clickNext.click()
  }


  montantInput(elem){
    this.elmInput = elem; 
    this.elmInput.value = elem.value.replace(/[^0-9.]/gi,'');
  }


    //--Add or Update
    async addPaiementForm(){

      let text_confirm = "";
      if(this.isAdd){
        text_confirm = this.__('msg.confirm_enregistrer_od')
      }else{
        text_confirm = this.__('msg.confirm_modifier_od')
      }

      Swal.fire({
        title: this.__('msg.confirm'),
        text: text_confirm,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#44A1A0',
        cancelButtonColor: '#FF7467',
        reverseButtons : true,
        confirmButtonText: this.__('msg.confirmed_valid_valider'),
        cancelButtonText: this.__('msg.cancel_valid_valider')
      }).then(async (result) => {
        if (result.value) {





               let listInputData: any = document.querySelectorAll('.test');
              this.tabAllData = [];
              let tabData = [];
              let test = 0;
              for (let index = 0; index < listInputData.length; index++) {
                  let montant = listInputData[index].childNodes[7].children[0].value;
                  let id_concerne = listInputData[index].childNodes[8].children[0].value;
                  let numero_serie = listInputData[index].childNodes[9].children[0].value;

                  tabData.push({
                    id: id_concerne,
                    montant: montant, 
                    numero_serie: numero_serie  
                  })
                }

              //--- Add etudiant
              if(this.isAdd ){

                this.ordre_paiement.user_creation = this.userId
                let tabEtudiantAll = [];
                let tabEtudiant = [];
                
                for (let i = 0; i < this.tabEtudiantCheck.length; i++) {
                  console.log(this.tabEtudiantCheck[i]);
                  let arr = tabData.find(_=>_.id==this.tabEtudiantCheck[i]);
                  console.log("arr : ", arr);
                  if(arr!=undefined){
                    tabEtudiantAll.push({concernees_id:this.tabEtudiantCheck[i],montant:arr.montant, numero_serie: arr.numero_serie });
                  }
                }

                tabEtudiant.push({concernees_id:tabData[0].id,montant:tabData[0].montant, numero_serie: tabData[0].numero_serie });
            
                if(this.tabEtudiantCheck!="") { this.ordre_paiement.detail = tabEtudiantAll; } 
                else { this.ordre_paiement.detail = tabEtudiant; }

                let res:any =  await this.http.post<any>(environment.ordre_paiement,this.ordre_paiement,valuesys.httpAuthOptions()).toPromise();
                  //--success
                  if(res.code === 201){

                        const resultSpecialite= this.categories.filter(word => word.id == this.ordre_paiement.categorie_concernees_id);
                        Object.assign(res.data.ordre_paiement, {"categorie":resultSpecialite[0]?.name});

                        const resultMinistere= this.etablissements.filter(word => word.id == this.ordre_paiement.etablissement_id);
                        Object.assign(res.data.ordre_paiement, {"etablissement":resultMinistere[0]?.name});



                    this.ordre_paiements.push(res.data.ordre_paiement);

                    function compare(a, b) {
                      if (a.numero_ordre < b.numero_ordre) { return 1; }
                      if (a.numero_ordre > b.numero_ordre) { return - 1; }
                      return 0;
                    }
                    this.ordre_paiements.sort(compare); // on passe la référence à la fonction de comparaison


                    this.toastr.success(res.msg,this.__("msg.success")) ;
                    this.close()

                  //--Warning : Donnée invalide, Session expiré
                  }else if(res.code === 400){
                    for (let item in res.data){
                      this.ordre_paiement_error[item] = res.data[item][0] ;
                    }
                    this.ordre_paiement_error = <ordre_paiement> this.helper.errorForm(res.data);
                    if(res.data.email){
                      this.toastr.warning(res.data.email[0],this.__("msg.warning")) ;
                    }else{
                      this.toastr.warning(res.msg,this.__("msg.warning")) ;
                    }
                  }
                  //--error
                  else {
                    this.toastr.error(res.msg,this.__("msg.error")) ;
                  }
              //--- Update etudiant
              }else {

                let listInputData: any = document.querySelectorAll('.test');
                let tabData = [];
                for (let index = 0; index < listInputData.length; index++) {
                    let montant = listInputData[index].childNodes[7].children[0].value;
                    let id_concerne = listInputData[index].childNodes[8].children[0].value;
                    let numero_serie = listInputData[index].childNodes[9].children[0].value;

                    tabData.push({ id: id_concerne, montant: montant, numero_serie: numero_serie  })
                  }
          
                this.ordre_paiement.user_creation = this.userId
                let tabEtudiantAdd = [];
                let tabEtudiantDel = [];

                for (let i = 0; i < this.tabAddEtudiant.length; i++) {
                  console.log(this.tabAddEtudiant[i]);
                  let arr = tabData.find(_=>_.id==this.tabAddEtudiant[i]);
                  console.log("arr : ", arr);
                  if(arr!=undefined){
                    tabEtudiantAdd.push({concernees_id:this.tabAddEtudiant[i],montant:arr.montant, numero_serie: arr.numero_serie });
                  }
          
                }


              this.ordre_paiement.ajout_detail = tabEtudiantAdd;


              for (let i = 0; i < this.tabDelEtudiantCheck.length; i++) {
                let post = this.tabDelEtudiantCheck[i].split('-') 
                tabEtudiantDel.push({concernees_id:post[0], montant:post[1]});
              }
              this.ordre_paiement.suppr_detail = tabEtudiantDel;
              this.ordre_paiement.operator_user = this.userId;

              console.log("xxxx", this.ordre_paiement)
              
              let res = await this.http.put<any>(environment.ordre_paiement+`/${this.ordre_paiement.id}`,  this.ordre_paiement ,valuesys.httpAuthOptions()).toPromise();
                  if(res.code == 201){

                    const resultEtablissement= this.etablissements.filter(word => word.id == this.ordre_paiement.etablissement_id as any);
                    this.ordre_paiement.etablissement = resultEtablissement[0]?.name;
                    this.ordre_paiement.nombre_concernees = res.data.ordre_paiement.nombre_concernees;
                    this.ordre_paiement.montant_global = res.data.ordre_paiement.montant_global;
                    const result= this.categories.filter(word => word.id == this.ordre_paiement.categorie_concernees_id);
                    this.ordre_paiement.categorie = result[0]?.name;

                    this.toastr.success(res.msg,this.__("msg.success"));
                    this.close();
                    
                  } else {
                    this.toastr.error(res.msg,this.__("msg.error"));
                  }
                
              } 
              this.isValid = false ;





      

        }
      })

 
    }

    async toggleValide(ordre_paiement:any|[],statut:number,btn:any){

      Swal.fire({
        title: this.__('swal.confirmTitle'),
        text: this.__('swal.confirmMsg'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#44A1A0',
        cancelButtonColor: '#FF7467',
        reverseButtons : true,
        cancelButtonText: this.__('swal.no'),
        confirmButtonText: this.__('swal.yes')
      }).then(async (result) => {

        let objetStatut = {
          "statut" : statut,
          "operator_user" : this.userId
        }

        if (result.value) {
    
          let res = await this.http.put<any>(environment.ordre_paiement+`/${ordre_paiement.id}/valRej`,  objetStatut ,valuesys.httpAuthOptions()).toPromise();
          if(res.code == 201){
            this.toastr.success(res.msg,this.__("msg.success"));
            ordre_paiement.statut = statut;
            if(statut == 1 || statut == 2){
              ordre_paiement.user_validation = res.data.user_validation;
              ordre_paiement.date_validation = res.data.date_validation;
            }
            this.close()
          } else if(res.code == 500 && res.data.msg && statut == 1){
            this.toastr.error(res.data.msg,this.__("msg.error"));
          }else{
            this.toastr.error(res.msg,this.__("msg.error"));
          }
        }
      })
    }

    async toggleValideBrouillon(ordre_paiement:any|[],statut:number,btn:any){

      Swal.fire({
        title: this.__('swal.confirmTitle'),
        text: this.__('swal.confirmMsg'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#44A1A0',
        cancelButtonColor: '#FF7467',
        reverseButtons : true,
        cancelButtonText: this.__('swal.no'),
        confirmButtonText: this.__('swal.yes')
      }).then(async (result) => {

        let objetStatut = {
          "statut" : statut,
          "operator_user" : this.userId
        }

        if (result.value) {
    
          let res = await this.http.get<any>(environment.ordre_paiement+"/validerBrouillon/"+ordre_paiement.id ,valuesys.httpAuthOptions()).toPromise();
          if(res.code == 201){
            this.toastr.success(res.msg,this.__("msg.success"));
            ordre_paiement.statut = statut;
            this.close()
          } else if(res.code == 500 && res.data.msg && statut == 1){
            this.toastr.error(res.data.msg,this.__("msg.error"));
          }else{
            this.toastr.error(res.msg,this.__("msg.error"));
          }
        }
      })
    }

    async toggleEnvoye(ordre_paiement:any|[]){

      Swal.fire({
        title: this.__('swal.confirmTitle'),
        text: this.__('swal.confirmMsg'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#44A1A0',
        cancelButtonColor: '#FF7467',
        reverseButtons : true,
        cancelButtonText: this.__('swal.no'),
        confirmButtonText: this.__('swal.yes')
      }).then(async (result) => {

        if (result.value) {


        let objetStatut = {
          "ordrePaiement_id" : ordre_paiement.id,
          "operator_user" : this.userId
        }
    
         let res = await this.http.post<any>(environment.ordre_paiement+`/envoie`,  objetStatut ,valuesys.httpAuthOptions()).toPromise();
          if(res.code == 200){
            this.toastr.success(res.msg,this.__("msg.success"));
            ordre_paiement.statut = 3;
            ordre_paiement.user_envoi = res.data.user_envoi;
            ordre_paiement.date_envoi = res.data.date_envoi;
            this.close()
          
          } else {
            this.toastr.error(res.data.msg,this.__("msg.error"));
            
          }

        }
      })
    }

    async envoiForm(id: number) {

      let ordrePaiement_id = id;
      console.log("ID ko iz io", ordrePaiement_id);

      Swal.fire({
        title: this.__('msg.confirm'),
        text: this.__('msg.warning_valide_envoyer'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#44A1A0',
        cancelButtonColor: '#FF7467',
        reverseButtons : true,
        confirmButtonText: this.__('msg.confirmed_valid_envoyer'),
        cancelButtonText: this.__('msg.cancel_valid_envoyer')
      }).then(async (result) => {
        if (result.value) {
          let res:any =  await this.http.post<any>(environment.detail_ordre_envoiee, {"ordrePaiement_id": ordrePaiement_id},valuesys.httpAuthOptions()).toPromise();
          // this.rerender();
          if(res.code == 200){
            Swal.fire(
              {
                icon: 'success',
                title: this.__('msg.valid_envoyer'),
                confirmButtonColor: '#44A1A0',
                text: res.msg,
              }
            )
          this.categories.push(res.data);
          this.close()
          this.getUrlDatatable(environment.ordre_paiement+"?page=1")
          }else {
            this.toastr.error(res.msg,this.__("msg.error"));
          }
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            {
              icon: 'error',
              title: this.__('msg.cancel_envoyer'),
              confirmButtonColor: '#44A1A0',
              text: this.__('msg.valid_envoyer_annuler'),
            }
          )
          this.close()
          this.getUrlDatatable(environment.ordre_paiement+"?page=1")
        }
      })
    }

     async openModalDetails(template: TemplateRef<any>, currentPaiement: ordre_paiement = null, elm) {

        elm.disabled = true
        this.titleModal = this.__('label_details');
        this.ordre_paiement = currentPaiement
        this.filtreStatus = 'tout'
        this.detail_ordre_paiements = [];
               
        this.getUrlDatatableDetailePaiementEtudiant(environment.detail_ordre_paiement + this.endPointGetInfoDetail + this.ordre_paiement.id + "?page=1");
        
        let resM: any = await this.http.get<any>(environment.mois_ordre_paiement+"?where=ordre_paiement_id|e|"+this.ordre_paiement.id, valuesys.httpAuthOptions()).toPromise();
        console.log(resM, "res")
  
        this.tabMois = resM.data;

        let tabM = "";
        let sep = "";
        for (let i = 0; i < this.tabMois.length; i++) {
          let post = this.tabMois[i].mois.split('-');
          let mois = "";
          if(post[0] == "01") mois =  "Janvier ";
          if(post[0] == "02") mois =  "Fevrier ";
          if(post[0] == "03") mois =  "Mars ";
          if(post[0] == "04") mois =  "Avril ";
          if(post[0] == "05") mois =  "Mai ";
          if(post[0] == "06") mois =  "Juin ";
          if(post[0] == "07") mois =  "Juillet ";
          if(post[0] == "08") mois =  "Août ";
          if(post[0] == "09") mois =  "Septembre ";
          if(post[0] == "10") mois =  "Octobre ";
          if(post[0] == "11") mois =  "Novembre ";
          if(post[0] == "12") mois =  "Decembre ";
          //let post = this.tabMois[i].mois.replace("-", " ");

          tabM += sep + mois + " " + post[1];
          sep = ", "
        }
        this.ordre_paiement.moisDetail = tabM;
        console.log(tabM);
        /*var monthNames = [
          "Janvier ",
          "Fevrier ",
          "Mars ",
          "Avril ",
          "Mai ",
          "Juin ",
          "Juillet ",
          "Août ",
          "Septembre ",
          "Octobre ",
          "Novembre ",
          "Decembre "
        ]; */

        this.resumeNbEtudiantParNiveau(this.ordre_paiement.id);

        



        let {data} = <HttpApiResponse>await this.http.get<any>(environment.document + "?where=ordre_paiement_id|e|" + currentPaiement.id, valuesys.httpAuthOptions()).toPromise();
        this.documentsPJ = <Array<documentPj>>data;
    
        console.log('this cocuments',this.documentsPJ)


        this.modalService.open(template, { windowClass:'mymodal-xl', backdrop:"static" });
        elm.disabled = false
    }


    async openModalPJ(template: TemplateRef<any>, currentPaiement: ordre_paiement = null, elm) {

      elm.disabled = true
      this.titleModal = this.__('label_details');
      this.ordre_paiement = currentPaiement
      this.filtreStatus = 'tout'
      this.detail_ordre_paiements = [];
             
  
 
     
      this.documentsPJ = [];
      console.log('this cocuments',this.documentsPJ)


      this.modalService.open(template, { windowClass:'mymodal-xl', backdrop:"static" });
      elm.disabled = false
  }

 

  async getEtablissement(id: number) {
      let res = await this.http.get<any>(environment.etablissement + id,valuesys.httpAuthOptions()).toPromise();
      if(res.code = 200){
        this.etablissements = res.data;
      }

  }

  async cocherBeneficiaire_ajout(currentEtudiant:any, elm) {
     console.log("etudiant", currentEtudiant.concernees_id);

      if(elm.checked ==true){
        this.tabEtudiantCheck.push(currentEtudiant.concernees_id);
      }else{
        this.tabEtudiantCheck = this.tabEtudiantCheck.filter(e => e !== currentEtudiant.concernees_id); 
      }
      console.log("liste béneficiaire cocher", this.tabEtudiantCheck);
    }

    async cocherBeneficiaire_modif(currentEtudiant:any, elm) {
      console.log("etudiant", currentEtudiant.concernees_id);
       if(elm.checked ==true){
         this.tabAddEtudiantCheck.push(currentEtudiant.concernees_id);
         this.tabAddEtudiant.push(currentEtudiant.concernees_id);
       }else{
         this.tabAddEtudiantCheck = this.tabAddEtudiantCheck.filter(e => e !== currentEtudiant.concernees_id); 
         this.tabAddEtudiant = this.tabAddEtudiant.filter(e => e !== currentEtudiant.concernees_id); 
       }
          console.log("liste béneficiaire cocher", this.tabAddEtudiantCheck);
    }

     async cocherToutBeneficiaire_modif(currentEtudiant:any = null, elm){
      this.tabAddEtudiant = [];
      this.tabAddEtudiantCheck = [];

      if(elm.checked ==true){
        this.isCheckedElmEtudiant = true;
        for (let i = 0; i < currentEtudiant.length; i++) { 
          var a = this.tabAddEtudiantCheck.indexOf(currentEtudiant[i].concernees_id);
          if(a == -1 ){
            this.tabAddEtudiantCheck.push(currentEtudiant[i].concernees_id);
            this.tabAddEtudiant.push(currentEtudiant[i].concernees_id);
          }
        }
        this.cocheTout = true;
      }else{
        this.isCheckedElmEtudiant = false;
          for (let i = 0; i < currentEtudiant.length; i++) {
          this.tabAddEtudiantCheck = this.tabAddEtudiantCheck.filter(e => e !== currentEtudiant[i].concernees_id); 
          this.tabAddEtudiant = this.tabAddEtudiant.filter(e => e !== currentEtudiant[i].concernees_id); 
        }
        this.cocheTout = false;
      }
        console.log("liste tous les béneficiaire cocher", this.tabAddEtudiantCheck);
     }

    async cocherToutBeneficiaire_ajout(currentEtudiant:any = null, elm){
      console.log(currentEtudiant,"xxxxxxxxxx", currentEtudiant.length)
      this.tabEtudiantCheck  = [];
        if(elm.checked ==true){
          this.isCheckedElmEtudiant = true;
          for (let i = 0; i < currentEtudiant.length; i++) {
            var a = this.tabEtudiantCheck.indexOf(currentEtudiant[i].concernees_id);
            if(a == -1 ){
              this.tabEtudiantCheck.push(currentEtudiant[i].concernees_id);
            }else console.log("xxxxxxx doublant xxxxxx", currentEtudiant[i].concernees_id) 
          }
        }else{
            this.isCheckedElmEtudiant = false;
            for (let i = 0; i < currentEtudiant.length; i++) {
              this.tabEtudiantCheck = this.tabEtudiantCheck.filter(e => e !== currentEtudiant[i].concernees_id); 
          }
        }
      console.log("liste tous les béneficiaire cocher", this.tabEtudiantCheck);

    }

    async cocherBeneficiaire_suppr(currentEtudiant:any = null, elm) {

       if(elm.checked ==true){ this.tabDelEtudiantCheck.push(currentEtudiant.concernees_id + '-'+currentEtudiant.montant); }
       else{ this.tabDelEtudiantCheck = this.tabDelEtudiantCheck.filter(e => e !== currentEtudiant.concernees_id+'-'+currentEtudiant.montant); }
          console.log("liste tous les béneficiaire cocher suprimer", this.tabDelEtudiantCheck);
        
    }

    async listeEtudiantSelectionner_ajout(currentEtudiant:any = null, elm) {
      this.importListOP = false;
      this.isListTrue = false;
       this.etudiants_checked_boursier = [];
        let tabEtudiantAll = []
        for (let i = 0; i < this.tabEtudiantCheck.length; i++) {
          tabEtudiantAll.push({id:this.tabEtudiantCheck[i]});
        }
        this.objetTest.ajout_detail = tabEtudiantAll;
        this.objetTest.etablissement_id = this.ordre_paiement.etablissement_id
        if(this.type_etablissement_etab_id == 1){
          let res = await this.http.post<any>(environment.detail_ordre_paiement + this.endPointGetInfoDetailByEtudiantArray, this.objetTest ,valuesys.httpAuthOptions()).toPromise();
          if(res.code = 200){  this.etudiants_checked_boursier = res.data; }
        }else{
          let res = await this.http.post<any>(environment.detail_ordre_paiement + this.endPointGetInfoDetailByConcerneArray, this.objetTest ,valuesys.httpAuthOptions()).toPromise();
          if(res.code = 200){  this.etudiants_checked_boursier = res.data; }
        }

          this.stepperEtape1=false
          this.stepperEtape2=false
          this.stepperEtape3=true 

    }

    handleInputChange(e) {

      console.log(e);
      var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      if (!files) {
        return;
      }
      for (var i = 0; i < files.length; i++) {
        if (files[i].size > 1000*1000 * 100) {
          this.toastr.error("taille image trop grande", "Error");
          $("#document").val("");
          return;
        }
        var reader = new FileReader();
      
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsDataURL(files[i]);
      }
    }
  
  
    _handleReaderLoaded(e) {
      let reader = e.target;
      let d :any = [];
      d.name = reader.result
      if (this.documents.length < 5)
        this.documents.push(d)
      console.log(this.documents)
      //this.partenaire.logo = reader.result;
    }

    

    async AddImportExcel(){

      this.isListTrue = false;
      this.importListOP = true;

      this.etudiants_checked_boursier = [];
      console.log("----",  this.tabAddEtudiantCheck);
      this.tabEtudiantCheck  = [];
      this.tabAddEtudiant = [];
      this.tabAddEtudiantCheck = [];

      this.savedDocument = this.documents.filter((_) => _.id == null);
      this.etab_c_id = this.ordre_paiement.etablissement_id
      this.categorie_concerne_id = this.ordre_paiement.categorie_concernees_id

      let objectOrdrePaiement = {
        "etablissement_id":this.etab_c_id,
        "categorie_concerne_id": this.categorie_concerne_id,
        "fileXls":this.savedDocument[0].name
      };
      console.log("object envoiée :", objectOrdrePaiement)

      let res =  await this.http.post<any>( environment.pension_ordre_import_excel,objectOrdrePaiement,valuesys.httpAuthOptions()).toPromise();
      console.log("Response Aprés post :", res);
             //--success
           if(res.code === 200){
             
            if(this.isAdd == false){ this.getListBeneficiaryinData(environment.detail_ordre_paiement + this.endPointGetInfoDetail + this.ordre_paiement.id + "?page=1"); 
          }
            this.etudiants_checked_boursier = res.data.trouver;
            let dataEtudiantImporter = res.data.trouver;

            for (let i = 0; i < dataEtudiantImporter .length; i++) { 
              if(this.isAdd == true){
                this.tabEtudiantCheck.push(dataEtudiantImporter[i].concernees_id);
              }else{
                this.tabAddEtudiantCheck.push(dataEtudiantImporter[i].concernees_id);
                this.tabAddEtudiant.push(dataEtudiantImporter[i].concernees_id);
              }
            }


            console.log("tab Add check :", this.tabAddEtudiantCheck);
            console.log("tab Add :", this.tabAddEtudiant);
            console.log("Response after post :", this.etudiants_checked_boursier);
            this.isListTrue = true;
             this.toastr.success(res.msg,this.__("msg.success")) ;
              this.ligne_parcourues = res.data.ligne_parcourue;
              this.ligne_traitees = res.data.ligne_traitees;
              this.ligne_echouees = res.data.ligne_echouees;

           //--Warning : Donnée invalide, Session expiré
           }else if(res.code === 400){
            this.ordre_paiement_error = <ordre_paiement> this.helper.errorForm(res.data);
             this.toastr.warning(res.msg,this.__("msg.warning")) ;
           }
           //--error
           else {
             this.toastr.error(res.msg,this.__("msg.error")) ;
          } 
  
        
      this.stepperEtape1=false
      this.stepperEtape2=false
      this.stepperEtape3=true
         
      
    }



    async listeEtudiantSelectionner_modif(currentEtudiant:etudiant = null, elm) {

      this.importListOP = false;
      this.isListTrue = false;
      this.etudiants_checked_boursier = [];
      this.getListBeneficiaryinData(environment.detail_ordre_paiement + this.endPointGetInfoDetail + this.ordre_paiement.id + "?page=1");
      console.log("detailEdit", this.tabAddEtudiantCheck)
      let tabEtudiantAll = []
      for (let i = 0; i < this.tabAddEtudiantCheck.length; i++) {
          tabEtudiantAll.push({id:this.tabAddEtudiantCheck[i]});
      }
      this.objetTest.ajout_detail = tabEtudiantAll;
      this.objetTest.etablissement_id = this.ordre_paiement.etablissement_id

      if(this.type_etablissement_etab_id == 1){
        let res = await this.http.post<any>(environment.detail_ordre_paiement + this.endPointGetInfoDetailByEtudiantArray, this.objetTest ,valuesys.httpAuthOptions()).toPromise();
        if(res.code = 200){  this.etudiants_checked_boursier = res.data; }
      }else{
        let res = await this.http.post<any>(environment.detail_ordre_paiement + this.endPointGetInfoDetailByConcerneArray, this.objetTest ,valuesys.httpAuthOptions()).toPromise();
        if(res.code = 200){  this.etudiants_checked_boursier = res.data; }
      }


      console.log(this.etudiants_checked_boursier);
      
      this.stepperEtape1=false
      this.stepperEtape2=false
      this.stepperEtape3=true
    }

   

    controleMontant(element){
      let x = element.value.replace(/[^0-9.]/gi,'');
      element.value = x
      this.ordre_paiement.montant_global = element.value
    }
    controleMontantPrime(element){
      let x = element.value.replace(/[^0-9.]/gi,'');
      element.value = x
      this.ordre_paiement.equipement = element.value
    }

    onFocusOutEvent(event: any){

      console.log(event.target.value);
      let valeur = event.target.value.replace(' ', '')
      if(valeur != ''){
        let res = Number(valeur).toFixed(2);
        this.ordre_paiement.montant_global = res
      }
      
    }

    onFocusOutEventPrime(event: any){

      console.log(event.target.value);
      let valeur = event.target.value.replace(' ', '')
      if(valeur != ''){
        let res = Number(valeur).toFixed(2);
        this.ordre_paiement.equipement = res
      }
      
    }

    async getListDatatableBeneficiary(url,col = "", refElement?: any, child?: any, searchMulti?:any){

      let filtre : any= "";
      if(this.e_filtreSelect !="" && this.e_filtreSelect !="undefined"){
        filtre = "&per_page=" + this.e_filtreSelect;
      }


      if(this.e_searchInput !="" && this.e_searchInput !="undefined"){
        this.e_search = "&where_or=nom|l|"+this.e_searchInput+",prenom|l|"+this.e_searchInput+",etudiant.code_nin|l|"+this.e_searchInput+",mention.name|l|"+this.e_searchInput+",niveau.name|l|"+this.e_searchInput+",bourse.montant|l|"+this.e_searchInput ;
      }else if(this.e_searchInput == "") {
        this.e_search = "";
      }
      let addScript = "";
      if(this.isAdd == false){
        addScript = "&extra="+this.ordre_paiement.id;
      }
      

      console.log("search", this.search)
      let toogle = await this.http.get<any>(url+this.e_child   + filtre + this.e_search + addScript,valuesys.httpAuthOptions()).toPromise();
      this.etudiants_boursier = toogle.data.data;
      console.log("url", this.etudiants_boursier)
  

// environment.concerne_ready_for_bill+"?page=1&where=,concernees.categorie_concerne_id|e|"+this.ordre_paiement.categorie_concernees_id + ",concernees.etablissement_id|e|"+this.ordre_paiement.etablissement_id
     
      if(this.type_etablissement_etab_id == 1){
        this.e_path = environment.etudiant_ready_for_bill;
      }else{
        this.e_path = environment.concerne_ready_for_bill;
      }
      this.e_currentPage = toogle.data.current_page;
      this.e_firstPage =this.e_path+"?page=1";
      this.e_prevPage = this.e_path+"?page="+(this.e_currentPage-1) ;
      this.e_nextPage = this.e_path+"?page="+(this.e_currentPage+1);
      this.e_lastPage = this.e_path +"?page="+ toogle.data.last_page ;
      this.e_lastNumPage = toogle.data.last_page;
      this.e_total = toogle.data.total;


      this.e_cPage_less_2 = 0
      this.e_cPage_less_1 = 0
      if(this.e_currentPage > 1 ){
        
        if(this.e_currentPage > 2 ){
          this.e_cPage_less_2 = this.e_currentPage - 2
          this.e_path_cPage_less_2 = this.e_path +"?page="+this.e_cPage_less_2;
        }
      
      this.e_cPage_less_1 = this.e_currentPage - 1 
      this.e_path_cPage_less_1 = this.e_path +"?page="+this.e_cPage_less_1;
      }
  
      
      this.e_cPage_more_1 = 0
      this.e_cPage_more_2 = 0
      if(this.e_currentPage < this.e_lastNumPage){
        this.e_cPage_more_1 = this.e_currentPage + 1 
        this.e_path_cPage_more_1 = this.e_path +"?page="+this.e_cPage_more_1;
  
        if(this.e_currentPage < this.e_lastNumPage - 1 ){
          this.e_cPage_more_2 = this.e_currentPage + 2 
          this.e_path_cPage_more_2 = this.e_path +"?page="+this.e_cPage_more_2;
        }
  
      }
  
      let e_info = this.__('datatable.info').replace('_START_', toogle.data.from)
      let e_info2 =e_info.replace('_END_', toogle.data.to)
      this.e_infoData = e_info2.replace('_TOTAL_', toogle.data.total)
  
    }




    async getFiltreStatus(status : any = ''){
      this.filtreStatus = status;     
      this.getUrlDatatableDetailePaiementEtudiant(environment.detail_ordre_paiement + this.endPointGetInfoDetail + this.ordre_paiement.id + "?page=1");
    }
    
    async getUrlDatatableDetailePaiementEtudiant(url,col = "", refElement?: any, child?: any, searchMulti?:any){

     
      let filtre : any= "";
      if(this.detail_e_filtreSelect !="" && this.detail_e_filtreSelect !="undefined"){
        filtre = "&per_page=" + this.detail_e_filtreSelect;
      }

      if(this.detail_searchInput !="" && this.detail_searchInput !="undefined"){
        this.detail_search = "&where_or=concernees.nom|l|"+this.detail_searchInput+",concernees.prenom|l|"+this.detail_searchInput+",concernees.telephone|l|"+this.detail_searchInput+",detail_ordre_paiement.montant|l|"+this.detail_searchInput;
      }else if(this.detail_searchInput == "") {
        this.detail_search = "";
      }

      let filtreStatus : any = '';
      if(this.filtreStatus != 'tout'){
        filtreStatus = "&where=detail_ordre_paiement.statut|e|"+ this.filtreStatus;
      }
     


      let toogle = await this.http.get<any>(url + filtre + this.detail_search + filtreStatus,valuesys.httpAuthOptions()).toPromise();
      this.detail_ordre_paiements = toogle.data.data;
      console.log("url Detail", this.detail_ordre_paiements)

      this.paginate.e_path = environment.detail_ordre_paiement + this.endPointGetInfoDetail + this.ordre_paiement.id ;
      this.paginate.e_currentPage = toogle.data.current_page;
      this.paginate.e_firstPage =this.paginate.e_path+"?page=1";
      this.paginate.e_prevPage = this.paginate.e_path+"?page="+(this.paginate.e_currentPage-1) ;
      this.paginate.e_nextPage = this.paginate.e_path+"?page="+(this.paginate.e_currentPage+1);
      this.paginate.e_lastPage = this.paginate.e_path +"?page="+ toogle.data.last_page ;
      this.paginate.e_lastNumPage = toogle.data.last_page;
      this.paginate.e_total = toogle.data.total;


      this.paginate.e_cPage_less_2 = 0
      this.paginate.e_cPage_less_1 = 0
      if(this.paginate.e_currentPage > 1 ){
        
        if(this.paginate.e_currentPage > 2 ){
          this.paginate.e_cPage_less_2 = this.paginate.e_currentPage - 2
          this.paginate.e_path_cPage_less_2 = this.paginate.e_path +"?page="+this.paginate.e_cPage_less_2;
        }
      
      this.paginate.e_cPage_less_1 = this.paginate.e_currentPage - 1 
      this.paginate.e_path_cPage_less_1 = this.paginate.e_path +"?page="+this.paginate.e_cPage_less_1;
      }
  
      
      this.paginate.e_cPage_more_1 = 0
      this.paginate.e_cPage_more_2 = 0
      if(this.paginate.e_currentPage < this.paginate.e_lastNumPage){
        this.paginate.e_cPage_more_1 = this.paginate.e_currentPage + 1 
        this.paginate.e_path_cPage_more_1 = this.paginate.e_path +"?page="+this.paginate.e_cPage_more_1;
  
        if(this.paginate.e_currentPage < this.paginate.e_lastNumPage - 1 ){
          this.paginate.e_cPage_more_2 = this.paginate.e_currentPage + 2 
          this.paginate.e_path_cPage_more_2 = this.paginate.e_path +"?page="+this.paginate.e_cPage_more_2;
        }
  
      }
      this.totalDateinfullPage= toogle.data.total;

      let detail_e_info = this.__('datatable.info').replace('_START_', toogle.data.from)
      let detail_e_info2 =detail_e_info.replace('_END_', toogle.data.to)
      this.paginate.e_infoData = detail_e_info2.replace('_TOTAL_', toogle.data.total)



      let filtreStatusM : any = '';
      if(this.filtreStatus != 'tout'){
        filtreStatusM = "?where=detail_ordre_paiement.statut|e|"+ this.filtreStatus;
      }

      let res = await this.http.get<any>(environment.detail_ordre_paiement+"/getMontantGobalOrdrePaiement/"+this.ordre_paiement.id + filtreStatusM,valuesys.httpAuthOptions()).toPromise();
      console.log("-----x-----",res)
      this.montantGlobal = res.data;
  
    }

    async getListBeneficiaryinData(url,col = "", refElement?: any, child?: any, searchMulti?:any){


      let filtre : any= "";
      if(this.detail_e_filtreSelect !="" && this.detail_e_filtreSelect !="undefined"){
        filtre = "&per_page=" + this.detail_e_filtreSelect;
      }


      if(this.e_detail_searchInput !="" && this.e_detail_searchInput !="undefined"){
        //this.detail_search = "&where_or=nom|l|"+this.detail_searchInput+",ordrepaiement|date_creation|l|"+this.detail_searchInput+",ordrepaiement|montant_global|l|"+this.detail_searchInput+",ordrepaiement|nombre_etudiant|l|"+this.searchInput+",etablissement|name|l|"+this.searchInput+"";
        this.e_detail_search = "&where_or=nom|l|"+this.e_detail_searchInput+",prenom|l|"+this.e_detail_searchInput+",etudiant.code_nin|l|"+this.e_detail_searchInput+",etudiant.telephone|l|"+this.e_detail_searchInput+",mention.name|l|"+this.e_detail_searchInput+",niveau.name|l|"+this.e_detail_searchInput;
      }else if(this.e_detail_searchInput == "") {
        this.e_detail_search = "";
      }



      let toogle = await this.http.get<any>(url+ filtre + this.e_detail_search,valuesys.httpAuthOptions()).toPromise();
      this.etudiants_boursier_in_data = toogle.data.data;
        
      this.paginateChecked.e_path = environment.detail_ordre_paiement+ this.endPointGetInfoDetail + this.ordre_paiement.id ;
      this.paginateChecked.e_currentPage = toogle.data.current_page;
      this.paginateChecked.e_firstPage =this.paginateChecked.e_path+"?page=1";
      this.paginateChecked.e_prevPage = this.paginateChecked.e_path+"?page="+(this.paginateChecked.e_currentPage-1) ;
      this.paginateChecked.e_nextPage = this.paginateChecked.e_path+"?page="+(this.paginateChecked.e_currentPage+1);
      this.paginateChecked.e_lastPage = this.paginateChecked.e_path +"?page="+ toogle.data.last_page ;
      this.paginateChecked.e_lastNumPage = toogle.data.last_page;
      this.paginateChecked.e_total = toogle.data.total;


      this.paginateChecked.e_cPage_less_2 = 0
      this.paginateChecked.e_cPage_less_1 = 0
      if(this.paginateChecked.e_currentPage > 1 ){
        
        if(this.paginateChecked.e_currentPage > 2 ){
          this.paginateChecked.e_cPage_less_2 = this.paginateChecked.e_currentPage - 2
          this.paginateChecked.e_path_cPage_less_2 = this.paginateChecked.e_path +"?page="+this.paginateChecked.e_cPage_less_2;
        }
      
      this.paginateChecked.e_cPage_less_1 = this.paginateChecked.e_currentPage - 1 
      this.paginateChecked.e_path_cPage_less_1 = this.paginateChecked.e_path +"?page="+this.paginateChecked.e_cPage_less_1;
      }
  
      
      this.paginateChecked.e_cPage_more_1 = 0
      this.paginateChecked.e_cPage_more_2 = 0
      if(this.paginateChecked.e_currentPage < this.paginateChecked.e_lastNumPage){
        this.paginateChecked.e_cPage_more_1 = this.paginateChecked.e_currentPage + 1 
        this.paginateChecked.e_path_cPage_more_1 = this.paginateChecked.e_path +"?page="+this.paginateChecked.e_cPage_more_1;
  
        if(this.paginateChecked.e_currentPage < this.paginateChecked.e_lastNumPage - 1 ){
          this.paginateChecked.e_cPage_more_2 = this.paginateChecked.e_currentPage + 2 
          this.paginateChecked.e_path_cPage_more_2 = this.paginateChecked.e_path +"?page="+this.paginateChecked.e_cPage_more_2;
        }
  
      }
  
      let detail_e_info = this.__('datatable.info').replace('_START_', toogle.data.from)
      let detail_e_info2 =detail_e_info.replace('_END_', toogle.data.to)
      this.paginateChecked.e_infoData = detail_e_info2.replace('_TOTAL_', toogle.data.total)
  
    }

    async detail_e_getFiltreSelect(){ this.getUrlDatatableDetailePaiementEtudiant(environment.detail_ordre_paiement + this.endPointGetInfoDetail +  this.ordre_paiement.id+"?page=1") }
   

    async getDetailSearchInput(){ this.getUrlDatatableDetailePaiementEtudiant(environment.detail_ordre_paiement + this.endPointGetInfoDetail + this.ordre_paiement.id+"?page=1") }
  
    async e_detail_e_getFiltreSelect(){ this.getListBeneficiaryinData(environment.detail_ordre_paiement + this.endPointGetInfoDetail +  this.ordre_paiement.id+"?page=1") }

    async e_getDetailSearchInput(){ this.getListBeneficiaryinData(environment.detail_ordre_paiement + this.endPointGetInfoDetail + this.ordre_paiement.id+"?page=1") }


    videTab(){
      this.tabEtudiantCheck = [];
      this.tabAddEtudiantCheck = []
    }

    getCalculeBourse(montant :any = '' ){
      let montantBourse = (this.ordre_paiement.nombre_mois * montant) + Number(this.ordre_paiement.equipement)
      return  formatNumber(montantBourse, ' ') 
    }

 
    
 async exportPdf(btnPdf) {
      await this.helper.exportPdf(this.print(this.detail_ordre_paiements),'la liste des utilisateurs avec le numero d\'ordre','');
  }


   async exportExcel(fileName) {

     this.helper.exportExcel(this.print(this.detail_ordre_paiements),this.__("print.list") + this.__("client.clients")).then(
      (response: any)=>{
        console.log('respons beee',response)
            let a = document.createElement("a"); 
            a.href = response.data;
            a.download = `${fileName}.xlsx`;
            a.click(); 
      },
      (error:any)=>{
        console.log(error)
      }
    );
  }

  print(detail_ordre_paiements : any){
    console.log(detail_ordre_paiements)
    return  detail_ordre_paiements.map((detail_ordre_paiements)=>{
      let t = {}
      t[this.__('ordre_paiement.numero_ordre')] = this.ordre_paiement.numero_ordre
      t[this.__('etudiant.code_nin')] = detail_ordre_paiements.code_nin
      t[this.__('etudiant.nom')] = detail_ordre_paiements.nom
      t[this.__('etudiant.prenom')] = detail_ordre_paiements.prenom
      t[this.__('etudiant.telephone')] = detail_ordre_paiements.telephone
      t[this.__('bachelier.niveau')] = detail_ordre_paiements.niveau
      t[this.__('etudiant.mention')] = detail_ordre_paiements.mention
      t[this.__('ordre_paiement.montant_paye')] = this.getCalculeBourse(detail_ordre_paiements?.bourse)
      t[this.__('etudiant.matching')] =  detail_ordre_paiements.matching ==  1 ? "M1" : (detail_ordre_paiements.matching == 2) ? "M2" : "Autre" 
      return t ;
    })  || [];

  }



   async exportPaiementExcel(fileName) {

    this.helper.exportExcel(this.printPaiement(this.ordre_paiements),this.__("print.list") + this.__("client.clients")).then(
      (response: any)=>{
        console.log('respons beee',response)
            let a = document.createElement("a"); 
            a.href = response.data;
            a.download = `${fileName}.xlsx`;
            a.click(); 
      },
      (error:any)=>{
        console.log(error)
      }
    );
  }

  printPaiement(ordre_paiement:ordre_paiement[]){
    console.log("nom " + ordre_paiement )
    return  ordre_paiement.map((ordre_paiement)=>{
      let t = {}
      t[this.__('ordre_paiement.n_ordre')] = ordre_paiement.numero_ordre 
      t[this.__('ordre_paiement.libelle')] = ordre_paiement.libelle 
      t[this.__('ordre_paiement.montant_global')] = this.formatNumber(ordre_paiement.montant_global, ' ')
      t[this.__('ordre_paiement.nombre_etudiant')] = this.formatNumber(ordre_paiement.nombre_etudiant, ' ')
      t[this.__('ordre_paiement.etablissement')] = ordre_paiement.etablissement?.name 
      t[this.__('ordre_paiement.date')] = ordre_paiement.date_creation
      t[this.__('table.statut')] =  (ordre_paiement.statut == 0 ? this.__("tooltip.attente") : (ordre_paiement.statut == 1) ? this.__('tooltip.valide') : (ordre_paiement.statut == 2) ? this.__('tooltip.rejete') : (ordre_paiement.statut == 3) ? this.__('ordre_paiement.envoie') : this.__('tooltip.brouillon'))

      return t ;
    })  || [];

  }


  async exportORtoExcel() {

    this.helper.exportExcel(this.printPaiementOR(this.ordre_paiements),'Listes ordres des paiments').then(
      (response: any)=>{
        console.log('respons beee',response)
            let a = document.createElement("a"); 
            a.href = response.data;
            a.download = `listes_ordre_paiment.xlsx`;
            a.click(); 
      },
      (error:any)=>{
        console.log(error)
      }
    );
  }

  printPaiementOR(ordre_paiement:any[]){
    return  ordre_paiement.map((ordre_paiement)=>{
      let t = {}
      t['N° ordre'] = ordre_paiement.numero_serie
      t['Libellé'] = ordre_paiement.libelle
      t['Montant global(AR)'] = ordre_paiement.montant_global
      t['Nombre bénéficiaire'] = ordre_paiement.nombre_concernees
      t['Catégorie'] = ordre_paiement.categorie
      t['Date début paiment'] = ordre_paiement.date_debut_paiement
      t['Date fin paiment'] = ordre_paiement.date_fin_paiement
      t['Statut'] = this.checkStatus(ordre_paiement.statut)
      return t ;
    })  || [];

  }

  async exportPdfOR() {
    await this.helper.exportPdf(this.printPaiementOR(this.ordre_paiements),'Listes ordres des paiments','','listes_ordres_paiments');
  }

  checkStatus(status: number | string): string {
    switch(status) {
      case -1:
        return 'Brouillon'
      case 0:
        return 'En attente'
      case 1:
        return 'Validé'
      case 2:
        return 'Envoyé'
      default:
        // code block


    } 
  }
  async downloadCanva(element: any) {
    this.http.get<any>(environment.base_url_crud+'ordrepaiement/CanvaExportOrderPaiement').subscribe(
        response => {
            this.sourcePath = response;
            setTimeout(() => {
                element.click()
            }, 1000);  
        },
        (error) => {
            this.toastr.error(error.message, this.__('msg.error'));

        }
    )
    
}

   async getYearCurrent(year) {
     var monthNames = [
       "Janvier ",
       "Fevrier ",
       "Mars ",
       "Avril ",
       "Mai ",
       "Juin ",
       "Juillet ",
       "Août ",
       "Septembre ",
       "Octobre ",
       "Novembre ",
       "Decembre "
     ];

     return [
       {
         mois: monthNames[0] + year,
         value: "01-" + year
       },
       {
         mois: monthNames[1] + year,
         value: "02-" + year
       },
       {
         mois: monthNames[2] + year,
         value: "03-" + year
       },
       {
         mois: monthNames[3] + year,
         value: "04-" + year
       },
       {
         mois: monthNames[4] + year,
         value: "05-" + year
       },
       {
         mois: monthNames[5] + year,
         value: "06-" + year
       },
       {
         mois: monthNames[6] + year,
         value: "07-" + year
       },
       {
         mois: monthNames[7] + year,
         value: "08-" + year
       },
       {
         mois: monthNames[8] + year,
         value: "09-" + year
       },
       {
         mois: monthNames[9] + year,
         value: "10-" + year
       },
       {
         mois: monthNames[10] + year,
         value: "11-" + year
       },
       {
         mois: monthNames[11] + year,
         value: "12-" + year
       }
     ];
   }
   async getCategorieByIdEtab(id,element) {
    this.categories = [];
    element.clearModel();

    let test = this.etablissements.filter(_=>_.id == id);
    console.log(this.etablissements)
    console.log(test)
    this.type_etablissement_etab_id = test[0].type_etablissement
    console.log("type", this.type_etablissement_etab_id)
    this.show_equipement = false;
    this.ordre_paiement.etat_equipement = '0';
    this.ordre_paiement.equipement = null;

    if(this.type_etablissement_etab_id == 1){
      this.show_equipement = true;
    }
    let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+id, valuesys.httpAuthOptions()).toPromise();
    if(res.code == 200) {
        this.categories = res.data;
    }
}

changeMois(){
  
  //if(this.ordre_paiement.nombre_mois == '0'){
    this.nbMois = this.ordre_paiement.nombre_mois;
  //}
  
}

testMois(itemValue){
  console.log("item ", itemValue)

  let retour = this.tabMois.find(el => el.mois == itemValue);
  console.log("retour, ", retour)
  if(retour =! "undefined"){
    return 1;
  }else{
    return 0;
  }
}

printDetailstOR(ordre_paiements:any[]){
  return  ordre_paiements.map((or)=>{
    let t = {}
    t['Numéro compte'] = or.num_compte
    t['Matricule'] = or.matricule
    t['Nom'] = or.nom
    t['Prénom'] = or.prenom
    t['Téléphone'] = or.telephone
    t['Niveau'] = or.niveau
    t['Type compte '] = or.type_compte == 0 ? this.__('tooltip.compte_paositra') : this.__('tooltip.compte_e_poketra')
    t['Montant à payer'] = formatNumber(or?.montant, ' ')
    t['Statut'] = this.checkStatusOR(or.statut)
    return t ;
  })  || [];
}
async exportPdfDetailsOR() {
  const nb_bnf = this.formatNumber(this.totalDateinfullPage, ' ');
  const  mgp = formatNumber(this.montantGlobal?.montant_paositra, ' ');
  const  mge = formatNumber(this.montantGlobal?.montant_e_poketra, ' ');
  await this.helper.exportPdf(this.printDetailstOR(this.detail_ordre_paiements),`Listes bénéficiaires N° d'ordre: ${this.ordre_paiement.numero_ordre} - ${this.ordre_paiement.etablissement}`,'',`ordre_${this.ordre_paiement.numero_ordre}`,true,nb_bnf,mgp,mge);
}

checkStatusOR(status: number | string): string {
  switch(status) {
    case 0:
      return this.__('tooltip.attente')
    case 1:
      return this.__('tooltip.payee')
    case -1:
      return this.__('tooltip.echec')
    default:
      // code block


  } 
}




async resumeNbEtudiantParNiveau(idOrdre : any = ''){
  console.log('33333333333', idOrdre)

   let toogle = await this.http.get<any>(environment.detail_ordre_paiement + "/resume/" + idOrdre,valuesys.httpAuthOptions()).toPromise();
    console.log("url in data", toogle)
    this.countResume = toogle.data.lenght;
    let tabEtudiantResume = [];
    tabEtudiantResume = toogle.data[0].nombre_par_niveau;

    this.nb_etudiant_par_niveau = new nb_etudiant_par_niveau();
    this.bourse_par_code = new bourse_par_code();
    this.bourse_par_nb_etudiant = new bourse_par_nb_etudiant();
    this.total_bourse_par_niveau = new total_bourse_par_niveau();
    this.total_bourse_avec_prime = new total_bourse_avec_prime();
    this.total_bourse_nb_mois_avec_prime = new total_bourse_nb_mois_avec_prime();
    
    let niveaux =["L1","L2", "L3", "M1", "M2", "DR"];
    let total : number = 0;
    let totalMontant : number = 0;
    for (let i = 0; i < niveaux.length; i++ ) {
        let code = niveaux[i];
      
        let tabPassant = tabEtudiantResume.filter(_ => _.code == code && _.code_redoublement == "N");

        console.log("xx", tabPassant);
        //-- -------------------- Passant -------------------------
        //-- nombre étudiant
        //-- montant bourse par mois
        //-- montant total bourse par nombre d'étudiant
        if(code == "L1" && tabPassant.length != 0){  
          this.nb_etudiant_par_niveau.nbL1_passant = tabPassant[0].total_par_code_redoublement; 
          this.bourse_par_code.montantL1_passant = tabPassant[0].montant;
          this.bourse_par_nb_etudiant.montantTotalL1_passant = tabPassant[0].montant_total_par_nb_etudiant;
        }
        if(code == "L2" && tabPassant.length != 0){  
          this.nb_etudiant_par_niveau.nbL2_passant = tabPassant[0].total_par_code_redoublement; 
          this.bourse_par_code.montantL2_passant = tabPassant[0].montant;
          this.bourse_par_nb_etudiant.montantTotalL2_passant = tabPassant[0].montant_total_par_nb_etudiant;
        }
        if(code == "L3" && tabPassant.length != 0){  
          this.nb_etudiant_par_niveau.nbL3_passant = tabPassant[0].total_par_code_redoublement; 
          this.bourse_par_code.montantL3_passant = tabPassant[0].montant;
          this.bourse_par_nb_etudiant.montantTotalL3_passant = tabPassant[0].montant_total_par_nb_etudiant;
        }
        if(code == "M1" && tabPassant.length != 0){  
          this.nb_etudiant_par_niveau.nbM1_passant = tabPassant[0].total_par_code_redoublement; 
          this.bourse_par_code.montantM1_passant = tabPassant[0].montant;
          this.bourse_par_nb_etudiant.montantTotalM1_passant = tabPassant[0].montant_total_par_nb_etudiant;
        }
        if(code == "M2" && tabPassant.length != 0){  
          this.nb_etudiant_par_niveau.nbM2_passant = tabPassant[0].total_par_code_redoublement; 
          this.bourse_par_code.montantM2_passant = tabPassant[0].montant;
          this.bourse_par_nb_etudiant.montantTotalM2_passant = tabPassant[0].montant_total_par_nb_etudiant;
        }
        if(code == "DR" && tabPassant.length != 0){  
          this.nb_etudiant_par_niveau.nbDR_passant = tabPassant[0].total_par_code_redoublement; 
          this.bourse_par_code.montantDR_passant = tabPassant[0].montant;
          this.bourse_par_nb_etudiant.montantTotalDR_passant = tabPassant[0].montant_total_par_nb_etudiant;
        }
        if(tabPassant.length != 0) {
          total = total + tabPassant[0].total_par_code_redoublement;
          console.log("--total-", totalMontant)
          console.log("---", tabPassant[0].montant_total_par_nb_etudiant)
          totalMontant = totalMontant + tabPassant[0].montant_total_par_nb_etudiant;
        }
        //--------------------- fin Passant -------------------



        let tabRedoublant = tabEtudiantResume.filter(_ => _.code == code && _.code_redoublement == "R");

        //-- -------------------- Redoublant -------------------------
        //-- nombre étudiant
        //-- montant bourse par mois
        //-- montant total bourse par nombre d'étudiant
        if(code == "L1" && tabRedoublant.length != 0){  
          this.nb_etudiant_par_niveau.nbL1_redoublant = tabRedoublant[0].total_par_code_redoublement; 
          this.bourse_par_code.montantL1_redoublant = tabRedoublant[0].montant;
          this.bourse_par_nb_etudiant.montantTotalL1_redoublant= tabRedoublant[0].montant_total_par_nb_etudiant;
        }
        if(code == "L2" && tabRedoublant.length != 0){  
          this.nb_etudiant_par_niveau.nbL2_redoublant = tabRedoublant[0].total_par_code_redoublement; 
          this.bourse_par_code.montantL2_redoublant = tabRedoublant[0].montant;
          this.bourse_par_nb_etudiant.montantTotalL2_redoublant= tabRedoublant[0].montant_total_par_nb_etudiant;
        
        }
        if(code == "L3" && tabRedoublant.length != 0){  
          this.nb_etudiant_par_niveau.nbL3_redoublant = tabRedoublant[0].total_par_code_redoublement; 
          this.bourse_par_code.montantL3_redoublant = tabRedoublant[0].montant;
          this.bourse_par_nb_etudiant.montantTotalL3_redoublant= tabRedoublant[0].montant_total_par_nb_etudiant;
      
        }
        if(code == "M1" && tabRedoublant.length != 0){  
          this.nb_etudiant_par_niveau.nbM1_redoublant = tabRedoublant[0].total_par_code_redoublement; 
          this.bourse_par_code.montantM1_redoublant = tabRedoublant[0].montant;
          this.bourse_par_nb_etudiant.montantTotalM1_redoublant= tabRedoublant[0].montant_total_par_nb_etudiant;
        
        }
        if(code == "M2" && tabRedoublant.length != 0){  
          this.nb_etudiant_par_niveau.nbM2_redoublant = tabRedoublant[0].total_par_code_redoublement; 
          this.bourse_par_code.montantM2_redoublant = tabRedoublant[0].montant;
          this.bourse_par_nb_etudiant.montantTotalM2_redoublant= tabRedoublant[0].montant_total_par_nb_etudiant;
        }
        if(code == "DR" && tabRedoublant.length != 0){  
          this.nb_etudiant_par_niveau.nbDR_redoublant = tabRedoublant[0].total_par_code_redoublement; 
          this.bourse_par_code.montantDR_redoublant = tabRedoublant[0].montant;
          this.bourse_par_nb_etudiant.montantTotalDR_redoublant= tabRedoublant[0].montant_total_par_nb_etudiant;
        
        }
        if(tabRedoublant.length != 0) {
          total = total + tabRedoublant[0].total_par_code_redoublement;
          console.log("--total-", totalMontant)
          console.log("---", tabRedoublant[0].montant_total_par_nb_etudiant)
          totalMontant = totalMontant + tabRedoublant[0].montant_total_par_nb_etudiant;
        }
        //--------------------- fin Redoublant ----------------

    }

    //--------total nombre d'étudiant par niveau--------
    this.nb_etudiant_par_niveau.totalL1 = this.nb_etudiant_par_niveau.nbL1_passant + this.nb_etudiant_par_niveau.nbL1_redoublant;
    this.nb_etudiant_par_niveau.totalL2 = this.nb_etudiant_par_niveau.nbL2_passant + this.nb_etudiant_par_niveau.nbL2_redoublant;
    this.nb_etudiant_par_niveau.totalL3 = this.nb_etudiant_par_niveau.nbL3_passant + this.nb_etudiant_par_niveau.nbL3_redoublant;
    this.nb_etudiant_par_niveau.totalM1 = this.nb_etudiant_par_niveau.nbM1_passant + this.nb_etudiant_par_niveau.nbM1_redoublant;
    this.nb_etudiant_par_niveau.totalM2 = this.nb_etudiant_par_niveau.nbM2_passant + this.nb_etudiant_par_niveau.nbM2_redoublant;
    this.nb_etudiant_par_niveau.totalDR = this.nb_etudiant_par_niveau.nbDR_passant + this.nb_etudiant_par_niveau.nbDR_redoublant;
    this.nb_etudiant_par_niveau.totalToutNiveau = total;
    //-------- FIN total nombre d'étudiant par niveau--------


    //-------- total montant bourse du mois des étudiants par niveau --------
    this.total_bourse_par_niveau.montantTotalL1 = this.bourse_par_nb_etudiant.montantTotalL1_passant + this.bourse_par_nb_etudiant.montantTotalL1_redoublant;
    this.total_bourse_par_niveau.montantTotalL2 = this.bourse_par_nb_etudiant.montantTotalL2_passant + this.bourse_par_nb_etudiant.montantTotalL2_redoublant;
    this.total_bourse_par_niveau.montantTotalL3 = this.bourse_par_nb_etudiant.montantTotalL3_passant + this.bourse_par_nb_etudiant.montantTotalL3_redoublant;
    this.total_bourse_par_niveau.montantTotalM1 = this.bourse_par_nb_etudiant.montantTotalM1_passant + this.bourse_par_nb_etudiant.montantTotalM1_redoublant;
    this.total_bourse_par_niveau.montantTotalM2 = this.bourse_par_nb_etudiant.montantTotalM2_passant + this.bourse_par_nb_etudiant.montantTotalM2_redoublant;
    this.total_bourse_par_niveau.montantTotalDR = this.bourse_par_nb_etudiant.montantTotalDR_redoublant + this.bourse_par_nb_etudiant.montantTotalDR_passant;
    this.bourse_par_nb_etudiant.montantTotalToutNiveau = totalMontant;
    //--------FIN total montant bourse du mois des étudiants par niveau --------


    //-------- total montant bourse du mois des étudiants par niveau avec prime --------
    this.total_bourse_avec_prime.montantL1_avecPrime = this.nb_etudiant_par_niveau.totalL1 * this.ordre_paiement.equipement;
    this.total_bourse_avec_prime.montantL2_avecPrime = this.nb_etudiant_par_niveau.totalL2 * this.ordre_paiement.equipement;
    this.total_bourse_avec_prime.montantL3_avecPrime = this.nb_etudiant_par_niveau.totalL3 * this.ordre_paiement.equipement;
    this.total_bourse_avec_prime.montantM1_avecPrime = this.nb_etudiant_par_niveau.totalM1 * this.ordre_paiement.equipement;
    this.total_bourse_avec_prime.montantM2_avecPrime = this.nb_etudiant_par_niveau.totalM2 * this.ordre_paiement.equipement;
    this.total_bourse_avec_prime.montantDR_avecPrime = this.nb_etudiant_par_niveau.totalDR * this.ordre_paiement.equipement;
    this.total_bourse_avec_prime.montantTotalToutNiveau_avecPrime = this.total_bourse_avec_prime.montantL1_avecPrime + this.total_bourse_avec_prime.montantL2_avecPrime +  this.total_bourse_avec_prime.montantL3_avecPrime +  this.total_bourse_avec_prime.montantM1_avecPrime +  this.total_bourse_avec_prime.montantM2_avecPrime +  this.total_bourse_avec_prime.montantDR_avecPrime   ;
    //-------- FIN total montant bourse du mois des étudiants par niveau avec prime --------

      
    //-------- total montant bourse du mois des étudiants par niveau  avec prime et nombre des mois --------
    this.total_bourse_nb_mois_avec_prime.montantL1_nb_mois_avecPrime = (this.total_bourse_par_niveau.montantTotalL1 * this.ordre_paiement.nombre_mois) + this.total_bourse_avec_prime.montantL1_avecPrime;
    this.total_bourse_nb_mois_avec_prime.montantL2_nb_mois_avecPrime = (this.total_bourse_par_niveau.montantTotalL2 * this.ordre_paiement.nombre_mois) + this.total_bourse_avec_prime.montantL2_avecPrime;
    this.total_bourse_nb_mois_avec_prime.montantL3_nb_mois_avecPrime = (this.total_bourse_par_niveau.montantTotalL3 * this.ordre_paiement.nombre_mois) + this.total_bourse_avec_prime.montantL3_avecPrime;
    this.total_bourse_nb_mois_avec_prime.montantM1_nb_mois_avecPrime = (this.total_bourse_par_niveau.montantTotalM1 * this.ordre_paiement.nombre_mois) + this.total_bourse_avec_prime.montantM1_avecPrime;
    this.total_bourse_nb_mois_avec_prime.montantM2_nb_mois_avecPrime = (this.total_bourse_par_niveau.montantTotalM2 * this.ordre_paiement.nombre_mois) + this.total_bourse_avec_prime.montantM2_avecPrime;
    this.total_bourse_nb_mois_avec_prime.montantDR_nb_mois_avecPrime = (this.total_bourse_par_niveau.montantTotalDR * this.ordre_paiement.nombre_mois) + this.total_bourse_avec_prime.montantDR_avecPrime;
    this.total_bourse_nb_mois_avec_prime.montantTotalToutNiveau_nb_mois_avecPrime = this.total_bourse_nb_mois_avec_prime.montantL1_nb_mois_avecPrime + this.total_bourse_nb_mois_avec_prime.montantL2_nb_mois_avecPrime +  this.total_bourse_nb_mois_avec_prime.montantL3_nb_mois_avecPrime +  this.total_bourse_nb_mois_avec_prime.montantM1_nb_mois_avecPrime +  this.total_bourse_nb_mois_avec_prime.montantM2_nb_mois_avecPrime +  this.total_bourse_nb_mois_avec_prime.montantDR_nb_mois_avecPrime   ;
    //-------- FIN total montant bourse du mois des étudiants par niveau avec prime et nombre des mois --------

    

}

listRecap(){
  console.log("------", this.countResume)
  if(this.countResume == 0){
    this.resumeNbEtudiantParNiveau(this.ordre_paiement.id);
  }
}


download(document) {
  console.log("document",document)
  const docSplit = document.name.split("/")
  const extensionsTAb = docSplit[docSplit.length - 1].split(".");
  this.helper.dowloadFile(document.name,extensionsTAb[0],extensionsTAb[1]);
}

  /*IMAGE ADD*/
  getUrl(url:string)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

handleInputChangePJ(e) {

  console.log(e);
  var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
  if (!files) {
    return;
  }
  for (var i = 0; i < files.length; i++) {
    if (files[i].size > 1000*1000 * 100) {
      this.toastr.error("taille image trop grande", "Error");
      $("#document").val("");
      return;
    }
    var reader = new FileReader();
  
    reader.onload = this._handleReaderLoadedPJ.bind(this);
    reader.readAsDataURL(files[i]);
  }
}
addImage(documentFile: HTMLInputElement) {
  documentFile.click()
}

async deleteImg(document: documentPj, img: HTMLElement) {
  img.classList.add('d-none')
  if (document.id) {
    let {code,msg} = await this.http.delete<HttpApiResponse>(environment.document + "/" + document.id ,constante.headers()).toPromise();
    this.toastr.success(msg,this.__('label.success'))
    this.documentsPJ = this.documentsPJ.filter(d => d !== document);
  } else {
    this.documentsPJ = this.documentsPJ.filter(d => d !== document);
  }
  img.classList.remove('d-none')

}

_handleReaderLoadedPJ(e) {
  let reader = e.target;
  let d = new documentPj();
  d.name = reader.result
  if (this.documentsPJ.length < 5)
    this.documentsPJ.push(d)
  console.log(this.documentsPJ)
  //this.partenaire.logo = reader.result;
}
async updatePJ() {


  console.log("photo", this.documentsPJ)

/*  let tabDocument : any = [];
for (let index = 0; index < this.documentsPJ.length; index++) {
  const element = this.documentsPJ[index].name;
  tabDocument.push({ name: element })

}*/

  this.Postdocument.ordre_paiement_id = this.ordre_paiement.id;
  this.Postdocument.document = this.documentsPJ;

  let res:any =  await this.http.post<any>(environment.ordre_paiement+'/upload_document_ordre_paiement',this.Postdocument,valuesys.httpAuthOptions()).toPromise();
  console.log(res)
    //--success
    if(res.code === 201){

      this.toastr.success(res.msg,this.__("msg.success")) ;
      this.close()
    //--Warning : Donnée invalide, Session expiré
    }
 
}


}

