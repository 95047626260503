import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { FalseLiteral } from 'typescript';
import { environment } from '../../../../../../../environments/environment';
import { valuesys } from '../../../../../../../options';
import { HttpApiResponse } from '../../../../../model/DataTablesResponse';
import { etablissement } from '../../../../../model/db';
import { carte, concerner } from '../../../../../model/pension';
import { AuthServiceService } from '../../../../../service/auth-service.service';
import { HelperService } from '../../../../../service/helper.service';
import { Translatable } from '../../../../../service/Translatable';
import Swal from "sweetalert2";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { datatableFr } from '../../../../../helpers/validators/datatable-fr';
import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
  selector: 'app-active-desactive-carte',
  templateUrl: './active-desactive-carte.component.html',
  styleUrls: ['./active-desactive-carte.component.scss']
})
export class ActiveDesactiveCarteComponent extends Translatable implements OnInit,OnDestroy{

  public concerner:concerner = new concerner();
  public concernerList:concerner[] = [];
  documents: any [] = [];
  matricule: string;
  numero_serie: string;
  etablissementId: number | string;
  public carteArray :carte[] = [] ;
  public etablissements:etablissement [] = [];
  superAdmin: number;
  currentEtabId: number | string;
  submitted: boolean;
  searchCarteActive: boolean;
  searchNumSerieSubmitted: boolean = false;
  situationCarteUpdated: number;
  requiredError: boolean = false;
  filename: string = "";
  dtOptionsToInsert: DataTables.Settings = {};
  dtOptionsError: DataTables.Settings = {};
  validatedExcel: boolean =  false

  readyToInsertList: any[] = []
  haveErrorList: any[];
  responseRecap: any = {};
  dtTriggerInsert: Subject<any> = new Subject<any>();
  dtTriggerError: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  isValid: boolean = false;
  numeroSerieExistInEtab: boolean = false;
  step2Carte: boolean = false;
  activeEtabId: number;
  constructor(
    private http: HttpClient,
    private helper: HelperService,
    private auth: AuthServiceService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,  
  ) {
    super();
  }

  async ngOnInit() {
    this.auth.initAutority(["GCA","DCA","AFC","ACD","ENC"]);
    this.dtOptionsToInsert = {
      pagingType: 'full_numbers',
      language: datatableFr,
      destroy:true
    };
    this.dtOptionsError = {
      pagingType: 'full_numbers',
      language: datatableFr,
      destroy:true
    };

    this.dtOptions[0] = {
      pagingType: 'full_numbers',
      language: datatableFr,
      destroy:true
    };
    this.dtOptions[1] = this.dtOptionsError = {
      pagingType: 'full_numbers',
      language: datatableFr,
      destroy:true
    };










    let userinfo = this.auth.account();
       
        this.superAdmin = userinfo.info.admin; 
        this.activeEtabId = userinfo.info.etablissement_id; 

        if(this.superAdmin != 1) {
          this.etablissementId = userinfo.info.etablissement_id;
        }else {
          this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);

        }
        
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTriggerInsert.unsubscribe();
    this.dtTriggerError.unsubscribe();

  }
  async getBeneficiaireByMatricule() {
    this.submitted = false;
    this.requiredError = false;
    if(!this.currentEtabId){
      this.requiredError = true;
      return
    }
    this.requiredError = false;
    this.searchCarteActive = false;
    let response: any = await this.http.get<HttpApiResponse>(environment.pension_concerners + "?page=1&where=concernees.matricule|e|"+this.matricule+",concernees.etablissement_id|e|"+this.currentEtabId,valuesys.httpAuthOptions()).toPromise();
    this.concernerList = response.data["data"]
    if(this.concernerList.length != 0) {
      this.concerner = response.data["data"][0];
      this.submitted = true
      return
    }
    this.submitted = true
  }
  async searchCarte() {
    // this.submitted = false;
    this.step2Carte = false;
    this.numeroSerieExistInEtab = false;
    this.searchNumSerieSubmitted = false;
    
    this.carteArray = [];
    const responseNumSerie: any =await this.http.get(environment.pension_cartes+"?page=1&where=carte.numero_serie|e|"+this.numero_serie,valuesys.httpAuthOptions()).toPromise();
    if(responseNumSerie.code == 200) {
      this.carteArray = responseNumSerie.data.data
      if(this.carteArray.length == 0) {
        this.numeroSerieExistInEtab = false;
        this.searchNumSerieSubmitted = true

        return false
      }else {
        this.step2Carte = true;
        this.numeroSerieExistInEtab = true;
        const response: any =await this.http.get(environment.pension_cartes+"?page=1&where=carte.numero_serie|e|"+this.numero_serie+",carte.etablissement_id|e|"+this.etablissementId,valuesys.httpAuthOptions()).toPromise();
        if(response.code == 200) this.carteArray = response.data.data
        console.log(this.carteArray,"crte array")
        this.searchNumSerieSubmitted = true;
      }
    }









  }

  activePanelSearchCarte() {
    this.searchCarteActive = true;
    this.etablissementId = this.concerner.etablissement_id;  
  }
  situationCarte(situation: number): string {
    switch (situation) {
      case 0:
        return this.__('carte.situation_0');
      case 1:
        return this.__('carte.situation_1');
      case 2:
        return this.__('carte.situation_2');
      case 3:
        return this.__('carte.situation_3');
      case 4:
          return this.__('carte.situation_4');
      default:
        break;
    }
  }
  async confirmAffectation(num_serie)
    {
        Swal.fire({
            title: this.__('msg.confirm')+" de vouloir" ,
            text: "Affecter la carte n° "+ num_serie +" au bénéficiaire : " + this.concerner.prenom + " "+ this.concerner.nom + " ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#44A1A0',
            cancelButtonColor: '#FF7467',
            reverseButtons : true,
            confirmButtonText: "Confirmer",
            cancelButtonText: "Annuler"

        }).then(async (result) => {

            if (result.value) {
                let sup = await this.http.post<HttpApiResponse>(environment.carte_concerner+"/affectation" , {"concerne_id":this.concerner.concernees_id, "numero_serie":num_serie},valuesys.httpAuthOptions()).toPromise();
                
                if(sup.code == 201){
                  Swal.fire({
                    icon: 'success',
                    title: "Donnée enregistrée",
                    confirmButtonColor: '#44A1A0',
                    text: sup.msg,
                  }).then((resultSuccess) => {
                      if(resultSuccess)
                      {
                        this.resetVariable();
                          // this.router.navigateByUrl(['/dashboard/BNF/plateforme/concernee/affectation-carte'],{state: {tabIndexActive: 1}});
                      }
                  })
                  // let response = await this.http.get<HttpApiResponse>(environment.pension_concerners + "/getConcernerByID ?page=1",valuesys.httpAuthOptions()).toPromise();
                  // if(response.code == 200) {
                  //   this.concerner = response.data["data"][0];
                    
                  // }
                 
                }else {
                    this.toastr.error(sup.data,this.__("msg.error"));
                }
            }
        })
    }
    goToCarte() {
      this.router.navigateByUrl('/dashboard/BNF/plateforme/concernee/affectation-carte',{state: {idBeneficiaire: this.concerner.concernees_id,tabIndexActive: 1}});
    }
    resetVariable() {
      this.searchCarteActive = false;
      this.searchNumSerieSubmitted = false;
      this.matricule = "";
      this.concerner = new concerner()
      this.concernerList = [];
      this.submitted = false;
      this.numero_serie = ""
      this.etablissementId = "";
      if(this.superAdmin == 1) this.currentEtabId = '';
    }
    async retirerCarte() {
      Swal.fire({
        title: this.__('msg.confirm'),
        text: "Retirer la carte?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#44A1A0',
        cancelButtonColor: '#FF7467',
        reverseButtons : true,
        confirmButtonText: "Retirer la carte",
        cancelButtonText: "Annuler"
      }).then(async (result) => {
        if (result.value) {
            let res =  await this.http.post<any>( environment.pension_retirer_carte,{
                numero_serie: this.carteArray[0].numero_serie
            },valuesys.httpAuthOptions()).toPromise();
              //--success
              if(res.code === 200){
                this.situationCarteUpdated = 0;
                // this.searchNumSerieSubmitted = false;
                (this.carteArray[0] as any).affected = 0
                console.log(this.carteArray,'retire')
                this.toastr.success(res.msg,this.__("msg.success")) ;
              //--Warning : Donnée invalide, Session expiré
              }else if(res.code === 400){
                this.toastr.warning(res.msg,this.__("msg.warning")) ;
              }
              //--error
              else {
                this.toastr.error(res.data,this.__("msg.error")) ;
              }
    
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            {
              icon: 'error',
              title: "Action aborter!",
              confirmButtonColor: '#44A1A0',
              text: this.__('msg.canceled_delete'),
            }
          )
        }
      })
  }

  // import 
  openModalImportExcel(template_3:TemplateRef<any>) {
    console.log("xxxx")
    
    this.modalService.open(template_3, { size: 'xl', backdrop:"static"  });
  } 
  handleInputChange(e,modelExcel: HTMLTemplateElement) {
    this.resetExcel();
    this.documents = [];
    this.filename = '';
    this.isValid = false;
    this.validateHeaderexcel(e,modelExcel)
    console.log(e);
    var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    if (!files) {
      return;
    }

    this.filename = files[0].name;
    for (var i = 0; i < files.length; i++) {
      if (files[i].size > 1000*1000 * 100) {
        this.toastr.error("taille image trop grande", "Error");
        $("#document").val("");
        return;
      }
      var reader = new FileReader();
    
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(files[i]);
    }
  }
  _handleReaderLoaded(e) {
    let reader = e.target;
    let d :any = {};
    console.log("reader",reader)
    d.name = reader.result
    this.documents[0] = d
    console.log(this.documents)
    //this.partenaire.logo = reader.result;
  }

  close(){
  
    this.modalService.dismissAll();
    this.resetExcel();
    this.filename = '';
    this.documents = [];
   
  }

  importExcel(documentFile: HTMLInputElement) {
     
    documentFile.click()
  }
  
  async  getRacpExcel() {
    this.resetExcel();

    let response = await this.http.post<HttpApiResponse>(environment.carte_concerner+"/readingFileAffectation", {fileXls:this.documents[0].name},valuesys.httpAuthOptions()).toPromise();
    
    if(response.code == 200) {
      this.responseRecap = response.data as any
      this.validatedExcel = true;

      this.readyToInsertList = response.data.ready_to_insert;
      this.dtTriggerInsert.next();
      this.haveErrorList = response.data.error;
      this.dtTriggerError.next();
      console.log(this.responseRecap,"respons reacpitulatif", this.readyToInsertList, this.haveErrorList)

    }else {
      this.toastr.error(response.msg,this.__("msg.error"));

    }
    console.log(response,"documetn")
  }

  async  submitAffectationExcel() {

    Swal.fire({
      title: this.__('msg.confirm')+" de vouloir" ,
      text: "Affecter le(s) "+this.readyToInsertList.length+" carte(s)  ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: "Confirmer",
      cancelButtonText: "Annuler"

  }).then(async (result) => {

      if (result.value) {
        let response = await this.http.post<HttpApiResponse>(environment.carte_concerner+"/readingFileAffectationInsert ", {fileXls:this.documents[0].name},valuesys.httpAuthOptions()).toPromise();
          
          if(response.code == 200){
            Swal.fire({
              icon: 'success',
              title: "Donnée enregistrée",
              confirmButtonColor: '#44A1A0',
              text: "Afféctation terminée avec succès",
            }).then((resultSuccess) => {
                if(resultSuccess)
                {
                  this.resetExcel()
                  this.documents = [];
                  this.filename = '';

                  this.close();
                }
            })  
          }else {
              this.toastr.error(response.msg,this.__("msg.error"));
          }
      }
  })
  }



  resetExcel() {
    this.readyToInsertList = [];
    this.haveErrorList = [];
    this.validatedExcel = false;
  }
  validateHeaderexcel(evt: any,modelExcel) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];



      /* save data */
      const dataExcel:any = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

      const headers: any[] = dataExcel[0] as [];
      console.log(headers,'headeres')

      if(headers.length != 2 || headers[0] != 'matricule' ||  headers[1] != 'numero_serie') {
        Swal.fire({
          icon: 'error',
          title: 'Désolé...',
          text: 'Respecter le format du fichier excel \n[matricule|numero_serie]',
          confirmButtonColor: '#44A1A0',
        })
        this.filename = ''
        this.documents = [];
        this.isValid = false;
        return
      }

    };
    this.isValid = true;
    reader.readAsBinaryString(target.files[0]);
  }

  async activeAndDesactiveCarete(num_serie: number,state: number) {
    

    console.log(this.etablissementId,"xxxe tab id")
    this.submitted = true;
    Swal.fire({
      title: this.__('msg.confirm')+" de vouloir" ,
      text:  state == 0 ? 'Activer la carte n° '+this.numero_serie: 'Désactiver'+" la carte n° "+this.numero_serie,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: "Confirmer",
      cancelButtonText: "Annuler"

  }).then(async (result) => {

      if (result.value) {
        let response = await this.http.post<any>(state == 1 ? environment.pension_desactiv_carte : environment.pension_active_carte,{
          "numero_serie": this.numero_serie
        },valuesys.httpAuthOptions()).toPromise();          
          if(response.code == 200){
            Swal.fire({
              icon: 'success',
              title: "Donnée enregistrée",
              confirmButtonColor: '#44A1A0',
              text: state == 0 ? 'Activation': 'Désactivation'+"Désactivation avec succès",
            }).then((resultSuccess) => {
                if(resultSuccess)
                {
                  this.carteArray = [];
                  this.etablissementId = "";
                  this.numero_serie = '';
                  this.submitted = false;
                  this.searchNumSerieSubmitted = false;
                }
            })  
          }else {
            
            Swal.fire(
              {
                icon: 'error',
                title: "Erreur!",
                confirmButtonColor: '#44A1A0',
                text: response.data
              }
            )
              // this.toastr.error(response.data,this.__("msg.error"));
          }
      }
  })
  }

}
