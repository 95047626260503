import { AfterViewChecked, Component, DoCheck, OnChanges, OnInit} from '@angular/core';
import { navItems } from '../../_nav';
import { INavData } from '@coreui/angular';
import { Translatable } from '../../administration/service/Translatable';
import { AuthServiceService} from "../../administration/service/auth-service.service";
import { Auth,profilage_module,profilage_droit,profilage_sous_module} from "../../administration/model/db";
import { Router,ActivatedRoute } from '@angular/router';
import Swal from "sweetalert2";
import {environment} from "../../../environments/environment";
import { DataSharingService } from '../../administration/service/data-sharing/data-sharing.service';

@Component({
  selector: 'app-default-for-me',
  templateUrl: './default-for-me.component.html',
  styleUrls: ['./default-for-me.component.scss']
})
export class DefaultForMeComponent extends Translatable implements OnInit {

  public sidebarMinimized = false;
  public navItems = navItems;

  public navModule : INavData[] = [];
  sideBar: any;
  public user : Auth = new Auth();
  module:profilage_module  = new profilage_module();
  moduleCode : String;

  constructor(private activatedRoute: ActivatedRoute,private router : Router,private auth : AuthServiceService,private dataSharingService: DataSharingService){
    super();
  }

  async ngOnInit() {
     this.dataSharingService.$profilImageSubject.subscribe(
      (response: string) => {
        // 
        try {
          this.user.info.avatar = response 
        } catch (error) {
          console.log(error)
        }     
      }
    )
   this.activatedRoute.params.subscribe(async _ => {
     let moduleCode = _.moduleCode;
     this.moduleCode = _.moduleCode;
     
     window['module'] = moduleCode;
     this.user = <Auth> await  this.auth.getLoginUser();
     if(this.user){
       this.module = this.user.modules.find((module : profilage_module) => module.code === moduleCode) ;
       if(!this.module){
         //alert('no find module')
       }
     }
   });
   
  }



  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }


  logout(){
    //btn.disabled = true;
    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmeLogOut'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      confirmButtonText: this.__('swal.yes'),
      cancelButtonText: this.__('swal.no'),
      reverseButtons : true
    }).then(async (result) => {
      if (result.value) {
      return  await this.router.navigate(['/logout'])
      //return  window.location.assign('/');
      }
      //btn.disabled = false;
    })
  }

  goToMe() : void{
    this.router.navigate(['/app/profile']);
  }

}
