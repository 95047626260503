import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {valuesys} from "../../../options";
import {Router} from "@angular/router";
import Login from "../model/Login";
import {Auth} from "../model/db";
import {HttpApiResponse} from "../model/DataTablesResponse";

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(private httpClient: HttpClient,private  router: Router) {
    this.getLoginUser(true);
  }

 public async setLoginUser(user:any){
    try {
      if(user){
        localStorage.setItem(environment.userItemName, JSON.stringify(user));
        return  true  
      }
      return false ;
    } catch (e) {
      return  false ;
    } 
  } 
 
 
  /*
  * Permet de retourner l'utilisateur actuellement connecté
  * le reload a true permet de faire une appel a api gatewate pour rafraissir
  * le reload a false permet de prendre l'utilisateur stocker en local
  * Si le reload est a false et que l'utilisateur actuel est null on rafraissit aussi
  * */
 public account(reload:boolean = false): Auth{
    try {
       let user:Auth = null;
       user = <Auth> JSON.parse(localStorage.getItem(environment.userItemName) || null);
       return user ;
    } catch (e) {
         //this.router.navigate(["/logout"]) ;
         return  null ;
    }
  }
  /*
  * Code sous module un tableau ou un string | le premier element du tableau on ne renseigne pas son code de module
  * Mais le reste du table on doit renseignez leur code de module dans modules
  * */
 public initAutority(codeSousModule:string|Array<string>,modules:Array<string> = []){
   if(typeof codeSousModule === 'string'){
     codeSousModule = [codeSousModule]
   }

   modules.push(window['module'].toString())
   window['authority'] =[];
   window['actions'] = null;
   window['authority']['module'] =modules;
   window['authority']['sous_module'] = codeSousModule;
   let user:Auth ;
   //user = <Auth> JSON.parse(localStorage.getItem(environment.userItemName) || null);
   user = <Auth> JSON.parse(localStorage.getItem(environment.userItemName) || null);
   window['authority']['user'] = user; 
  }

 public async  getLoginUser(reload:boolean = false){
    try {
      let user ={};
      if(reload || !localStorage.getItem(environment.userItemName)){
        let res = await this.httpClient.get<any>(environment.baseUrl + environment.userAuth, valuesys.httpAuthOptions()).toPromise() ;
        if(res.code === 200){
          user = res.data ;
          this.setLoginUser(user) ;
        }else {
          await  this.router.navigate(["/logout"])
        }
      }
      user = <Auth> JSON.parse(localStorage.getItem(environment.userItemName) || null);
      return user ;
    } catch (e) {
        await  this.router.navigate(["/logout"]) ;
      return null ;
    } 
  }

  public async isConnected(){}

  public setToken(token:string,expires_in:number){
    localStorage.setItem(environment.authItemName, token);
    let t = new Date();
    t.setSeconds(t.getSeconds() + (expires_in));
    localStorage.setItem(valuesys.timeTokenName, t.getTime() + "");
  }

  public async getMenu(basemodule : string, code = null){}

  async loginUser(login:Login){

  try {
      let response: any = await this.httpClient.post<any>(environment.baseUrl + environment.login, login, valuesys.httpOptions).toPromise();
      console.log(response);

      if (response.code === 200) {
        return  response ;
      } else {
        return response ;
      }
    } catch (e) {
      console.log(e)
      return  false ;
    }
  }

  async refreshToken(){
   setTimeout(async ()=>{
     if(AuthServiceService.refresh()){
       let {data,code} = await this.httpClient.get<HttpApiResponse>(environment.baseUrl+environment.refresh,valuesys.httpAuthOptions()).toPromise();
       if(code === 200){
         this.setToken(data.access_token,data.expires_in)
       }
     }
   },70) 
  }

  private static refresh(){
   let timeToken_ =  +window.localStorage.getItem(valuesys.timeTokenName) || 0;
    let now = (new Date()).getTime();
    console.log((timeToken_ - now) / 1000 );
   return timeToken_ - now   <= valuesys.authRefreshInterval;
  }
}
