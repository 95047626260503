<br><div class="repMenu">
    <!-- <div class="row">
        <div class="col-lg-8"><span class="ariare" [routerLink]="['/app']" style="cursor: pointer;" >{{__('menu.accueil')}} </span>  > <span class="ariare" [routerLink]="['/app/module/PAC']" style="cursor: pointer;"  >{{__('menu.parametre')}} </span>  > <span class="ariare"> {{__('parcours.title')}}</span></div>


    </div> -->
    <div class="row  d-flex  justify-content-start">
        
            
        <div class="breadcrumb flat">
          <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
          <a [routerLink]="['/app/module/HST']" routerLinkActive="active">Historique</a>
          <a [routerLink]="['/dashboard/HST/plateforme/journalbord']"  routerLinkActive="active">Journal de bord</a>
          <a [routerLink]="['/dashboard/HST/plateforme/historiquecashflow']"  routerLinkActive="active">Cash flow</a>
        </div>
    </div>
</div>