/* tslint:disable */
import { UEModel } from './ue.model';
//schemats generate -c mysql://root:root@crud-phco.dev.local/phco_preprod -o db.ts

/**
 * AUTO-GENERATED FILE @ 2020-03-24 01:44:16 - DO NOT EDIT!
 *
 * This file was automatically generated by schemats v.3.0.3
 * $ schemats generate -c mysql://username:password@localhost/jirama_db -t abonnement -t action -t action_profil -t agence -t client -t compteur -t document -t facture -t incident -t module -t partenaire -t plateforme -t profil -t reclamation -t service -t sous_module -t token -t transaction -t type_action -t type_client -t type_compteur -t user -s jirama_db
 *
 */

export class plateforme {
  id: number ;
  name: string ;
  code: string ;
  description: string | null ;
  state: number | null ;
  users: user [] | null | [] ;

}

export class etudiant {
  id: number ;
  concernees_idetudiant_id: string ;
  nom: string ;
  prenom: string ;
  date_naissance: string | null ;
  sexe: any | null ;
  adresse: string | null ;
  email: string | null ;
  telephone: string | null ;
  lieu_naissance: string | null ;
  cin_passeport: number | null ;
  date_livrance: string | null ;
  date_expiration: string | null ;
  telephone_urgence: string | null ;
  annee_bac: number | null ;
  num_bac: string | null ;
  serie_id: number | null ;
  grade_id: number | null ;
  niveau_id: number | null ;
  bourse_id: number | null ;
  civilite: string | null ;
  mention_id: number | null ;
  passage: any | null ;
  annee_scolaire_id: any | null ;
  type_etablissement_id: any | null ;
  documents : any | [];
  password: string | null ;
  situation_matrimoniale: string | null ;
  nationalite_id: number | null ;
  code_redoublement: string | null ;
  code_nin: string | null ;
  etablissement_id: any | null ;
  boursier: string | null ;
  checkboursier: any | null ;
  photo: string | null ;
  state: number | 0 ;
  nationalite: any | null | [] ;
  serie: any | null | [] ;
  niveau: any | null | [] ;
  mention: any | null | [] ;
  bourse: any | null | [] ;
  etat_niveau: any | null | [] ;
  matching: string ;
  date_debut: any | null | [] ;
  date_fin: any | null | [] ;
  note : number ;
}

export class bachelier {
  id: number ;
  nom: string ;
  prenom: string ;
  date_naissance: string | null ;
  sexe: any | null ;
  adresse: string | null ;
  email: string | null ;
  telephone: string | null ;
  lieu_naissance: string | null ;
  cin_passeport: number | null ;
  date_livrance: string | null ;
  date_expiration: string | null ;
  telephone_urgence: string | null ;
  annee_bac: number | null ;
  num_bac: string | null ;
  serie_id: number | null ;
  civilite: string | null ;
  password: string | null ;
  situation_matrimoniale: string | null ;
  nationalite_id: number | null ;
  code_redoublement: string | null ;
  code_nin: string | null ;
  etablissement_id: any | null ;
  boursier: string | null ;
  checkboursier: any | null ;
  photo: string | null ;
  state: number | 0 ;
  nationalite: any | null | [] ;
  serie: any | null | [] ;
}

export class etudiant2 {
  matricule: number ;
  name: string ;
  prenom: string ;
  genre: string ;
  domaine: string ;
  mention: string ;
  telephone: string ;
  email: string ;
  state: number | 0 ;
}
export class profil {
  id: number ;
  name: string ;
  code: string ;
  state: number | null ;
  actionProfils: action_profil | null | [] ;
  users: user | null | [] ;

}

export class action_profil {
  id: number ;
  profil_id: number ;
  action_id: number ;
  state: number | null ;
  //action: action | null ;
  profil: profil | null ;

}

export class profilage_module {
  id : number;
  sous_modules : profilage_sous_module[] = [];
  name : string;
  icon : string;
  code : string;
  state : number | null;
  hasOneSubModuleAction:boolean
}

export class profilage_sous_module{
  id : number;
  actions : profilage_droit[] = [];
  name : string;
  icon : string;
  code : string;
  state : number | null;
  hasOneAction:boolean
}

export class profilage_droit{
  id : number;
  state : number;
  name : string;
  exist : number;
  code : string;
  type_action_id:number;
  url:string;
}

export class token {
  id: number ;
  type: string ;
  token: string ;
  refresh_token: string | null ;
  user_id: number ;
  state: number | null ;
  user: user | null ;

}

export class user {
  id: number ;
  admin: number | null ;
  l_name: string ;
  f_name: string ;
  email: string ;
  address: string | null ;
  phone: string ;
  password: string | null ;
  state: number | null ;
  last_connection: Date | null ;
  first_connexion: number | null ;
  avatar: string | null ;
  profil_id: number ;
  photo: any ;
  plateforme_id: number ;
  etablissement_id: number ;
  type_etablissement_id: any ;
  profil: profil | null ;
  plateforme: plateforme | null ;
  tokens: token [] | null|[] ;
  etablissement : any;
  type_etablissement: number;
 

}

export class Auth {
  info : user|null;
  modules: profilage_module[] | [];
  etablissement: etablissement
}



export class type_etablissement {
  id: number ;
  name: string ;
  state: number | null ;
}

export class type_user {
  id: number ;
  name: string ;
  state: number | null ;
}

export class module {
  id: number ;
  name: string ;
  code: string ;
  icon: string | null ;
  state: number | null ;
  sousModules: sous_module [] | null ;

}

export class sous_module {
  id: number ;
  name: string ;
  code: string ;
  icon: string | null ;
  state: number | null ;
  module_id: number ;
  module: module | null ;
  actions: action [] | null | [];

}

export class type_action {
  id: number ;
  name: string ;
  code: string ;
  state: number | null ;
  actions: action [] | null | [];

}
export class action {
  id: number ;
  name: string ;
  code: string ;
  method: string ;
  url: string ;
  state: number | null ;
  type_action_id: number ;
  sous_module_id: number ;
  sousModule : sous_module | null;
  typeAction : type_action | null;
  actionProfils : action_profil [] | null | [];

}

export class etablissement {
  id: number ;
  code_rne: any ;
  sigle: string ;
  name: string ;
  telephone: string ;
  email: string ;
  adresse: string ;
  code_postal: string ;
  siteweb: string ;
  logo: string ;
  nom_ministre: string ;
  type_etablissement_id: number ;
  ville_id: number ;
  province_id: number ;
  state: number | null ;
  documents: document [] | null ;
  ville: any ;
  user_id:any;
  prenom_ministre: string;
  signature: string;
  code_universite: number;
  type_universite: number;
  type_etablissement: number;;

}

export class ville {
  id: number ;
  name: string ;
  state: number | null ;
  province_id: number ;
  province: any | null ;

}

export class province {
  id: number ;
  name: string ;
  state: number | null ;

}
export class admin_etab {
  id: number ;
  user_id: string ;
  etablissement_id: string ;
  state: number | null ;

}
export class document {
  static createElement(arg0: string) {
    throw new Error('Method not implemented.');
  }
  id: number ;
  name: string | null ;
  etablissement_id: number ;
  state: number | null ;

}


export class documentPj {
  id: number ;
  name: string | null ;
  state: number | null ;

}
export class documentLogo {
  id: number ;
  name: string | null ;
  state: number | null ;

}
export class documentRectoCIN {
  id: number ;
  name: string | null ;
  type: number | null ;
}

export class documentVersoCIN {
  id: number ;
  name: string | null ;
  type: number | null ;
}

export class documentPJBourse {
  id: number ;
  name: string | null ;
  type: number | null ;
}

export class Postdocument{
  id : number;
  ordre_paiement_id : number;
  document : any;
}

export class centre_bacc {
  id: number ;
  name: string ;
  state: number | null ;
}
export class centre_examen {
  id: number ;
  name: string ;
  state: number | null ;
}

export class moyen_paiment {
  id: number ;
  name: string ;
  state: number | null ;
}

export class frais_inscription {
  id: number ;
  montant: string ;
  name: string ;
  grade_id: number ;
  annee_scolaire_id: number ;
  etablissement_id: any ;
  state: number | null ;
  annee_scolaire: annee_scolaire [] | null ;
  grade: grade [] | null ;

}

export class inscription{
  id: number ;
  date_inscription: string ;
  num_inscription: string ;
  etablissement_id: string ;
  etudiant_id: number ;
  faculte_id: number ;
  etudiant : any | null;
  get_domaine : any | null;
  mention  : any | null;
  niveau   : any | null;
  bourse   : any | null;
  annee_scolaire_id: number ;
  mention_id: number ;
  bourse_id: number | null;
  frais_inscription_id: number ;
  user_id: number ;
  niveau_id: number ;
  state: number | null ;
  matricule: any | null ;
  nom: any | null ;
  prenom: any | null ;
  date_de_naissance: any | null ;
  telephone: any | null ;
  cin_passeport: any | null ;
  annee_bac: any | null ;
  code_redoublement: any | null ;
  boursier: any | null ;
  domaine: any | null ;
  fac_name : any;
  annee_scolaire_name : any;
  etablissement_name : any;
}

export class grade {
  id: number ;
  code: string ;
  name: string ;
  debut: string ;
  fin: string ;
  abreviation: string ;
  state: number | null ;
}
export class annee_scolaire {
  id: number ;
  name: string ;
  date_debut: string ;
  date_fin: string ;
  etat: any ;
  etatCheck: any ;
  etablissement_id: number ;
  state: number | null ;
}

export class domaine {
  id: number ;
  name: string ;
  code: string ;
  abreviation: string ;
  etablissement_id: number ;
  domaine_id: number ;
  state: number | null ;
}


export class vocation {
  id: number ;
  name: string ;
  abreviation: string ;
  state: number | null ;

}


export class specialite {
  id: number ;
  name: string ;
  code: string ;
  abreviation: string ;
  state: number | null ;
}

export class serie {
  id: number ;
  name: string ;
  code: string ;
  state: number | null ;

}
export class office {
  id: number ;
  name: string ;
  code: string ;
  state: number | null ;

}
export class niveau {
  id: number ;
  name: string ;
  code: string ;
  state: number | null ;
  grade_id: number ;
  grade: any ;
}
export class mention_bacc {
  id: number ;
  name: string ;
  code: string ;
  state: number | null ;

}
export class mention {
  id: number ;
  name: string ;
  code: string ;
  abreviation: string ;
  state: number | null ;
  grade_id: number ;
  vocation_id: number ;
  domaine_id: number ;
  specialite_id: number ;
  etablissement_id: any ;
  faculte_id: any ;
  vocation : any;
  specialite : any;
  domaine : any;
  grade : any;
  faculte : any;
  etablissement : any;
  type_etablissement_id : any;

}
export class nationalite {
  id: number ;
  name: string ;
  code: string ;
  state: number | null ;

}

export class faculte {
  id: number ;
  code: string ;
  name: string ;
  etablissement_id : number;
  etablissement : any;
  type_etablissement_id : any;
  state: number | null ;
}

export class pre_inscription {
  id: number ;
  num_inscription: string ;
  annee_scolaire_id: string ;
  statut: string ;
  date_preinscription: string | null ;
  date_traitement: string | null ;
  bachelier_id: number | null ;
  state: number | null ;
  choix_mention : any | [];
  get_domaine : any | [];

}

export class nombre_preinscription_a_valider_par_mention {
  id: number ;
  name: string ;
  code: string ;
  state: number | null ;

}

export class bourse {
  id: number ;
  montant: any ;
  niveau_id: string ;
  grade_id: string ;
  type_etablissement_id:string;
  etablissement_id: string ;
  code_redoublement: string ;
  etablissement : any | [];
  niveau : any | [];
  state: number | null ;
}

export class ordre_paiement {
  id: number ;
  numero_ordre: string ;
  mois: any[] ;
  date_debut_paiement: string ;
  date_fin_paiement: string ;
  montant_global: string ;
  nombre_etudiant: string ;
  libelle: string ;
  wallet_carte : any;
  etat_equipement : any = '0';
  equipement : any;
  nombre_mois : any = '';
  statut: any ;
  etablissement_id: number ;
  type_etablissement_id:number;
  user_creation: any ;
  user_name_creation: any ;
  date_creation: string ;
  user_validation: any ;
  user_name_validation: any ;
  date_validation: string ;
  categorie_concernees_id: any;
  user_envoi: any ;
  user_name_envoi: any ;
  date_envoi: string ;
  user: any ;
  code: string ;
  state: number | null ;
  etablissement : any;
  detail : any;
  suppr_detail : any;
  ajout_detail : any;
  operator_user : any;
  nombre_concernees:any;
  categorie : any;
  id_ordre_ministere : any;
  moisItems: any;
  moisDetail: string;
  niveau_id: any;
}
export class objetTest {
  ajout_detail : any;
  etablissement_id : any;
}

export class detail_ordre_paiement{
  id: number ;
  ordre_paiement_id: number ;
  etudiant_id: number ;
  date_paiement: string ;
  state: string ;
}
export class nb_etudiant_par_niveau{
  nbL1_passant: number  = 0;
  nbL1_redoublant: number  = 0;
  nbL2_passant: number = 0;
  nbL2_redoublant: number = 0;
  nbL3_passant: number = 0;
  nbL3_redoublant: number = 0;
  nbM1_passant: number = 0;
  nbM1_redoublant: number = 0;
  nbM2_passant: number = 0;
  nbM2_redoublant: number = 0;
  nbDR_redoublant: number = 0;
  nbDR_passant: number = 0;
  totalL1: number = 0;
  totalL2: number = 0;
  totalL3: number = 0;
  totalM1: number = 0;
  totalM2: number = 0;
  totalDR: number = 0;
  totalToutNiveau: number;
}
export class bourse_par_code{
  montantL1_passant: number  = 0;
  montantL1_redoublant: number  = 0;
  montantL2_passant: number = 0;
  montantL2_redoublant: number = 0;
  montantL3_passant: number = 0;
  montantL3_redoublant: number = 0;
  montantM1_passant: number = 0;
  montantM1_redoublant: number = 0;
  montantM2_passant: number = 0;
  montantM2_redoublant: number = 0;
  montantDR_redoublant: number = 0;
  montantDR_passant: number = 0;
}

export class bourse_par_nb_etudiant{
  montantTotalL1_passant: number  = 0;
  montantTotalL1_redoublant: number  = 0;
  montantTotalL2_passant: number = 0;
  montantTotalL2_redoublant: number = 0;
  montantTotalL3_passant: number = 0;
  montantTotalL3_redoublant: number = 0;
  montantTotalM1_passant: number = 0;
  montantTotalM1_redoublant: number = 0;
  montantTotalM2_passant: number = 0;
  montantTotalM2_redoublant: number = 0;
  montantTotalDR_redoublant: number = 0;
  montantTotalDR_passant: number = 0;
  montantTotalToutNiveau: number;

}

export class total_bourse_par_niveau{
  montantTotalL1: number = 0;
  montantTotalL2: number = 0;
  montantTotalL3: number = 0;
  montantTotalM1: number = 0;
  montantTotalM2: number = 0;
  montantTotalDR: number = 0;
  montantTotalToutNiveau: number;
}

export class total_bourse_avec_prime{
  montantL1_avecPrime: number = 0;
  montantL2_avecPrime: number = 0;
  montantL3_avecPrime: number = 0;
  montantM1_avecPrime: number = 0;
  montantM2_avecPrime: number = 0;
  montantDR_avecPrime: number = 0;
  montantTotalToutNiveau_avecPrime: number;
}

export class total_bourse_nb_mois_avec_prime{
  montantL1_nb_mois_avecPrime: number = 0;
  montantL2_nb_mois_avecPrime: number = 0;
  montantL3_nb_mois_avecPrime: number = 0;
  montantM1_nb_mois_avecPrime: number = 0;
  montantM2_nb_mois_avecPrime: number = 0;
  montantDR_nb_mois_avecPrime: number = 0;
  montantTotalToutNiveau_nb_mois_avecPrime: number;
}




export class paginate{
  e_where : any;
  e_tabExportEtud : any;
  e_search : any= "";
  e_child : any= "";
  e_annee_scolaire_encour : any = "";
  e_tri : any= "";
  e_order : any= "";
  e_endPOint : any= "";
  e_searchColumn: string;
  e_column: string;
  e_childCol: string;

  //--paginate
  e_paginateListe : any ;
  e_nextPage : any;
  e_prevPage : any ;
  e_lastPage : any ;
  e_firstPage : any ;
  e_currentPage : any ;
  e_total : any ;
  e_path : any ;
  e_lastNumPage : any ;
  e_infoData : any ;
  e_cPage_less_2 : number;
  e_path_cPage_less_2 : any;
  e_cPage_less_1 : number;
  e_path_cPage_less_1 : any;
  e_cPage_more_1 : number;
  e_path_cPage_more_1 : any;
  e_cPage_more_2 : number;
  e_path_cPage_more_2 : any;
  //--end-paginate

}

//--- Phase 2  --------

export class grade_enseignant {
  id: number ;
  abreviation: string ;
  name: string ;
  niveau: number ;
  state: number ;
}

export class categorie {
  id: number ;
  name: string ;
  niveau: number ;
  state: number ;
}

export class enseignant {
  id: number ;
  nom: string ;
  prenom: string ;
  date_naissance: string | null ;
  sexe: number;
  adresse: string;
  telephone: string;
  email: string;
  numero_matricule: string;
  cin: string;
  type: number;
  domaine_id: number;
  specialite_id: number;
  state: number ;
  specialite:any;
  domaine:any;
  etablissement:any;
  etablissement_id: number;
  type_etablissement:any;
  type_etablissement_id: number;
  tab_etablissement: any;
  enseignant : any;
  enseignant_id : number;
  disponible : any;
}

export class encadrement {
  id: number ;
  date: string ;
  objet: string ;
  heure_ED: number ;
  heure_ET: number;
  enseignant_id: number;
  enseignant: any;
  grade: any;
  type_etablissement_id: number;
  type_etablissement: any;
  annee_scolaire_id: number;
  annee_scolaire: any;
  etablissement_id:number;
  etablissement:any;
  detail:any;
  ajout_detail:any;
  suppr_detail:any;
  faculte_id:any;
  type: number;
  parcour_id: number;
  grade_id:number;
  state: number ;
  nom: string;
}

export class echelon {
  id: number ;
  name: string ;
  niveau: number ;
  state: number ;
}

export class evolution_enseignant {
  id: number ;
  date: any ;
  indice_id: number;
  categorie_id: number;
  grade_enseignant_id: number;
  enseignant_id: number;
  echelon_id: number;
  state: number ;
}

export class etablissement_enseignant {
  id: number ;
  etablissement_id: number ;
  enseignant_id: number;
  state: number ;
}
export class encadrement_etudiant {
  id: number ;
  encadrement_id: number ;
  etudiant_id: number;
  state: number ;
}

export class mention_diplome{
  id: number ;
  name:any;
  state: number ;
}
export class diplome{
  id: number ;
  abreviation:any;
  grade_id:number;
  grade:any;
  president_etablissement_id:number;
  president:any;
  faculte_id:number
  etablissement_id:number;
  type_etablissement_id:number;
  faculte:any
  name:any;
  state: number ;
}

export class parcours{
  id: number ;
  mention_id:number;
  mention: any;
  type_etablissement_id:number  
  etablissement_id:number;
  domaine_id:number;
  faculte_id:number
  faculte:any;
  etablissement:any;
  enseignant_id:number;
  enseignant:any
  name:any;
  state: number ;
}
export class salle{
  id: number ;
  reference:any;
  capacite: number;
  faculte_id:number;
  faculte:any;
  name:any;
  state: number ;
}
export class semestre{
  id: number ;
  abreviation:any;
  niveau: any;
  niveau_id:number;
  name:any;
  parite:any;
  state: number ;
}
export class type_cours{
  id: number ;
  name:any;
  code:string;
  state: number ;
}

export class element_constitutif{
  id: number ;
  // code:any;
  name:any;
  // volume_horaire: number;
  // credit: number;
  // formule_examen:any;
  etablissement_id : any;
  etablissement:any;
  faculte_id : any;
  faculte:any;
  // type_etablissement_id : any;
  unite_enseignement_id: any;
  uniteenseignement:any;
  // domaine_id: any;
  // semestre_id: any;
   mention_id:any;
   parcours_id: any;
  // grade_id:any;
  // niveau_id: any;
  // annee_scolaire_id: any;

  state: number ;
}
export class parcours_ue{
  id: number ;
  parcours_id:any;
  unite_enseignement_id:any;
  annee_scolaire_id:any
  state: number ;
}

export class SemetreParcoursModel{
  annee_scolaire_id: number;
  etablissement_id: number;
  id: number;
  parcour: parcours;
  parcours_id: number;
  semestre_id: number;
  state: number;
}
export class EcUeModel{
  ec: element_constitutif[];
  ue: UEModel;
}


export class president{
  id: number ;
  nom: any;
  prenom: any;
  date_fonction: Date;
  signature: any
  etablissement_id: number
  type_etablissement_id : any;
  user_id: any;
  state: number ;
  annee_scolaire_id:number;
}

export class indice{
  id: number ;
  name:any;
  state: number ;
}

export class UeSemetreEc{
  id: number;
  element_constitutif_id: number;
  unite_enseignement_id: number;
  semestre_id: number;
  annee_scolaire_id: number;
  etablissement_id: number;
  domaine_id: number;
  mention_id: number;
  parcours_id: number;
  grade_id: number;
  niveau_id: number;
  faculte_id: number;
  coefficient: number;
  credit : number;
  formule_examen : string;
  volume_horaire: number;
  type_etablissement_id: number;
  type: number;
  parcour_unite_enseignement_id: number;
  formule: any;
}

export class inscription_pedagogique{
  id: number ;
  parcours_id: number;
  semestre_id:number;
  niveau_id:number;
  mention_id:number;
  etudiant_id:number;
  etablissement_id:number;
  annee_scolaire_id:number;
  faculte_id:number;
}
export class detail_inscription_pedagogique{
  id: number ;
  semestre_ec_id: number;
  inscription_pedagogique_id:number;

}

export class detail_encadrement_etudiant{
  id: number ;
  encadrement_id: number ;
  etudiant_id: number ;
  state: string ;
}

export class type_exam{
  id: number ;
  name: string ;
  abreviation: string ;
  state: string ;
}

export class type_examen {
  id: number ;
  name: string ;
  abreviation: string ;
  specificite: number ;
  state: number ;
}

export class cours {
  id: number ;
  date_heure_debut: string ;
  date_heure_fin: string ;
  date: string ;
  heure_debut: string ;
  heure_fin: string ;
  salle_id: number ;
  etablissement_id: number ;
  enseignant_id: number ;
  effectue: string ;
  nombre_heure: string ;
  type_cours_id: number ;
  annee_scolaire_id: number ;
  faculte_id: number ;
  niveau_id: number ;
  mention_id: number ;
  semestre_id: number ;
  state: number ;
  ec_cours : any;
}
export class ec_cours{
  id: number ;
  semestre_ec_id: number;
  cours_id :number;

}
export class examen {
  id: number ;
  date_debut: string ;
  date_fin: string ;
  date: string ;
  heure_debut: string ;
  heure_fin: string ;
  etablissement_id: number ;
  enseignant_id: number ;
  effectue: string ;
  nombre_heure: string ;
  type_examen_id: number ;
  annee_scolaire_id: number ;
  faculte_id: number ;
  niveau_id: number ;
  mention_id: number ;
  semestre_id: number ;
  state: number ;
  ec_examen : any;
  semestre_ec_id : number;
}

/**
 * WS EDUTIC MODEL
 */
export class InsertBoursierConcerner {
  etablissement_id : number;
  categorie_concerne_id : number;
  niveau_id : number;
}