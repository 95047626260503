import { Component, OnInit } from '@angular/core';
import { RouterModule, Router ,ActivatedRoute} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';
import { valuesys } from '../../../../../../../options';
import { concerner,carte } from '../../../../../model/pension';
import { HttpApiResponse } from "../../../../../model/DataTablesResponse";
import { Translatable } from '../../../../../service/Translatable';
import { AuthServiceService } from '../../../../../service/auth-service.service';
import Swal from "sweetalert2";
import { ToastrService } from 'ngx-toastr';
import { etablissement, ordre_paiement } from '../../../../../model/db';
import { iif, Observable } from 'rxjs';
import { map } from 'jquery';
import { HelperService } from '../../../../../service/helper.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-affectation-carte',
  templateUrl: './affectation-carte.component.html',
  styleUrls: ['./affectation-carte.component.scss']
})
export class AffectationCarteComponent extends Translatable implements OnInit {
    submitted:  boolean = false;
    public ordre_paiements :ordre_paiement[] = [] ;
    idBeneficiaire : any;
    public concerner:concerner = new concerner();
    public carteArray :carte[] = [] ;
    documentLogo: any = [];
    update : boolean = false;
    /** TABLE CONFIG **/
    public searchColumn: string;
    public column: string;
    public childCol: string;
    public paginateListe : any = [];
    public nextPage : any = [];
    public prevPage : any = [];
    public lastPage : any = [];
    public firstPage : any = [];
    public currentPage : any = [];
    public total : any = [];
    public path : any = [];
    public lastNumPage : any = [];
    public infoData : any = [];
    public cPage_less_2 : number;
    public path_cPage_less_2 : any;
    public cPage_less_1 : number;
    public path_cPage_less_1 : any;
    public cPage_more_1 : number;
    public path_cPage_more_1 : any;
    public cPage_more_2 : number;  
    public path_cPage_more_2 : any;
    numero_serie: string;
    havecard: boolean = false;
    /** TABLE CONFIG **/

    dtOptions: DataTables.Settings = {};
    superAdmin:any="";
    etabId:any=''
    endPOint:any=""
    filtre_etablissement_id:any='';
    search:any="";
    filtreBase:boolean=false
    filtreBaseParametres:any=''
    searchInput : any= "";
    searchCol : any= "";
    filtreSelect:any= 10;
    tri : any= "";
    order : any= "";
    child : any= "";
    facID:any=""
    public etablissements:etablissement [] = [];

    table_condition : boolean = false;
    carteForm :FormGroup
    /** variable for template **/
    isCarteActive : boolean = false;
    showDataTableCarte : boolean = false;
    idtest: any;
    where: string;
    state$: Observable<object>;
    etablissementId: number | string;
    numCarte: string;
    tabIndexActive: number = 0;
    constructor(
        private route : ActivatedRoute,
        private http: HttpClient,
        private auth : AuthServiceService,  
        private toastr: ToastrService, 
        private router: Router,
        private helper: HelperService,
        private fb: FormBuilder
    ) {
       super(); 
       this.carteForm = this.fb.group({
        etabliment_id: ['',Validators.required],
        numero_serie: ['',Validators.required],
       })
      }

    async ngOnInit(): Promise<void> {
        console.log(history.state,'xxxxxxxxxxxxx id state')
        this.idBeneficiaire = history.state.idBeneficiaire
        this.tabIndexActive = history.state.tabIndexActive
        if(!this.idBeneficiaire) {
          this.router.navigate(['/dashboard/BNF/plateforme/concernee']);
          return;
        }
       // this.getConcerneId();

        let response = await this.http.get<HttpApiResponse>(environment.pension_concerners + "/getConcernerByID/" + this.idBeneficiaire + "?page=1",valuesys.httpAuthOptions()).toPromise();
        this.concerner = response.data["data"][0];
        if(this.concerner.carte_numero_serie) this.havecard = true;

        console.log("IDDDDDDDDDDD :", this.concerner.concernees_id);

        this.endPOint = environment.pension_detailPaiementConcernees+"/"+this.concerner.concernees_id+"?page=1";
        console.log("xxxxxx", this.endPOint)

          this.triTableAffectation('numero_serie','desc')
          this.triTable('numero_serie','desc');

          if(this.showDataTableCarte){
            this.getUrlDatatableAffect_Carte(environment.pension_cartes+"?page=1")
          } else {
          this.getUrlDatatable(environment.pension_detailPaiementConcernees+"/"+this.concerner.concernees_id+"?page=1")
          }


    }

  
    async getConcerneId()
    {
        let response = await this.http.get<HttpApiResponse>(environment.pension_concerners + "/getConcernerByID/" + this.idBeneficiaire + "?page=1",valuesys.httpAuthOptions()).toPromise();
        if(response.code == 200)
        {
            this.concerner = response.data["data"][0];
            /** ON RECUPERE LES INFORMATION DU CARTE SI ACTIVE **/
            if(this.concerner.carte_numero_serie != null)
            {
                this.isCarteActive = true;
            }
        }
        console.log(this.concerner);
        
    }

    async getDatatableCarte()
    {
        
        let userinfo = this.auth.account();
        this.etabId  = userinfo.info.etablissement_id; 
        this.superAdmin = userinfo.info.admin; 
        
        if(this.superAdmin==1){
          this.endPOint = environment.pension_cartes+"?page=1&where=active|e|0";
        }
        await this.getUrlDatatableAffect_Carte(this.endPOint);
        // this.showDataTableCarte = true;
    }

    async triTable(col, order){
      this.tri = col;
      this.order = order;
      this.getUrlDatatable(environment.pension_detailPaiementConcernees+"/"+this.concerner.concernees_id+"?page=1")
    }

    /** Get data with dataTable personalize **/
    async getUrlDatatable(url,col = "", refElement?: any, child?: any, searchMulti?:any){

        console.log("select : "+this.filtreSelect)
        console.log(refElement)

        this.where = "&where=";

        if(refElement){
        this.searchColumn = refElement.value
        this.column = col
        this.childCol = child
        }

      if(searchMulti){
        if(this.superAdmin==1){
          if(this.filtre_etablissement_id!="")
          this.search = this.search+",etablissement.id|e|"+ this.filtre_etablissement_id ;
        }
        this.filtreBase=true
      } 
      else {
        if(col != "" || this.searchColumn ){
          if(this.searchColumn !="" && this.searchColumn !="undefined"){
            if(this.superAdmin==1)
            this.search = this.column+"|l|"+this.searchColumn;
            else
            this.search = ","+this.column+"|l|"+this.searchColumn;
          }else if(this.searchColumn == "") {
            this.search = "";
          }
        } 

        else {
          if(col != "" || this.searchColumn ){
            if(this.searchColumn !="" && this.searchColumn !="undefined"){
              this.search = ","+this.column+"|l|%"+this.searchColumn+"%";
            }else if(this.searchColumn == "") {
              this.search = "";
            }
          }
        else{
          if(this.searchInput !="" && this.searchInput !="undefined"){
            this.where = ""
            this.search = "&where_or=concernees.nom|l|"+this.searchInput+",ordre_paiement.date_debut_paiement|l|"+this.searchInput+",ordre_paiement.date_fin_paiement|l|"+this.searchInput+",detail_ordre_paiement.date_paiement|l|"+this.searchInput+",detail_ordre_paiement.montant|l|"+this.searchInput+""
          }else if(this.searchInput == "") {
            this.search = "";
          }
        }
      }
  }

  let filtre : any= "";
  if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
    filtre = "&per_page=" + this.filtreSelect;
  }

  let triage : any= "";
  if(this.tri !="" && this.tri !="undefined"){
    triage = "&__order__="+ this.order+","+ this.tri;
  }
  console.log("search", this.search)

  this.path = environment.pension_detailPaiementConcernees+"/"+this.concerner.concernees_id;

  let toogle = await this.http.get<any>(url + this.where + this.search  + filtre + triage,valuesys.httpAuthOptions()).toPromise();
  console.log(toogle,"togglleeeeeee&&&&&&&&&&")
  //this.carteArray = toogle.data.data;
  this.ordre_paiements = toogle.data.data;

  console.log("Togle data paiement : ", toogle.data.data)

  //console.log("UUUUUUUUUUUUUUUU_rrrrrrrrrrrr :",  toogle.data.current_page)

  this.currentPage = toogle.data.current_page;

  console.log("UUUUUUUUUUUUUUUU_xxxxxxxxxxx :",  this.currentPage)

  this.firstPage =this.path+"?page=1";
  this.prevPage = this.path+"?page="+(this.currentPage-1) ;
  this.nextPage = this.path+"?page="+(this.currentPage+1);
  console.log("UUUUUUUUUUUUUUUU :",  this.nextPage);
  this.lastPage = this.path +"?page="+ toogle.data.last_page ;

  this.lastNumPage = toogle.data.last_page;
  this.total = toogle.data.total;

  this.cPage_less_2 = 0
  this.cPage_less_1 = 0
  if(this.currentPage > 1 ){
    
    if(this.currentPage > 2 ){
      this.cPage_less_2 = this.currentPage - 2
      this.path_cPage_less_2 = this.path +"?page="+this.cPage_less_2;
    }
  
  this.cPage_less_1 = this.currentPage - 1 
  this.path_cPage_less_1 = this.path +"?page="+this.cPage_less_1;
  }

  
  this.cPage_more_1 = 0
  this.cPage_more_2 = 0
  if(this.currentPage < this.lastNumPage){
    this.cPage_more_1 = this.currentPage + 1 
    this.path_cPage_more_1 = this.path +"?page="+this.cPage_more_1;

    if(this.currentPage < this.lastNumPage - 1 ){
      this.cPage_more_2 = this.currentPage + 2 
      this.path_cPage_more_2 = this.path +"?page="+this.cPage_more_2;
    }

  }

  let info = this.__('datatable.info').replace('_START_', toogle.data.from)
  let info2 =info.replace('_END_', toogle.data.to)
  this.infoData = info2.replace('_TOTAL_', toogle.data.total)

    }

    async getSearch(){
        console.log("getSearch :", this.searchInput)
        this.getUrlDatatable(environment.pension_detailPaiementConcernees+"/"+this.concerner.concernees_id+"?page=1"+this.child)
    }

    async getFiltreSelect(){
      console.log("Filter par page :", this.searchInput)
    
      this.getUrlDatatable(environment.pension_detailPaiementConcernees+"/"+this.concerner.concernees_id+"?page=1")
    }

    async getSearchInput(){
        this.getUrlDatatable(environment.pension_detailPaiementConcernees+"/"+this.concerner.concernees_id+"?page=1")
    }

    async goToAffectation() {
      this.router.navigate(['/dashboard/CAR/plateforme/affecterCarte']);
      // this.submitted = false;
      // let userinfo = this.auth.account();
      //   // this.etabId  = userinfo.info.etablissement_id; 
        
      //   this.superAdmin = userinfo.info.admin; 
      //   if(this.superAdmin == 0) this.etablissementId = userinfo.info.etablissement_id;
      //   this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);
      //   this.etablissementId = this.concerner.etablissement_id;
      //   this.showDataTableCarte = true;
    }
     /** Get data with dataTable personalize **/
    async getUrlDatatableAffect_Carte(url,col = "", refElement?: any, child?: any, searchMulti?:any){
      this.showDataTableCarte = true;
      console.log("select : "+this.filtreSelect)
      console.log(refElement)

      this.where = "&where=";

      if(refElement){
      this.searchColumn = refElement.value
      this.column = col
      this.childCol = child
      }

    if(searchMulti){
      if(this.superAdmin==1){
        if(this.filtre_etablissement_id!="")
        this.search = this.search+",etablissement.id|e|"+ this.filtre_etablissement_id ;
      }
      this.filtreBase=true
    } 
    else {
      if(col != "" || this.searchColumn ){
        if(this.searchColumn !="" && this.searchColumn !="undefined"){
          if(this.superAdmin==1)
          this.search = this.column+"|l|"+this.searchColumn;
          else
          this.search = ","+this.column+"|l|"+this.searchColumn;
        }else if(this.searchColumn == "") {
          this.search = "";
        }
      } 

      else {
        if(col != "" || this.searchColumn ){
          if(this.searchColumn !="" && this.searchColumn !="undefined"){
            this.search = ","+this.column+"|l|%"+this.searchColumn+"%";
          }else if(this.searchColumn == "") {
            this.search = "";
          }
        }
      else{
        if(this.searchInput !="" && this.searchInput !="undefined"){
          this.where = ""
          this.search = "&where_or=concernees.nom|l|"+this.searchInput+",ordre_paiement.date_debut_paiement|l|"+this.searchInput+",ordre_paiement.date_fin_paiement|l|"+this.searchInput+",detail_ordre_paiement.date_paiement|l|"+this.searchInput+",detail_ordre_paiement.montant|l|"+this.searchInput+""
        }else if(this.searchInput == "") {
          this.search = "";
        }
      }
    }
}

let filtre : any= "";
if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
  filtre = "&per_page=" + this.filtreSelect;
}

let triage : any= "";
if(this.tri !="" && this.tri !="undefined"){
  triage = "&__order__="+ this.order+","+ this.tri;
}
console.log("search", this.search)

this.path = environment.pension_cartes;

let toogle = await this.http.get<any>(url + this.where + this.search  + filtre + triage,valuesys.httpAuthOptions()).toPromise();

this.carteArray = toogle.data.data;

console.log("Togle data :", this.carteArray)

console.log("UUUUUUUUUUUUUUUU_rrrrrrrrrrrr :",  toogle.data.current_page)

this.currentPage = toogle.data.current_page;

console.log("UUUUUUUUUUUUUUUU_xxxxxxxxxxx :",  this.currentPage)

this.firstPage =this.path+"?page=1";
this.prevPage = this.path+"?page="+(this.currentPage-1) ;
this.nextPage = this.path+"?page="+(this.currentPage+1);
console.log("UUUUUUUUUUUUUUUU :",  this.nextPage);
this.lastPage = this.path +"?page="+ toogle.data.last_page ;

this.lastNumPage = toogle.data.last_page;
this.total = toogle.data.total;

this.cPage_less_2 = 0
this.cPage_less_1 = 0
if(this.currentPage > 1 ){
  
  if(this.currentPage > 2 ){
    this.cPage_less_2 = this.currentPage - 2
    this.path_cPage_less_2 = this.path +"?page="+this.cPage_less_2;
  }

this.cPage_less_1 = this.currentPage - 1 
this.path_cPage_less_1 = this.path +"?page="+this.cPage_less_1;
}


this.cPage_more_1 = 0
this.cPage_more_2 = 0
if(this.currentPage < this.lastNumPage){
  this.cPage_more_1 = this.currentPage + 1 
  this.path_cPage_more_1 = this.path +"?page="+this.cPage_more_1;

  if(this.currentPage < this.lastNumPage - 1 ){
    this.cPage_more_2 = this.currentPage + 2 
    this.path_cPage_more_2 = this.path +"?page="+this.cPage_more_2;
  }

}

let info = this.__('datatable.info').replace('_START_', toogle.data.from)
let info2 =info.replace('_END_', toogle.data.to)
this.infoData = info2.replace('_TOTAL_', toogle.data.total)

    }

    async getFiltreSelectAffectation(){
      console.log("Filter par page :", this.searchInput);
      this.getUrlDatatable(environment.pension_cartes+"?page=1")
    }

    async triTableAffectation(col, order){
      this.tri = col;
      this.order = order;
      this.getUrlDatatable(environment.pension_cartes+"?page=1")
    }

    async getSearchInputAffectation(){
      this.getUrlDatatable(environment.pension_cartes+"?page=1")
  }





    async confirmAffectation(num_serie)
    {
        Swal.fire({
            title: this.__('msg.confirm'),
            text: "Affecter la carte n° "+ num_serie +" au bénéficiaire : " + this.concerner.prenom + " "+ this.concerner.nom + " ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#44A1A0',
            cancelButtonColor: '#FF7467',
            reverseButtons : true,
            confirmButtonText: "Confirmer",
            cancelButtonText: "Annuler"

        }).then(async (result) => {

            if (result.value) {
                let sup = await this.http.post<HttpApiResponse>(environment.carte_concerner+"/affectation" , {"concerne_id":this.concerner.concernees_id, "numero_serie":num_serie},valuesys.httpAuthOptions()).toPromise();
                
                if(sup.code == 201){
                  let response = await this.http.get<HttpApiResponse>(environment.pension_concerners + "/getConcernerByID/" + this.idBeneficiaire + "?page=1",valuesys.httpAuthOptions()).toPromise();
                  if(response.code == 200) {
                    this.concerner = response.data["data"][0];
                    this.isCarteActive = true;
                    Swal.fire({
                      icon: 'success',
                      title: "Donnée enregistrée",
                      confirmButtonColor: '#44A1A0',
                      text: sup.msg,
                    }).then((resultSuccess) => {
                        if(resultSuccess)
                        {
                            //this.router.navigate(['/dashboard/BNF/plateforme/carte_concerner/affectation']);
                        }
                    })
                  }
                 
                }else {
                    this.toastr.error(sup.data,this.__("msg.error"));
                }
            }
        })
    }


    addImage(documentFile: HTMLInputElement) {
     
        documentFile.click()
      }
    
      handleInputLogo(e) {
        var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        if (!files) {
          return;
        }
        //this.etablissement.logo = files[0].name;
    
        for (var i = 0; i < files.length; i++) {
          if (files[i].size > 1000*1000 * 100) {
            this.toastr.error("taille image trop grande", "Error");
            $("#document").val("");
            return;
          }
          var reader = new FileReader();
          var pattern = /image-*/;
    
          if (!files[i].type.match(pattern)) {
            this.toastr.error("invalid format", "Error");   
            return;
          }
          reader.onload = this._handleReaderLogoLoaded.bind(this);
          reader.readAsDataURL(files[i]);
        }
    
      }
    
      _handleReaderLogoLoaded(e) {
        let reader = e.target;
        this.documentLogo.name = reader.result;
        this.concerner.photo = reader.result;
        this.update = true;
      }
      //--Add or Update
      async updateImg(){
          //--add Logo
          this.concerner.photo = this.documentLogo.name;
          let res =  await this.http.put<any>( environment.pension_concerners + '/'+this.concerner.concernees_id,this.concerner,valuesys.httpAuthOptions()).toPromise();
          //--success
          if(res.code === 201){
            this.toastr.success(res.msg,this.__("msg.success")) ;
          //--Warning : Donnée invalide, Session expiré
          }else if(res.code === 400){
            this.toastr.warning(res.msg,this.__("msg.warning")) ;
          }
          //--error
          else {
            this.toastr.error(res.msg,this.__("msg.error")) ;
          }
        
      }


      async retirerCarte() {
          Swal.fire({
            title: this.__('msg.confirm')+" de vouloir",
            text: "Retirer la carte?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#44A1A0',
            cancelButtonColor: '#FF7467',
            reverseButtons : true,
            confirmButtonText: "Retirer la carte",
            cancelButtonText: "Annuler"
          }).then(async (result) => {
            if (result.value) {
                let res =  await this.http.post<any>( environment.pension_retirer_carte,{
                    numero_serie: this.concerner.carte_numero_serie
                },valuesys.httpAuthOptions()).toPromise();
                  //--success
                  if(res.code === 200){
                    this.toastr.success(res.msg,this.__("msg.success")) ;
                    this.havecard = false;
                    //this.isCarteActive = false;
                  //--Warning : Donnée invalide, Session expiré
                  }else if(res.code === 400){
                    this.toastr.warning(res.msg,this.__("msg.warning")) ;
                  }
                  //--error
                  else {
                    this.toastr.error(res.data,this.__("msg.error")) ;
                  }
        
      
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                {
                  icon: 'error',
                  title: "Action aborter!",
                  confirmButtonColor: '#44A1A0',
                  text: this.__('msg.canceled_delete'),
                }
              )
            }
          })














      }

      async searchCarte() {
        this.submitted = false;
        this.carteArray = [];
        const response: any =await this.http.get(environment.pension_cartes+"?page=1&where=active|e|0,carte.numero_serie|e|"+this.numero_serie+",carte.etablissement_id|e|"+this.etablissementId,valuesys.httpAuthOptions()).toPromise();
        if(response.code == 200) this.carteArray = response.data.data
        this.submitted  = true;

      }

      situationCarte(situation: number): string {
        switch (situation) {
          case 0:
            return this.__('carte.situation_0');
          case 1:
            return this.__('carte.situation_1');
          case 2:
            return this.__('carte.situation_2');
          case 3:
            return this.__('carte.situation_3');
          case 4:
              return this.__('carte.situation_4');
          default:
            break;
        }
      }

      choiseLink(type_action) {
        switch (type_action) {
          case 0:
            return 'reserverCarte|réserver' 
          case 1:
            return 'libererCarte|libérer' 
          case 2:
            return 'lancerPersonalisationCarte|lancer la personnalisation' 
          case 3:
            return 'enrolerCarte|enroler' 
          case 4:
            return 'confirmerPersonalisationCarte|confirmer la personnalisation' 
                   
          default:
            break;
        }
      }
      async gestionCarte(num_serie: string,type_action: number)
      {
          const path = environment.pension_cartes+'/'+this.choiseLink(type_action).split('|')[0];
          Swal.fire({
              title: this.__('msg.confirm'),
              text: "Voulez vous "+this.choiseLink(type_action).split('|')[1]+" la carte?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#44A1A0',
              cancelButtonColor: '#FF7467',
              reverseButtons : true,
              confirmButtonText: "Confirmer",
              cancelButtonText: "Annuler"
  
          }).then(async (result) => {
  
              if (result.value) {
                  let sup = await this.http.post<HttpApiResponse>(path, {"numero_serie":num_serie},valuesys.httpAuthOptions()).toPromise();
                  if(sup.code == 200){
                    let response = await this.http.get<HttpApiResponse>(environment.pension_concerners + "/getConcernerByID/" + this.idBeneficiaire + "?page=1",valuesys.httpAuthOptions()).toPromise();
                    if(response.code == 200) {
                      this.concerner = response.data["data"][0];
                      this.isCarteActive = true;
                      Swal.fire({
                        icon: 'success',
                        title: "Donnée enregistrée",
                        confirmButtonColor: '#44A1A0',
                        text: sup.msg,
                      }).then((resultSuccess) => {
                          if(resultSuccess)
                          {
                              //this.router.navigate(['/dashboard/BNF/plateforme/carte_concerner/affectation']);
                          }
                      })
                    }
                   
                  }else {
                      this.toastr.error(sup.data,this.__("msg.error"));
                  }
              }
          })
      }
    
    
}
