<br><div class="repMenu">
    <div class="row  d-flex  justify-content-between">       
      <div class="breadcrumb flat">
        <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
        <a [routerLink]="['/app/module/ADM']" routerLinkActive="active">Administration</a>
        <a [routerLink]="['/dashboard/ADM/plateforme/etablissement']"  routerLinkActive="active" *ngIf="autority('ETA_1')">Gestion ministères</a>
        <a [routerLink]="['/dashboard/ADM/administration/user']"  routerLinkActive="active" *ngIf="autority('USR_1')"> Gestion utilisateurs</a>
        <a [routerLink]="['/dashboard/ADM/administration/profil']"  routerLinkActive="active"  *ngIf="autority('PRFL_1')">Gestion profils</a>
        <a [routerLink]="['/dashboard/ADM/administration/module']"  routerLinkActive="active" *ngIf="autority('MDL_1')">Modules</a>
        <a [routerLink]="['/dashboard/ADM/administration/sousmodule']"  routerLinkActive="active" *ngIf="autority('SMDL_1')">Sous modules</a>
        <a [routerLink]="['/dashboard/ADM/plateforme/province']"  routerLinkActive="active" *ngIf="autority('PRV_1')">Province</a>

      </div>
  </div>
</div> 
<div  [ngClass]="{
  'p-2  repMenu mt-2 d-flex justify-content-end align-items-center': true,
  'bg-white ': autority('SMDL_2')
}">
  <div class="d-flex align-items-center">
    <button class="button-control" *ngIf="autority('SMDL_2')" #addBtnUser (click)="openModal(addSousModule,null,addBtnUser)" ><i class="fa fa-plus"></i>{{__('sous_module.btn_add')}}</button>
       
  </div>
   
</div>
<div class="row">
    <div class="col-lg-12">
      <br>
      <div class="card">
        <div class="card-body">
            <table datatable  [dtOptions]="dtOptions" style="width: 100%"  class="table table-striped table-hover " >
            <thead>
              <tr>
                <th class="text-center">{{__('sous_module.icon')}}</th>
                <th class="text-center">{{__('sous_module.code')}}</th>
                <th class="text-left">{{__('sous_module.name')}}</th>               
                <th class="text-center">{{__('sous_module.module')}}</th>
                <th class="actionTab text-center">{{__('table.action')}}</th>
              </tr>
            </thead>
            <tbody *ngIf="sous_modules?.length != 0" >
                <tr *ngFor="let sous_module of sous_modules">
                  <td class="text-center"><i class={{sous_module.icon}}> </i> </td>
                  <td class="text-center">{{ sous_module?.code }}</td>
                  <td class="text-left">{{ sous_module?.name }}</td>
                  <td class="text-center">{{ sous_module?.module.name }}</td>              
                  <td class="text-center">
                      <i class="fa fa-edit icon-table" *ngIf="autority('SMDL_3')" ngbTooltip="{{__('tooltip.edite')}}"  #openModalBtnEdit (click)="openModal(addSousModule,sous_module,openModalBtnEdit)" ></i>
                      <i class="fa fa-toggle-on icon-table vert" ngbTooltip="{{__('tooltip.active')}}" #buttonElement (click)="toggleState(sous_module,0,buttonElement)" *ngIf="sous_module.state == 1 && autority('SMDL_6')" ></i>
                      <i class="fa fa-toggle-off icon-table vert" ngbTooltip="{{__('tooltip.desactiver')}}" #buttonElement (click)="toggleState(sous_module,1,buttonElement)" *ngIf="sous_module.state == 0 && autority('SMDL_6')" ></i>
                      <i class="fa fa-trash icon-table red" *ngIf="autority('SMDL_5')"  ngbTooltip="{{__('tooltip.delete')}}" #openModalBtnDelete (click)="delete(sous_module,openModalBtnDelete)"></i>
                  </td>

              </tr>
            </tbody>
            <tbody *ngIf="sous_modules?.length == 0">
            <tr>
              <td colspan="4" >{{__('label_no_data')}}</td>
            </tr>
          </tbody> 
          </table>
          
        </div>
      
      </div>
    </div>
  </div>
     
  <ng-template #addSousModule let-modal style="background-color: red; width: auto;">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div class="modal-body">
        <br>
        <form ngNativeValidate [formGroup]="registerForm" (ngSubmit)="addSousModuleForm()">
        <div class="row"> 
          <div class="col-lg-6">
            <div class="form-group">
              <label >
                {{__('sous_module.module_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                <span *ngIf="submitted && f.module.errors" class="invalid-form-feedback">
                    <span *ngIf="f.code.errors.required"> / Ce champ est obligatoire</span>
                </span>
            </label>
                <ng-select 
                    formControlName="module" 
                    [(ngModel)]="sous_module.module_id" 
                    class="form-control"
                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.module.errors }"
                >
                    <ng-option  *ngFor="let module of modules" [value]="module?.id ">{{ module?.name }}</ng-option>
                </ng-select>
            </div>

            <div class="form-group">
                <label >
                    {{__('sous_module.code')}} (<span class="required-red" >{{__('label.require')}}</span>)
                    <span *ngIf="submitted && f.code.errors" class="invalid-form-feedback">
                        <span *ngIf="f.code.errors.required"> / Ce champ est obligatoire</span>
                    </span>
                </label>
                <input 
                    formControlName="code" 
                    type="text" 
                    class="form-control" 
                    name="code" 
                    id="code" 
                    [(ngModel)]="sous_module.code"  
                    placeholder="{{__('sous_module.code')}}" 
                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.code.errors }"
                >
            </div>
           
          </div>

          <div class="col-lg-6">
           
            <div class="form-group">
                <label >
                    {{__('sous_module.name')}} (<span class="required-red" >{{__('label.require')}}</span>)
                    <span *ngIf="submitted && f.libelle.errors" class="invalid-form-feedback">
                        <span *ngIf="f.libelle.errors.required"> / Ce champ est obligatoire</span>
                    </span>
                </label>
                <input 
                    formControlName="libelle" 
                    type="text" 
                    class="form-control" 
                    name="name" 
                    id="name" 
                    [(ngModel)]="sous_module.name"  
                    placeholder="{{__('sous_module.name')}}" 
                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.libelle.errors }"
                >
            </div>
            <div class="form-group">
                <label >
                    {{__('sous_module.icon')}} (<span class="required-red" >{{__('label.require')}}</span>) 
                    <span *ngIf="submitted && f.icone.errors" class="invalid-form-feedback">
                        <span *ngIf="f.icone.errors.required"> / Ce champ est obligatoire</span>
                    </span>
                </label>
                <input 
                    formControlName="icone" 
                    type="text" 
                    class="form-control" 
                    name="icon" 
                    id="icon" 
                    [(ngModel)]="sous_module.icon"  
                    placeholder="{{__('sous_module.icon')}}" 
                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.icone.errors }"
                >
            </div>
          </div>       
        </div>
      

        <div class="row">
            <div class="col-lg-12">
                <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == true"   >{{__('tooltip.enreg')}}</button>            
                <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false"  > {{__('tooltip.edite')}}</button>            
                <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>            
          </div>      
        </div>
        </form>

    </div>
    
    
  </ng-template>





