import { INavData } from '@coreui/angular';
import { Translatable } from './administration/service/Translatable';

const tranlatable = new Translatable();

export const navItems: INavData[] = [
  {
    name: tranlatable.__('menu.tableau_board'),
    url: '/accueil/dashboard',
    icon: 'fa fa-home',
  },
  {
    name: tranlatable.__('menu.parametre'),
    url: '/parametre',
    icon: 'fa fa-cog',
    children: [
      {
        name: 'plateforme',
        url: '/parametre/plateforme',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'action',
        url: '/parametre/action',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'Centre d\'examen',
        url: '/parametre/centre_exam',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'Centre du Bacc',
        url: '/parametre/centre_bacc',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'Frais d\'inscription',
        url: '/parametre/frais_inscrit',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'Domaine',
        url: '/parametre/domaine',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'Grade',
        url: '/parametre/grade',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'Année scolaire',
        url: '/parametre/annee_scolaire',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'Spécialité',
        url: '/parametre/specialite',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'Vocation',
        url: '/parametre/vocation',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'Type d\'établissement',
        url: '/parametre/type_etablissement',
        icon: 'fa fa-location-arrow'
      },
      {
        name: 'serie',
        url: '/parametre/serie',
        icon: 'fa fa-location-arrow'
      },
    ]
  },
  {
    name: tranlatable.__('menu.gest_etudiant'),
    url: '/accueil/etudiant',
    icon: 'fa fa-graduation-cap',
    children: [
      {
        name: tranlatable.__('menu.etudiant'),
        url: '/accueil/etudiant',
        icon: 'fa fa-location-arrow'
      },
    ]
  },
  {
    name: tranlatable.__('menu.gest_etab'),
    url: '/plateforme/etab',
    icon: 'fa fa-institution',
    children: [
      {
        name: tranlatable.__('menu.etablissement'),
        url: '/plateforme/etab',
        icon: 'fa fa-location-arrow'
      },
    ]
  },

  {
    name: tranlatable.__('menu.gest_user'),
    url: '/admin/utilisateur',
    icon: 'fa fa-user-circle',
    children: [
      {
        name: tranlatable.__('menu.utilisateur'),
        url: '/admin/utilisateur',
        icon: 'fa fa-location-arrow'
      },
    ]
  },

  {
    name: "Gestion des modules",
    url: '/admin/module',
    icon: 'fa fa-user-circle',
    children: [
      {
        name: 'Module',
        url: '/admin/module',
        icon: 'fa fa-location-arrow'
      },


    ]
  },

  {
    name: "Gestion des sous modules",
    url: '/admin/sous-module',
    icon: 'fa fa-user-circle',
    children: [
      {
        name: 'Sous module',
        url: '/admin/sous-module',
        icon: 'fa fa-location-arrow'
      },
    ]
  },

  {
    name: tranlatable.__('menu.gest_profil'),
    url: '/admin/profil-gestion',
    icon: 'fa fa-graduation-cap',
    children: [
      {
        name: "Profile",
        url: '/admin/profil-gestion',
        icon: 'fa fa-location-arrow'
      },
    ]
  },

  {
    name: "Gestion des types utilisateurs",
    url: '/plateforme/type-user',
    icon: 'fa fa-user-circle',
    children: [
      {
        name: 'Type utilisateur',
        url: '/plateforme/type-user',
        icon: 'fa fa-location-arrow'
      },
 


    ]
  },

];
