<br>
<div class="repMenu">
  <div class="row  d-flex  justify-content-between">     
      <div class="breadcrumb flat">
          <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
          <a [routerLink]="['/app/module/CAR']"  routerLinkActive="active">{{__('menu.carte')}}</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/carte']" routerLinkActive="active" *ngIf="autority('GCA_1')">{{__('carte.title')}}</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/listeCarteParMinistere']"  routerLinkActive="active" *ngIf="autority('DCA_1')">Disponibiliter des cartes</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/affecterCarte']"  routerLinkActive="active" *ngIf="autority('AFC_1')">Affecter carte</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/ActiverDesactive']"  routerLinkActive="active" *ngIf="autority('ACD_1')">Activer / Desactiver carte</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/EnrolerCarte']"  routerLinkActive="active" *ngIf="autority('ENC_1')">Enroler carte</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/planifier_creation_compte']"  routerLinkActive="active" *ngIf="autority('CRC_1')">Création de compte</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/demandeenrolement']"  routerLinkActive="active" *ngIf="autority('DEN_1')">Demande enrolement</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/demandewallet']"  routerLinkActive="active" *ngIf="autority('DEW_1')">Demande création compte</a>
      </div>
  </div>
</div> 

<div class="row">
<div class="col-lg-12">
  <br>
  <div class="card" *ngIf="concernerSuccess?.length == 0 && concernerEchec?.length == 0">
    <div class="card-body">

          <form ngNativeValidate [formGroup]="registerForm" (ngSubmit)="filtreBeneficiaire()">

              <div class="row">
            
                <div class="col-lg-4">
                  <div class="form-group">
                    <label >{{__('concerner.ministere')}} (<span class="required-red" >{{__('label.require')}}</span>)
                        <span *ngIf="submitted && f.etablissement_id.errors" class="invalid-form-feedback">
                            <span *ngIf="f.etablissement_id.errors.required"> / Ce champ est obligatoire</span>
                        </span>
                    </label>
                    <ng-select placeholder="{{__('carte.ministere')}}" 
                        [(ngModel)]="etablissement_id" 
                        class="form-control"
                        [readonly]="superAdmin == 0 && activeEtabId != 1"
                        formControlName="etablissement_id"
                        [ngClass]="{ 'invalid-form-input-validate': submitted && f.etablissement_id.errors }"
                        (change)="getCategorieByIdEtab($event,categorieEl)"
                        >
                        <ng-option  *ngFor="let etab of etablissements" [value]="etab?.id ">{{ etab?.name }}</ng-option>
                    </ng-select> 
                    </div>
                </div>
            
                <div class="col-lg-3">
                  <div class="form-group">
                    <label >{{__('concerner.categorie_concerne_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                        <span *ngIf="submitted && f.categorie_id.errors" class="invalid-form-feedback">
                            <span *ngIf="f.categorie_id.errors.required"> / Ce champ est obligatoire</span>
                        </span>
                    </label>
                    <ng-select placeholder="{{__('concerner.categorie_concerne_id')}}"
                        [(ngModel)]="categorie_concernees_id" 
                        class="form-control"
                        formControlName="categorie_id"
                        [ngClass]="{ 'invalid-form-input-validate': submitted && f.categorie_id.errors }"
                        #categorieEl

                        >
                        <ng-option  *ngFor="let categorie of categories" [value]="categorie?.id ">{{ categorie?.name }}</ng-option>
                    </ng-select> 
                    </div>
                </div> 
                
                <div class="col-lg-3">
                  <div class="form-group">
                    <label >{{__('concerner.matricule')}}
                        <span *ngIf="submitted && f.matricule.errors" class="invalid-form-feedback">
                            <span *ngIf="f.matricule.errors.required"> / Ce champ est obligatoire</span>
                        </span>
                    </label>
                    
                      <input 
                        formControlName="matricule" 
                        type="text" 
                        class="form-control" 
                        name="matricule" 
                        id="email" 
                        [(ngModel)]="f_matricule"  
                        placeholder="{{__('concerner.matricule')}}"
                        [ngClass]="{ 'invalid-form-input-validate': submitted && f.matricule.errors }"
                        >
                  </div> 
                </div>
                
            
            
                <div class="col-lg-2 d-flex align-items-center mt-1" >
                    <button style="height: 44px;" type="submit" name="button" class="btn button-control" >{{__('btn.filtre')}}</button>       
                </div>
            


              </div>
          </form>
    </div>
  </div>
</div>
</div> 

<div class="row">

  <div class="col-lg-12">
    <br>
    <div class="card" *ngIf="concernerSuccess?.length == 0 && concernerEchec?.length == 0">
      <div class="card-body">
        <div class="row footer-paginate" >
          <div class="col-lg-4"> 
            <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span> 
            <select name="" id="" [(ngModel)]="filtreSelect" (change)="getFiltreSelect()" class="search-input" >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="2000">2000</option>
              <option value="5000">5000</option>
              <option value="10000">10000</option>
            </select> 
            <span style="color: #666 !important;" > {{__('datatable.element')}}  </span> 
          </div>
          <div class="col-lg-8 btn-paginate">
            <span style="color: #666 !important;" > {{__('datatable.rechercher')}} : </span><input type="text" [(ngModel)]="searchInput" (keyup.enter)="getSearchInput()" class="search-input">
          </div> 
        </div>
        <table style="width: 100%"  class="table table-striped table-hover " >
          <thead class="detail">
            <tr>
              <th class="text-left">{{__('concerner.matricule')}}
                <br>
                  <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.matricule','asc')" *ngIf="tri=='concernees.matricule' && order=='desc'" ></i> 
                  <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.matricule ','desc')"  *ngIf="tri=='concernees.matricule' && order=='asc'"  ></i> 
                  <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.matricule','asc')" *ngIf="tri!='concernees.matricule'" ></i> 
                  <input type="text" #matricule class="inputCol" name="matricule" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.matricule',matricule, false)" id="">
              </th>
              <th class="text-left">{{__('concerner.nom')}}
                <br>
                <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.nom','asc')" *ngIf="tri=='concernees.nom' && order=='desc'" ></i> 
                <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.nom','desc')"  *ngIf="tri=='concernees.nom' && order=='asc'"  ></i> 
                <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.nom','_asc')" *ngIf="tri!='concernees.nom'" ></i> 
                <input type="text" #nom class="inputCol" name="nom" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.nom',nom, false)" id="">
              </th>
              <th class="text-left">{{__('concerner.prenom')}}
                <br>
                <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.prenom','asc')" *ngIf="tri=='concernees.prenom' && order=='desc'" ></i> 
                <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.prenom','desc')"  *ngIf="tri=='concernees.prenom' && order=='asc'"  ></i> 
                <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.prenom','_asc')" *ngIf="tri!='concernees.prenom'" ></i> 
                <input type="text" #prenom class="inputCol" name="prenom" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.prenom',prenom, false)" id="">
              </th>
              <th class="text-left">{{__('concerner.telephone')}}
                <br>
                <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.telephone','asc')" *ngIf="tri=='concernees.telephone' && order=='desc'" ></i> 
                <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.telephone','desc')"  *ngIf="tri=='concernees.telephone' && order=='asc'"  ></i> 
                <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.telephone','asc')" *ngIf="tri!='concernees.telephone'" ></i> 
                <input type="text" #telephone class="inputCol" name="email" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.telephone',telephone, false)" id="">
              </th>
              <th class="text-left">{{__('ordre_paiement.categorie')}}
                <br>
                <i class="fa fa-caret-down icon-tri" (click)="triTable('categorie_concernees.name','asc')" *ngIf="tri=='categorie_concernees.name' && order=='desc'" ></i> 
                <i class="fa fa-caret-up icon-tri" (click)="triTable('categorie_concernees.name','desc')"  *ngIf="tri=='categorie_concernees.name' && order=='asc'"  ></i> 
                <i class="fa fa-caret-left icon-tri" (click)="triTable('categorie_concernees.name','asc')" *ngIf="tri!='categorie_concernees.name'" ></i> 
                <input type="text" #categorie class="inputCol" name="categorie" (keyup.enter)="getUrlDatatable(endPOint, 'categorie_concernees.name',categorie, false)" id="">
              </th>
              <th class=" text-center">
                <input  #checkAll  type="checkbox" name=""  ngbTooltip="Cocher tout / Décocher tout"  (click)="cocherToutBeneficiaire(concernerArray,checkAll)" >
              </th>
            </tr>
          </thead>
          <tbody *ngIf="concernerArray?.length != 0"  class="detail" >
              <tr *ngFor="let concerner of concernerArray">
                    <td class="text-left">{{ concerner?.matricule }}</td>    
                    <td class="text-left">{{ concerner?.nom}}</td> 
                    <td class="text-left">{{ concerner?.prenom}}</td> 
                    <td class="text-left">{{ concerner?.telephone }}</td>  
                    <td class="text-left">{{ concerner?.categorie_name }}</td>
                    <td class="text-center">
                      <input #checkBeneficiaire type="checkbox" [checked]="isCheckedElmBeneficiaire" (click)="cocherBeneficiaire(concerner,checkBeneficiaire)" >
                    </td>
              </tr>
          </tbody>
          <tbody *ngIf="concernerArray?.length == 0">
            <tr>
              <td colspan="6" >{{__('label_no_data')}}</td>
            </tr>
          </tbody> 
        </table>

        <div class="row footer-paginate pt-2" *ngIf="concernerArray.length != 0">
          <div class="col-lg-4"> 
            <span class="three-point" style="padding-left: 0px;" >{{infoData}}</span> 
          </div>
          <div class="col-lg-8 btn-paginate" >
            <button *ngIf="currentPage != 1" (click)="getUrlDatatable(firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
            <button *ngIf="currentPage != 1" (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
            <button *ngIf="currentPage > 3" class="btn-pagination-hr"  (click)="getUrlDatatable(firstPage)" > 1 </button>
            <span *ngIf="currentPage > 3" class="three-point" > ... </span>
            <button *ngIf="cPage_less_2 > 0 && currentPage < 4" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_less_2)" > {{cPage_less_2}} </button>
            <button *ngIf="cPage_less_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_less_1)" > {{cPage_less_1}} </button>
            <button (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{currentPage}} </button>
            <button *ngIf="cPage_more_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_more_1)" > {{cPage_more_1}} </button>
            <button *ngIf="(cPage_more_2 > 0 && cPage_more_2 > lastNumPage && cPage_more_1 > lastNumPage && currentPage > lastNumPage) || cPage_more_2 == lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_more_2)" > {{cPage_more_2}} </button>
            <span *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="three-point" > ... </span>
            <button *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(lastPage)" > {{lastNumPage}} </button>
            <button *ngIf="currentPage < lastNumPage" (click)="getUrlDatatable(nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
            <button *ngIf="currentPage < lastNumPage" (click)="getUrlDatatable(lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
          </div>
        </div>
        <br>
        <div style="flex: right;" *ngIf="concernerArray.length != 0">
            <button type="button" name="button" [disabled]="tabBeneficiaireCheck.length == 0"  (click)="createCompteBeneficiaire()" class="btn mb-4 button-control"    >{{__('tooltip.create_compte')}}</button>                      
        </div>
    
      </div>

    </div>

    <div class="card" *ngIf="concernerSuccess?.length != 0 || concernerEchec?.length != 0" >
      <div class="card-body">

        <div class="container">
          <div class="card" >

              <div class="card-body" style="margin: 0 50px;">
                <div style="text-align:center" ></div>
                <div class="text-center">
                  <span class="title-card-profil padding-50px " style="color: #44a1a0; font-weight: bold;"  ><i class="fa fa-window-restore"></i> {{__('concerner.create_compte_ben')}} </span>
                  </div>
                  <br>


                <div class="row d-flex  align-items-center">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label > {{__('concerner.nb_demande_enregistrer')}} : <span style="font-weight: bold;"> {{concernerSuccess?.length}} </span> </label>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label >  {{__('concerner.nb_demande_non_enregistrer')}} : <span style="font-weight: bold;"> {{concernerEchec?.length}} </span> </label>
                    </div>
                  </div>
                </div> 

                <!--div class="row d-flex  align-items-center">
                  <div class="col-lg-6">
                    <table class="table table-hover table-striped">
                      <thead>
                          <tr>
                            <th class="text-center">{{__('concerner.matricule_beneficiaire_cree')}} </th>
                          </tr>
                        </thead>
                        <tbody *ngIf="concernerSuccess?.length != 0" >
                            <tr *ngFor="let concerner of concernerSuccess ; let i = index">
                                <td class="text-center">{{ concerner?.concerne_id }}</td>
                          </tr>
                        </tbody>
                        <tbody *ngIf="concernerSuccess?.length == 0">
                          <tr>  <td class="text-center" > - </td> </tr>
                        </tbody> 
                      </table>
                  </div>
                  <div class="col-lg-6">
                    <table class="table table-hover table-striped">
                      <thead>
                          <tr>
                            <th class="text-center">{{__('concerner.matricule_beneficiaire_non_cree')}}</th>
                          </tr>
                      </thead>
                      <tbody *ngIf="concernerEchec?.length != 0" >
                          <tr *ngFor="let concerner of concernerEchec ;">
                              <td class="text-center">{{ concerner?.matricule}}</td> 
                          </tr>
                      </tbody>
                      <tbody *ngIf="concernerEchec?.length == 0">
                        <tr> <td colspan="9" class="text-center" > - </td> </tr>
                      </tbody> 
                    </table>
                  </div>
                </div --> 
                <hr>
                <div style="text-align: center;" >
                  <button type="button" name="cancel"  (click)="annuler()" style="background-color: #ff7467; padding: 10px; color: white; font-weight: bold;margin-top: 10px; width:200px;"  class="btn mb-4 button-recuperation"> {{__('btn.retour')}}</button>            
                </div>
                <br>
              </div>

          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>

