import formatNumber from 'number-handler'
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChartType } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { environment } from '../../../../../../../environments/environment';
import { valuesys } from '../../../../../../../options';
import { etablissement, ordre_paiement } from '../../../../../model/db';
import { categorie } from '../../../../../model/pension';
import { AuthServiceService } from '../../../../../service/auth-service.service';
import { HelperService } from '../../../../../service/helper.service';
import { Translatable } from '../../../../../service/Translatable';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import addSpace from 'number-handler';

@Component({
  selector: 'app-resume-bourse-univ',
  templateUrl: './resume-bourse-univ.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./resume-bourse-univ.component.scss']
})
export class ResumeBourseUnivComponent extends  Translatable implements OnInit {


  formatNumber : any = formatNumber; 

  dtOptions: DataTables.Settings = {};
  titleModal:string ;
  title:string ;
  public categories: categorie [] = [];
  
  etabId:any ;
  public module_code = window['module'];
  public superAdmin : number ;
  registerForm : FormGroup;
  submitted    = false;

  searchInput : any= "";
  filtreSelect : any= 10;
  where : any= "";
  search : any= "";
  child : any= "";
  tri : any= "";
  order : any= "";
  endPOint : any= "";
  public searchColumn: string;
  public column: string;
  public childCol: string;

   //--paginate
   public paginateListe : any = [];
   public nextPage : any = [];
   public prevPage : any = [];
   public lastPage : any = [];
   public firstPage : any = [];
   public currentPage : any = [];
   public total : any = [];
   public path : any = [];
   public lastNumPage : any = [];
   public infoData : any = [];
   public cPage_less_2 : number;
   public path_cPage_less_2 : any;
   public cPage_less_1 : number;
   public path_cPage_less_1 : any;
   public cPage_more_1 : number;
   public path_cPage_more_1 : any;
   public cPage_more_2 : number;  
   public path_cPage_more_2 : any;
  //--end-paginate
  etatResumeUnivList:  any[] = []; 

  public etablissements:etablissement [] = [];

  filtre_etablissement_id : any;
  filtre_categorie_concernees_id: any;
   

  public ordre_paiements :ordre_paiement[] = [] ;
  dateDebut: string = this.formatDateYMD(new Date());
  dateFin: string = this.formatDateYMD(new Date());
  submitFiltreDate: boolean = false;
  etablissementId: number;
  // Chart Pour genre
  doughnutChartLabels: Label[] = [];
  doughnutChartData: MultiDataSet = [];
  doughnutChartType: ChartType = 'doughnut';
  public chartOptions: any = {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 80
  };
  public chartColors: Array<any> = [
      { // first color
        backgroundColor: ['#834090', '#cb2a7c','#bf96e8','#99185f','#d2418a','#ef5fa7']
      }
  ];

  
  // Chart Pour nationalité 
  doughnutChartLabelsNationalite: Label[] = [];
  doughnutChartDataNationalite: MultiDataSet = [];
  doughnutChartTypeNationalite: ChartType = 'doughnut';
  public chartOptionsNationalite: any = {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 80
  };
  public chartColorsNationalite: Array<any> = [
      { // first color
        backgroundColor: ['#834090', '#cb2a7c','#bf96e8','#99185f','#d2418a','#ef5fa7']
      }
  ];

  public detail_dashboard :any = [] ;
  public nombre_etudiant_inscrit_par_mention :any = [] ;
  public recapitulatif_preinscris_a_valider_par_etablissement :any = [] ;
  public recapitulatif_preinscription_a_valider_par_genre :any = [] ;
  public recapitulatifs :any = [] ;
  public recapitulatif_etudiant_inscris_par_code_redoublement :any = [] ;
  public recapitulatif_etudiant_inscris_nationalite :any = [] ;
  detailPaiment: any[] = [];
  detailRecord: any[] = [];
  constructor(private helper: HelperService,             
              private http: HttpClient,
              private auth : AuthServiceService,  
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private modalService: NgbModal, 
              private toastr: ToastrService
               
    ) {
      super();

    }

  async ngOnInit() {
    let where = "";
    let userinfo = this.auth.account()
    this.etabId = userinfo.info.etablissement_id;
    this.superAdmin = userinfo.info.admin;
    // if(this.superAdmin == 0 && this.etabId != 1) where = "?where=etablissement.id|e|"+this.etabId;

    
    console.log(this.ordre_paiements,"xxxxx")
    
    this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);
  
    // this.categories = await this.helper.getSelectList(environment.pension_categories,['name']);

   
      this.route.queryParams.subscribe(async params => {
        const idUniv = +params['idUniv'];
        const dateDebut = params['date_debut'];
        const dateFin = params['date_fin'];
        console.log('datefin:',dateFin,'---date date debut:',dateDebut)
        if(idUniv) {
          this.etablissementId = idUniv;
          this.dateDebut = this.formatDateYMD(new Date(dateDebut));
          this.dateFin = this.formatDateYMD(new Date(dateFin));          
          let resp:any = await this.http.get<any>(`${environment.etatResumeBourseUniversite}?etablissement_id=${idUniv}&date_debut=${dateDebut}&date_fin=${dateFin}`, valuesys.httpAuthOptions()).toPromise();
          if(resp.code == 200) {
            this.etatResumeUnivList = resp.data;
          } else {
            this.etatResumeUnivList = [];
            this.toastr.error(resp.msg,this.__("msg.error"));
          }
       
        } else {
          this.dateDebut = this.formatDateYMD(new Date());
          this.dateFin = this.formatDateYMD(new Date());
        }



        // this.postId = +params['id'];
        // this.userName = params['name'];
        // Do something with postId and userName, like display them on the page
      });
   
    }



 

  async getSearchInput(){
    let where = "";
    let date = ""
    if(this.dateFin && this.dateDebut) date = `&date_debut=${this.dateDebut}&date_fin=${this.dateFin}`;
    if(this.superAdmin == 0) where += "&where=etablissement.id|e|"+this.etabId
    this.endPOint = environment.pension_detail_dashboard_listecarte+"?page=1"+where+date;
    this.getUrlDatatable(this.endPOint )
  }

  async getFiltreSelect(){   
    let where = "";
    let date = ""
    if(this.dateFin && this.dateDebut) date = `&date_debut=${this.dateDebut}&date_fin=${this.dateFin}`;
    if(this.superAdmin == 0) where += "&where=etablissement.id|e|"+this.etabId
    this.endPOint = environment.pension_detail_dashboard_listecarte+"?page=1"+where+date;
    this.getUrlDatatable(this.endPOint )
  }


  async getSearch(){
    this.getUrlDatatable(this.endPOint )
  }
  async triTable(col, order){
    this.tri = col;
    this.order = order;
    this.getUrlDatatable(this.endPOint )
  }

  async getUrlDatatable(url,col = "", refElement?: any, child?: any, searchMulti?:any){


          this.where = "";

          if(refElement){
          this.searchColumn = refElement.value
          this.column = col
          }

       
          if(col != "" || this.searchColumn ){
            if(this.searchColumn !="" && this.searchColumn !="undefined"){
              if(this.superAdmin==1)
              this.search = this.column+"|l|"+this.searchColumn;
              else
              this.search = ","+this.column+"|l|"+this.searchColumn;
            }else if(this.searchColumn == "") {
              this.search = "";
            }
          } 

          else {

            if(this.searchInput !="" && this.searchInput !="undefined"){
              this.where = ""
              this.search = "&where_or=etablissement.name|l|%"+this.searchInput+ "%"
            }else if(this.searchInput == "") {
              this.search = "";
            }
          }
    

    let filtre : any= "";
    if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
      filtre = "&per_page=" + this.filtreSelect;
    }

    let triage : any= "";
    if(this.tri !="" && this.tri !="undefined"){
      triage = "&__order__="+ this.order+","+ this.tri;
    }
    console.log("search", this.search)

    let toogle = await this.http.get<any>(url + this.where + this.search  + filtre + triage,valuesys.httpAuthOptions()).toPromise();
   
    this.ordre_paiements = toogle.data.data;

    console.log("Togle data :",  this.ordre_paiements)
  }



  async addSelectForm(){

    

      let filtre_etablissement = '';
    if(this.filtre_etablissement_id != '' && this.filtre_etablissement_id != undefined){
      filtre_etablissement = ",concernees.etablissement_id|e|" + this.filtre_etablissement_id ;
    }
    let filtre_categorie = '';
    if(this.filtre_categorie_concernees_id != '' && this.filtre_categorie_concernees_id != undefined){
      filtre_categorie = ",concernees.categorie_concerne_id|e|" + this.filtre_categorie_concernees_id ;
    }

    let resp = await this.http.get<any>(environment.pension_detail_dashboard_listecarte_by_cat_id+"?where=1|e|1" + filtre_etablissement + filtre_categorie, valuesys.httpAuthOptions()).toPromise();

    this.recapitulatifs = resp.data;
    this.submitted = true;

  }
  async filtreByDate(){
 
    this.submitFiltreDate = true;
    let resp:any = await this.http.get<any>(`${environment.etatResumeBourseUniversite}?etablissement_id=${this.etablissementId}&date_debut=${this.dateDebut}&date_fin=${this.dateFin}`, valuesys.httpAuthOptions()).toPromise();
    if(resp.code == 200) {
      this.etatResumeUnivList = resp.data;
    } else {
      this.etatResumeUnivList = [];
      this.toastr.error(resp.msg,this.__("msg.error"));
    }
  }

  async exportToExcel() {
    const etab_name = this.etablissements.find((val) => val.id == this.etablissementId).name;
    this.helper.exportExcel(this.printPaiement(this.etatResumeUnivList),'Listes états resumé bourse université - '+etab_name+' - date du '+this.dateDebut+' - '+this.dateFin).then(
      (response: any)=>{
            let a = document.createElement("a"); 
            a.href = response.data;
            a.download = `etat_resume_univ.xlsx`;
            a.click(); 
      },
      (error:any)=>{
        console.log(error)
      }
    );
  }
  async exportDetailToExcel() {
    const etab_name = this.etablissements.find((val) => val.id == this.etablissementId).name;
    this.helper.exportExcel(this.printDetail(this.detailPaiment),'Listes étudiants bourse payée - université - '+etab_name+' - date du '+this.dateDebut+' - '+this.dateFin).then(
      (response: any)=>{
            let a = document.createElement("a"); 
            a.href = response.data;
            a.download = `list_etudiant_bourse_paye.xlsx`;
            a.click(); 
      },
      (error:any)=>{
        console.log(error)
      }
    );
  }
  printPaiement(ordre_paiement:any[]){
    return  ordre_paiement.map((ordre_paiement)=>{
      let t = {}
      t[this.__('resume_univ.t1')] = ordre_paiement.code
      t[this.__('resume_univ.t2')] = ordre_paiement.nb_boursier
      t[this.__('resume_univ.t3')] = ordre_paiement.nb_boursier_carte
      t[this.__('resume_univ.t4')] = ordre_paiement.nb_boursier_wallet
      t[this.__('resume_univ.t5')] = ordre_paiement.nb_paiment
      t[this.__('resume_univ.t6')] = ordre_paiement.nb_compte_no_credite
      t[this.__('resume_univ.t7')] = ordre_paiement.montan_paiment
      t[this.__('resume_univ.t8')] = ordre_paiement.nb_retrait_epoketra_PAOMA
      t[this.__('resume_univ.t9')] = ordre_paiement.montant_retrait_epoketra_PAOMA
      t[this.__('resume_univ.t10')] = ordre_paiement.nb_retrait_paositra_PAOMA
      t[this.__('resume_univ.t11')] = ordre_paiement.montant_retrait_paositra_PAOMA
     
      return t ;
    })  || [];

  }
  printDetail(ordre_paiement:any[]){
    return  ordre_paiement.map((ordre_paiement)=>{
      let t = {}
      t[this.__('resume_detail.t1')] = ordre_paiement.num_compte
      t[this.__('resume_detail.t2')] = ordre_paiement.IDetudiant
      t[this.__('resume_detail.t3')] = ordre_paiement.prenom
      t[this.__('resume_detail.t4')] = ordre_paiement.nom
      t[this.__('resume_detail.t5')] = ordre_paiement.niveau
      t[this.__('resume_detail.t6')] = ordre_paiement.telephone
      t[this.__('resume_detail.t7')] = ordre_paiement.num_transaction
      t[this.__('resume_detail.t8')] = ordre_paiement.date_paiement
      t[this.__('resume_detail.t9')] = ordre_paiement.montant
      t[this.__('resume_detail.t10')] = ordre_paiement.type_compte     
      return t ;
    })  || [];

  }
  async exportPdf() {
    const etab_name = this.etablissements.find((val) => val.id == this.etablissementId).name;
    await this.helper.exportPdf(this.printPaiement(this.etatResumeUnivList),'Listes états resumé bourse université - '+etab_name+' - date du '+this.dateDebut+' - '+this.dateFin,'','etat_resume_univ');
  }
  async exportDetailPdf() {
    const etab_name = this.etablissements.find((val) => val.id == this.etablissementId).name;
    await this.helper.exportPdf(this.printDetail(this.detailPaiment),'Listes étudiants bourse payée - université - '+etab_name+' - date du '+this.dateDebut+' - '+this.dateFin,'','list_etudiant_bourse_paye');
  }
  

  formatDateYMD(date: Date) {
    return date.toISOString().split('T')[0];
  }
  async openModal(template:TemplateRef<any>,niveau: string) {
    this.titleModal = "Détail paiement"
    let resp:any = await this.http.get<any>(`${environment.detailPaiement}/${this.etablissementId}/${this.dateDebut}/${this.dateFin}`, valuesys.httpAuthOptions()).toPromise();
    if(resp.code == 200) {
      let response = resp.data;
      this.detailPaiment = response;
      this.modalService.open(template, { windowClass:'mymodal-xl', backdrop:"static" });
    } else {
      this.detailPaiment = [];
      this.toastr.error(resp.msg,this.__("msg.error"));
    }

  }
  close(){
    this.modalService.dismissAll();
  }
  addSpace(number,separator = " ") {
    const format = (num) => String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
    return format(number).toString().split(',').join(separator) || '';
  }

}
 