import { Component, OnInit } from '@angular/core';
import { Translatable } from '../../administration/service/Translatable';
import { Auth,profilage_module,profilage_sous_module } from "../../administration/model/db";
import { AuthServiceService } from "../../administration/service/auth-service.service";
import { ActivatedRoute, Router } from "@angular/router";
import Swal from "sweetalert2";
import { DataSharingService } from '../../administration/service/data-sharing/data-sharing.service';


@Component({
  selector: 'app-home-sous-module',
  templateUrl: './home-sous-module.component.html',
  styleUrls: ['./home-sous-module.component.scss']
})
export class HomeSousModuleComponent extends Translatable implements OnInit {

  module:profilage_module  = new profilage_module();
  public user    : Auth = new Auth();

  constructor(private router : Router,private activatedRoute: ActivatedRoute, private auth : AuthServiceService,private dataSharingService: DataSharingService) {super()}

  ngOnInit() {
    this.dataSharingService.$profilImageSubject.subscribe(
      (response: string) => {
        // 
        try {
          this.user.info.avatar = response 
        } catch (error) {
          console.log(error)
        }     


      }
    )
    this.activatedRoute.params.subscribe(async _ => {
      let moduleCode = _.moduleCode;
      this.user = <Auth> await this.auth.getLoginUser();
      if(this.user){
        this.module = this.user.modules.find((module : profilage_module) => module.code === moduleCode) ;
        if(!this.module){
         // alert('no find module')
        }
      }

    });

  }

  filterSousModule(sousmodules:profilage_sous_module[]){
    return  sousmodules.filter((_)=>_.hasOneAction === true || this.user.info.admin === 1)
  }

  goToDasboard(urlLink)
  {
    console.log(urlLink,"link")
    this.router.navigate([urlLink]);
  }

  logout(){
    //btn.disabled = true;
    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmeLogOut'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      confirmButtonText: this.__('swal.yes'),
      cancelButtonText: this.__('swal.no'),
      reverseButtons : true
    }).then(async (result) => {
      if (result.value) {
        return  await this.router.navigate(['/logout'])
      }
    })
  }

  goToMe() : void{
    this.router.navigate(['/app/profile']);
  }

}
