export const datatableFr: any = {
    "emptyTable":     "Pas de donné pour le moment",
    "info":           "Affichage de l'élément _START_ à _END_ sur _TOTAL_ éléments",
    "infoEmpty":      "Showing 0 to 0 of 0 entries",
    "infoFiltered":   "(filtered from _MAX_ total entries)",
    "infoPostFix":    "",
    "thousands":      ",",
    "lengthMenu":     "Afficher _MENU_ éléments",
    "loadingRecords": "Loading...",
    "processing":     "",
    "search":         "Rechercher:",
    "zeroRecords":    "No matching records found",
    "paginate": {
        "first":      "Premier",
        "last":       "Dernier",
        "next":       "Suivant",
        "previous":   "Précédent"
    },
    "aria": {
        "sortAscending":  ": activate to sort column ascending",
        "sortDescending": ": activate to sort column descending"
    }
  }