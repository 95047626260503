import { Component, OnInit ,TemplateRef} from '@angular/core';
import { Translatable } from '../..//../../service/Translatable';
import { DataTablesResponse} from "../../../../model/DataTablesResponse";
import { HelperService} from "../../../../service/helper.service";
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { HttpClient} from "@angular/common/http";
import { province } from "../../../../model/db";
import { valuesys} from "../../../../../../options";
import { environment} from "../../../../../../environments/environment";
import { AuthServiceService} from "../../../../service/auth-service.service";
import { DataTableDirective} from "angular-datatables";
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";

@Component({  
  selector: 'app-province',
  templateUrl: './province.component.html',
  styleUrls: ['./province.component.scss']
})
export class ProvinceComponent extends Translatable implements OnInit {

    dtOptions  : DataTables.Settings = {};
    titleModal : string ;
    isAdd      : boolean = true ;
    isValid    : boolean = false ;
    title      : string;
    public provinces      : province[] = [] ;
    public province       : province = new province();
    public province_error : province ;


    constructor(
            private modalService: NgbModal,   
            private auth : AuthServiceService,  
            private toastr: ToastrService,          
            private helper: HelperService,
            private http: HttpClient) 
    { super() }

    ngOnInit(): void {
        this.auth.initAutority(['ETA','USR','PRFL','MDL','SMDL','PRV']);
        this.initDatatable();
    }

    initDatatable(){
        this.isAdd = true ;
        this.isValid = false ;
        this.title = this.__("lebel_users");
        this.dtOptions = this.helper.dataTableOption();
        this.dtOptions.columns = [
            {
                "data": "name"
            }
      ];


        this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
            this.http
            .get<DataTablesResponse>(
                environment.province+"?" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
            ).subscribe(resp => {
                this.provinces = resp.data;
                console.log(this.provinces);
                callback({
                    recordsTotal: resp.recordsTotal,
                    recordsFiltered: resp.recordsFiltered,
                    data:[]
                });
            });
        } 
    }

    async openModal(template:TemplateRef<any>,currenProvince:province =null,elem) {
        this.province_error = new province()
        
        if(currenProvince == null){
            this.isAdd = true ;
            this.province = new province() ;
            this.titleModal = this.__('province.add') ;
        }else {

            this.isAdd = false ;
            this.titleModal = this.__('province.edit') ;
            this.province = currenProvince ;
        } 

        this.modalService.open(template, { size: 'xl', backdrop:"static" });
    } 


    //--fermeture du modal
    close(){
        this.modalService.dismissAll()
        this.isValid = false ;
        this.province = new province()
        this.titleModal = this.__('label_user_edit') ;
        this.province_error = new province();
    }

    //--Add or Update
    async sendProvince(){
        this.province_error = new province()
        this.isValid = true ;
        //--- Add Module
        if(this.isAdd ){
            let res:any =  await this.http.post<any>(environment.province,this.province,valuesys.httpAuthOptions()).toPromise();
            //--success
            if(res.code === 201){
                this.toastr.success(res.msg,this.__("msg.success")) ;
                this.province.state = 1;
                this.provinces.push(this.province);
                this.close()
            //--Warning : Donnée invalide, Session expiré
            }else if(res.code === 400){
                for (let item in res.data){
                    this.province_error[item] = res.data[item][0] ;
                }
                this.province_error = <province> this.helper.errorForm(res.data);
                this.toastr.warning(res.msg,this.__("msg.warning")) ;
            }
            //--error
            else {
                this.toastr.error(res.msg,this.__("msg.error")) ;
            }
            //--- Update Module
        }else {
            let res =  await this.http.put<any>(environment.province + '/'+this.province.id,this.province,valuesys.httpAuthOptions()).toPromise();
            console.log(res);
            //--success
            if(res.code === 201){
                this.toastr.success(res.msg,this.__("msg.success")) ;
                this.close()
                //--Warning : Donnée invalide, Session expiré
            }else if(res.code === 400){
                this.province_error = <province> this.helper.errorForm(res.data);
                this.toastr.warning(res.msg,this.__("msg.warning")) ;
            }
            //--error
            else {
                this.toastr.error(res.msg,this.__("msg.error")) ;
            }
        }
        this.isValid = false ;
    }

    async toggleState(province:province,state:number,btn:any){
        btn.disabled = true;
        let toogle = await this.http.get<any>(environment.province+`/${province.id}/state/${state}`,valuesys.httpAuthOptions()).toPromise();
        if(toogle.code == 201){
            this.toastr.success(toogle.msg,this.__("msg.success"));
            province.state = toogle.data.state 
        }else {
            this.toastr.error(toogle.msg,this.__("msg.error"));
        }
        btn.disabled = false;
    }


    delete(currentProvince:province,elm){
        elm.disabled = true
        Swal.fire({
            title: this.__('msg.confirm'),
            text: this.__('msg.warning_delete'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#44A1A0',
            cancelButtonColor: '#FF7467',
            reverseButtons : true,
            confirmButtonText: this.__('msg.confirmed_delete'),
            cancelButtonText: this.__('msg.cancel_delete')
        }).then(async (result) => {
            if (result.value) {
                let sup = await this.http.delete<any>(environment.province+"/" + currentProvince.id,valuesys.httpAuthOptions()).toPromise();
                // this.rerender();
                if(sup.code == 204){
                    this.provinces = this.provinces.filter(function (item,index) {
                        return item.id != currentProvince.id
                    })
                    Swal.fire({
                        icon: 'success',
                        title: this.__('msg.deleted'),
                        confirmButtonColor: '#44A1A0',
                        text: sup.msg,
                    })
                }else {
                    this.toastr.error(sup.msg,this.__("msg.error"));
                }

                elm.disabled = false

            }else if (result.dismiss === Swal.DismissReason.cancel) {
                elm.disabled = false
                Swal.fire(
                {
                    icon: 'error',
                    title: this.__('msg.canceled'),
                    confirmButtonColor: '#44A1A0',
                    text: this.__('msg.canceled_delete'),
                })
            }
        })
    }

}
