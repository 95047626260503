<br>
<div class="repMenu">
  <div class="row  d-flex  justify-content-between">


    <div class="breadcrumb flat">
      <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
      <a [routerLink]="['/dashboard/BNF/plateforme/concernee']"
        routerLinkActive="active">{{__('menu.beneficiaire')}}</a>
      <a [routerLink]="['/dashboard/BNF/plateforme/categorie_concerner']" routerLinkActive="active"> Liste des
        catégories bénéficiaire</a>
      <!-- <a href="#">Terminer</a> -->
    </div>
  </div>
</div>
<div *ngIf="autority('CACO_2')" class="bg-white p-2  repMenu mt-2 d-flex justify-content-end align-items-center">
  <div class="d-flex align-items-center">
    <button *ngIf="autority('CACO_2')" class="button-control" #addBtnUser
      (click)="openModal(addCategorie,null,addBtnUser)"><i class="fa fa-plus"></i>{{__('categorie_concerner.btn_add')}}</button>

  </div>

</div>
<div class="row">
  <div class="col-lg-12">
    <br>
    <div class="card">
      <div class="card-body">
        <table datatable [dtOptions]="dtOptions" style="width: 100%" class="table table-striped table-hover ">
          <thead>
            <tr>
              <th class="text-center">{{__('type_user.name')}}</th>
              <!-- <th class="text-center">{{__('carte.ministere')}}</th> -->
              <th class="actionTab text-center">{{__('table.action')}}</th>
            </tr>
          </thead>
          <tbody *ngIf="categories?.length != 0">
            <tr *ngFor="let categorie of categories">
              <!-- <td class="text-left">{{ categorie?.etablisement.name }}</td>    -->
              <td class="text-left">{{ categorie?.name }}</td>

              <td class="text-center">
                <i class="fa fa-edit icon-table" ngbTooltip="{{__('tooltip.edite')}}" #openModalBtnEdit
                  (click)="openModal(addCategorie,categorie,openModalBtnEdit)" *ngIf="autority('CACO_3')"></i>
                <i class="fa fa-toggle-on icon-table vert" ngbTooltip="{{__('tooltip.desactiver')}}" #buttonElement
                  (click)="toggleState(categorie,0,buttonElement)"
                  *ngIf="categorie.state == 1 && autority('CACO_6')"></i>
                <i class="fa fa-toggle-off icon-table vert" ngbTooltip="{{__('tooltip.active')}}" #buttonElement
                  (click)="toggleState(categorie,1,buttonElement)"
                  *ngIf="categorie.state == 0 && autority('CACO_6')"></i>
                <i class="fa fa-trash icon-table red" ngbTooltip="{{__('tooltip.delete')}}" #openModalBtnDelete
                  *ngIf="autority('CACO_5')" (click)="delete(categorie,openModalBtnDelete)"></i>
              </td>

            </tr>
          </tbody>
          <tbody *ngIf="categories?.length == 0">
            <tr>
              <td colspan="4">{{__('label_no_data')}}</td>
            </tr>
          <tbody>
        </table>

      </div>

    </div>
  </div>
</div>

<ng-template #addCategorie let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form ngNativeValidate (ngSubmit)="addCategorieForm()">
    <div class="modal-body">
      <br>

      <div class="form-group">
        <label>{{__('categorie_concerner.name')}} (<span class="required-red">{{__('label.require')}}</span>)</label>
        <input type="text" required="true" class="form-control" name="name" id="name" [(ngModel)]="categorie.name"
          placeholder="{{__('categorie_concerner.name')}}">
      </div>

      <div class="row">

        <button type="submit" name="button" class="btn mb-4 button-control"
          *ngIf="isAdd == true">{{__('tooltip.enreg')}}</button>
        <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false">
          {{__('tooltip.edite')}}</button>
        <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel">
          {{__('tooltip.cancel')}}</button>

      </div>
    </div>
  </form>

</ng-template>