<br><div class="repMenu">
    <div class="row  d-flex  justify-content-between">  
      <div class="breadcrumb flat">
        <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
        <a [routerLink]="['/app/module/ADM']" routerLinkActive="active">Administration</a>
        <a [routerLink]="['/dashboard/ADM/plateforme/etablissement']"  routerLinkActive="active" *ngIf="autority('ETA_1')">Gestion ministères</a>
        <a [routerLink]="['/dashboard/ADM/administration/user']"  routerLinkActive="active" *ngIf="autority('USR_1')"> Gestion utilisateurs</a>
        <a [routerLink]="['/dashboard/ADM/administration/profil']"  routerLinkActive="active"  *ngIf="autority('PRFL_1')">Gestion profils</a>
        <a [routerLink]="['/dashboard/ADM/administration/module']"  routerLinkActive="active" *ngIf="autority('MDL_1')">Modules</a>
        <a [routerLink]="['/dashboard/ADM/administration/sousmodule']"  routerLinkActive="active" *ngIf="autority('SMDL_1')">Sous modules</a>
        <a [routerLink]="['/dashboard/ADM/plateforme/province']"  routerLinkActive="active" *ngIf="autority('PRV_1')">Province</a>
      </div>
  </div>
</div> 
<div [ngClass]="{
  'p-2  repMenu mt-2 d-flex justify-content-end align-items-center': true,
  'bg-white': autority('ETA_2')
}" class="bg-white p-2  repMenu mt-2 d-flex justify-content-end align-items-center">
  <div class="d-flex align-items-center">
    <button class="button-control" *ngIf="autority('ETA_2')" #addBtnUser (click)="openModal(addEtab,null,addBtnUser,otherEl)" ><i class="fa fa-plus"></i>{{__('etablissement.btn_add')}}</button>   
  </div>
</div>
<div class="row">
    <div class="col-lg-12">
      <br>
      <div class="card">
        <div class="card-body">
            <table datatable  [dtOptions]="dtOptions"  style="width: 100%" class="table table-striped table-hover " >
              <thead>
                <tr>
                  <th class="text-center">Code</th>
                  <th class="text-center">{{__('etablissement.name')}}</th>
                  <th class="text-center">{{__('etablissement.typ_etablissment')}}</th>
                  <th class="text-center">{{__('etablissement.code_univ')}}</th>
                  <!-- <th class="text-center">{{__('etablissement.nom_ministre')}}</th>
                  <th class="text-center">{{__('etablissement.prenom_ministre')}}</th> -->
                  <th class="text-center">{{__('etablissement.email')}}</th>
                  <th class="text-center">{{__('etablissement.telephone')}}</th>
                  <!-- <th class="text-center">{{__('etablissement.adresse')}}</th> -->
                  <th class="actionTab text-center">{{__('table.action')}}</th>
                </tr>
              </thead>  
              <tbody *ngIf="etablissements?.length != 0" >
                  <tr *ngFor="let etablissement of etablissements">
                    <td class="text-left">{{ etablissement?.code }}</td>
                    <td class="text-left">{{ etablissement?.name }}</td>
                  <td class="text-left">{{ etablissement?.type_etablissement == 0 ?  "Autres" : "Université" }}</td>
                  <td class="text-left">{{ etablissement?.code_universite }}</td>
                  <td class="text-left">{{ etablissement?.email }}</td>            
                  <td class="text-left">{{ etablissement?.telephone }}</td>  
                  <!-- <td class="text-left">{{ etablissement?.adresse }}</td>               -->
                  <td class="text-center"> 
                      <i class="fa fa-cogs icon-table vert" *ngIf="autority('ETA_7')" ngbTooltip="{{__('tooltip.affectation')}}" #openModalDetail (click)="openModalAffectation(showAffectation,etablissement,openModalDetail)" ></i>
                      <i class="fa fa-eye icon-table vert" *ngIf="autority('ETA_4')" ngbTooltip="{{__('tooltip.details')}}" #openModalDetail (click)="openModalDetails(showDetail,etablissement,openModalDetail)" ></i>
                      <i class="fa fa-edit icon-table" *ngIf="autority('ETA_3')" ngbTooltip="{{__('tooltip.edite')}}" #openModalBtnEdit (click)="openModal(addEtab,etablissement,openModalBtnEdit)" ></i>
                      <i class="fa fa-toggle-on icon-table vert" ngbTooltip="{{__('tooltip.desactiver')}}" #buttonElement (click)="toggleState(etablissement,0,buttonElement)" *ngIf="etablissement.state == 1 && autority('ETA_6')" ></i>
                      <i class="fa fa-toggle-off icon-table vert" ngbTooltip="{{__('tooltip.active')}}" #buttonElement (click)="toggleState(etablissement,1,buttonElement)" *ngIf="etablissement.state == 0 && autority('ETA_6')" ></i>
                      <i class="fa fa-trash icon-table red" *ngIf="autority('ETA_5')" ngbTooltip="{{__('tooltip.delete')}}" #openModalBtnDelete (click)="delete(etablissement,openModalBtnDelete)"></i>    
                  </td>

                </tr>
              </tbody>
              <tbody *ngIf="etablissements?.length == 0">
              <tr>
                  <td colspan="7" >{{__('label_no_data')}}</td>
              </tr>
            <tbody> 
          </table>
          
        </div>
      </div>
    </div>
  </div>
     
  <ng-template #addEtab let-modal >
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form ngNativeValidate [formGroup]="registerForm" (ngSubmit)="addEtabForm()">
        <div class="modal-body">
        <br>
        <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                  <label >
                    {{__('etablissement.name')}} (<span class="required-red" >{{__('label.require')}}</span>)
                    <span *ngIf="submitted && f.nom.errors" class="invalid-form-feedback">
                        <span *ngIf="f.nom.errors.required"> / Ce champ est obligatoire</span>
                    </span>
                  </label>
                  <input 
                    formControlName="nom" 
                    type="text" 
                    class="form-control" 
                    name="name" 
                    id="name" 
                    [(ngModel)]="etablissement.name"  
                    placeholder="{{__('etablissement.name')}}" 
                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.nom.errors }"
                  >
              </div>
            </div>
            <div class="col-lg-6"> 
              <div class="form-group">
                <label >
                  {{__('etablissement.nom_ministre')}} (<span class="required-red" >{{__('label.require')}}</span>)
                  <span *ngIf="submitted && f.nom_ministre.errors" class="invalid-form-feedback">
                      <span *ngIf="f.nom_ministre.errors.required"> / Ce champ est obligatoire</span>
                  </span>
                </label>
                <input 
                  formControlName="nom_ministre" 
                  type="text" name="nom_ministre" 
                  id="nom_ministre" 
                  [(ngModel)]="etablissement.nom_ministre" 
                  class="form-control"  
                  placeholder="{{__('etablissement.nom_ministre')}}"
                  [ngClass]="{ 'invalid-form-input-validate': submitted && f.nom_ministre.errors }"
                >
              </div>
            </div>
        </div>
          <div class="row">
            <div class="col-lg-6"> 
              <div class="form-group">
                <label >
                  {{__('etablissement.prenom_ministre')}} (<span class="required-red" >{{__('label.require')}}</span>)
                  <span *ngIf="submitted && f.prenom_ministre.errors" class="invalid-form-feedback">
                      <span *ngIf="f.prenom_ministre.errors.required"> / Ce champ est obligatoire</span>
                  </span>
              </label>
                <input 
                  formControlName="prenom_ministre" 
                  type="text" name="prenom_ministre" 
                  id="prenom_ministre" 
                  [(ngModel)]="etablissement.prenom_ministre" 
                  class="form-control"  
                  placeholder="{{__('etablissement.prenom_ministre')}}"
                  [ngClass]="{ 'invalid-form-input-validate': submitted && f.prenom_ministre.errors }"
                  >
              </div>
            </div>

            <div class="col-lg-6"> 
              <div class="form-group">
                  <label for="exampleInputEmail1">
                      {{__('etablissement.telephone')}} (<span class="required-red" >{{__('label.require')}}</span>)
                      <span *ngIf="submitted && f.phone.errors" class="invalid-form-feedback">
                          <span *ngIf="f.phone.errors.required"> / Ce champ est obligatoire</span>
                      </span>
                  </label>
                  <br>
                  <input #element formControlName="phone" type="text" ng2TelInput   
                    (intlTelInputObject)="telInputObject($event)" 
                    oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                    onKeyPress="if(this.value.length==10) return false;"
                    (countryChange)="onCountryChange($event)"
                    [ng2TelInputOptions]="{initialCountry: currenCode,preferredCountries:['mg','sn']}"
                    (focus)="controle(element)" 
                    (input)="controle(element)" 
                    name="phone" 
                    id="phone" 
                    class="form-control input" 
                    placeholder="03X XX XXX XX" 
                    [(ngModel)]="etablissement.telephone" 
                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.phone.errors }"
                   >
              </div>
            </div>

        </div>

        <div class="row">
          
          <div class="col-lg-6">
            <div class="form-group">
                <label >
                  {{__('etablissement.province')}} (<span class="required-red" >{{__('label.require')}}</span>)
                  <span *ngIf="submitted && f.province.errors" class="invalid-form-feedback">
                      <span *ngIf="f.province.errors.required"> / Ce champ est obligatoire</span>
                  </span>
                </label>
                <ng-select 
                  formControlName="province" 
                  class="form-control" 
                  name="province_id"  
                  [(ngModel)]="etablissement.province_id" 
                  [ngClass]="{ 'invalid-form-input-validate': submitted && f.province.errors }"
                >
                  <ng-option  *ngFor="let province of provinces" [value]="province?.id ">{{ province?.name }}</ng-option>
              </ng-select>                
            </div>
        </div>

          <div class="col-lg-6"> 
            <div class="form-group">
                <label >
                    {{__('etablissement.adresse')}} (<span class="required-red" >{{__('label.require')}}</span>)
                    <span *ngIf="submitted && f.adresse.errors" class="invalid-form-feedback">
                        <span *ngIf="f.adresse.errors.required"> / Ce champ est obligatoire</span>
                    </span>
                </label>
                <input 
                    formControlName="adresse" 
                    type="text" 
                    name="adresse" 
                    id="adresse" 
                    [(ngModel)]="etablissement.adresse"  
                    class="form-control"  
                    placeholder="{{__('etablissement.adresse')}}"
                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.adresse.errors }"
                    >
            </div>
            
          </div> 
         
        </div>

        <div class="row">
            
            <div class="col-lg-6">
              <div class="form-group">
                  <label >
                    {{__('etablissement.email')}} (<span class="required-red" >{{__('label.require')}}</span>)
                    <span *ngIf="submitted && f.email.errors" class="invalid-form-feedback">
                        <span *ngIf="f.email.errors.required"> / Ce champ est obligatoire</span>
                    </span>
                  </label>
                  <input 
                    formControlName="email" 
                    type="email" 
                    pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                    class="form-control" 
                    name="email" 
                    id="email" 
                    [(ngModel)]="etablissement.email"  
                    placeholder="{{__('etablissement.email')}}"
                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.email.errors }"
                    >
              </div>
            </div>

            <div class="col-lg-6">        
              <div class="form-group">
                <label >
                    {{__('etablissement.code_postal')}} (<span class="required-red" >{{__('label.require')}}</span>)
                    <span *ngIf="submitted && f.code_postal.errors" class="invalid-form-feedback">
                        <span *ngIf="f.code_postal.errors.required"> / Ce champ est obligatoire</span>
                    </span>
                </label>
                <input 
                    formControlName="code_postal"
                    type="number" class="form-control"
                    name="code_postal"
                    id="code_postal"
                    [(ngModel)]="etablissement.code_postal"
                    placeholder="{{__('etablissement.code_postal')}}"
                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.code_postal.errors }"
                    >
              </div>
            </div>
            <div class="col-lg-6">        
              <div class="form-group">
                <label >
                    {{__('etablissement.type_univ')}} (<span class="required-red" >{{__('label.require')}}</span>)
                    <span *ngIf="submitted && f.type_universite.errors" class="invalid-form-feedback">
                        <span *ngIf="f.type_universite.errors.required"> / Ce champ est obligatoire</span>
                    </span>
                </label>
                <br>
                <span>
                  <input #otherEl  [checked]="etablissement.type_etablissement == 0" formControlName="type_universite" type="radio" name="type_universite" id="type_universite_other" [value]="0" [(ngModel)]="etablissement.type_etablissement"> {{__('etablissement.other')}} 
                </span>
                <span class="ml-2">
                  <input [checked]="etablissement.type_etablissement == 1" formControlName="type_universite" type="radio" name="type_universite" id="type_universite_univ" [value]="1" [(ngModel)]="etablissement.type_etablissement"> {{__('etablissement.univ')}} 
                </span>
                <!-- <input 
                    formControlName="type_universite"
                    type="number" class="form-control"
                    name="type_univ"
                    id="type_univ"
                    [(ngModel)]="etablissement.type_universite"
                    placeholder="{{__('etablissement.type_univ')}}"
                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.type_universite.errors }"
                    > -->
              </div>
            </div>
            <div class="col-lg-6" *ngIf="etablissement.type_etablissement == 1">        
              <div class="form-group">
                <label >
                    {{__('etablissement.code_univ')}} (<span class="required-red" >{{__('label.require')}}</span>)
                    <span *ngIf="submitted" class="invalid-form-feedback">
                        <span *ngIf=" codeUniveReqiured"> / Ce champ est obligatoire</span>
                        <span *ngIf="codeUnivMax5"> / Miximum 5 chiffres</span>
                    </span>
                </label>
                <input 
                    formControlName="code_universite"
                    type="number" class="form-control"
                    name="code_universite"
                    id="code_universite"
                    [(ngModel)]="etablissement.code_universite"
                    [maxlength]="5"
                    placeholder="{{__('etablissement.code_univ')}}"
                    [ngClass]="{ 'invalid-form-input-validate': submitted && codeUniveReqiured }"
                    [required]="etablissement.type_etablissement == 1"
                    >
              </div>
            </div>
            
            <div class="col-lg-6">
              <label >
                <span *ngIf="submitted && f.logo.errors" class="invalid-form-feedback">
                    <i class="fa fa-times-circle" *ngIf="f.logo.errors.required"></i>
                </span>
                {{__('etablissement.logo')}}
              </label>
              <span 
                class="imgAdd"
                (click)="documentLogo.length <= 4 ? addImage(documentFileLogo) : false" 
                tooltip="{{documentLogo.length <= 4 ? __('tooltip.click_add') : __('tooltip.document_max')}}">
                  <i class="fa fa-plus" style="padding-left: 20px; font-size: 17px;"></i>
              </span>
              <br>
              <span class="imgUpload">
                <img  [src]="documentLogo.name"style="height: 50px;" >
              </span>
               <input accept="image/*" formControlName="logo" (change)="handleInputLogo($event)" #documentFileLogo style="display: none"  id="documentlogo" name="documentlogo" type="file" class="file" multiple > 
            </div>
           
  
          </div> 
        
          <div class="row">
              <div class="col-lg-12">
                  <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == true"  >{{__('tooltip.enreg')}}</button>            
                  <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false" > {{__('tooltip.edite')}}</button>            
                  <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>            
            </div>
          
            
          </div>


        </div>
    </form>
    
  </ng-template>



  <ng-template #showDetail let-modal >
    <div class="modal-header" style="text-align: center;" >
      <h4 class="modal-title text-center" id="modal-basic-title">{{__('label_details')}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>


        <div class="modal-body">

          <tabset>
            <tab heading="{{__('etablissement.info_etab')}}">
              <table style="width: 100%;">
                <tr>
                  <td class="tab-detail-title" nowrap>Code</td>
                  <td class="tab-detail">{{etablissement.code}}</td>
                  <td class="tab-detail-title" nowrap>{{__('etablissement.name')}}</td>
                  <td class="tab-detail">{{etablissement.name}}</td>
                </tr>
                <tr>
                  <td class="tab-detail-title">{{__('etablissement.adresse')}}</td>
                  <td class="tab-detail">{{etablissement.adresse}}</td>
                  <td class="tab-detail-title">{{__('etablissement.telephone')}}</td>
                  <td class="tab-detail">{{etablissement.telephone}}</td>
                </tr>
                <tr>
                  <td class="tab-detail-title">{{__('etablissement.email')}}</td>
                  <td class="tab-detail">{{etablissement.email}}</td>
                  <td class="tab-detail-title">{{__('etablissement.code_postal')}}</td>
                  <td class="tab-detail">{{etablissement.code_postal}}</td>
                  
                </tr>
                <tr>
                  <td class="tab-detail-title">{{__('etablissement.nom_ministre')}}</td>
                  <td class="tab-detail">{{etablissement.nom_ministre}}</td>
                  <td class="tab-detail-title">{{__('etablissement.prenom_ministre')}}</td>
                  <td class="tab-detail">{{etablissement.prenom_ministre}}</td>
                </tr>
                <tr>
                  <td class="tab-detail-title">{{__('etablissement.type_univ')}}</td>
                  <td class="tab-detail">{{etablissement.type_etablissement == 0 ? "Autres" : "Université"}}</td>
                  <td class="tab-detail-title">{{__('etablissement.code_univ')}}</td>
                  <td class="tab-detail">{{etablissement.code_universite}}</td>
                </tr>
          
              </table>
            </tab>

            <tab heading="{{__('etablissement.info_doc')}}">

              <div class="row" style="margin-left: 20px;">
                <div class="col-lg-4">
                  <label class="label-doc">{{__('etablissement.info_doc')}} </label>
                </div>
              </div>
              <div class="row" style="margin-left: 20px;">
                <div class="col-lg-4">
                    <div class="row">
                      <div class="col-lg-8 img-detail"  > 
                        <img src="{{etablissement.logo}}" alt="" style="width: 150px;">
                      </div>
                      
                    </div>
                </div>
              </div>
            </tab>
            
          </tabset>

      </div>
    
  </ng-template>


  <ng-template #showAffectation let-modal >
    <div class="modal-header" style="text-align: center;" >
      <h4 class="modal-title text-center" id="modal-basic-title"> {{ __('tooltip.affectation') }} : {{titleModalAffect}} </h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
        <div class="modal-body">
        <div class="card">
          <div class="card-header"></div>
          <div class="card-body"> 
              <div class="row">
                  <div class="col-lg-4" *ngFor="let categorie of categories" >
                    <div class="form-group">
                        <input type="checkbox" required="true" #checkEtablissement (click)="updateCheck(categorie.idEtab, categorie.id,checkEtablissement)" [checked]="categorie.selected == 'checked'" name="name[]" id="name"  >
                        <label >&nbsp;&nbsp;{{ categorie.name }} </label>
                    </div>
                  </div>
                  <div class="col-lg-12 text-center" *ngIf="categories.length == 0" >
                    <div class="form-group">
                        <label >&nbsp;&nbsp;{{ __('etablissement.no_affect_etablissement') }} </label>
                    </div>
                  </div>
                
              </div>
          </div>
      </div>

      </div>
    
  </ng-template>


