import formatNumber from 'number-handler'
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChartType } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { environment } from '../../../../../../../environments/environment';
import { valuesys } from '../../../../../../../options';
import { etablissement, etudiant, ordre_paiement } from '../../../../../model/db';
import { categorie } from '../../../../../model/pension';
import { AuthServiceService } from '../../../../../service/auth-service.service';
import { HelperService } from '../../../../../service/helper.service';
import { Translatable } from '../../../../../service/Translatable';

@Component({
  selector: 'app-liste-carte-par-ministere',
  templateUrl: './liste-carte-par-ministere.component.html',
  styleUrls: ['./liste-carte-par-ministere.component.scss']
})
export class ListeCarteParMinistereComponent extends  Translatable implements OnInit  {
  
  
  etabId:any ;
  userId:any ;

  public superAdmin : number ;
  

  public etudiants_boursier :etudiant[] = [] ;
  public ordre_paiement:ordre_paiement = new ordre_paiement();
  public etablissements:etablissement [] = [];
   

   tabAllData: any[];
  //--end-paginate
  public where : string  = "";


  public ordre_paiements :ordre_paiement[] = [] ;
  
  dtOptions1: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  dtOptions3: DataTables.Settings = {};
  dtOptions4: DataTables.Settings = {};
  dtOptions5: DataTables.Settings = {};
  dtOptions6: DataTables.Settings = {};
  dtOptions7: DataTables.Settings = {};

  constructor(private helper: HelperService,             
              private http: HttpClient,
              private auth : AuthServiceService,  
               
    ) {super()}

  async ngOnInit() {
    this.auth.initAutority(["GCA","DCA","AFC","ACD","ENC"]);

    let userinfo = this.auth.account()
    this.etabId = userinfo.info.etablissement_id;
    this.superAdmin = userinfo.info.admin;
    
    if(this.superAdmin == 0 && this.etabId != 1) this.where = "?where=etablissement.id|e|"+this.etabId;
    


    const response: any = await this.http.get(environment.pension_list_carte_par_ministere+this.where,valuesys.httpAuthOptions()).toPromise();
    this.ordre_paiements = response.data
    // this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);
    }

  
}
