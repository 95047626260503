import { Component, OnInit, TemplateRef } from '@angular/core';
import { Translatable } from '../..//../../service/Translatable';
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {HelperService} from "../../../../service/helper.service";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from "@angular/common/http";
import {plateforme} from "../../../../model/db";
import {valuesys} from "../../../../../../options";
import {environment} from "../../../../../../environments/environment";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {DataTableDirective} from "angular-datatables";
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";


@Component({
  selector: 'app-plateforme',
  templateUrl: './plateforme.component.html',
  styleUrls: ['./plateforme.component.scss']
})
export class PlateformeComponent extends Translatable implements OnInit {

  
  dtOptions: DataTables.Settings = {};
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  title:string ;
  public plateformes :plateforme[] = [] ;
  public plateforme:plateforme = new plateforme();
  public plateforme_error : plateforme ;
  closeResult: string = '';
  selectedPays: string = '';
  public module_code = window['module'];

  constructor(             
            private modalService: NgbModal,   
            private auth : AuthServiceService,  
            private toastr: ToastrService,          
            private helper: HelperService,
            private http: HttpClient) { 
    super()
  }

  async ngOnInit() {
    this.auth.initAutority("USR");
    this.initDatatable()
  }

  initDatatable(){
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "name"
      },
      {
        "data": "code"
      },
      {
        "data": "desc"
      },
    
    ];


    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.plateforme+"?" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.plateformes = resp.data;
        console.log(this.plateformes);
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    } 

  
  }
  

  async openModal(template:TemplateRef<any>,currenUser:plateforme =null,elem) {

    this.plateforme_error = new plateforme()
    //elem.disabled = true ;
  
   // this.plateforme = await this.helper.getSelectList( environment.plateforme,['name']);
    if(currenUser == null){
      this.isAdd = true ;
      this.plateforme = new plateforme() ;
      this.titleModal = this.__('label_user_new') ;
    }else {
      /*TEL*/
      //this.currenCode = this.helper.getCodePaysByNum(currenUser.phone.substr(0,3));
      /*TEL*/
      this.isAdd = false ;
      this.titleModal = this.__('label_user_edit') ;
      this.plateforme = currenUser ;

    } 
    //elem.disabled = false ;
    //this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});

    this.modalService.open(template, { size: 'xl', backdrop:"static"  });

  } 


  async toggleState(plateforme:plateforme,state:number,btn:any){
    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.plateforme+`/${plateforme.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      plateforme.state = toogle.data.state
  
    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }

  //--fermeture du modal
  close(){

    this.modalService.dismissAll()
    this.isValid = false ;
    this.plateforme = new plateforme()
    this.titleModal = this.__('label_user_edit') ;
    this.plateforme_error = new plateforme();
  }

  //--Add or Update
  async addplateformeForm(){
    this.plateforme_error = new plateforme()
    this.isValid = true ;
    //--- Add plateforme
    if(this.isAdd ){

      let res:any =  await this.http.post<any>(environment.plateforme,this.plateforme,valuesys.httpAuthOptions()).toPromise();

      //--success
        if(res.code === 201){
          this.toastr.success(res.msg,this.__("msg.success")) ;
          this.plateforme.state = 1;
          this.plateformes.push(res.data);
          this.close()
        //--Warning : Donnée invalide, Session expiré
        }else if(res.code === 400){
          for (let item in res.data){
            this.plateforme_error[item] = res.data[item][0] ;
          }
          this.plateforme_error = <plateforme> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        //--error
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
    //--- Update plateforme
    }else {

      let res =  await this.http.put<any>( environment.plateforme + '/'+this.plateforme.id,this.plateforme,valuesys.httpAuthOptions()).toPromise();

      //--success
        if(res.code === 201){
          this.toastr.success(res.msg,this.__("msg.success")) ;
          this.close()
        //--Warning : Donnée invalide, Session expiré
        }else if(res.code === 400){
          this.plateforme_error = <plateforme> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        //--error
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
    }
    this.isValid = false ;
  }





  delete(deletedUser:plateforme,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.plateforme+"/" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.plateformes= this.plateformes.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
            {
              icon: 'success',
              title: this.__('msg.deleted'),
              confirmButtonColor: '#44A1A0',
              text: sup.msg,
            }
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          {
            icon: 'error',
            title: this.__('msg.canceled'),
            confirmButtonColor: '#44A1A0',
            text: this.__('msg.canceled_delete'),
          }
        )
      }
    })
  }

}
