<br><div class="repMenu">
  <div class="row  d-flex  justify-content-between">     
    <div class="breadcrumb flat">
      <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
      <a [routerLink]="['/app/module/CAR']"  routerLinkActive="active">{{__('menu.carte')}}</a>
      <a [routerLink]="['/dashboard/CAR/plateforme/carte']" routerLinkActive="active" *ngIf="autority('GCA_1')">{{__('carte.title')}}</a>
      <a [routerLink]="['/dashboard/CAR/plateforme/listeCarteParMinistere']"  routerLinkActive="active" *ngIf="autority('DCA_1')">Disponibiliter des cartes</a>
      <a [routerLink]="['/dashboard/CAR/plateforme/affecterCarte']"  routerLinkActive="active" *ngIf="autority('AFC_1')">Affecter carte</a>
      <a [routerLink]="['/dashboard/CAR/plateforme/ActiverDesactive']"  routerLinkActive="active" *ngIf="autority('ACD_1')">Activer / Desactiver carte</a>
      <a [routerLink]="['/dashboard/CAR/plateforme/EnrolerCarte']"  routerLinkActive="active" *ngIf="autority('ENC_1')">Enroler carte</a>
      <a [routerLink]="['/dashboard/CAR/plateforme/planifier_creation_compte']"  routerLinkActive="active" *ngIf="autority('CRC_1')">Création de compte</a>
      <a [routerLink]="['/dashboard/CAR/plateforme/demandeenrolement']"  routerLinkActive="active" *ngIf="autority('DEN_1')">Demande enrolement</a>
      <a [routerLink]="['/dashboard/CAR/plateforme/demandewallet']"  routerLinkActive="active" *ngIf="autority('DEW_1')">Demande création compte</a>
    </div>

</div>
</div> 
<div class="row">
    <div class="col-lg-12">
      
      <br><br>

      <div class="card">
        
        <div class="card-body">


       

          <table class="table table-hover table-striped" style="width: 100%;" >
            <thead>
              <tr>
                <th>{{__('dashboard.etab')}} <br>
                
                </th>
                <th class="text-center">{{__('dashboard.nb_total')}} <br>
               
                </th>
                <th class="text-center">{{__('dashboard.nb_dispo')}} <br>
               
                </th>
                <th class="text-center">{{__('dashboard.nb_affected')}}  <br>
                
                </th>
              </tr>
        
            </thead>
            <tbody *ngIf="this.ordre_paiements?.length != 0" class="body-paginate-hr" >
               <tr *ngFor="let ordre_paiement of ordre_paiements">
                <td class="text-left">{{ ordre_paiement?.name }}</td>
                <td class="text-center">{{ ordre_paiement?.total }}</td>
                <td class="text-center">{{ ordre_paiement?.disponible }}</td>
                <td class="text-center">{{ ordre_paiement?.affected }}</td>
              </tr>
            </tbody>
            <tbody *ngIf="ordre_paiements?.length == 0">
            <tr>
                <td colspan="9" class="text-center" >{{__('label_no_data')}}</td>
            </tr>
          </tbody> 
        
          </table>
          

        </div>
      
      </div>
    </div>

  </div>