import { Component, OnInit, TemplateRef } from '@angular/core';
import { Translatable } from '../..//../../service/Translatable';
import { DataTablesResponse } from "../../../../model/DataTablesResponse";
import { environment } from "../../../../../../environments/environment";
import { valuesys } from "../../../../../../options";
import { profil,profilage_module,profilage_sous_module } from "../../../../model/db";
import { HttpClient } from "@angular/common/http";
import { HelperService } from "../../../../service/helper.service";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import * as $ from "jquery";
import {AuthServiceService} from "../../../../service/auth-service.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends Translatable implements OnInit {

  dtOptions: DataTables.Settings = {};
  public profils:any = [];
  public profil :profil = new profil();
  public modules:profilage_module [] = []
  isAdd         : boolean = false ;
  isValid       : boolean = false ;
  titleModal    : string ;
  titleProfilage : string = "" ;


  constructor(
    private http: HttpClient,
    private helper: HelperService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private auth : AuthServiceService,  

  ) { super() }

  ngOnInit(){
    this.auth.initAutority(['ETA','USR','PRFL','MDL','SMDL','PRV']);
    this.initDatatable();
  }

  initDatatable(){
   
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {data:"name"},
      {data:"code"},
    ];


    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.profil+"?where=code|d|ESC&" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.profils = resp.data;
        console.log(this.profils);
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    } 
  }

  async openModal(template:TemplateRef<any>,currenProfil:profil=null,elem) {
    this.profils = await this.helper.getSelectList(environment.profil,['name']);
    if(currenProfil == null){
      this.isAdd = true ;
      this.profil = new profil() ;
      this.titleModal = "Ajout profil" ;
    }else {
      this.isAdd = false ;
      this.titleModal = "Modification profil" ;
      this.profil = currenProfil ;

    } 
    this.modalService.open(template, { size: 'xl', backdrop:"static"  });
  } 

  async toggleState(type:profil,state:number,btn:any){
    $(btn).attr('disabled');
    btn.disabled = true;
    let toogle = await this.http.get<any>( environment.profil+`/${type.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      type.state = toogle.data.state

    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }

  async detail(detailProfil:profil, template:TemplateRef<any>){
    this.titleProfilage = detailProfil.name ;
    this.profil= detailProfil;
    let res =  await this.http.get<any>(environment.profilage+"/"+detailProfil.id,valuesys.httpAuthOptions()).toPromise();
    this.modules = res.data;
    this.modalService.open(template,{size: 'xl', backdrop:"static" });

  }

  async updateActionAll(action,elm,$event){
    $event.stopPropagation();
    let id_droits = action.map((item)=> item.id).join(',');
    if(elm.checked ==true){
      let res =  await this.http.post<any>( environment.profilage,{action_id:id_droits,profil_id:this.profil.id,state:1},valuesys.httpAuthOptions()).toPromise();
      console.log(res);
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        action = action.map((item)=> {
          item.state = 1 ;
          return item ;
        });
        elm.checked= true;
      }else {
        action = action.map((item)=> {
          if(item.state == 0)
            item.state = 0 ;
          return item ;
        });
        elm.checked= false;
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    else{
      let res =  await this.http.post<any>( environment.profilage,{action_id:id_droits,profil_id:this.profil.id,state:0},valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        action = action.map((item)=> {
          item.state = 0 ;
          return item ;
        });
        elm.checked= false;
      }else {
        action = action.map((item)=> {
          if(item.state == 1)
            item.state = 0 ;
          item.state = 1 ;
          return item ;
        });
        elm.checked= true;
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }
    
  }

  async updateAction(action,elm){
    elm.disabled= true;

    if(elm.checked ==true){
      let res =  await this.http.post<any>( environment.profilage ,{action_id:action.id,profil_id:this.profil.id,state:1},valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        action.state = 1 ;
        elm.checked= true;
      }else {
        elm.checked= false;
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }else{
      let res =  await this.http.post<any>(environment.profilage,{action_id:action.id,profil_id:this.profil.id,state:0},valuesys.httpAuthOptions()).toPromise();
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        action.state = 0 ;
        elm.checked= false;
      }else {
        elm.checked= true;
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }
    }

    elm.disabled = false;
  }

  isCheckedAll(s_module:profilage_sous_module){
    
    return  s_module.actions.filter((item)=>item.state == 1).length ==  s_module.actions.length
  }



  close(){
    this.modalService.dismissAll()
    this.isValid = false ;
    this.profil = new profil()
    this.titleModal = "" ;
  }

  async addProfilForm()
  {
    if(this.isAdd ){

      let res:any =  await this.http.post<any>(environment.profil,this.profil,valuesys.httpAuthOptions()).toPromise();
      
      if(res.code === 201){
        this.toastr.success(res.msg,this.__("msg.success")) ;
        console.log(res);
        this.profils.state = 1;
        this.profils.push( res.data );
        console.log("profil : ",res.data);
        this.close()
      }else if(res.code === 400){
          
      }else{

      }

    }else{

      let res =  await this.http.put<any>( environment.profil + '/'+this.profil.id,this.profil,valuesys.httpAuthOptions()).toPromise();
  
      if(res.code === 201){

         this.profils.find((_) =>_.id == this.profil.id).name = this.profil.name;
         this.profils.find((_) =>_.id == this.profil.id).code = this.profil.code;

         this.toastr.success(res.msg,this.__("msg.success")) ;
         this.close();

      }else{
        this.toastr.error(res.msg,this.__("msg.error")) ;
      }

    }
  }

  delete(deletedProfil:profil,elm)
  {
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.profil+"/" + deletedProfil.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.profils = this.profils.filter(function (item,index) {
            return item.id != deletedProfil.id
          })
          Swal.fire(
            {
              icon: 'success',
              title: this.__('msg.deleted'),
              confirmButtonColor: '#44A1A0',
              text: sup.msg,
            }
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          {
            icon: 'error',
            title: this.__('msg.canceled'),
            confirmButtonColor: '#44A1A0',
            text: this.__('msg.canceled_delete'),
          }
        )
      }
    })
  }

}
