import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthServiceService} from "../../../../service/auth-service.service";

import { Translatable } from '../../../../service/Translatable';
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../../service/helper.service";
//import {NgxUiLoaderService} from "ngx-ui-loader";
import {valuesys} from "../../../../../../options";
import {Auth, user} from "../../../../model/db";
import {environment} from "../../../../../../environments/environment";
import { Router } from '@angular/router';
import {Location} from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordStrengthValidator } from '../../../../helpers/validators/password-strength.validators';
import { DataSharingService } from '../../../../service/data-sharing/data-sharing.service';

@Component({
  selector: 'app-me',
  templateUrl: './me.component.html',
  styleUrls: ['./me.component.scss']
})
export class MeComponent extends Translatable implements OnInit {

  //public user : Auth = new Auth();


  title:string ;
  mdp: string= ""
  mdp_new: string= ""
  mdp_confirm_new: string= ""
  ok:boolean = false
  error:boolean = false
  documentLogo: any = [];
  public user :user = new user();
  public authUser :Auth = new Auth();
  update : any = false;
  public form: FormGroup;

  constructor(private router : Router  ,
    private activatedRoute: ActivatedRoute,
    private auth : AuthServiceService,
    private http: HttpClient,
    private toastr: ToastrService,
    private helper: HelperService,
    private sanitizer: DomSanitizer,
    private _location: Location,
    private dataSharingService: DataSharingService,
    fb: FormBuilder
    ){
    super()
    this.form = fb.group({
      password: ['', [Validators.required, PasswordStrengthValidator]],
      confirm_pass: ['', Validators.required],
      password_old: ['', Validators.required],


    });
  }
  async ngOnInit() {
    //this.authUser = <Auth> await  this.auth.getLoginUser();
    this.title = this.__("Profile.user_profil");
    let currentUser :any  =  await this.auth.getLoginUser();
    console.log(currentUser)
    let res1 = await this.http.get<any>(environment.baseUrl+ environment.me ,valuesys.httpAuthOptions()).toPromise();
    this.authUser = <Auth> res1.data;
    this.user = this.authUser.info ;

    
    console.log("image User", this.user.photo);

    this.documentLogo.name = this.user.photo;
  }

  goToDashBoard() : void{
   
    
  }

  controlConfirm(){
    if(this.mdp_new != ""){
      if((this.mdp_new === this.form.get('confirm_pass').value) && !this.form.get('password').hasError('passwordStrength')){
        this.ok = true
        this.error = false
      }else{
        this.ok = false
        this.error = true
      }
    }
    console.log("confirl control",this.error)
  }
  control(){
    if(this.mdp_new == ""){
      if(this.mdp_confirm_new !=""){
        this.ok = false
        this.mdp_confirm_new = ""
        this.error = false
      }
    }
  }
  async updateMdp(){
    console.log(this.form)
    let resp  = await this.http.put<any>(environment.baseUrl+ environment.refreshPassword,{id:this.user.id,password_old:this.form.get('password_old').value,password:this.mdp_new},valuesys.httpAuthOptions()).toPromise();
    console.log(resp);
    if(resp.code == 201){
      this.toastr.success(resp.msg, this.__("msg.success"));
      this.mdp = ""
      this.mdp_new = ""
      this.mdp_confirm_new = ""
      this.router.navigate(['/logout']);

    }else if(resp.code == 400){

      this.toastr.error(resp.data.password[0], this.__("msg.error"));
      this.mdp_new = ""
      this.mdp_confirm_new = ""

    }else{
      this.toastr.error(resp.msg, this.__("msg.error"));
      this.mdp = ""
      this.mdp_new = ""
      this.mdp_confirm_new = ""
    }
     
  }
  setDefault(){
    this.ok = false
    this._location.back();
  }


   /*IMAGE ADD*/
  // sanitizer:DomSanitizer =DomSanitizer.;
  getUrl(url:string)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

   addImage(documentFile: HTMLInputElement) {
     
    documentFile.click()
  }

 
  handleInputLogo(e) {
    var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    if (!files) {
      return;
    }
    //this.etablissement.logo = files[0].name;

    for (var i = 0; i < files.length; i++) {
      if (files[i].size > 1000*1000 * 100) {
        this.toastr.error("taille image trop grande", "Error");
        $("#document").val("");
        return;
      }
      var reader = new FileReader();
      var pattern = /image-*/;

      if (!files[i].type.match(pattern)) {
        this.toastr.error("invalid format", "Error");
        return;
      }
      reader.onload = this._handleReaderLogoLoaded.bind(this);
      reader.readAsDataURL(files[i]);
    }

  }

  _handleReaderLogoLoaded(e) {
    let reader = e.target;
    this.documentLogo.name = reader.result;
    this.user.avatar = reader.result;
    this.update = true;
  }
  //--Add or Update
  async updateImg(){
      //--add Logo
      this.user.avatar = this.documentLogo.name;
      let res =  await this.http.put<any>( environment.user + '/'+this.user.id,this.user,valuesys.httpAuthOptions()).toPromise();
      //--success
      if(res.code === 201){
        console.log("mmmmmmmmmmmmmmmmmmm");

        this.dataSharingService.$profilImageSubject.next(res.data.avatar);
        this.toastr.success(res.msg,this.__("msg.success")) ;
      //--Warning : Donnée invalide, Session expiré
      }else if(res.code === 400){
        this.toastr.warning(res.msg,this.__("msg.warning")) ;
      }
      //--error
      // else {
      //   this.toastr.error(res.msg,this.__("msg.error")) ;
      // }
    
  }

}
