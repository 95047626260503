
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Translatable } from '../..//../../service/Translatable';
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {HelperService} from "../../../../service/helper.service";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from "@angular/common/http";
import {module, sous_module} from "../../../../model/db";
import {valuesys} from "../../../../../../options";
import {environment} from "../../../../../../environments/environment";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {DataTableDirective} from "angular-datatables";
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-sous-module',
  templateUrl: './sous-module.component.html',
  styleUrls: ['./sous-module.component.scss']
})
export class SousModuleComponent extends Translatable implements OnInit {

  dtOptions: DataTables.Settings = {};
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  title:string ;
  public sous_modules :sous_module[] = [] ;
  public sous_module:sous_module = new sous_module();
  public sous_module_error : sous_module ;
  public modules:module [] = [];
  /*
    FORM VALIDATOR
  */
  registerForm : FormGroup;
  submitted    = false;

  

  closeResult: string = '';
  selectedPays: string = '';

  constructor(             
            private modalService: NgbModal,   
            private auth : AuthServiceService,  
            private toastr: ToastrService,          
            private helper: HelperService,
            private http: HttpClient,
            private formBuilder: FormBuilder) { 
    super()
  }

  async ngOnInit() {
    this.registerForm = this.formBuilder.group({
        module:  ['', Validators.required],
        libelle: ['', Validators.required],
        code:    ['', Validators.required],
        icone:   ['', Validators.required]
    });
    this.auth.initAutority(['ETA','USR','PRFL','MDL','SMDL','PRV']);
    this.initDatatable()
  }

  // GET REGISTER FORM VALIDATE
  get f() { return this.registerForm.controls; }

  initDatatable(){
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "name"
      },
      {
        "data": "code"
      },
      {
        "data": "icone",
        "orderable" :false,
        "searchable":false,
      },
      {
        "data": "module",
        "name": "module.name"
      },
    
   
    ];


    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.sous_module+"?child=module&" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.sous_modules = resp.data;
        console.log("Sous module : "+resp);
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    } 

  
  }
  

  async openModal(template:TemplateRef<any>,currenUser:sous_module =null,elem) {
    this.sous_module_error = new sous_module()
    //elem.disabled = true ;
   // this.plateforme = await this.helper.getSelectList( environment.plateforme,['name']);
   this.modules = await this.helper.getSelectList(environment.module,['name']);

   // this.plateforme = await this.helper.getSelectList( environment.plateforme,['name']);
    if(currenUser == null){
      this.isAdd = true ;
      this.sous_module = new sous_module() ;
      this.titleModal = this.__('sous_module.add') ;
    }else {
      /*TEL*/
      //this.currenCode = this.helper.getCodePaysByNum(currenUser.phone.substr(0,3));
      /*TEL*/
      this.isAdd = false ;
      this.titleModal = this.__('sous_module.edit') ;
      this.sous_module = currenUser ;

    } 
    //elem.disabled = false ;
    //this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});

    this.modalService.open(template, { size: 'xl', backdrop:"static"  });

  } 


  async toggleState(sous_module:sous_module,state:number,btn:any){
    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.sous_module+`/${sous_module.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      sous_module.state = toogle.data.state
  
    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }

  //--fermeture du modal
  close(){
    this.modalService.dismissAll()
    this.isValid = false ;
    this.sous_module = new sous_module()
    this.titleModal = this.__('label_user_edit') ;
    this.sous_module_error = new sous_module();
  }

  //--Add or Update
  async addSousModuleForm(){

    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    this.sous_module_error = new sous_module()
    this.isValid = true ;
    //--- Add sous_module
    if(this.isAdd ){
      let res:any =  await this.http.post<any>(environment.sous_module,this.sous_module,valuesys.httpAuthOptions()).toPromise();
        //--success
        if(res.code === 201){
          this.toastr.success(res.msg,this.__("msg.success")) ;
          this.sous_module.state = 1;

          const resultSpecialite= this.modules.filter(word => word.id == this.sous_module.module_id);
          Object.assign(res.data, {"module": {"name" : resultSpecialite[0]?.name}});

          this.sous_modules.push( res.data);
          this.close()
        //--Warning : Donnée invalide, Session expiré
        }else if(res.code === 400){
          for (let item in res.data){
            this.sous_module_error[item] = res.data[item][0] ;
          }
          this.sous_module_error = <sous_module> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        //--error
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
    //--- Update sous_module
    }else {
        let res =  await this.http.put<any>( environment.sous_module + '/'+this.sous_module.id,this.sous_module,valuesys.httpAuthOptions()).toPromise();
        //--success
        if(res.code === 201){

          const resultSpecialite= this.modules.filter(word => word.id == this.sous_module.module_id);
          this.sous_module.module.name = resultSpecialite[0]?.name;

          this.toastr.success(res.msg,this.__("msg.success")) ;
          this.close()
        //--Warning : Donnée invalide, Session expiré
        }else if(res.code === 400){
          this.sous_module_error = <sous_module> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        //--error
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
    }
    this.isValid = false ;
  }





  delete(deletedUser:sous_module,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.sous_module+"/" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.sous_modules= this.sous_modules.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
            {
              icon: 'success',
              title: this.__('msg.deleted'),
              confirmButtonColor: '#44A1A0',
              text: sup.msg,
            }
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          {
            icon: 'error',
            title: this.__('msg.canceled'),
            confirmButtonColor: '#44A1A0',
            text: this.__('msg.canceled_delete'),
          }
        )
      }
    })
  }



}
