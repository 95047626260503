<br><div class="repMenu">
    <div class="row  d-flex  justify-content-between">     
      <div class="breadcrumb flat">
        <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
        <a [routerLink]="['/app/module/CAR']"  routerLinkActive="active">{{__('menu.carte')}}</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/carte']" routerLinkActive="active" *ngIf="autority('GCA_1')">{{__('carte.title')}}</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/listeCarteParMinistere']"  routerLinkActive="active" *ngIf="autority('DCA_1')">Disponibiliter des cartes</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/affecterCarte']"  routerLinkActive="active" *ngIf="autority('AFC_1')">Affecter carte</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/ActiverDesactive']"  routerLinkActive="active" *ngIf="autority('ACD_1')">Activer / Desactiver carte</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/EnrolerCarte']"  routerLinkActive="active" *ngIf="autority('ENC_1')">Enroler carte</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/planifier_creation_compte']"  routerLinkActive="active" *ngIf="autority('CRC_1')">Création de compte</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/demandeenrolement']"  routerLinkActive="active" *ngIf="autority('DEN_1')">Demande enrolement</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/demandewallet']"  routerLinkActive="active" *ngIf="autority('DEW_1')">Demande création compte</a>
    </div>

      <!-- <button type="button" class="button-control ng-star-inserted" (click)="openModalImportExcel(addImportExcel)"><i class="fa fa-file-excel-o"></i> Affectation multiple</button> -->

</div>
</div> 
<div class="bg-white my-3 py-3">
    <div class="container">
        <form  (ngSubmit)="searchCarte()" class="card" ngNativeValidate> 
            <div class="card-body">
                <div class="row d-flex flex-column align-items-center">

                    <div class="col-lg-6" *ngIf="superAdmin == 1">
                        <div class="form-group">
                            <label >{{__('concerner.etablissement_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                                <span class="required-red" *ngIf="!etablissementId && submitted">/ Ce champ est obligatoire</span>
                                <!-- <span *ngIf="submitted && f.etablissement_id.errors" class="invalid-form-feedback" >                      
                                <span *ngIf="f.etablissement_id.errors.required"> / Ce champ est obligatoire</span>
                                </span> -->
                            </label>
                            <ng-select placeholder="{{__('concerner.etablissement_id')}}"
                            aria-readonly=""
                                [(ngModel)]="etablissementId" 
                                class="form-control"
                                [readonly]="superAdmin == 0 && activeEtabId != 1"
                                >
                                <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
                            </ng-select>
                
                            <!--  <input formControlName="etablissement_id"  type="number" class="form-control" [(ngModel)]="concerner.etablissement_id" id="etablissement_id" placeholder="{{__('concerner.etablissement_id')}}" > -->
                        </div>
                        </div>
                    <div class="col-md-6 form-group ">
                        <label for="">Numero de serie</label>
                        <input required  min="0" type="number"  [(ngModel)]="numero_serie" class="form-control" name="numero_serie"  id="">
                        <div class="w-100 d-flex justify-content-center">
                            <button type="submit" style="height: 40px;" class="button-control ng-star-inserted mt-2">Rechercher</button>
                            <!-- <button (click)="searchCarte()">test</button> -->
                        </div>
                    </div>
                </div>
          <!-- <hr> -->
                <div class="container mt-2"  >
                    <hr>
                    <div class="alert alert-danger text-center" *ngIf="carteArray.length == 0 && searchNumSerieSubmitted && step2Carte">
                        <i class="fa fa-times-circle text-danger"></i>
                        Ce carte n'appartient pas dans  {{ superAdmin == 1 ? "le ministère séléctionné" : "votre ministère" }}
                    </div>
                    <div class="alert alert-danger text-center " *ngIf="numeroSerieExistInEtab == false && searchNumSerieSubmitted">
                        <i class="fa fa-times-circle text-danger"></i>
                        Carte introuvable
                    </div>
                </div>
                <div *ngIf="carteArray.length != 0 && searchNumSerieSubmitted &&  carteArray[0].affected == 0" style="text-align: center;">
                    <div class="alert alert-danger">
                        <i class="fa fa-times-circle text-danger"></i>
                        Cette carte n'est pas affécté à une bénéficiaire
                    </div>
                </div>
                <div *ngIf="carteArray.length != 0 && searchNumSerieSubmitted &&  carteArray[0].affected != 0 && carteArray[0].situation != 4" style="text-align: center;">
                    <div class="alert alert-danger">
                        <i class="fa fa-times-circle text-danger"></i>
                        Cette carte n'est pas encore enroler
                    </div>
                </div>
                <ng-container *ngIf="carteArray.length != 0 && carteArray[0].affected != 0 && carteArray[0].situation == 4 && searchNumSerieSubmitted">
                    <div  style="text-align: center;">
                        <div class="row" style="text-align:center">
                            <div class="col-12">
                                <i class="fa fa-user" style="font-size:4rem;color: #45a1a1;"></i>
                                <h3>Information sur le bénéficiaire</h3>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                          <div class="col-lg-3">
                              <h6 class="title-info">Matricule</h6>
                              <p class="data-info">{{ carteArray[0]?.matricule}}</p>
                          </div>
                            <div class="col-lg-3">
                                <h6 class="title-info">Nom & prénom</h6>
                                <p class="data-info">{{ carteArray[0]?.prenom }} {{ carteArray[0]?.nom }}</p>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="title-info">Niveau</h6>
                                <p class="data-info">{{ carteArray[0]?.niveau}}</p>
                            </div>
                            <div class="col-lg-3">
                                <h6 class="title-info">Téléphone</h6>
                                <p class="data-info">+{{ carteArray[0]?.telephone}}</p>
                            </div>
                        
                        </div>
                        <hr>               
                        
                       </div>
                       <div  style="text-align: center;">
                          <div class="row" style="text-align:center">
                              <div class="col-12">
                                  <i class="fa fa-credit-card" style="font-size:4rem;color: #45a1a1;"></i>
                                  <h3>Information sur la carte</h3>
                              </div>
                          </div>
                          <hr>
                          <div class="row">
                              <div class="col-lg-3">
                                  <h6 class="title-info">Numéro de série</h6>
                                  <p class="data-info">{{ carteArray[0]?.numero_serie }}</p>
                              </div>
                             
                              <div class="col-lg-3">
                                  <h6 class="title-info">Ministère</h6>
                                  <p class="data-info">{{ carteArray[0]?.name}}</p>
                              
                              </div>
                              <div class="col-lg-3">
                                  <h6 class="title-info">Situation</h6>
                                  <p class="data-info">{{ situationCarte(carteArray[0]?.situation)}}</p>
                              
                              </div>
                              <div class="col-lg-3">
                                <h6 class="title-info">État</h6>
                                <p class="data-info">{{ carteArray[0]?.active == 0 ?  'Désactivé' : 'Activé'}}</p>
                            </div>
                          </div>
                          <hr>               
                          <div class="row d-flex justify-content-center" >
                              <button type="button" class="button-control ng-star-inserted" *ngIf="carteArray[0].active == 0 && autority('GCA_7')" (click)="activeAndDesactiveCarete(carteArray[0].active,0)">Activer carte</button>
                              <button type="button" style="background: #fc7f7f" class="button-control ng-star-inserted" *ngIf="carteArray[0].active == 1 && autority('GCA_8')" (click)="activeAndDesactiveCarete(carteArray[0].active,1)">Désactiver carte</button>
                              <div *ngIf="carteArray[0].active == 0 && !autority('GCA_7') || carteArray[0].active == 1 && !autority('GCA_8')" style="text-align: center;">
                                <div class="alert alert-danger">
                                   {{ carteArray[0].active == 0 ? "Vous n'avez pas le droit d'activer cette carte"  : "Vous n'avez pas le droit de désactiver cette carte"}}
                                </div>
                            </div>
                          </div>
                         </div>
                </ng-container>
          
            </div>
          </form>
        
    </div>

    
</div>

