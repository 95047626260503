<br><div class="repMenu">
    <!-- <div class="row">
        <div class="col-lg-8"><span class="ariare" [routerLink]="['/app']" style="cursor: pointer;" >{{__('menu.accueil')}} </span>  > <span class="ariare" [routerLink]="['/app/module/PAC']" style="cursor: pointer;"  >{{__('menu.parametre')}} </span>  > <span class="ariare"> {{__('parcours.title')}}</span></div>


    </div> -->
    <div class="row  d-flex  justify-content-start">
        
            
        <div class="breadcrumb flat">
          <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
          <a [routerLink]="['/app/module/HST']" routerLinkActive="active">Historique</a>
          <a [routerLink]="['/dashboard/HST/plateforme/journalbord']"  routerLinkActive="active">Journal de bord</a>
          <a [routerLink]="['/dashboard/HST/plateforme/historiquecashflow']"  routerLinkActive="active">Cash flow</a>
        </div>
    </div>
</div>
<div  class="bg-white p-2  repMenu mt-2 d-flex justify-content-start align-items-center">
  
    <div class="d-flex align-items-center export">
     <button class="px-2" (click)="exportLogToExcel()">
      Export excel
      <img class="mb-1" src="assets/img/excel.png" alt="">
  
  
      </button>
      <button (click)="exportPdf()" class="ml-3 px-2">
        Export Pdf
        <img   class="mb-1" src="assets/img/pdf-file.png" alt="">
  
        </button>
    </div>
  </div> 

  <div class="row">
    <div class="col-lg-12">
      <br>
      <div class="card">
        <div class="card-body">
              <div class="row d-flex justify-content-center">
                  <div class="col-md-3">
                    <label >{{__('journalbord.date_debut')}}</label>
                    <input [(ngModel)]="dateDebut" type="date" class="form-control">

                  </div>
                  <div class="col-md-3">
                    <label >{{__('journalbord.date_fin')}}</label>
                    <input [(ngModel)]="dateFin" type="date" class="form-control">

                  </div>
                      
                 <div class="pt-1">
                    <button  style="height: 44px;" type="submit" name="button" (click)="getUrlDatatable(endPOint, '','','',true)" class="mt-4 btn button-control" >{{__('btn.filtre')}}</button>

                 </div>
              </div>
        </div>
      </div>
    </div>
  </div> 

<div class="row">
    <div class="col-lg-12">
        <br>
        <div class="card">
            <div class="card-body">
                <div class="row footer-paginate" >
                    <div class="col-lg-4">
                        <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span>
                        <select name="" id="" [(ngModel)]="filtreSelect" (change)="getFiltreSelect()" class="search-input" >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="2000">2000</option>
                            <option value="5000">5000</option>
                            <option value="10000">10000</option>
                        </select>
                        <span style="color: #666 !important;" > {{__('datatable.element')}}  </span>
                    </div>
                    <div class="col-lg-8 btn-paginate">
                        <span style="color: #666 !important;" > {{__('datatable.rechercher')}} : </span><input type="text" [(ngModel)]="searchInput" (keyup.enter)="getSearchInput()" class="search-input">
                    </div>

                </div>
                <table  style="width: 100%"  class="table table-striped table-hover " >
                    <thead>
                      
                    <tr>
                      <th class="text-left">{{__('journalbord.date')}}
                        <br>
                        <i class="fa fa-caret-down icon-tri" (click)="triTable('journal_bord.date','asc')" *ngIf="tri=='journal_bord.date' && order=='desc'" ></i>
                        <i class="fa fa-caret-up icon-tri" (click)="triTable('journal_bord.date','desc')"  *ngIf="tri=='journal_bord.date' && order=='asc'"  ></i>
                        <i class="fa fa-caret-left icon-tri" (click)="triTable('journal_bord.date','_asc')" *ngIf="tri!='journal_bord.date'" ></i>
                        <input type="date" #date class="inputCol" name="date" (keyup.enter)="getUrlDatatable(endPOint, 'journal_bord.date',date, 'j')" id="">
                    </th>
    
                      <th>{{__('journalbord.table')}} <br>
                      <i class="fa fa-caret-down icon-tri" (click)="triTable('journal_bord.table_name','asc')" *ngIf="tri=='journal_bord.table_name' && order=='desc'" ></i> 
                      <i class="fa fa-caret-up icon-tri" (click)="triTable('journal_bord.table_name','desc')"  *ngIf="tri=='journal_bord.table_name' && order=='asc'"  ></i> 
                      <i class="fa fa-caret-left icon-tri" (click)="triTable('journal_bord.table_name','asc')" *ngIf="tri!='journal_bord.table_name'" ></i> 
                      <input type="text" #table_name class="inputCol" name="table_name" (keyup.enter)="getUrlDatatable(endPOint, 'table_name',table_name, 'journal_bord')" id="">
    
                    </th>
                        <th class="text-left">{{__('journalbord.action')}}

                            <br>
                            <i class="fa fa-caret-down icon-tri" (click)="triTable('journal_bord.action ','asc')" *ngIf="tri=='journal_bord.action ' && order=='desc'" ></i>
                            <i class="fa fa-caret-up icon-tri" (click)="triTable('journal_bord.action ','desc')"  *ngIf="tri=='journal_bord.action ' && order=='asc'"  ></i>
                            <i class="fa fa-caret-left icon-tri" (click)="triTable('journal_bord.action ','asc')" *ngIf="tri!='journal_bord.action '" ></i>
                            <input type="text" #action class="inputCol" name="action" (keyup.enter)="getUrlDatatable(endPOint, 'journal_bord.action',action, 'j')" id="">
                        </th>

                        <th class="text-left">NOM
                            <br>
                            <i class="fa fa-caret-down icon-tri" (click)="triTable('user.f_name','asc')" *ngIf="tri=='user.f_name' && order=='desc'" ></i>
                            <i class="fa fa-caret-up icon-tri" (click)="triTable('user.f_name','desc')"  *ngIf="tri=='user.f_name' && order=='asc'"  ></i>
                            <i class="fa fa-caret-left icon-tri" (click)="triTable('user.f_name','_asc')" *ngIf="tri!='user.l_name'" ></i>
                            <input type="text" #f_name class="inputCol" name="f_name" (keyup.enter)="getUrlDatatable(endPOint, 'user.f_name',f_name, 'j')" id="">
                        </th>


                        <th class="text-left">PRENOM
                            <br>
                            <i class="fa fa-caret-down icon-tri" (click)="triTable('user.l_name','asc')" *ngIf="tri=='user.l_name' && order=='desc'" ></i>
                            <i class="fa fa-caret-up icon-tri" (click)="triTable('user.l_name','desc')"  *ngIf="tri=='user.l_name' && order=='asc'"  ></i>
                            <i class="fa fa-caret-left icon-tri" (click)="triTable('user.l_name','_asc')" *ngIf="tri!='user.l_name'" ></i>
                            <input type="text" #l_name class="inputCol" name="l_name" (keyup.enter)="getUrlDatatable(endPOint, 'user.l_name',l_name, 'j')" id="">
                        </th>

                        <th>{{__('journalbord.ip_adress')}} <br>
                          <i class="fa fa-caret-down icon-tri" (click)="triTable('logs.ip_adress','asc')" *ngIf="tri=='logs.ip_adress' && order=='desc'" ></i> 
                          <i class="fa fa-caret-up icon-tri" (click)="triTable('logs.ip_adress','desc')"  *ngIf="tri=='logs.ip_adress' && order=='asc'"  ></i> 
                          <i class="fa fa-caret-left icon-tri" (click)="triTable('logs.ip_adress','asc')" *ngIf="tri!='logs.ip_adress'" ></i> 
                          <input type="text" #ip_adress class="inputCol" name="ip_adress" (keyup.enter)="getUrlDatatable(endPOint, 'ip_adress',ip_adress, 'logs')" id="">
        
                        </th>
                        

                        <th class=" text-center">{{__('table.action')}}</th>
                    </tr>
                    </thead>
                    <tbody *ngIf="journal_bord?.length != 0" >
                    <tr *ngFor="let j of journal_bord">
                      <td class="text-left">{{ j?.date | date: 'dd/MM/yyyy hh:mm a'  }}  </td>
                      <td class="text-left">{{ j?.table_name }}</td>
                        <td class="text-left">{{ j?.action }}</td>
                        <td class="text-left">{{ j?.f_name }}</td>
                        <td class="text-left">{{ j?.l_name }}</td>
                        <td class="text-left">{{ j?.ip_adress }}</td>

                        <td class="text-center">
                              <!-- <i class="fa fa-trash icon-table red" *ngIf="autority('JRB_5')" ngbTooltip="{{__('tooltip.delete')}}" #openModalBtnDelete (click)="delete(j,openModalBtnDelete)"></i> -->
                              <i class="fa fa-eye icon-table vert"ngbTooltip="{{__('tooltip.details')}}" #openModalDetail (click)="openModalDetails(datailModal,j)" ></i>
                        </td>

                    </tr>
                    </tbody>
                    <tbody *ngIf="journal_bord?.length == 0">
                    <tr>
                        <td colspan="6" >{{__('label_no_data')}}</td>
                    </tr>
                    </tbody>
                </table>

                <div class="row footer-paginate" >
                    <div class="col-lg-4">
                        <span class="three-point" style="padding-left: 0px;" >{{infoData}}</span>
                    </div>
                    <div class="col-lg-8 btn-paginate" >
                        <button  (click)="getUrlDatatable(firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
                        <button  (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
                        <button *ngIf="currentPage > 3" class="btn-pagination-hr"  (click)="getUrlDatatable(firstPage)" > 1 </button>
                        <span *ngIf="currentPage > 3" class="three-point" > ... </span>
                        <button *ngIf="cPage_less_2 > 0 && currentPage < 4" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_less_2)" > {{cPage_less_2}} </button>
                        <button *ngIf="cPage_less_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_less_1)" > {{cPage_less_1}} </button>
                        <button (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{currentPage}} </button>
                        <button *ngIf="cPage_more_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_more_1)" > {{cPage_more_1}} </button>
                        <button *ngIf="(cPage_more_2 > 0 && cPage_more_2 > lastNumPage && cPage_more_1 > lastNumPage && currentPage > lastNumPage) || cPage_more_2 == lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_more_2)" > {{cPage_more_2}} </button>
                        <span *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="three-point" > ... </span>
                        <button *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(lastPage)" > {{lastNumPage}} </button>
                        <button  (click)="getUrlDatatable(nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
                        <button  (click)="getUrlDatatable(lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>






<ng-template #datailModal let-modal >
    <div class="modal-header" style="text-align: center;" >
      <h4 class="modal-title text-center" id="modal-basic-title">{{__('label_details')}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>


        <div class="modal-body p-2">


            <tabset>
                <!-- <tab heading="{{__('journalbord.historique')}}" style="overflow: auto;">
                    <table style="width: 100%;">
                        <tr>
                          <td class="tab-detail-title" nowrap>{{__('journalbord.date_hour')}}</td>
                          <td class="tab-detail">{{loguser.date}}</td>
                          <td class="tab-detail-title" nowrap>{{__('journalbord.action')}}</td>
                          <td class="tab-detail" colspan="3">{{loguser.action}}</td>
                        
                        </tr>
                     
                        <tr>
                          <td class="tab-detail-title" nowrap>{{__('journalbord.ip_adress')}}</td>
                          <td class="tab-detail" colspan="3">{{loguser.ip_adress}}</td>
                          <td class="tab-detail-title" nowrap>{{__('journalbord.data')}}</td>
                          <td class="tab-detail" colspan="3">{{loguser.data}}</td>
                        
                        </tr>

                        <tr>
                          <td class="tab-detail-title" nowrap>{{__('journalbord.nom')}}</td>
                          <td class="tab-detail">{{loguser.f_name}}</td>
                          <td class="tab-detail-title">{{__('journalbord.prenom')}}</td>
                          <td class="tab-detail">{{loguser.f_name}}</td>
                        
                        </tr>
                     
                        
                       
                      </table>
                </tab> -->


                <tab heading="{{__('journalbord.historique')}}" style="overflow: auto;">
                  <table style="width: 100%;">
                      <tr>
                        <td class="tab-detail-title" nowrap>{{__('journalbord.date')}}</td>
                        <td class="tab-detail">{{loguser.date}}</td>
                        <td class="tab-detail-title">{{__('journalbord.table')}}</td>
                        <td class="tab-detail">{{loguser.table_name}}</td>
                      
                      </tr>
                      <tr>
                        <td class="tab-detail-title" nowrap>{{__('journalbord.action')}}</td>
                        <td class="tab-detail" colspan="3">{{loguser.action}}</td>
                      
                      </tr>
                      <tr>
                        <td class="tab-detail-title" nowrap>{{__('journalbord.ip_adress')}}</td>
                        <td class="tab-detail" colspan="3">{{loguser.ip_adress}}</td>
                      
                      </tr>

                      <tr>
                        <td class="tab-detail-title" nowrap>{{__('journalbord.nom')}}</td>
                        <td class="tab-detail">{{loguser.f_name}}</td>
                        <td class="tab-detail-title">{{__('journalbord.prenom')}}</td>
                        <td class="tab-detail">{{loguser.l_name}}</td>
                      
                      </tr>
                      <tr>
                        <td class="tab-detail-title" nowrap>{{__('journalbord.data')}}</td>
                        <td class="tab-detail" colspan="3">{{loguser.data_log}}</td>
                      
                      </tr>

                      
                     
                    </table>
              </tab>


                
                
              </tabset>







      </div>
    
  </ng-template>

