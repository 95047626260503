<br><div class="repMenu">
    <div class="row  d-flex  justify-content-between">   
      <div class="breadcrumb flat">
        <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
        <a [routerLink]="['/app/module/ADM']" routerLinkActive="active">Administration</a>
        <a [routerLink]="['/dashboard/ADM/plateforme/etablissement']"  routerLinkActive="active" *ngIf="autority('ETA_1')">Gestion ministères</a>
        <a [routerLink]="['/dashboard/ADM/administration/user']"  routerLinkActive="active" *ngIf="autority('USR_1')"> Gestion utilisateurs</a>
        <a [routerLink]="['/dashboard/ADM/administration/profil']"  routerLinkActive="active"  *ngIf="autority('PRFL_1')">Gestion profils</a>
        <a [routerLink]="['/dashboard/ADM/administration/module']"  routerLinkActive="active" *ngIf="autority('MDL_1')">Modules</a>
        <a [routerLink]="['/dashboard/ADM/administration/sousmodule']"  routerLinkActive="active" *ngIf="autority('SMDL_1')">Sous modules</a>
        <a [routerLink]="['/dashboard/ADM/plateforme/province']"  routerLinkActive="active" *ngIf="autority('PRV_1')">Province</a>

      </div>
  </div>
</div> 


<div [ngClass]="{
  'p-2  repMenu mt-2 d-flex justify-content-end align-items-center': true,
  'bg-white': autority('PRFL_2')
}">
  <div class="d-flex align-items-center">
    <button class="button-control" *ngIf="autority('PRFL_2')" #addBtnUser (click)="openModal(addProfil,null,addBtnUser)"><i class="fa fa-plus"></i>Ajouter</button>
       
  </div>
   
</div>

<div class="row">
    <div class="col-lg-12">
      <br>
      <div class="card">
        <div class="card-body">
            <table datatable  [dtOptions]="dtOptions" style="width: 100%;" class="table table-striped table-hover " >
            <thead>
              <tr>
                <th class="text-left" >{{__('profil.name_table_label')}}</th>
                <th class="text-center">{{__('profil.code_table_label')}}</th>
                <th class="actionTab text-center">{{__('table.action')}}</th>
              </tr>
            </thead>
            <tbody *ngIf="profils?.length != 0" >
                <tr *ngFor="let profil of profils">
                <td class="text-left" >{{ profil.name }}</td>
                <td class="text-center">{{ profil?.code }}</td>
                <td class="text-center">
                    <i class="fa fa-eye icon-table vert"  *ngIf="autority('PRFL_8')"  ngbTooltip="{{__('tooltip.profilage')}}" (click)="detail(profil,profilage)"></i>
                    <i class="fa fa-edit icon-table" *ngIf="autority('PRFL_3')" ##openModalBtnEdit ngbTooltip="{{__('tooltip.edite')}}" (click)="openModal(addProfil,profil,openModalBtnEdit)" ></i>
                    <i class="fa fa-toggle-on icon-table vert"  ngbTooltip="{{__('tooltip.desactiver')}}" #buttonElement  *ngIf="profil.state == 1 && autority('PRFL_6')" (click)="toggleState(profil,0,buttonElement)"></i>
                    <i class="fa fa-toggle-off icon-table vert"  #buttonElement ngbTooltip="{{__('tooltip.active')}}" *ngIf="profil.state == 0 && autority('PRFL_6')" (click)="toggleState(profil,1,buttonElement)"></i>
                    <i class="fa fa-trash icon-table red" *ngIf="autority('PRFL_5')"  #openModalBtnDelete ngbTooltip="{{__('tooltip.delete')}}" (click)="delete(profil,openModalBtnDelete)"></i>
                </td>

              </tr>
            </tbody>
            <tbody *ngIf="profils?.length == 0">
            <tr>
                <td colspan="6" >{{__('label_no_data')}}</td>
            </tr>
        	</tbody> 
          </table>
          
        </div>
      
      </div>
    </div>
</div>

<!--MODAL PROFIL ADD AND EDIT-->
<ng-template #addProfil let-modal style="background-color: red; width: 1000px;">
	<div class="modal-header">
	  <h4 class="modal-title" id="modal-basic-title">{{ titleModal }}</h4>
	  <button type="button" class="close" aria-label="Close" (click)="close()">
	    <span aria-hidden="true">×</span>
	  </button>
	</div>

	
	<div class="modal-body" style="padding: 30px;">
    <form ngNativeValidate (ngSubmit)="addProfilForm()" >
  		<div class="row">
  		    <div class="col-lg-6">
  		        <div class="form-group">
  		            <label >{{__('profil.name_table_label')}} :</label>
  		            <input type="text" required="true"class="form-control" name="name" id="name" [(ngModel)]="profil.name"  placeholder="Nom" >
  		        </div>
  		    </div>
  		    <div class="col-lg-6"> 
  		        <div class="form-group">
  		            <label >{{__('profil.code_table_label')}}</label>
  		            <input  type="text" name="code" id="code" [(ngModel)]="profil.code" required="true" class="form-control"  placeholder="Code" >
  		        </div>
  		    </div>
  		</div>
  		<div class="row">
  		    <div class="col-lg-12">
  		        <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == true"  >{{__('profil.btn_add')}}</button>            
  		        <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false" >{{__('profil.btn_save')}}</button>            
  		        <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel">{{__('profil.btn_cancel')}}</button>            
  		  	</div>
  		</div>
    </form>
	</div>
	
</ng-template>
<!--MODAL PROFIL ADD AND EDIT-->

<!-- MODAL PROFILAGE -->
<ng-template #profilage >

  <div class="modal-header">
  	<h4 class="modal-title text-center">{{__('profil.modal_title_profiage')}} : {{titleProfilage}}</h4>
    <button  type="button" class="close pull-right" aria-label="Close" (click)="close()">&times;</button>
  </div>

  <div class="modal-body profil-attribution-content"  >
    <!--PANEL-->
    <div class="container-fluid">
      <div class="panel-group" id="accordion">
        <!--START LEVEL 1-->
        <accordion [isAnimated]="true" [closeOthers]="true" >
          <accordion-group *ngFor="let item of modules;let i = index" [panelClass]="customClass" [isOpen]="false" #module>
          		<div accordion-heading style="color: white;font-weight: bold;">

          			<i class="fa fa-lg" 
                  [ngClass]="{'fa-caret-up': module?.isOpen, 'fa-caret-down': !module?.isOpen}" 
                  style="width: 25px">
                </i>
          		   	{{item.name}}
      		  	</div>
              <!--START LEVEL 2-->
          		<accordion [isAnimated]="true">
          			<accordion-group *ngFor="let s_module of item.sous_modules" panelClass="customClass" [isOpen]="false" #s_modules>

			    		<div accordion-heading style="color: white;font-weight: bold;text-align: center;">
			    			<input  #checkAll 
			    				type="checkbox" name="" 
			    				[checked]="isCheckedAll(s_module)" 
			    				(click)="updateActionAll(s_module.actions,checkAll,$event)" 
			    				style="position: absolute; left: 10px;top:14px">

                  <i class="fa fa-lg" 
                  [ngClass]="{'fa-caret-up': s_modules?.isOpen, 'fa-caret-down': !s_modules?.isOpen}" 
                  style="width: 25px;position: absolute;right: 5px;"></i>
                  
			    		   	{{s_module.name}}
					  	</div>

          				<div class="row">
          				  <div class="col-md-4" *ngFor="let action of s_module.actions">
          				    <input #check type="checkbox" name=""
          				    	[checked]="action.state==1" 
          				    	(click)="updateAction(action,check)"
      				    	>
          				    <label class="labelProfilage" >&nbsp;&nbsp;{{action.name}}</label>
          				  </div>
          				</div>

          			</accordion-group>
          		</accordion>
              <!--END LEVEL 2-->

          </accordion-group>
        </accordion>
        <!--END LEVEL 1-->
      </div>
    </div>
    <!--PANEL-->
  </div>


</ng-template>
     
