import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { LoginComponent } from './administration/components/fragment/auth/login/login.component';
import { HomeModuleComponent } from './containers/home-module/home-module.component';
import { HomeSousModuleComponent } from './containers/home-sous-module/home-sous-module.component';
import { StaticLayoutComponent } from './containers/static-layout/static-layout.component';
import { UtilisateurComponent } from './administration/components/fragment/admin/utilisateur/utilisateur.component';
import { ModuleComponent } from './administration/components/fragment/admin/module/module.component';
import { MeComponent } from './administration/components/fragment/auth/me/me.component';
import { LogoutComponent } from './administration/components/fragment/auth/logout/logout.component';
import { SousModuleComponent } from './administration/components/fragment/admin/sous-module/sous-module.component';
import { PlateformeComponent } from './administration/components/fragment/parametre/plateforme/plateforme.component';
import { ActionComponent } from './administration/components/fragment/parametre/action/action.component';
import { ProfileComponent } from './administration/components/fragment/admin/profile/profile.component';
import { ProvinceComponent } from './administration/components/fragment/admin/province/province.component';
import { CategorieConcernerComponent } from './administration/components/fragment/parametre/plateforme/categorie-concerner/categorie-concerner.component';
import { ConcernerComponent } from './administration/components/fragment/parametre/plateforme/concerner/concerner.component';
import { OrdrePaiementComponent } from './administration/components/fragment/parametre/plateforme/ordre-paiement/ordre-paiement.component';
import { CarteComponent } from './administration/components/fragment/parametre/plateforme/carte/carte.component';
import { EtablissementComponent } from './administration/components/fragment/admin/etablissement/etablissement.component';
import { LogUtilisateurComponent } from './administration/components/fragment/parametre/plateforme/log-utilisateur/log-utilisateur.component';
import { CashFlowComponent } from './administration/components/fragment/parametre/plateforme/cash-flow/cash-flow.component';
import { AffectationCarteComponent } from './administration/components/fragment/parametre/plateforme/affectation-carte/affectation-carte.component';
import { TableauBordComponent } from './administration/components/fragment/parametre/plateforme/tableau-bord/tableau-bord.component';
import { ListeCarteParMinistereComponent } from './administration/components/fragment/parametre/plateforme/liste-carte-par-ministere/liste-carte-par-ministere.component';
import { DefaultForMeComponent } from './containers/default-for-me/default-for-me.component';
import { AffectCarteComponent } from './administration/components/fragment/parametre/plateforme/affect-carte/affect-carte.component';
import { ActiveDesactiveCarteComponent } from './administration/components/fragment/parametre/plateforme/active-desactive-carte/active-desactive-carte.component';
import { EnrolerCarteComponent } from './administration/components/fragment/parametre/plateforme/enroler-carte/enroler-carte.component';
import { CreationCompteComponent } from './administration/components/fragment/parametre/plateforme/creation-compte/creation-compte.component';
import { DemandeEnroulementComponent } from './administration/components/fragment/parametre/plateforme/demande-enroulement/demande-enroulement.component';
import {DemandewalletComponent} from './administration/components/fragment/parametre/plateforme/demandewallet/demandewallet.component';
import { ResumeBourseComponent } from './administration/components/fragment/parametre/plateforme/resume-bourse/resume-bourse.component';
import { ResumeBourseUnivComponent } from './administration/components/fragment/parametre/plateforme/resume-bourse-univ/resume-bourse-univ.component';


export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path : 'logout',
    component : LogoutComponent,
    data : { title : "Profil" }
  },
  {
    path: 'dashboard/:moduleCode',
    component: DefaultLayoutComponent,
    data: { title: 'Home'},
    children:[
      {
        path: 'administration',
        children : [
          {
            path: 'module',
            component: ModuleComponent,
            data: {
              title: 'Module'
            }
          },
          {
            path: 'user',
            component: UtilisateurComponent,
            data: {
              title: 'Utilisateur'
            }
          },
          {
            path : 'profil',
            component : ProfileComponent,
            data : { title : "Profil" }
          },
         
          {
            path : 'sousmodule',
            component : SousModuleComponent,
            data : { title : "Sous module" }
          },
          
        ]
      },
      {
        path: 'administration/settings',
        children : [
          {
            path: 'action',
            component: ActionComponent,
            data: {
              title: 'Action'
            }
          },
          {
            path: 'plateforme',
            component: PlateformeComponent,
            data: {
              title: 'Plateforme'
            }
          },
        ]
      },

      {
        path:'plateforme',
        children : [
          {
            path: 'categorie_concerner',
            component: CategorieConcernerComponent,
            data: {
              title: 'Categorie'
            }
          },
          {
            path: 'concernee',
            component: ConcernerComponent,
            data: {
              title: 'Concerner'
            }
          },
          {
            path: 'carte',
            component: CarteComponent,
            data: {
              title: 'Carte'
            },
            pathMatch: 'full'
          },
          {
            path: 'ordrepaiement',
            component: OrdrePaiementComponent,
            data: {
              title: 'Ordre paiment'
            }
          },
          {
            path : 'etablissement',
            component : EtablissementComponent,
            data : { title : "Etablissesment" }
          },
          {
            path : 'province',
            component : ProvinceComponent,
            data : { title : "Province" }
          },
          {
            path : 'journalbord',
            component : LogUtilisateurComponent,
            data : { title : "Historique utilisateurs" }
          },
          {
            path : 'historiquecashflow',
            component : CashFlowComponent,
            data : { title : "Cash flow" }
          },
          {
            path : 'concernee/affectation-carte',
            component : AffectationCarteComponent
          },
          {
            path : 'accueil/dash',
            component : TableauBordComponent
          },
          {
            path : 'listeCarteParMinistere',
            component : ListeCarteParMinistereComponent,
            data : { title : "List cartes par Ministére" }
          },
          {
            path: "carte_concerner/affectation",
            component: AffectCarteComponent,
            pathMatch: 'full'
          },
          {
            path: 'affecterCarte',
            component: AffectCarteComponent,
            pathMatch: 'full'
          },
          {
            path: "ActiverDesactive",
            component: ActiveDesactiveCarteComponent,
            pathMatch: 'full'
          },
          {
            path: "EnrolerCarte",
            component: EnrolerCarteComponent,
            pathMatch: 'full'
          },
          {
            path: "planifier_creation_compte",
            component: CreationCompteComponent,
            pathMatch: 'full'
          },
          {
            path: "demandeenrolement",
            component: DemandeEnroulementComponent,
            pathMatch: 'full'
          },
          {
            path: "demandewallet",
            component:DemandewalletComponent,
            pathMatch: 'full'
          },
          {
            path: "historiquecashflow",
            children: [
              {
                path: "etatResumeBourse",
                component: ResumeBourseComponent
              },
              {
                path: "etatResumeBourseUniversite",
                component: ResumeBourseUnivComponent
              }
            ]
          }
        ]
      }
      
    ]
  },
  {
    path: 'app',
    children : [
      {
        path : '',
        component : HomeModuleComponent,
        data : { title: 'Dashbord de Gestion' },
      },
      {
        path : 'module/:moduleCode',
        component : HomeSousModuleComponent,
        data : { title: 'Dashbord de Gestion' },
      },
    ]
  },
  {
    path: "app/profile",
    component: DefaultForMeComponent,
    children: [
      {
        path: "",
        component: MeComponent
      }
    ]
  },
  // {
  //   path: '**',
  //   redirectTo: 'app'
  // }

];

@NgModule({
  imports: [ RouterModule.forRoot(routes,{ useHash: false,enableTracing:false, onSameUrlNavigation:'reload' }) ],
exports: [ RouterModule ]
})
export class AppRoutingModule {}
