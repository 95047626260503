import { Component, OnInit } from '@angular/core';
import { Translatable } from '../../administration/service/Translatable';
import {AuthServiceService} from "../../administration/service/auth-service.service";
import { Router } from '@angular/router';
import {Auth,profilage_module} from "../../administration/model/db";
import Swal from "sweetalert2";

@Component({
  selector: 'app-home-module',
  templateUrl: './home-module.component.html',
  styleUrls: ['./home-module.component.scss']
})
export class HomeModuleComponent extends Translatable  implements OnInit {

  public user    : Auth = new Auth();
  public modules : profilage_module []= [] ;

  constructor(private router : Router,private auth : AuthServiceService) { super()}

  async ngOnInit() {
   this.user = <Auth> await  this.auth.getLoginUser();
   this.modules = this.user.modules;
   this.modules =this.modules.filter(_=> _.hasOneSubModuleAction || this.user.info.admin === 1)
  }

  goToModule(module)
  {
    console.log(module);
    this.router.navigate([module]);
  }

  logout(){
    //btn.disabled = true;
    Swal.fire({
      title: this.__('swal.confirmTitle'),
      text: this.__('swal.confirmeLogOut'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      confirmButtonText: this.__('swal.yes'),
      cancelButtonText: this.__('swal.no'),
      reverseButtons : true
    }).then(async (result) => {
      if (result.value) {
        return  await this.router.navigate(['/logout'])
      }
    })
  }

  goToMe() : void{
    this.router.navigate(['/app/profile']);
  }

}
