<br><div class="repMenu">
    <div class="row  d-flex  justify-content-between">
      <div class="breadcrumb flat">
        <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
        <a [routerLink]="['/app/module/PAC']" routerLinkActive="active"> Paramètres & configuration</a>
        <a [routerLink]="['/dashboard/PAC/administration/settings/action']"  routerLinkActive="active"> Liste des actions</a>
      </div>  
 

</div>
</div> 
<div  class="bg-white p-2  repMenu mt-2 d-flex justify-content-end align-items-center">
  <div class="d-flex align-items-center">
    <button class="button-control" #btn1 *ngIf="superAdmin==1 " (click)="genererAction(btn1)" >{{__('action.generer')}}</button>
    <button class="button-control" #btn2 *ngIf="superAdmin==1 " (click)="addNewAction(btn2)" >{{__('action.update')}}</button>
  </div>
</div>
<div class="row">
    <div class="col-lg-12">
      <br>
      <div class="card">
        <div class="card-body">
            <table datatable  [dtOptions]="dtOptions" class="table table-striped table-hover " >
            <thead>
              <tr>
                <th class="text-center">{{__('action.name')}}</th>
                <th class="text-center">{{__('action.code')}}</th>
                <th class="text-center">{{__('action.url')}}</th>
                <th class="text-center">{{__('action.method')}}</th>
                <th class="text-center">{{__('type_action.type_action')}}</th>
                <th class="text-center">{{__('sous_module.sousmodule')}}</th>
              </tr>
            </thead>
            <tbody *ngIf="actions?.length != 0" >
                <tr *ngFor="let action of actions">
                  <!--<td>{{ pay.id }}</td>-->
                  <td class="text-left">{{ action.name }}  </td>
                  <td class="text-center"> {{ action.code }}</td>
                  <td class="text-center"> {{ action.url }}</td>
                  <td class="text-center"> {{ action.method }}</td>
                  <td class="text-center"> {{ action?.typeaction?.name }}</td>
                  <td class="text-center"> {{ action?.sousmodule?.name }}</td>
                </tr>
                </tbody>
                <tbody *ngIf="actions?.length == 0">
                <tr>
                  <td colspan="9" class="no-data-available text-center">{{__('label_no_data')}}</td>
                </tr>
            </tbody>
          </table>
          
        </div>
      
      </div>
    </div>
  </div>