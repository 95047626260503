<br><div class="repMenu">
    <div class="row  d-flex  justify-content-between">      
      <div class="breadcrumb flat">
        <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
        <a [routerLink]="['/app/module/ADM']" routerLinkActive="active">Administration</a>
        <a [routerLink]="['/dashboard/ADM/plateforme/etablissement']"  routerLinkActive="active" *ngIf="autority('ETA_1')">Gestion ministères</a>
        <a [routerLink]="['/dashboard/ADM/administration/user']"  routerLinkActive="active" *ngIf="autority('USR_1')"> Gestion utilisateurs</a>
        <a [routerLink]="['/dashboard/ADM/administration/profil']"  routerLinkActive="active"  *ngIf="autority('PRFL_1')">Gestion profils</a>
        <a [routerLink]="['/dashboard/ADM/administration/module']"  routerLinkActive="active" *ngIf="autority('MDL_1')">Modules</a>
        <a [routerLink]="['/dashboard/ADM/administration/sousmodule']"  routerLinkActive="active" *ngIf="autority('SMDL_1')">Sous modules</a>
        <a [routerLink]="['/dashboard/ADM/plateforme/province']"  routerLinkActive="active" *ngIf="autority('PRV_1')">Province</a>

      </div>
  </div>
</div> 
<div [ngClass]="{
  'p-2  repMenu mt-2 d-flex justify-content-end align-items-center': true,
  'bg-white': autority('PRV_2')
}">
  <div class="d-flex align-items-center">
    <button class="button-control" *ngIf="autority('PRV_2')" #addBtnUser (click)="openModal(addProvince,null,addBtnUser)" ><i class="fa fa-plus"></i>{{__('province.add')}}</button>
       
  </div>
   
</div>
<div class="row">
    <div class="col-lg-12">
      <br>
      <div class="card">
        <div class="card-body">
            <table datatable  [dtOptions]="dtOptions" style="width: 100%"  class="table table-striped table-hover " >
            <thead>
              <tr>
                <th class="text-left">{{__('province.name')}}</th>
                <th class="actionTab text-center">{{__('table.action')}}</th>
              </tr>
            </thead>
            <tbody *ngIf="provinces?.length != 0" >
                <tr *ngFor="let province of provinces">
                <td class="text-left">{{ province?.name }}</td>             
                <td class="text-center">
                    <i class="fa fa-edit icon-table" *ngIf="autority('PRV_3')" ngbTooltip="{{__('tooltip.edite')}}"  #openModalBtnEdit (click)="openModal(addProvince,province,openModalBtnEdit)" ></i>
                    <i class="fa fa-toggle-on icon-table vert"   ngbTooltip="{{__('tooltip.desactiver')}}" #buttonElement (click)="toggleState(province,0,buttonElement)" *ngIf="province.state == 1 && autority('PRV_6')" ></i>
                    <i class="fa fa-toggle-off icon-table vert"  ngbTooltip="{{__('tooltip.active')}}" #buttonElement (click)="toggleState(province,1,buttonElement)" *ngIf="province.state == 0 && autority('PRV_6')" ></i>
                    <i class="fa fa-trash icon-table red" *ngIf="autority('PRV_5')"  ngbTooltip="{{__('tooltip.delete')}}" #openModalBtnDelete (click)="delete(province,openModalBtnDelete)"></i>
                </td>

              </tr>
            </tbody>
            <tbody *ngIf="provinces?.length == 0">
            <tr>
              <td colspan="2" >{{__('label_no_data')}}</td>
            </tr>
          </tbody> 
          </table>
          
        </div>
      
      </div>
    </div>
</div>

<ng-template #addProvince let-modal >
  	<div class="modal-header">
    	<h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
    	<button type="button" class="close" aria-label="Close" (click)="close()">
      		<span aria-hidden="true">×</span>
    	</button>
  	</div>

  	<form ngNativeValidate (ngSubmit)="sendProvince()" >

      	<div class="modal-body">
      		<br>
      		<div class="row">
          
	      		<div class="col-lg-12">
	        
	        		<div class="form-group">
	            		<label >{{__('province.name_province')}} (<span class="required-red" >{{__('label.require')}}</span>) </label>
	            		<input type="text" required="true"class="form-control" name="name" id="name" [(ngModel)]="province.name"  placeholder="{{__('module.name')}}" >
	        		</div>
	      		</div>
      
      		</div>

	      	<div class="row">
	          	<div class="col-lg-12">
	              	<button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == true"  >{{__('tooltip.enreg')}}</button>            
	              	<button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false"  > {{__('tooltip.edite')}}</button>            
	              	<button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>            
	        	</div>
	      	</div>


      	</div>
  	</form>
  
</ng-template>
     






