import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Translatable } from '../../../../service/Translatable';
import { AuthServiceService} from "../../../../service/auth-service.service";
import Login from '../../../../model/Login';
import { ToastrService }  from "ngx-toastr";
import { valuesys } from "../../../../../../options";
import { HttpClient } from '@angular/common/http';
import { HttpApiResponse } from "../../../../model/DataTablesResponse";
import { environment } from "../../../../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends Translatable  implements OnInit {
  
  password : String = "password";
  show     = false;
  loginBtn : boolean = false ;
  login    : Login = new Login();

  constructor(
    private router : Router,
    private auth   : AuthServiceService,
    private toastr : ToastrService,
    private httpClient : HttpClient
  ){
    super()
  }

  async ngOnInit() {
    localStorage.setItem('lang','fr');
    this.show     = false;
   
    let {code} = await this.httpClient.get<HttpApiResponse>(environment.baseUrl+environment.ping,valuesys.httpAuthOptions()).toPromise();
    if(code === 200){
      this.toastr.success("Tu es déjà connecté",'Session valide');
      //let uri = this.urlParser(window.localStorage.getItem('return'));
       return  this.router.navigate(['/app']);
    }
    
    
    this.login = new Login();
  }

  urlParser(href) {
    let l = document.createElement("a");
    l.href = href;
    return l;
  };

  async loginSubmit() {
    
   try {

     this.loginBtn = true ; 
     let response = await this.auth.loginUser(this.login);
     
      if (response.code === 200) {
 
        this.auth.setToken(response.data.access_token,response.data.expires_in);
        let uri = this.urlParser(window.localStorage.getItem('return'));
        await this.auth.getLoginUser(true);
        const me = JSON.parse(localStorage.getItem(environment.userItemName));
        if(me.info.first_connexion == 0){
          this.router.navigate(['auth','me'])
          return 
        }


        return  this.router.navigate(['/app']);

      } else {

        this.loginBtn = false ;
        this.toastr.error(response.msg, 'Erreur de connexion');
      }
     
    } catch (e) {
      this.loginBtn = false ;
      this.toastr.error('Veuillez verifier votre connexion internet', 'Erreur de connexion');
    }
  }

  showPassword() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }


}



