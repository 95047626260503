<br><div class="repMenu">
    <div class="row  d-flex  justify-content-start">     
      <div class="breadcrumb flat">
        <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
        <a [routerLink]="['/app/module/DAS']" routerLinkActive="active">Tableau de bord</a>
        <a [routerLink]="['/dashboard/DAS/plateforme/accueil/dash']"  routerLinkActive="active">Dashboard</a>
        <a [routerLink]="['/dashboard/DAS/plateforme/historiquecashflow/etatResumeBourse']"  routerLinkActive="active">{{__('resume.etat_res_bourse')}}</a>
        <a [routerLink]="['/dashboard/DAS/plateforme/historiquecashflow/etatResumeBourseUniversite']"  routerLinkActive="active">{{__('resume.etat_res_bourse_univ')}}</a>
      </div>
  </div>
  </div> 
  <div class=" bg-white p-2  repMenu mt-2 w-100">
    <div class="export">
      <button class="px-2" (click)="exportToExcel()" [disabled]="!etablissementId">
       Export excel
       <img class="mb-1" src="assets/img/excel.png" alt="">
   
   
       </button>
       <button (click)="exportPdf()" class="ml-3 px-2" [disabled]="!etablissementId">
         Export Pdf
         <img   class="mb-1" src="assets/img/pdf-file.png" alt="">
   
         </button>
     </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      
      <br>

      <div class="card">
        
        <div class="card-body" style="overflow-x: scroll">
          <div class="row footer-paginate d-flex align-items-center" >
            <div class="col-md-12 row d-flex align-items-center justify-content-center" >
                <div class="form-group col-md-3 ">
                    <label >{{__('carte.ministere')}} (<span class="required-red" >{{__('label.require')}}</span>)

                    </label>
                    <ng-select placeholder="{{__('carte.ministere')}}"
                               [(ngModel)]="etablissementId"
                               class="form-control"
                               [readonly]="superAdmin == 0"
                    >
                        <ng-option  *ngFor="let etab of etablissements" [value]="etab?.id ">{{ etab?.name }}</ng-option>
                    </ng-select>
                </div>
                <div class="form-group col-md-3 ">
                  <label >Date début ordre paiement (<span class="required-red" >{{__('label.require')}}</span>)
                    <span  class="invalid-form-feedback" >                      
                      <span *ngIf="submitFiltreDate && !dateDebut"> / Ce champ est obligatoire</span>
                    </span>
                  </label>
                  <input  type="date" class="form-control" [(ngModel)]="dateDebut"  placeholder="{{__('concerner.date_naissance')}}" >
              </div>
                <div class="form-group col-md-3">
                  <label >Date fin ordre paiement(<span class="required-red" >{{__('label.require')}}</span>)
                    <span  class="invalid-form-feedback" >                      
                      <span *ngIf="submitFiltreDate && !dateFin"> / Ce champ est obligatoire</span>
                    </span>
                  </label>
                  <input   type="date" class="form-control" [(ngModel)]="dateFin"  placeholder="{{__('concerner.date_naissance')}}" >
              </div>
              <div class="col-md-3 d-flex justify-content-start">
                <button [disabled]="!dateDebut || !dateFin || !etablissementId" style="height: 44px;" type="button" (click)="filtreByDate()" name="button" class="mt-2 btn button-control" >{{__('btn.filtre')}}</button>       

              </div>
            </div>
            
          </div>

        
          <!-- <accordion-group *ngFor="let s_module of item.sous_modules" panelClass="customClass" [isOpen]="false" #s_modules> -->
            <table class="table table-hover table-striped" style="width: 100%;overflow-x: scroll;" >
              <thead>
                <tr>
                  
                  <th class="text-center">{{__('resume_univ.t1')}} <br>
                  </th>
                  <th class="text-center">{{__('resume_univ.t2')}} <br>
                  </th>
                  <th class="text-center">{{__('resume_univ.t3')}} <br>
                  </th>
                  <th class="text-center">{{__('resume_univ.t4')}} <br>
                  </th>
                  <th class="text-center">{{__('resume_univ.t5')}}  <br>
                  </th>
                  <th class="text-center">{{__('resume_univ.t6')}}  <br>
                  </th>
                  <th class="text-center">{{__('resume_univ.t7')}}  <br>
                  </th>
                  <th class="text-center">{{__('resume_univ.t8')}}  <br>
                  </th>
                  <th class="text-center">{{__('resume_univ.t9')}}  <br>
                  </th>
                  <th class="text-center">{{__('resume_univ.t10')}}  <br>
                  </th>
                  <th class="text-center">{{__('resume_univ.t11')}}  <br>
                  </th>
               
                  <th class="text-center">{{__('resume_univ.t12')}}  <br>
                  </th>
                </tr>
              </thead>
              
              <tbody class="body-paginate-hr" >
                 <tr *ngFor="let r of etatResumeUnivList">
                  <td class="text-center">{{ r?.code }}</td>
                  <td class="text-center">{{ addSpace(r?.nb_boursier) }}</td>
                  <td class="text-center">{{ addSpace(r?.nb_boursier_carte) }}</td>
                  <td class="text-center">{{ addSpace(r?.nb_boursier_wallet) }}</td>
                  <td class="text-center">{{ addSpace(r?.nb_paiment) }}</td>
                  <td class="text-center">{{ addSpace(r?.nb_compte_no_credite) }}</td>
                  <td class="text-center">{{ addSpace(r?.montan_paiment) }}</td>
                  <td class="text-center">{{ addSpace(r?.nb_retrait_epoketra_PAOMA) }}</td>
                  <td class="text-center">{{ addSpace(r?.montant_retrait_epoketra_PAOMA) }}</td>
                  <td class="text-center">{{ addSpace(r?.nb_retrait_paositra_PAOMA) }}</td>
                  <td class="text-center">{{ addSpace(r?.montant_retrait_paositra_PAOMA) }}</td>
                  <td class="text-center">
                    <button  (click)="openModal(detailTemplate,r?.code)"  class="btn" style="border: 2px solid #44a1a0;">
                        <i class="fa fa-eye"></i>
                      </button>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="etatResumeUnivList?.length == 0">
              <tr>
                  <td colspan="11" class="text-center" >{{__('label_no_data')}}</td>
              </tr>
            </tbody> 
          
            </table>
         
        </div>
      
      </div>
    </div>

  </div>
  <ng-template #detailTemplate style="background-color: red; width: auto;">
    
    <div class="card">
      
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="close()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        <div class="card-body">
          <div class="export my-2">
            <button class="px-2" (click)="exportDetailToExcel()" >
             Export excel
             <img class="mb-1" src="assets/img/excel.png" alt="">
         
         
             </button>
             <button (click)="exportDetailPdf()" class="ml-3 px-2" >
               Export Pdf
               <img   class="mb-1" src="assets/img/pdf-file.png" alt="">
         
               </button>
           </div>
            <table class="table table-hover table-striped" style="width: 100%;overflow: scroll-x;" >
                <thead>
                  <tr>
                    
                    <th class="text-center">{{__('resume_detail.t1')}} <br>
                    </th>
                    <th class="text-center">{{__('resume_detail.t2')}} <br>
                    </th>
                    <th class="text-center">{{__('resume_detail.t3')}} <br>
                    </th>
                    <th class="text-center">{{__('resume_detail.t4')}} <br>
                    </th>
                    <th class="text-center">{{__('resume_detail.t5')}}  <br>
                    </th>
                    <th class="text-center">{{__('resume_detail.t6')}}  <br>
                    </th>
                    <th class="text-center">{{__('resume_detail.t7')}}  <br>
                    </th>
                    <th class="text-center">{{__('resume_detail.t8')}}  <br>
                    </th>
                    <th class="text-center">{{__('resume_detail.t9')}}  <br>
                    </th>
                    <th class="text-center">{{__('resume_detail.t10')}}  <br>
                    </th>
                    <!-- <th class="text-center">{{__('resume_detail.t11')}}  <br>
                    </th>
                    <th class="text-center">{{__('resume_detail.t12')}}  <br>
                    </th>
                    <th class="text-center">{{__('resume_detail.t13')}}  <br>
                    </th> -->
                  </tr>
                </thead>
                <tbody class="body-paginate-hr" >
                    <tr *ngFor="let r of detailPaiment">
                        <td class="text-center">{{ r?.num_compte }}</td>
                        <td class="text-center">{{ r?.IDetudiant }}</td>
                        <td class="text-center">{{ r?.prenom }}</td>
                        <td class="text-center">{{ r?.nom }}</td>
                        <td class="text-center">{{ r?.niveau }}</td>
                        <td class="text-center">{{ r?.telephone }}</td>
                        <td class="text-center">{{ r?.num_transaction }}</td>
                        <td class="text-center">{{ r?.date_paiement }}</td>
                        <td class="text-center">{{ addSpace(r?.montant) }}</td>
                        <td class="text-center">{{ r?.type_compte }}</td>
                        <!-- <td class="text-center">{{ r?.date_retrait }}</td>
                        <td class="text-center">{{ r?.montant_retrait }}</td>
                        <td class="text-center">{{ r?.lieu_retrait }}</td> -->
                      </tr>
                </tbody>
                <tbody *ngIf="detailPaiment?.length == 0">
                <tr>
                    <td colspan="13" class="text-center" >{{__('label_no_data')}}</td>
                </tr>
              </tbody> 
            
              </table>
        </div>
    </div>
  </ng-template>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    