<!--/ HOME Content -->
<style>
  .icon-header{
    color: #44A1A0;
  }
</style>

<!-- [navbarBrandMinimized]="{src: 'assets/img/Logo-MESUPRES.png',  alt: 'Mada Logo',style : 'display :block !important'}" -->

<app-header
  [navbarBrandRouterLink]="['/app']"
  [fixed]="true"
  [navbarBrandMinimized]="{src: 'assets/img/mptdn.png',  alt: 'mptdn Logo',style : 'display :block !important;width: 130px'}"
  [navbarBrandFull]="{src: 'assets/img/Logo-Madagascar-republic.png',  alt: 'Mada Logo', id:'logo-mada'}"
  [sidebarToggler]="'false'"
  [asideMenuToggler]="false"
  [mobileSidebarToggler] = "false"
  [mobileAsideMenuToggler] = "false"
   >
  

  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item d-md-down-none">
      <a class="icon-header" href="#" style="margin: 0 18px;">
       <!--  <img src="assets/img/Icon feather-mail.svg"/> -->
        
      </a>
    </li>

    <li class="nav-item d-md-down-none" style="cursor:pointer;display: none">
      <a class="icon-header"style=" margin: 0 18px;">
        <div ngbDropdown class="d-inline-block">
         <!--  <img id="dropdownBasic1" ngbDropdownToggle src="assets/img/Icon ionic-md-notifications-outline.svg"/>-->
          
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="margin-top: 25px!important; height: 350px!important; overflow-y: scroll!important;">
            <ul class="list-group"  >
              <li   class="list-group-item " style="cursor: default !important;"  >
                <a role="button" tabindex="0" class="media" >
                    <span style="cursor: pointer" class="pull-left media-object media-icon">
                        <i class="fa fa-eye" ></i>
                    </span>
                  <div class="media-body">
                    <span style="cursor: pointer" class="block">Notification 1 <sup  style="color: red"><br>Nouvelle</sup> </span>
                    <br>
                    <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
                  </div>
                </a>
              </li>

              <li   class="list-group-item " style="cursor: default !important;"  >
                <a role="button" tabindex="0" class="media" >
                    <span style="cursor: pointer" class="pull-left media-object media-icon">
                        <i class="fa fa-eye" ></i>
                    </span>
                  <div class="media-body">
                    <span style="cursor: pointer" class="block">Notification 2 <sup  style="color: red"><br>Nouvelle</sup> </span>
                    <br>
                    <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
                  </div>
                </a>
              </li>

              <li   class="list-group-item " style="cursor: default !important;"  >
                <a role="button" tabindex="0" class="media" >
                    <span style="cursor: pointer" class="pull-left media-object media-icon">
                        <i class="fa fa-eye" ></i>
                    </span>
                  <div class="media-body">
                    <span style="cursor: pointer" class="block">Notification 1 <sup  style="color: red"><br>Nouvelle</sup> </span>
                    <br>
                    <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
                  </div>
                </a>
              </li>

              <li   class="list-group-item " style="cursor: default !important;"  >
                <a role="button" tabindex="0" class="media" >
                    <span style="cursor: pointer" class="pull-left media-object media-icon">
                        <i class="fa fa-eye" ></i>
                    </span>
                  <div class="media-body">
                    <span style="cursor: pointer" class="block">Notification 4 <sup  style="color: red"><br>Nouvelle</sup> </span>
                    <br>
                    <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
                  </div>
                </a>
              </li>

              <li   class="list-group-item " style="cursor: default !important;"  >
                <a role="button" tabindex="0" class="media" >
                    <span style="cursor: pointer" class="pull-left media-object media-icon">
                        <i class="fa fa-eye" ></i>
                    </span>
                  <div class="media-body">
                    <span style="cursor: pointer" class="block">Notification 5 <sup  style="color: red"><br>Nouvelle</sup> </span>
                    <br>
                    <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
                  </div>
                </a>
              </li>

              
            </ul>
          </div>

        </div>
      </a>
      
    </li>   

    <div class="btn-group" dropdown placement="bottom right" style="margin-left: 20px;">
    <!--  <img id="dropdownBasic1" dropdownToggle class="dropdown-toggle" src="assets/img/Icon ionic-md-notifications-outline.svg"/>-->
     
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" id="notification-dropdown" role="menu"> 
        <li class="dropdown-header text-center">
          <strong>Notification</strong>
        </li>
        <li   class="list-group-item " style="cursor: default !important;"  >
          <a role="button" tabindex="0" class="media" >
              <span style="cursor: pointer" class="pull-left media-object media-icon">
                  <i class="fa fa-eye" ></i>
              </span>
            <div class="media-body">
              <span style="cursor: pointer" class="block">Notification 1 <sup  style="color: red"><br>Nouvelle</sup> </span>
              <br>
              <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
            </div>
          </a>
        </li>

        <li   class="list-group-item " style="cursor: default !important;"  >
          <a role="button" tabindex="0" class="media" >
              <span style="cursor: pointer" class="pull-left media-object media-icon">
                  <i class="fa fa-eye" ></i>
              </span>
            <div class="media-body">
              <span style="cursor: pointer" class="block">Notification 2 <sup  style="color: red"><br>Nouvelle</sup> </span>
              <br>
              <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
            </div>
          </a>
        </li>

        <li   class="list-group-item " style="cursor: default !important;"  >
          <a role="button" tabindex="0" class="media" >
              <span style="cursor: pointer" class="pull-left media-object media-icon">
                  <i class="fa fa-eye" ></i>
              </span>
            <div class="media-body">
              <span style="cursor: pointer" class="block">Notification 1 <sup  style="color: red"><br>Nouvelle</sup> </span>
              <br>
              <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
            </div>
          </a>
        </li>

        <li   class="list-group-item " style="cursor: default !important;"  >
          <a role="button" tabindex="0" class="media" >
              <span style="cursor: pointer" class="pull-left media-object media-icon">
                  <i class="fa fa-eye" ></i>
              </span>
            <div class="media-body">
              <span style="cursor: pointer" class="block">Notification 4 <sup  style="color: red"><br>Nouvelle</sup> </span>
              <br>
              <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
            </div>
          </a>
        </li>

        <li   class="list-group-item " style="cursor: default !important;"  >
          <a role="button" tabindex="0" class="media" >
              <span style="cursor: pointer" class="pull-left media-object media-icon">
                  <i class="fa fa-eye" ></i>
              </span>
            <div class="media-body">
              <span style="cursor: pointer" class="block">Notification 5 <sup  style="color: red"><br>Nouvelle</sup> </span>
              <br>
              <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
            </div>
          </a>
        </li>

        <li   class="list-group-item " style="cursor: default !important;"  >
          <a role="button" tabindex="0" class="media" >
              <span style="cursor: pointer" class="pull-left media-object media-icon">
                  <i class="fa fa-eye" ></i>
              </span>
            <div class="media-body">
              <span style="cursor: pointer" class="block">Notification 2 <sup  style="color: red"><br>Nouvelle</sup> </span>
              <br>
              <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
            </div>
          </a>
        </li>

        <li   class="list-group-item " style="cursor: default !important;"  >
          <a role="button" tabindex="0" class="media" >
              <span style="cursor: pointer" class="pull-left media-object media-icon">
                  <i class="fa fa-eye" ></i>
              </span>
            <div class="media-body">
              <span style="cursor: pointer" class="block">Notification 1 <sup  style="color: red"><br>Nouvelle</sup> </span>
              <br>
              <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
            </div>
          </a>
        </li>

        <li   class="list-group-item " style="cursor: default !important;"  >
          <a role="button" tabindex="0" class="media" >
              <span style="cursor: pointer" class="pull-left media-object media-icon">
                  <i class="fa fa-eye" ></i>
              </span>
            <div class="media-body">
              <span style="cursor: pointer" class="block">Notification 4 <sup  style="color: red"><br>Nouvelle</sup> </span>
              <br>
              <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
            </div>
          </a>
        </li>

        <li   class="list-group-item " style="cursor: default !important;"  >
          <a role="button" tabindex="0" class="media" >
              <span style="cursor: pointer" class="pull-left media-object media-icon">
                  <i class="fa fa-eye" ></i>
              </span>
            <div class="media-body">
              <span style="cursor: pointer" class="block">Notification 5 <sup  style="color: red"><br>Nouvelle</sup> </span>
              <br>
              <small class="text-muted" style="cursor: default !important;" >23 sept 2021 11:00:00 </small>
            </div>
          </a>
        </li>

      </ul>
    </div>


    <li class="nav-item dropdown" ngbDropdown id="user-icon-dropdown" placement="bottom right">
        <a class="nav-link"  href="#" role="button" id="dropdownBasic1" ngbDropdownToggle (click)="false">
          <img src="{{ user?.info?.avatar }}" *ngIf="user?.info?.avatar  != ''" style="width: 45px;height: 45px;" class="img-avatar"/>
          <img src="assets/img/Ellipse 1.png" *ngIf="user?.info?.avatar  == ''" style="width: 45px;height: 45px;" class="img-avatar"/>
        </a>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <div class="dropdown-header text-center"><strong>Compte</strong></div>
          <a class="dropdown-item" href="#"><i class="fa fa-envelope-o"></i> Messages<span class="badge badge-success">42</span></a>
          <div class="dropdown-header text-center"><strong>Paramètres</strong></div>
          <a class="dropdown-item" (click)="goToMe()"><i class="fa fa-user"></i> Profile</a>
          <a class="dropdown-item" (click)="logout()"><i class="fa fa-lock"></i> Déconnexion</a>
        </div>
    </li>

    <li class="nav-item d-md-down-none dropdown" (click)="goToMe()" dropdown placement="bottom right" style="margin-left:75px;margin-right: 17px;">
      <a class="icon-header" data-toggle="dropdown"  style="cursor: pointer;"  role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle>
        <img src="{{ user?.info?.avatar }}" *ngIf="user?.info?.avatar  != ''" style="width: 45px;height: 45px;" class="img-avatar"/>
        <img src="assets/img/Ellipse 1.png" *ngIf="user?.info?.avatar  == ''" style="width: 45px;height: 45px;" class="img-avatar"/>
      </a>
    </li>

    <li class="nav-item d-md-down-none dropdown" (click)="goToMe()" dropdown placement="bottom right">
      <a  href="javascript:void(0)">
        <ul style="list-style:none;text-align: left;color: #44a1a0;padding: 0px;">
          <li><b>{{ user?.info?.f_name || '--' | uppercase }}</b></li>
          <li>{{ user?.info?.l_name || '--' | uppercase }}</li>
        </ul>
      </a>
    </li>

    <li class="nav-item d-md-down-none" (click)="logout()" style="margin-left:50px;margin-right: 25px; cursor: pointer;">
     <img  src="assets/img/Icon awesome-sign-out-alt copie.svg"/>
    </li>
  </ul>

</app-header>


<section id="content" style="position: initial; padding-top: 150px;padding-right: 100px;padding-left: 100px;padding-bottom: 6rem;">
    <div class="page page-ui-widgets">

      <div class="col-md-12 col-sm-4">
            <div class="card">
              <div class="card-body text-center">
                <div class="h2 py-3"><span style="color: #5aacab;font-size: 22px;">{{__('default.bienvenu')}}</span></div>
              </div>
            </div>
      </div>

      <div class="row clearfix" style="margin: auto">
          <div class="col-6 col-lg-3 " *ngFor="let module of modules" (click)="goToModule('/app/module/'+module.code)">
            <div class="card card-module">
              <div class="card-body p-3 clearfix d-flex align-items-center">
                <i class="module-icon fa {{module.icon}} p-3 font-2xl mr-3 float-left" style="background-color: #5aacab;color: white;"></i>
                <div class="h5  mb-0 mt-2 title-module">{{module.name}}</div>
                <div class="text-muted text-uppercase font-weight-bold font-xs"></div>
              </div>
              <div class="card-footer px-3 py-2">
              </div>
            </div>
          </div>
      </div>
    </div>
</section>



<app-footer class="footer-module-layout">
  <span><a href="https://coreui.io"></a><small style="font-size: 0.8rem;
    font-weight: 300;"><b>Copyright &copy; 2022, Tous droits réservés.</b></small> </span>
  <span class="ml-auto"><img src="assets/img/mptdn.png" style="width: 280px;padding-right: 141px; font: normal normal medium 13px/16px Helvetica Neue;" class="img-avatar" alt="admin@bootstrapmaster.com"/></span>
</app-footer>
  <!--/ HOME Content -->