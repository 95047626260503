<div style="position: relative;left: -40px;">
  <br>
<div class="repMenu" style="padding: 0 85px;">
  <div class="row">
    <div class="breadcrumb flat w-100">
      <a>Profil</a>
    </div>
  </div>
</div> 

<hr>

  <div class="animated fadeIn me" >
   
    <div class="row">
      <div class="col-lg-7">
        <div class="card overflow-hidden">
          <div class="card-body border-card-green height-me"> 
            <div class="row">
              <div class="col-lg-5" style="text-align: center!important;">
                <span class="title-card-profil padding-50px  "><i class="fa fa-image"></i>Photo de profil</span>

                <br><br><br><br>
                
                <span class="imgUpload" >
                  <img  [src]="user.avatar"style="width 200px !important; max-height:200px;max-width: 260px;" >
                </span>
                <hr style="border: 1px solid #44a1a0;width: 150px;">
                <span 
                  class="imgAdd"
                  style="font-size: 17px;cursor: pointer;"
                  (click)="documentLogo.length <= 4 ? addImage(documentFileLogo) : false"  >
                  {{__('me.pdp')}} <i class="fa fa-plus" style="padding-left: 20px; font-size: 17px;cursor: pointer;"></i>
                </span> 
                <br><br>
                <button type="button" name="button" *ngIf="update == true" (click) ="updateImg()" style="float:none" class="btn mb-4 button-control"> {{__('tooltip.enreg')}}</button>            
                 <input accept="image/*" (change)="handleInputLogo($event)" #documentFileLogo style="display: none"  id="documentlogo" name="documentlogo" type="file" class="file" multiple > 
              </div>
              <div class="col-lg-7">
              
                    <!-- <div class="img-right"><img src="assets/img/Groupe 63.svg" class="height-400px"></div> -->
        
                    <div class="text-center">
                    <span class="title-card-profil padding-50px "><i class="fa fa-user"></i> {{__('profil.info_profil')}}</span>
                    </div>
                    <br><br><br>
                    <div class="row padding-50px" >
                        <div class="col-lg-4">
                          <div class="form-group ">
                              <label class="label-gras" for="exampleInputEmail1">{{__('profil.nom')}} :</label>
                            </div>
                        </div>
        
                        <div class="col-lg-8">
                            <div class="form-group text-end ">
                                <label for="exampleInputEmail1">{{ user?.l_name || '--' | uppercase }}</label>
                        </div>
                        </div>
                    </div>
        
        
                    <div class="row padding-50px" >
                        <div class="col-lg-4">
                          <div class="form-group">
                              <label class="label-gras"  for="exampleInputEmail1">{{__('profil.prenom')}} :</label>
                            </div>
                        </div>
        
                        <div class="col-lg-8">
                            <div class="form-group text-end ">
                                <label for="exampleInputEmail1">{{ user?.f_name || '--' | uppercase }}</label>
                        </div>
                        </div>
                    </div>
        
                    <div class="row padding-50px" >
                        <div class="col-lg-4">
                          <div class="form-group">
                              <label class="label-gras"  for="exampleInputEmail1">{{__('profil.email')}} :</label>
                            </div>
                        </div>
        
                        <div class="col-lg-8">
                            <div class="form-group text-end ">
                                <label for="exampleInputEmail1">{{ user?.email || '--' }}</label>
                        </div>
                        </div>
                    </div>
        
                    <div class="row padding-50px" >
                        <div class="col-lg-4">
                          <div class="form-group">
                              <label class="label-gras"  for="exampleInputEmail1">{{__('profil.telephone')}} :</label>
                            </div>
                        </div>
        
                        <div class="col-lg-8">
                            <div class="form-group text-end ">
                                <label for="exampleInputEmail1">{{ user?.phone || '--' }}</label>
                        </div>
                        </div>
                    </div>
        
                    <div class="row padding-50px" >
                        <div class="col-lg-4">
                          <div class="form-group">
                              <label class="label-gras"  for="exampleInputEmail1">{{__('profil.adresse')}} :</label>
                            </div>
                        </div>
        
                        <div class="col-lg-8">
                            <div class="form-group text-end ">
                                <label for="exampleInputEmail1">{{ user?.address || '--' }}</label>
                        </div>
                        </div>
                    </div>
        
                    <div class="row padding-50px" >
                        <div class="col-lg-4">
                          <div class="form-group">
                              <label class="label-gras"  for="exampleInputEmail1">{{__('profil.profil')}} :</label>
                            </div>
                        </div>
        
                        <div class="col-lg-8">
                            <div class="form-group text-end ">
                                <label for="exampleInputEmail1">{{ user?.profil?.name || '--' }} </label>
                        </div>
                        </div>
                    </div>
        
                    
          
                  
              </div>
           
            </div>
          </div>
        </div>
      </div>
  
      <div class="col-lg-5">

        <div class="card">
          <div class="card-body border-card-green height-me " >
            <div class="text-center">
            <span class="title-card-profil padding-50px "><i class="fa fa-unlock-alt"></i> {{__('profil.mdf_pdw')}}</span>
            </div>
            <br><br><br>
            <form class="px-3" role="form" ngNativeValidate (submit)="updateMdp()" [formGroup]="form">

              <div class="row d-flex flex-column" >
               
                
                    <div class="form-group d-flex flex-column">
                        <label for="exampleInputEmail1">{{__('me.ancien')}}</label>
                        <input formControlName="password_old" type="password" [(ngModel)]= "mdp" name="mdp" required class="form-control input-pswd" placeholder="{{__('me.placeholder_actu')}}" >        
                  </div>
                  <div class="form-group d-flex flex-column">
                      <label for="exampleInputEmail1">{{__('me.new')}}</label>
                      <input minlength="8" type="password" formControlName="password" [(ngModel)]="mdp_new" name="mdp_new" (input)="controlConfirm()" required class="form-control input-pswd" placeholder="{{__('me.placeholder_new')}}" >        
                  
                      <!-- control password -->
                      <div class="errors" *ngIf="form.get('password').invalid && (form.get('password').touched || form.get('password').dirty)">
                        <div class="text-danger pt-1" *ngIf="form.get('password').hasError('required')">
                          Le champ ne doit pas être vide
                        </div>
                        <div class="error-text text-danger pt-1" *ngIf="form.get('password').hasError('passwordStrength')">
                         {{form.get('password').errors['passwordStrength']}}
                        </div>
                        </div>
                        
                        
                      <!-- end control password -->
                  
                    </div>
                  <div class="form-group d-flex flex-column">
                      <label for="exampleInputEmail1">{{__('me.confirm')}}</label>
                      <input #confirm_pass [(ngModel)]="mdp_confirm_new" name="mdp_confirm_new" type="password" formControlName="confirm_pass" required class="form-control input-pswd" placeholder="{{__('me.placeholder_confirm')}}" (input)="controlConfirm()">
                      <span *ngIf="ok" class="glyphicon glyphicon-ok form-control-feedback"></span>     
                  </div>
                  
            
                
              </div>
              <div class="row d-flex justify-content-between" >
                  <button type="button" name="cancel" (click)= "setDefault()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>            
                  <button type="submit" name="button" [disabled]= "error" class="btn mb-4 button-control"> {{__('tooltip.enreg')}}</button>            
              </div>

            </form>

        
        </div>
      </div>
  
    </div>
    <!--/.row-->
  
  </div>
</div>






</div>