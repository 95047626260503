<br><div class="repMenu">
    <div class="row">
      <div class="col-lg-10"><span class="ariare" [routerLink]="['/app']" style="cursor: pointer;" >{{__('menu.accueil')}} </span>  > <span class="ariare" [routerLink]="['/app/module/PAC']" style="cursor: pointer;"  >{{__('menu.parametre')}} </span>  > <span class="ariare"> {{__('plateforme.title')}}</span></div>
        <div class="col-lg-2 div-add pt-2">
        </div>
    </div>
</div> 
<div class="row">
    <div class="col-lg-12">
      <br>
      <div class="card">
        <div class="card-body">
            <table datatable  [dtOptions]="dtOptions"  style="width: 100%" class="table table-striped table-hover " >
            <thead>
              <tr>
                <th class="text-left">{{__('plateforme.name')}}</th>
                <th class="text-left">{{__('plateforme.code')}}</th>
                <th class="text-left">{{__('plateforme.desc')}}</th>
                <th class="actionTab text-center">{{__('table.action')}}</th>
              </tr>
            </thead>
            <tbody *ngIf="plateformes?.length != 0" >
                <tr *ngFor="let plateforme of plateformes">
                <td class="text-left">{{ plateforme?.name }}</td>
                <td class="text-left">{{ plateforme?.code }}</td>
                <td class="text-left">{{ plateforme?.description }}</td>              
                <td class="text-center">
                    <i class="fa fa-edit icon-table" ngbTooltip="{{__('tooltip.edite')}}" #openModalBtnEdit (click)="openModal(addplateforme,plateforme,openModalBtnEdit)" ></i>
                    <i class="fa fa-toggle-on icon-table vert" ngbTooltip="{{__('tooltip.desactiver')}}" #buttonElement (click)="toggleState(plateforme,0,buttonElement)" *ngIf="plateforme.state == 1" ></i>
                    <i class="fa fa-toggle-off icon-table vert" ngbTooltip="{{__('tooltip.active')}}" #buttonElement (click)="toggleState(plateforme,1,buttonElement)" *ngIf="plateforme.state == 0" ></i>
                    <i class="fa fa-trash icon-table red" ngbTooltip="{{__('tooltip.delete')}}" #openModalBtnDelete (click)="delete(plateforme,openModalBtnDelete)"></i>
                </td>

              </tr>
            </tbody>
            <tbody *ngIf="plateformes?.length == 0">
            <tr>
              <td colspan="4" >{{__('label_no_data')}}</td>
            </tr>
          </tbody> 
          </table>
          
        </div>
      
      </div>
    </div>
  </div>
     
  <ng-template #addplateforme let-modal style="background-color: red; width: auto;">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{__('plateforme.btn_add')}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <form ngNativeValidate (ngSubmit)="addplateformeForm()">

        <div class="modal-body">
        <br>

        <div class="row">
            
          <div class="col-lg-4">
            <div class="form-group">
                <label >{{__('plateforme.code')}} (<span class="required-red" >{{__('label.require')}}</span>) :</label>
                <input type="text" required="true"class="form-control" name="code" id="code" [(ngModel)]="plateforme.code"  placeholder="{{__('plateforme.code')}}" >
            </div>
           
        </div>
        <div class="col-lg-4">
        
          <div class="form-group">
              <label >{{__('plateforme.name')}} (<span class="required-red" >{{__('label.require')}}</span>) :</label>
              <input type="text" required="true"class="form-control" name="name" id="name" [(ngModel)]="plateforme.name"  placeholder="{{__('plateforme.name')}}" >
          </div>
        
        </div>
        <div class="col-lg-4">
         
          <div class="form-group">
              <label >{{__('plateforme.desc')}} (<span class="required-red" >{{__('label.require')}}</span>) :</label>
              <input type="text" required="true"class="form-control" name="description" id="description" [(ngModel)]="plateforme.description"  placeholder="{{__('plateforme.desc')}}" >
          </div>
      </div>
      
        
        </div>

        <div class="row">
            <div class="col-lg-12">
                <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == true"  >{{__('tooltip.enreg')}}</button>            
                <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false" > {{__('tooltip.edite')}}</button>            
                <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>            
          </div>
         
          
        </div>


        </div>
    </form>
    
  </ng-template>





