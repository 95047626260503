import { Component, OnInit, TemplateRef } from '@angular/core';
import {valuesys} from '../../../../../../../options';
import {environment} from '../../../../../../../environments/environment';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AuthServiceService} from '../../../../../service/auth-service.service';
import {ToastrService} from 'ngx-toastr';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HelperService} from '../../../../../service/helper.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Translatable} from '../../../../../service/Translatable';
import {etablissement} from '../../../../../model/db';
import {journalbord} from '../../../../../model/pension';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-log-utilisateur',
  templateUrl: './log-utilisateur.component.html',
  styleUrls: ['./log-utilisateur.component.scss']
})
export class LogUtilisateurComponent extends Translatable implements OnInit {

  dtOptions: DataTables.Settings = {};
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  title:string ;
  documents: any [] = [];

  registerForm !: FormGroup;

  submitted    = false;
  currentEtabName:any=''
  currentTypeEtabName:any=''
  etabId:any=''
  endPOint:any=""

  filtre_etablissement_id:any=''

  filtreBase:boolean=false
  filtreBaseParametres:any=''
  superAdmin:any=""
  search:any=""
  filtreSelect:any= 10;
  tri : any= "";
  order : any= "";
  child : any= "";
  facID:any=""

  public journalbords :journalbord[] = [] ;
  public searchColumn: string;
  public column: string;
  public childCol: string;
  public journalbord:journalbord = new journalbord();
  public paginateListe : any = [];
  public nextPage : any = [];
  public prevPage : any = [];
  public lastPage : any = [];
  public firstPage : any = [];
  public currentPage : any = [];
  public total : any = [];
  public path : any = [];
  public lastNumPage : any = [];
  public infoData : any = [];
  public cPage_less_2 : number;
  public path_cPage_less_2 : any;
  public cPage_less_1 : number;
  public path_cPage_less_1 : any;
  public cPage_more_1 : number;
  public path_cPage_more_1 : any;
  public cPage_more_2 : number;
  public path_cPage_more_2 : any;
  public journal_bord : journalbord []



  public c_envoye:any = {}
  public etab_c_id:any
  public cat_c_id:any
  public prov_c_id:any


  searchInput : any= "";

  searchCol : any= "";

  public tab_concernee: any [] = [];

  savedDocument: any [] = [];

  documentLogo: any = [];

  public etablissements:etablissement [] = [];


  public ligne_parcourues: string = '';
  public ligne_traitees: string = '';
  public ligne_echouees: string = '';
  public reference_matricule_echec: [] = [];
  dateDebut: string = this.formatDateYMD(new Date());
  dateFin: string = this.formatDateYMD(new Date());
  loguser: any = {};
  constructor(
      private modalService: NgbModal,
      private auth : AuthServiceService,
      private toastr: ToastrService,
      private formBuilder: FormBuilder,
      private helper: HelperService,
      private router: Router,
      private http: HttpClient) {
    super()
  }

  async ngOnInit() {
    this.auth.initAutority("JRB");
    let userinfo = this.auth.account()
    this.etabId  = userinfo.info.etablissement_id;
    this.superAdmin = userinfo.info.admin;

    if(this.superAdmin==1 || this.etabId == 1){
      this.endPOint = environment.pension_journal_bord+"?page=1&date_debut="+this.dateDebut+"&date_fin="+this.dateFin;
    }
    else{
      this.filtre_etablissement_id=this.etabId
      this.getCurrentEtablissement(this.etabId)
    }
    this.getUrlDatatable(this.endPOint)
  };

  async getFiltreSelect(){
    this.getUrlDatatable(this.endPOint)
  }


  getCurrentEtablissement(etabId: any) {
    throw new Error('Method not implemented.');
  }

  async getSearchInput(){
    this.getUrlDatatable(environment.pension_journal_bord+"?page=1"+this.child)
  }

  async getUrlDatatable(url,col="", refElement?: any, child?: any, searchMulti?:any){
    let filtreDate;
    if(refElement){
      this.searchColumn = refElement.value
      this.column = col
      this.childCol = child
    }
    this.filtreBase=true
      if( this.dateDebut > this.dateFin ){
        this.toastr.warning(this.__('msg.dateDebut_dateFin_error'),this.__("msg.warning"));
        return;
      }else{
         filtreDate = "&date_debut="+this.dateDebut +"&date_fin="+this.dateFin;
      }
      this.search = filtreDate;
    if(searchMulti){
      if(this.superAdmin==1){
        if(this.filtre_etablissement_id!="")
          this.search = this.search+",etablissement.id|e|"+ this.filtre_etablissement_id ;
      }

      

    }else{
      console.log("COL :" +col)
      console.log("Search column :" +this.searchColumn)
      if(col != "" || this.searchColumn ){
        if(this.searchColumn !="" && this.searchColumn !="undefined"){
          if(this.superAdmin==1)
            this.search = "&where="+this.column+"|l|%"+this.searchColumn+"%";
          else
            this.search = ","+this.column+"|l|%"+this.searchColumn+"%";
        }else if(this.searchColumn == "") {
          this.search = "";
        }
      }else{

        if(col != "" || this.searchColumn ){
          if(this.searchColumn !="" && this.searchColumn !="undefined"){
            this.searchCol = "&where="+this.childCol+"."+this.column+"|l|"+this.searchColumn+"";
          }else if(this.searchColumn == "") {
            this.searchCol = "";
          }
        }else{
          if(this.searchInput !="" && this.searchInput !="undefined"){

            this.searchCol = "&where_or=journal_bord.action|l|%"+this.searchInput+",user.l_name|l|"+this.searchInput+",user.f_name|l|"+this.searchInput;

          }else if(this.searchInput == "") {
            this.searchCol = "";
          }
        }
      }

    }

    let filtre : any= "";
    if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
      filtre = "&per_page=" + this.filtreSelect;
    }

    let triage : any= "";
    if(this.tri !="" && this.tri !="undefined"){
      triage = "&__order__="+ this.order+","+ this.tri;
    }
    // console.log("search", this.search)
    let toogle = await this.http.get<any>(url+this.search+ this.searchCol +triage + filtre ,valuesys.httpAuthOptions()).toPromise();
    //console.log("*****VARIABLES FILTRES***"+  this.search)
    this.journal_bord = toogle.data.data;
    //console.log("*****insrcription_boursiers***", this.inscriptions)


    this.path = environment.pension_journal_bord;
    this.currentPage = toogle.data.current_page;
    this.firstPage =this.path+"?page=1";
    this.prevPage = this.path+"?page="+(this.currentPage-1) ;
    this.nextPage = this.path+"?page="+(this.currentPage+1);
    this.lastPage = this.path +"?page="+ toogle.data.last_page ;
    this.lastNumPage = toogle.data.last_page;
    this.total = toogle.data.total;


    console.log("*****VARIABLES End point ***"+  this.firstPage)


    this.cPage_less_2 = 0
    this.cPage_less_1 = 0
    if(this.currentPage > 1 ){

      if(this.currentPage > 2 ){
        this.cPage_less_2 = this.currentPage - 2
        this.path_cPage_less_2 = this.path +"?page="+this.cPage_less_2;
      }

      this.cPage_less_1 = this.currentPage - 1
      this.path_cPage_less_1 = this.path +"?page="+this.cPage_less_1;
    }


    this.cPage_more_1 = 0
    this.cPage_more_2 = 0
    if(this.currentPage < this.lastNumPage){
      this.cPage_more_1 = this.currentPage + 1
      this.path_cPage_more_1 = this.path +"?page="+this.cPage_more_1;

      if(this.currentPage < this.lastNumPage - 1 ){
        this.cPage_more_2 = this.currentPage + 2
        this.path_cPage_more_2 = this.path +"?page="+this.cPage_more_2;
      }

    }

    let info = this.__('datatable.info').replace('_START_', toogle.data.from)
    let info2 =info.replace('_END_', toogle.data.to)
    this.infoData = info2.replace('_TOTAL_', toogle.data.total)

  }


  delete(deletedConcerner:journalbord,elm){
    console.log("ID", deletedConcerner.id);
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.pension_journal_bord+"/" + deletedConcerner.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          /*this.journalbords= this.journalbords.filter(function (item,index) {
            return item.id != deletedConcerner.id
          }*/
          this.getUrlDatatable(this.endPOint);
          Swal.fire(
              {
                icon: 'success',
                title: this.__('msg.deleted'),
                confirmButtonColor: '#44A1A0',
                text: sup.msg,
              }
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
            {
              icon: 'error',
              title: this.__('msg.canceled'),
              confirmButtonColor: '#44A1A0',
              text: this.__('msg.canceled_delete'),
            }
        )
      }
    })
  }
  async exportLogToExcel() {

    this.helper.exportExcel(this.printPaiement(this.journal_bord),'Historiques utilisateurs connéctés').then(
      (response: any)=>{
        console.log('respons beee',response)
            let a = document.createElement("a"); 
            a.href = response.data;
            a.download = `hitoriques_utilisateurs_connecté.xlsx`;
            a.click(); 
      },
      (error:any)=>{
        console.log(error)
      }
    );
  }

  printPaiement(ordre_paiement:any[]){
    return  ordre_paiement.map((ordre_paiement)=>{
      let t = {}
      t['Action'] = ordre_paiement.action
      t['Nom'] = ordre_paiement.f_name
      t['Prénom'] = ordre_paiement.l_name
      t['Date'] = ordre_paiement.date
      return t ;
    })  || [];

  }
  async exportPdf() {
    await this.helper.exportPdf(this.printPaiement(this.journal_bord),'Historiques utilisateurs connéctés','','hitoriques_utilisateurs_connecté');
  }
formatDateYMD(date: Date) {
  return date.toISOString().split('T')[0];
}

openModalDetails(template: TemplateRef<any>, currentLog: any) {
 
  this.titleModal = this.__('concerner.modifier');
  this.loguser = currentLog;
  this.modalService.open(template, {size: 'lg', backdrop: 'static'});
}
close(){
  this.modalService.dismissAll();
  this.loguser = {};
  
}
async triTable(col, order){
  this.tri = col;
  this.order = order;
  this.getUrlDatatable(environment.pension_journal_bord+"?page=1")
}



// typeof yourVariable === 'object' && yourVariable !== null

}
// &date_debut=2022-11-04&date_fin=2022-11-04
