<br><div class="repMenu">
    <div class="row  d-flex  justify-content-between">     
        <div class="breadcrumb flat">
            <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
            <a [routerLink]="['/app/module/CAR']"  routerLinkActive="active">{{__('menu.carte')}}</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/carte']" routerLinkActive="active" *ngIf="autority('GCA_1')">{{__('carte.title')}}</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/listeCarteParMinistere']"  routerLinkActive="active" *ngIf="autority('DCA_1')">Disponibiliter des cartes</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/affecterCarte']"  routerLinkActive="active" *ngIf="autority('AFC_1')">Affecter carte</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/ActiverDesactive']"  routerLinkActive="active" *ngIf="autority('ACD_1')">Activer / Desactiver carte</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/EnrolerCarte']"  routerLinkActive="active" *ngIf="autority('ENC_1')">Enroler carte</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/planifier_creation_compte']"  routerLinkActive="active" *ngIf="autority('CRC_1')">Création de compte</a>
            <a [routerLink]="['/dashboard/CAR/plateforme/demandeenrolement']"  routerLinkActive="active" *ngIf="autority('DEN_1')">Demande enrolement</a>
             <a [routerLink]="['/dashboard/CAR/plateforme/demandewallet']"  routerLinkActive="active" *ngIf="autority('DEW_1')">Demande création compte</a>

        </div>
    

     
</div>
</div> 
<div *ngIf="autority('AFC_3')"  class="bg-white p-2  repMenu mt-2 d-flex justify-content-end align-items-center">
   <div class="d-flex align-items-center">
    <a
    #canvaAffectation
    [href]="sourcePath"
    [download]="'modele_import_affectation_carte.xlsx'"
    [attr.disabled]="dwld.busy"
    #dwld="wmDownload"
    class="d-none"
   
  >
    download
  </a>
  <label class="custom-file-upload ml-4 mb-0" (click)="downloadCanva(canvaAffectation)">
  
  
  <i class="fa fa-download"></i>{{__("ordre_paiement.download_fichier")}}
  </label>
<button type="button" class="button-control ng-star-inserted" (click)="openModalImportExcel(addImportExcel)"><i class="fa fa-file-excel-o"></i> Affectation multiple</button>
        
   </div>
    
</div>
<div class="bg-white my-3 py-3">
    <form class="row d-flex justify-content-around" ngNativeValidate (ngSubmit)="getBeneficiaireByMatricule()">
        <div class="col-md-4" *ngIf="superAdmin == 1">
            <div class="form-group">
                <label >{{__('concerner.etablissement_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                    <span class="required-red" *ngIf="!currentEtabId && requiredError">/ Ce champ est obligatoire</span>
                </label>
                <ng-select placeholder="{{__('concerner.etablissement_id')}}"
                aria-readonly=""
                    [(ngModel)]="currentEtabId" 
                    class="form-control"
                    [readonly]="superAdmin != 1 && activeEtabId != 1"
                    reqiured
                    >                                
                    <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label for="etablissment">Numero matricule</label>
                <input   class="form-control" [(ngModel)]="matricule" type="text" #element_matricul (input)="controleMatricule(element_matricul)" name="matricule" id="" required>
            </div>
        </div>
        <div class="col-md-12 d-flex justify-content-center">
            <button type="submit" class="button-control ng-star-inserted" style="float:none">Rechercher </button>
    
        </div>
    </form>
    <div class="container mt-2"  >
        <hr>
        <div class="alert alert-danger text-center" *ngIf="concernerList.length == 0 && submitted && step2">
            <i class="fa fa-times-circle text-danger"></i>
            Ce bénéficiaire ne se trouve pas dans  {{ superAdmin == 1 ? "le ministère séléctionné" : "votre ministère" }}
        </div>
        <div class="alert alert-danger text-center" *ngIf="beneficaireExistInEtab == false && submitted">
            <i class="fa fa-times-circle text-danger"></i>
            Numéro matricule introuvable
        </div>
    </div>
    <div class="container" *ngIf="concernerList.length != 0 && submitted && !concerner.carte_numero_serie">
        <fieldset class="border p-2">
            <legend  class="w-auto">Inforamtions du matricule {{matricule}}</legend>
            <div>
                <table class="w-100">
                    <tr>
                        <td>Ministère: </td>
                        <td class="text-muted">{{ concerner?.etablissement_name }}</td>
                        <td>N° matricule: </td>
                        <td class="text-muted">{{ concerner?.matricule }}</td>
                    </tr>
                    <tr>
                        <td>Catégorie: </td>
                        <td class="text-muted">{{ concerner?.categorie }}</td>
                        <td>Sexe: </td>
                        <td class="text-muted">{{ concerner?.sexe }}</td>
                    </tr>
                    <tr>
                        <td>Date de naissance: </td>
                        <td class="text-muted">{{ concerner?.date_naissance }}</td>
                        <td>Type de pièce: </td>
                        <td class="text-muted">
                            {{concerner?.type_piece == 0 ? 'CIN' : 'Passeport' }}
                        </td>
                    </tr>
                    <tr>
                        <td>Téléphone: </td>
                        <td class="text-muted">{{ concerner?.telephone }}</td>
                        <td>Email: </td>
                        <td class="text-muted">
                            {{concerner?.email}}
                        </td>
                    </tr>
                    <tr>
                        <td>Adresse: </td>
                        <td class="text-muted">{{ concerner?.adresse }}</td>
                        <td>code postal: </td>
                        <td class="text-muted">
                            {{concerner?.code_postal }}
                        </td>
                    </tr>
                </table>
            </div>
         </fieldset>
         <div class="col-md-12 mt-2 d-flex justify-content-center">
            <button type="button"  *ngIf="!searchCarteActive" (click)="activePanelSearchCarte()" class="button-control ng-star-inserted" style="float:none">Rechercher la carte</button>
         </div>
    </div>
    <div class="container" *ngIf="concernerList.length != 0 && submitted && concerner.carte_numero_serie" style="text-align: center;">
        <hr>
        <div class="alert alert-danger">
            <i class="fa fa-times-circle text-danger"></i>
            Ce bénéficiare a déjà une carte avec le numéro série {{concerner.carte_numero_serie}}
        </div>
    </div>
    
    <div class="container" *ngIf="searchCarteActive">
        <form  (ngSubmit)="searchCarte()" class="card" ngNativeValidate> 
            <div class="card-body">
          <div class="row d-flex flex-column align-items-center">

              <div class="col-lg-6" *ngIf="superAdmin == 1">
                  <div class="form-group">
                      <label >{{__('concerner.etablissement_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                          <span class="required-red" *ngIf="!etablissementId">/ Ce champ est obligatoire</span>
                        <!-- <span *ngIf="submitted && f.etablissement_id.errors" class="invalid-form-feedback" >                      
                          <span *ngIf="f.etablissement_id.errors.required"> / Ce champ est obligatoire</span>
                        </span> -->
                      </label>
                      <ng-select placeholder="{{__('concerner.etablissement_id')}}"
                      aria-readonly=""
                          [(ngModel)]="etablissementId" 
                          class="form-control"
                          [readonly]="true"
                          >
                          <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
                      </ng-select>
        
                     <!--  <input formControlName="etablissement_id"  type="number" class="form-control" [(ngModel)]="concerner.etablissement_id" id="etablissement_id" placeholder="{{__('concerner.etablissement_id')}}" > -->
                  </div>
                </div>
              <div class="col-md-6 form-group ">
                  <label for="">Numero de serie</label>
                  <input required  type="number" min="0" [(ngModel)]="numero_serie" class="form-control" name="numero_serie"  id="">
                  <div class="w-100 d-flex justify-content-center">
                      <button type="submit" style="height: 40px;" class="button-control ng-star-inserted mt-2">Rechercher</button>
                      <!-- <button (click)="searchCarte()">test</button> -->
                  </div>
              </div>
          </div>
          <!-- <hr> -->
          <!-- <div *ngIf="carteArray.length == 0 && searchNumSerieSubmitted"style="text-align: center;">
              <div class="row d-flex flex-column align-items-center" style="text-align:center">
                  <div class="col-12">
                      <img src="assets/img/empty-set.png" style="width: 5%;" alt="">
                   
                      <h3 class="mt-2">Aucune carte correspondant</h3>
                  </div>
              </div>
          </div> -->

          <div class="container mt-2"  >
            <hr>
            <div class="alert alert-danger text-center" *ngIf="carteArray.length == 0 && searchNumSerieSubmitted && step2Carte">
                <i class="fa fa-times-circle text-danger"></i>
                Ce carte n'appartient pas dans  {{ superAdmin == 1 ? "le ministère séléctionné" : "votre ministère" }}
            </div>
            <div class="alert alert-danger text-center " *ngIf="numeroSerieExistInEtab == false && searchNumSerieSubmitted">
                <i class="fa fa-times-circle text-danger"></i>
                {{__('affect.carte_not_found')}}
            </div>
        </div>
          
          <div *ngIf="carteArray.length != 0 && searchNumSerieSubmitted &&  carteArray[0].affected == 0" style="text-align: center;">
              <div class="row" style="text-align:center">
                  <div class="col-12">
                      <i class="fa fa-credit-card" style="font-size:4rem;color: #45a1a1;"></i>
                      <h3>{{__('affect.info_carte')}}</h3>
                  </div>
              </div>
              <hr>
              <div class="row">
                  <div class="col-lg-3">
                      <h6 class="title-info">Numéro de série</h6>
                      <p class="data-info">{{ carteArray[0]?.numero_serie }}</p>
                  </div>
                  <div class="col-lg-3">
                      <h6 class="title-info">Niveau</h6>
                      <p class="data-info">{{ carteArray[0]?.niveau}}</p>
                  </div>
                  <div class="col-lg-3">
                      <h6 class="title-info">Ministère</h6>
                      <p class="data-info">{{ carteArray[0]?.name}}</p>
                  
                  </div>
                  <div class="col-lg-3">
                      <h6 class="title-info">Situation</h6>
                      <p class="data-info">{{ situationCarte(carteArray[0]?.situation)}}</p>
                  
                  </div>
              </div>
              <hr>               
              <div class="row d-flex justify-content-center" >
                  <button style="height: 40px;" type="button" class="button-control ng-star-inserted" (click)="confirmAffectation(carteArray[0]?.numero_serie)">{{__('concerner.affecte')}}</button>

              </div>
             </div>
             <hr>
             <div *ngIf="carteArray.length != 0 && searchNumSerieSubmitted &&  carteArray[0].affected == 1" style="text-align: center;">
                <div class="alert alert-danger">
                    <i class="fa fa-times-circle text-danger"></i>
                    Carte déjà affecté au bénéficiaire {{ carteArray[0]?.prenom}} {{ carteArray[0]?.nom}} avec matricule {{ carteArray[0]?.matricule }}
                </div>
               </div>

            </div>
          </form>
        
    </div>
</div>


<!-- import -->
<ng-template #addImportExcel style="background-color: red; width: auto;">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Affectation multiple</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">x</span>
      </button>
    </div>
  
    <form  >
  
      <div class="modal-body">
        <div class="row d-flex flex-column align-items-center">
            <div class="col-lg-6 text-center">
                NB: <span class="text-muted">Le fichier doit avoir une extension xlsx</span>
            </div>
            <div class="col-lg-6">
                <label >
                  <!-- <span *ngIf="submitted && f_import.photo.errors" class="invalid-form-feedback">
                      <i *ngIf="f_import.photo.errors.required"></i>
                  </span> -->
                </label>
                <br>
                <input 
                (change)="handleInputChange($event,modelExcel)" 
                #documentExcel 
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                name="document" 
                type="file" 
                >
                <label for="file-upload" class="custom-file-upload w-100" (click)="importExcel(documentExcel)">
                  <i class="fa fa-cloud-upload"></i> <span class="ml-2">{{filename != "" ? filename : "Importer un fichier excel"}}</span>
              </label>
            </div>
           
            
        </div>
   
    
        <div class="row mt-5">
          <div class="col-lg-12 d-flex justify-content-center">
          
           <button type="button" [disabled]="!isValid" (click)="getRacpExcel()" name="button" class="btn mb-4 button-control"  >Valider</button>   
  
            <!-- <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>        -->
  
          </div>
        </div>
  
        <hr>
        <div class="container"  *ngIf="validatedExcel && responseRecap?.ligne_parcourue == 0">
            <div class="alert alert-warning">
                <i class="fa fa-warning"></i> {{__('affect.empty_doc')}}
            </div>
        </div>
        <div class="row" *ngIf=" validatedExcel && responseRecap?.ligne_parcourue != 0 && (readyToInsertList?.length != 0 || haveErrorList?.length != 0)">
            <hr>
                <h4 class="text-center">Tableau recapitulatif</h4>
            <hr>
            <div class="col-md-12" *ngIf="validatedExcel && readyToInsertList?.length != 0">
                <div>
                    <hr>
                        <h5 class="text-center"> {{__('affect.ready_insert')}}</h5>
                    <hr>

                </div>
                <table datatable [dtOptions]="dtOptions[0]" class="row-border hover">
                    <thead class="header-table">
                      <tr>
                        <th>Matricule</th>
                        <th>Numero serie</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of readyToInsertList">
                        <td>{{item.matricule}}</td>
                        <td>{{item.numero_serie}}</td>
                      </tr>
                      
                    </tbody>
                  </table>
                  <hr class="mt-2">
                  <div class="row d-flex justify-content-end mt-3">
                    <button type="button" (click)="submitAffectationExcel()" name="button" class="btn mb-4 button-control"  >{{__('affect.valid_affect')}}</button>
                  </div>
                  
            </div>
            <div class="col-md-12" *ngIf="validatedExcel && haveErrorList?.length != 0 || readyToInsertList.length == 0">
                <div>
                    <hr>
                    <h5 class="text-center">{{__('affect.error_line')}}</h5>
                    <hr>
                </div>
                <table datatable [dtOptions]="dtOptions[1]"  class="row-border hover">
                    <thead class="header-table">
                      <tr>
                        <th>Matricule</th>
                        <th>Numero serie</th>
                        <th>Message d'erreur</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let itemError of haveErrorList">
                        <td>{{itemError.matricule}}</td>
                        <td>{{itemError.numero_serie}}</td>
                        <td class="text-danger">{{itemError.error_message}}</td>
                      </tr>
                      
                    </tbody>
                </table>
                  
            </div>
        </div>
  
  
      </div>
  </form>
    
  </ng-template> 

