import {HttpClient} from '@angular/common/http';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataTableDirective} from 'angular-datatables';
import {ToastrService} from 'ngx-toastr';
import Swal from 'sweetalert2';
import {environment} from '../../../../../../../environments/environment';
import {valuesys} from '../../../../../../../options';
import { etablissement, parcours, province, niveau, InsertBoursierConcerner } from '../../../../../model/db';
import {categorie, concerner} from '../../../../../model/pension';
import {AuthServiceService} from '../../../../../service/auth-service.service';
import {HelperService} from '../../../../../service/helper.service';
import {Translatable} from '../../../../../service/Translatable';
import {RouterModule, Router} from '@angular/router';
import {setInputFilter} from '../../../../../helpers/validators/validation-type';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
    selector: 'app-concerner',
    templateUrl: './concerner.component.html',
    styleUrls: ['./concerner.component.scss'],
})
export class ConcernerComponent extends Translatable implements OnInit,AfterViewInit {
    type_pieces = [
        {
            id: 0,
            type: 'CIN'
        },
        {
            id: 1,
            type: 'Passeport'
        }
    ];

    types = [

        {
            id: '',
            typ: 'Pas de carte'
        },
        {
            id: 3,
            typ: 'Personnalisée'
        },
        {
            id: 4,
            typ: 'Enrôlée'
        }
    ];

    compte = [

        {
            id:-1,
            comptes: 'Pas de compte'
        },
        {
            id:'0',
            comptes: 'Poasitra Money'
        },
        {
            id:1,
            comptes:'E-Poketra'
        }
    ];
    etablissement_id: number;
    categorie_concernees_id: number;
    situation_cart_id: string;
    type_compte_id: number;
    dtOptions: DataTables.Settings = {};
    titleModal: string;
    isAdd: boolean = true;
    isValid: boolean = false;
    title: string;
    tabUE = Array();
    concernerArray: parcours[] = [];
    documents: any [] = [];
    registerForm !: FormGroup;
    registerFormImporExcel !: FormGroup;
    registerFormEtudiantBoursier !: FormGroup;
    submitted = false;
    currentEtabName: any = '';
    currentTypeEtabName: any = '';
    etabId: any = '';
    etab: any = '';
    endPOint: any = '';
    filtre_etablissement_id: any = '';
    filtreBase: boolean = false;
    filtreBaseParametres: any = '';
    superAdmin: any = '';
    search: any = '';
    filtreSelect: any = 10;
    tri: any = '';
    order: any = '';
    child: any = '';
    facID: any = '';
    tabbenefshow: boolean = false;
    disableEtabInput: boolean = false;
    disableCatInput: boolean = false;
    disableExcelInput: boolean = false;
    totalparcourue: number = 0;
    totalinvalid: number = 0;
    totalvalid: number = 0;
    public searchColumn: string;
    public column: string;
    public childCol: string;
    public paginateListe: any = [];
    public nextPage: any = [];
    public prevPage: any = [];
    public lastPage: any = [];
    public firstPage: any = [];
    public currentPage: any = [];
    public total: any = [];
    public path: any = [];
    public lastNumPage: any = [];
    public infoData: any = [];
    public cPage_less_2: number;
    public path_cPage_less_2: any;
    public cPage_less_1: number;
    public path_cPage_less_1: any;
    public cPage_more_1: number;
    public path_cPage_more_1: any;
    public cPage_more_2: number;
    public path_cPage_more_2: any;
    public concerners: concerner[] = [];
    public concerner: concerner = new concerner();
    public concerner_error: concerner;
    public c_envoye: any = {};
    public etab_c_id: any;
    public cat_c_id: any;
    public beneficiaire_verif: any[] = [];
    etab_id: number;
    cat_id: number;
    searchInput: any = '';
    searchCol: any = '';
    tab_concernee: any [] = [];
    savedDocument: any [] = [];
    documentLogo: any = [];
    public etablissements: etablissement [] = [];
    public provinces: province [] = [];
    public categories: categorie [] = [];
    public ligne_parcourues: string = '';
    public ligne_traitees: string = '';
    public ligne_echouees: string = '';
    public reference_matricule_echec: [] = [];
    public name_etablissement_selected: string = '';
    public name_categorie_selected: string = '';
    public name_province_selected: string = '';
    profileImage: any = '';
    filename: string = '';
    lengthPiece: number;
    matrculeLengthError: boolean = false;
    pieceLengthError: boolean = false;
    isFocused: boolean = false;
    sourcePath = '';
    activeEtabId: number;
    filterInsertList = [
        {
            id: 1,
            text: 'Existant dans la base'
        },
        {
            id: 2,
            text: 'Numéro téléphone existant'
        },
        {
            id: 3,
            text: 'Pas de matricule'
        },
        {
            id: 4,
            text: 'Lignes invalides'
        },
        {
            id: 5,
            text: 'Lignes valides'
        },
        {
            id: 6,
            text: 'Toutes les lignes'
        },
        {
            id: 7,
            text: 'Téléphone doublons'
        },
    ]
    allBV: any[] = [];
    isImported: boolean = false;
    niveauList: niveau[] = [];
    niveau_id: number;
    filtreName: string = '';
    insertBoursierConcerner: InsertBoursierConcerner = new InsertBoursierConcerner();
    @ViewChild('selectDropdownAddConcerner') ngSelectComponentAdd: NgSelectComponent;
    constructor(
        private modalService: NgbModal,
        private auth: AuthServiceService,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private helper: HelperService,
        private router: Router,
        private http: HttpClient) {
        super();
    }
    ngAfterViewInit(): void {
        console.log(this.ngSelectComponentAdd,'afeter view')
    }
    async ngOnInit() {
        this.auth.initAutority('BNF');
        this.registerForm = this.formBuilder.group({
            etablissement_id: ['', Validators.required],
            categorie_concerne_id: ['', Validators.required],
            type_piece: ['', Validators.required],
            sexe: ['', Validators.required],
            nom: ['', Validators.required],
            prenom: ['', Validators.required],
            matricule: ['', [Validators.required]],
            numero_piece: ['', Validators.required],
            date_livrance: ['', Validators.required],
            telephone: [''],
            email: ['', [Validators.required, Validators.email]],
            adresse: [''],
            date_naissance: ['', Validators.required],
            code_postal: [''],
        });
        this.registerFormImporExcel = this.formBuilder.group({
            etab_id: ['', Validators.required],
            cat_id: ['', Validators.required],
            fichier: ['', Validators.required]
        });

        this.registerFormEtudiantBoursier = this.formBuilder.group({
            etab_id: ['', Validators.required],
            niveau_id: ['', Validators.required]
        });
        this.etablissements = await this.helper.getSelectList(environment.etablissement, ['name']);
        let userinfo = this.auth.account();
        this.etabId = userinfo.info.etablissement_id;
        this.activeEtabId = userinfo.info.etablissement_id;

        this.superAdmin = userinfo.info.admin;
        if (this.superAdmin == 1) {
            this.endPOint = environment.pension_concerners + '?page=1';
        } else {
            this.etablissement_id = this.etabId;
            console.log('TST PCR :', this.etablissement_id);
            this.filtre_etablissement_id = this.etabId;
            this.endPOint = environment.pension_concerners + '?page=1&where=concernees.etablissement_id|e|' + this.etabId;
            let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+this.etabId, valuesys.httpAuthOptions()).toPromise();
            if(res.code == 200) {
                this.categories = res.data
            }
        }
        this.getUrlDatatable(this.endPOint);
    }

    changeTypePiece(typePiece: number) {
        if (typePiece == 0) {
            this.lengthPiece = 12;
        } else if (typePiece == 1) {
            this.lengthPiece = 9;
        } else {
            typePiece = null;
        }
    }

    get f() {
        return this.registerForm.controls;
    }
    get f_import() {
        return this.registerFormImporExcel.controls;
    }
    get f_boursier_etudiant() {
        return this.registerFormEtudiantBoursier.controls;
    }
    getCurrentEtablissement(etabId: any) {
        throw new Error('Method not implemented.');
    }
    async getSearchInput() {
        this.getUrlDatatable(environment.pension_concerners + '?page=1' + this.child);
    }
    async getFiltreSelect() {
        this.getUrlDatatable(this.endPOint);
    }

    filtreFormUrl() : String
    {
        let url : String = "";
        this.isValid = true;
        let id_etab = this.etablissement_id;
        let id_cat = this.categorie_concernees_id;
        let id_sit = this.situation_cart_id;
        let id_compte = this.type_compte_id;
        if (!id_etab && !id_cat && id_sit && !id_compte == undefined) {
            
            if (this.superAdmin == 1) {
                url = '';
            } else {
                this.etablissement_id = this.etabId;
                this.filtre_etablissement_id = this.etabId;
                url = 'concernees.etablissement_id|e|' + this.etabId;
            }
        }else{
            let path = '';
            if (id_etab) {
                path += 'concernees.etablissement_id|e|'+id_etab+',';
            }
            if (id_cat) {
                path += 'concernees.categorie_concerne_id|e|' +id_cat+ ',';
            }
            if (id_compte) {
                path += 'concernees.type_compte|e|'+id_compte+',';
            }

            if (id_sit != '' && id_sit != undefined) {
                path += 'carte.situation|e|' + id_sit + ',';
            }

            url = path;
        }
        return url;
    }

    async addSelectForm() {
        this.isValid = true;
        let id_etab = this.etablissement_id;
        let id_cat = this.categorie_concernees_id;
        let id_sit = this.situation_cart_id;
        let id_compte = this.type_compte_id;
        console.log(id_sit == '', 'kkkkkkk', id_sit);

        if (!id_etab && !id_cat && id_sit && !id_compte == undefined) {
            console.log('!id_etab && !id_cat && id_sit != ""');
            if (this.superAdmin == 1) {
                this.endPOint = environment.pension_concerners + '?page=1';
            } else {
                this.etablissement_id = this.etabId;
                this.filtre_etablissement_id = this.etabId;
                this.endPOint = environment.pension_concerners + '?page=1&where=concernees.etablissement_id|e|' + this.etabId;
            }

            this.getUrlDatatable(this.endPOint);
            return;
        }

        let path = '';
        if (id_etab) {
            console.log('etablissment', id_etab);
            path += 'concernees.etablissement_id|e|'+id_etab+',';
        }
        if (id_cat) {
            path += 'concernees.categorie_concerne_id|e|' +id_cat+ ',';
        }
        if (id_compte) {
            path += 'concernees.type_compte|e|'+id_compte+',';
        }
        if (id_sit != '' && id_sit != undefined) {
            path += 'carte.situation|e|' + id_sit+',';
        }
        
        this.endPOint = environment.pension_concerners + '?page=1&where=' + path;
        console.log(this.endPOint);
        this.getUrlDatatable(this.endPOint);
        this.submitted = true;

    }

    async getUrlDatatable(url ,col = '', refElement?: any, child?: any, searchMulti?: any) {
        
        /** VERIFICATION DES FILTRES SI CE NE SONT PAS NULS */

        if (refElement) {
            this.searchColumn = refElement.value;
            this.column = col;
            this.childCol = child;
        }
        if (searchMulti) {
            
            if (this.superAdmin == 1) {
                
                if (this.filtre_etablissement_id != '') {
                    this.search = this.search + ',etablissement.id|e|' + this.filtre_etablissement_id;
                }
            }
            this.filtreBase = true;

        }else{
        console.log("COL :" +col)
        console.log("Search column :" +this.searchColumn)
        if(col != "" || this.searchColumn ){
          if(this.searchColumn !="" && this.searchColumn !="undefined"){
            if(this.superAdmin==1)
            this.search = "&where="+this.column+"|l|"+this.searchColumn+"";
            else
            this.search = ","+this.column+"|l|"+this.searchColumn+"";
          }else if(this.searchColumn == "") {
            this.search = "";
          }
        }else{
            console.log("xxxxx 1111")
          if(col != "" || this.searchColumn ){
            console.log("xxxxx 2")
            if(this.searchColumn !="" && this.searchColumn !="undefined"){
                console.log("xxxxx 333")
              this.searchCol = "&where="+this.childCol+"."+this.column+"|l|"+this.searchColumn+"";
            }else if(this.searchColumn == "") {
                console.log("xxxxx 4444")
              this.searchCol = "";
            }
          }else{
            if(this.searchInput !="" && this.searchInput !="undefined"){
                console.log("xxxxxxxxxxxMMMMMMMMMM")
              this.searchCol = "&where_or=concernees.matricule|l|"+this.searchInput+",concernees.nom|l|"+this.searchInput+",concernees.prenom|l|"+this.searchInput+",concernees.num_compte|l|"+this.searchInput;

            }else if(this.searchInput == "") {
                console.log("xxxxx 55555")
              this.searchCol = "";
            }
          }
        }
  
      }
      
      let id_etab = this.etablissement_id;
        let id_cat = this.categorie_concernees_id;
        let id_sit = this.situation_cart_id;
        let id_compte = this.type_compte_id;
        console.log(id_sit == '', 'kkkkkkk', id_sit);

        // if (!id_etab && !id_cat && id_sit && !id_compte == undefined) {
        //     console.log('!id_etab && !id_cat && id_sit != ""');
        //     if (this.superAdmin == 1) {
        //         this.endPOint = environment.pension_concerners + '?page=1';
        //     } else {
        //         this.etablissement_id = this.etabId;
        //         this.filtre_etablissement_id = this.etabId;
        //         this.endPOint = environment.pension_concerners + '?page=1&where=concernees.etablissement_id|e|' + this.etabId;
        //     }

        
        // }

        let path = '';
        if (id_etab) {
            console.log('etablissment', id_etab);
            path += 'concernees.etablissement_id|e|'+id_etab+',';
        }
        if (id_cat) {
            path += 'concernees.categorie_concerne_id|e|' +id_cat+ ',';
        }
        if (id_compte) {
            path += 'concernees.type_compte|e|'+id_compte+',';
        }
        if (id_sit != '' && id_sit != undefined) {
            path += 'carte.situation|e|' + id_sit+',';
        }

        this.searchCol +=path; 
        
        let filtre: any = '';
        if (this.filtreSelect != '' && this.filtreSelect != 'undefined') {
            filtre = '&per_page=' + this.filtreSelect;
        }

        let triage: any = '';
        if (this.tri != '' && this.tri != 'undefined') {
            triage = '&__order__=' + this.order + ',' + this.tri;
        }

        console.log(this.search,' ===>search')
        console.log(this.searchCol,' ===>searchCl')
        console.log(triage,' ===>triage')
        console.log(filtre,' ===>filtre')
        this.searchCol +="&where="+path;
        if(col != "" || this.searchColumn ){
            this.searchCol = "";
          }
    
        /* IMPORTANT POUR LA PAGINATION */
        console.log(url + this.search + this.searchCol + triage + filtre);
        let toogle = await this.http.get<any>(url + this.search + this.searchCol + triage + filtre, valuesys.httpAuthOptions()).toPromise();
        this.concernerArray = toogle.data.data;
        this.path = environment.pension_concerners;
        this.currentPage = toogle.data.current_page;
        this.firstPage = this.path + '?page=1';
        this.prevPage = this.path + '?page=' + (this.currentPage - 1);
        this.nextPage = this.path + '?page=' + (this.currentPage + 1);
        this.lastPage = this.path + '?page=' + toogle.data.last_page;
        
        this.lastNumPage = toogle.data.last_page;
        this.total = toogle.data.total;
        this.cPage_less_2 = 0;
        this.cPage_less_1 = 0;
        if (this.currentPage > 1) {
            if (this.currentPage > 2) {
                this.cPage_less_2 = this.currentPage - 2;
                this.path_cPage_less_2 = this.path + '?page=' + this.cPage_less_2;
            }
            this.cPage_less_1 = this.currentPage - 1;
            this.path_cPage_less_1 = this.path + '?page=' + this.cPage_less_1;
        }
        this.cPage_more_1 = 0;
        this.cPage_more_2 = 0;
        if (this.currentPage < this.lastNumPage) {
            this.cPage_more_1 = this.currentPage + 1;
            this.path_cPage_more_1 = this.path + '?page=' + this.cPage_more_1;
            if (this.currentPage < this.lastNumPage - 1) {
                this.cPage_more_2 = this.currentPage + 2;
                this.path_cPage_more_2 = this.path + '?page=' + this.cPage_more_2;
            }

        }
        let info = this.__('datatable.info').replace('_START_', toogle.data.from);
        let info2 = info.replace('_END_', toogle.data.to);
        this.infoData = info2.replace('_TOTAL_', toogle.data.total);
    }

    controleMatricule(matricule) {
        this.concerner.matricule = matricule.value;

    }
    controleNumero_piece(element) {
        let x = '';
        if (this.concerner.type_piece == '0') {
            x = element.value.replace(/[^0-9]/gi, '');
            if (x.toString().length < 12) {
                this.pieceLengthError = true;
            } else {
                this.pieceLengthError = false;
            }
        } else {
            x = element.value.replace(/[^[a-zA-Z0-9_]+$]/gi, '');
            if (x.toString().length < 9) {
                this.pieceLengthError = true;
            } else {
                this.pieceLengthError = false;
            }
        }
        element.value = x;
        this.concerner.numero_piece = element.value;
    }

    async openModalAdd(template: TemplateRef<any>, currenUser: concerner = null, elem,selectDropdownAddConcerner: any) {
        
        this.resetForm();
        this.etablissements = await this.helper.getSelectList(environment.etablissement, ['name']);
        this.provinces = await this.helper.getSelectList(environment.province, ['name']);
        // this.categories = await this.helper.getSelectList(environment.pension_categories, ['name']);
        console.log('Province', this.provinces);
        this.concerner_error = new concerner();
        this.tab_concernee = [];
        if (currenUser == null) {
            this.isAdd = true;
            this.concerner = new concerner();
            if (this.superAdmin == 0 || this.auth.account().info.etablissement_id == 1) {
                this.concerner.etablissement_id = this.auth.account().info.etablissement_id;
                let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+this.concerner.etablissement_id, valuesys.httpAuthOptions()).toPromise();
                if(res.code == 200) {
                    this.categories = res.data
                }
            }
            this.titleModal = this.__('concerner.add');
        } else {
            this.isAdd = false;
            this.titleModal = this.__('concerner.modifier');
            this.concerner = currenUser;

        }
        this.modalService.open(template, {windowClass: 'glady', backdrop: 'static', modalDialogClass: 'glady'});
        console.log(selectDropdownAddConcerner,'select')
    }
    async openModal(template_2: TemplateRef<any>, currenUser: concerner, elem) {
        console.log(currenUser,'current user')
        this.etablissements = await this.helper.getSelectList(environment.etablissement, ['name']);
        this.provinces = await this.helper.getSelectList(environment.province, ['name']);
        this.concerner_error = new concerner();
        this.isAdd = false;
        this.titleModal = this.__('concerner.modifier');
        this.concerner = currenUser;
        this.endPOint = environment.pension_concerners + '?page=1&where=concernees.etablissement_id|e|' + this.etabId;
            let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+currenUser.etablissement_id, valuesys.httpAuthOptions()).toPromise();
            if(res.code == 200) {
                this.categories = res.data
            }
        this.modalService.open(template_2, {size: 'lg', backdrop: 'static'});
    }
    async openModalImportExcel(template_3: TemplateRef<any>) {
        this.etablissements = await this.helper.getSelectList(environment.etablissement, ['name']);
        if (this.superAdmin == 0) {
            this.etab_id = this.auth.account().info.etablissement_id;
            let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+this.etab_id, valuesys.httpAuthOptions()).toPromise();
            if(res.code == 200) {
                this.categories = res.data
            }
        }
        this.concerner_error = new concerner();
        this.isAdd = false;
        this.titleModal = this.__('concerner.ajout_par_import_excel');
        this.modalService.open(template_3, {windowClass: 'glady', backdrop: 'static', modalDialogClass: 'glady'});
    }
    async toggleState(currenUser: concerner, state: number, btn: any) {
        btn.disabled = true;
        let toogle = await this.http.get<any>(environment.pension_concerners + `/${currenUser.concernees_id}/state/${state}`, valuesys.httpAuthOptions()).toPromise();
        if (toogle.code == 201) {
            this.toastr.success(toogle.msg, this.__('msg.success'));
            currenUser.state = toogle.data.state;
        } else {
            this.toastr.error(toogle.msg, this.__('msg.error'));
        }
        btn.disabled = false;
    }

    close() {
        this.modalService.dismissAll();
        this.registerFormImporExcel.get('etab_id').setValue('');
        this.registerFormImporExcel.get('cat_id').setValue('');
        this.registerFormImporExcel.get('fichier').setValue('');
        this.beneficiaire_verif = [];
        this.tabbenefshow = false;
        this.disableEtabInput = false;
        this.disableCatInput = false;
        this.disableExcelInput = false;
        this.isValid = false;
        this.concerner = new concerner();
        this.titleModal = this.__('label_user_edit');
        this.concerner_error = new concerner();
        this.submitted = false;
        this.isFocused = false;
        this.isImported = false;
        this.categories = [];
        // this.etablissements = [];
        this.beneficiaire_verif = [];
        this.insertBoursierConcerner = new InsertBoursierConcerner();
    }
    delete(deletedConcerner: concerner, elm) {
        console.log('ID', deletedConcerner.concernees_id);
        elm.disabled = true;
        Swal.fire({
            title: this.__('msg.confirm'),
            text: this.__('msg.warning_delete'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#44A1A0',
            cancelButtonColor: '#FF7467',
            reverseButtons: true,
            confirmButtonText: this.__('msg.confirmed_delete'),
            cancelButtonText: this.__('msg.cancel_delete')
        }).then(async (result) => {
            if (result.value) {
                let sup = await this.http.delete<any>(environment.pension_concerners + '/' + deletedConcerner.concernees_id, valuesys.httpAuthOptions()).toPromise();
                if (sup.code == 204) {
                    this.concerners = this.concerners.filter(function (item, index) {
                        return item.concernees_id != deletedConcerner.concernees_id;
                    });
                    Swal.fire(
                        {
                            icon: 'success',
                            title: this.__('msg.deleted'),
                            confirmButtonColor: '#44A1A0',
                            text: sup.msg,
                        }
                    ).then((accept) => {
                        if (accept) {
                            let userinfo = this.auth.account();
                            this.etabId = userinfo.info.etablissement_id;
                            this.superAdmin = userinfo.info.admin;

                            if (this.superAdmin == 1) {
                                this.endPOint = environment.pension_concerners + '?page=1&where=concernees.etablissement_id|e|1';
                            } else {
                                this.filtre_etablissement_id = this.etabId;
                                this.getCurrentEtablissement(this.etabId);
                            }
                            this.getUrlDatatable(this.endPOint);
                        }
                    });
                } else {
                    this.toastr.error(sup.msg, this.__('msg.error'));
                }
                elm.disabled = false;

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                elm.disabled = false;
                Swal.fire(
                    {
                        icon: 'error',
                        title: this.__('msg.canceled'),
                        confirmButtonColor: '#44A1A0',
                        text: this.__('msg.canceled_delete'),
                    }
                );
            }
        });
    }

    async addtab_concernee() {
        this.concerner_error = new concerner();
        console.log(this.concerner, 'concerner data');
        this.isValid = true;
        this.submitted = true;
        // if (this.registerForm.invalid) {
        //     if (this.registerForm.get('matricule').value.toString().length < 6) {
        //         console.log('invalid matricule');
        //         this.matrculeLengthError = true;
        //     }
        //     return;
        // }
        // this.matrculeLengthError = false;

        if (this.concerner.code_postal == undefined) {
            this.concerner.code_postal = null;
        } else {
            this.concerner.code_postal = this.concerner.code_postal;
        }
        this.etab_c_id = this.concerner.etablissement_id;
        this.cat_c_id = this.concerner.categorie_concerne_id;
        let tel = '';
        if(this.concerner.telephone == '03' || this.concerner.telephone == undefined){
            tel = "";
        }else{
            tel = '261' + this.concerner.telephone.toString().slice(1, this.concerner.telephone.toString().length);
        }
        let Cx = {
            'nom': this.concerner.nom,
            'prenom': this.concerner.prenom,
            'matricule': this.concerner.matricule,
            'numero_piece': this.concerner.numero_piece,
            'date_livrance': this.concerner.date_livrance,
            'telephone': tel,
            'type_piece': this.concerner.type_piece,
            'sexe': this.concerner.sexe,
            'email': this.concerner.email,
            'date_naissance': this.concerner.date_naissance,
            'code_postal': this.concerner.code_postal,
            'adresse': this.concerner.adresse,
            'photo': this.profileImage,
            'categorie_concerne_id': this.concerner.categorie_concerne_id
        };
        this.tab_concernee.push(Cx);
        this.concerner = new concerner();
        if(this.superAdmin == 1) this.concerner.etablissement_id =  this.auth.account().info.etablissement_id;
        this.documents = [];
        this.submitted = false;
    }
    removeConcerne(matricule: string | number) {
        this.tab_concernee = this.tab_concernee.filter((item) => item.matricule != matricule);
    }

    async getEtablissemenNameSelected(element) {
        console.log(element,"element etab")
        element.clearModel();
        /** POUR AFFICHAGE NOM ETABLISSEMENT SELECTED **/
        this.categories = [];
        let etablissementSelected: etablissement = this.etablissements.find(x => x.id === this.concerner.etablissement_id);
        this.name_etablissement_selected = etablissementSelected.name;
        this.endPOint = environment.pension_concerners + '?page=1&where=concernees.etablissement_id|e|' + this.etabId;
        let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+this.concerner.etablissement_id, valuesys.httpAuthOptions()).toPromise();
        if(res.code == 200) {
            this.categories = res.data
        }
        
    }

    getConcerneNameSelected() {
        /** POUR AFFICHAGE NOM CATEGORIE SELECTED **/
        let concernerSelected: categorie = this.categories.find(x => x.id === this.concerner.categorie_concerne_id);
        this.name_categorie_selected = concernerSelected.name;
    }

    getProvinceNameSelected() {
        /** POUT AFFICHAGE NOM PROVINCE SELECTED **/
        let provinceName: province = this.provinces.find(x => x.id === this.concerner.province_id);
        this.name_province_selected = provinceName.name;
    }

    backForm() {
        this.tab_concernee = [];
        console.log(this.tab_concernee.length);
    }

    //--Add or Update
    async addConcernerForm() {
        console.log(this.registerForm, 'test register form');
        console.log('CONCERNER : ' + JSON.stringify(this.concerner));

        this.concerner_error = new concerner();
        this.isValid = true;
        this.submitted = true;

        
        //--- Add grade
        // if(!this.registerForm.invalid) {
            if (this.isAdd) {

                let concerne_a_envoyer = {
                    'categorie_concerne_id': this.cat_c_id,
                    'etablissement_id': this.etab_c_id,
                    'concernees': this.tab_concernee,
    
                };
                if (JSON.stringify(concerne_a_envoyer) === '{}') {
                    return;
                }
                let res: any = await this.http.post<any>(environment.pension_concerners, concerne_a_envoyer, valuesys.httpAuthOptions()).toPromise();
    
                //--success
                if (res.code === 201) {
                    if (res.data.telephone_invalide.length != 0) {
                        this.toastr.error('Le format du téléphone est invalide   ', this.__('msg.error'));
                        return;
                    }
                    if (res.data.existant_base.length != 0) {
                        this.toastr.error('Numéro matricule ' + res.data.existant_base[0].matricule + ' existe déjà', this.__('msg.error'));
                        return;
                    }
                    if (res.data.telephone_exist.length != 0) {
                        this.toastr.error('Numéro téléphone ' + res.data.telephone_exist[0].telephone + ' existe déjà', this.__('msg.error'));
                        return;
                    }
                    this.toastr.success(res.msg, this.__('msg.success'));
                    this.close();
                    this.getUrlDatatable(this.endPOint);
                } else if (res.code === 400) {
                    for (let item in res.data) {
                        this.concerner_error[item] = res.data[item][0];
                    }
                    this.concerner_error = <concerner>this.helper.errorForm(res.data);
                    this.toastr.warning(res.msg, this.__('msg.warning'));
                }
                //--error
                else {
                    let doublon = res.data.indexOf('Duplicate');
                    if (doublon > -1) {
                        this.toastr.error(this.__('msg.error_doublon'), this.__('msg.error'));
                    } else {
                        this.toastr.error(res.msg, this.__('msg.error'));
                    }
                }
                //--- Update grade
            } else {
    
                this.concerner_error = new concerner();
                this.isValid = true;
                this.submitted = true;
                let prefix = ""
                if(this.isFocused) prefix = "00261"
                else prefix = "0"
                if (this.registerForm.invalid) {
                    return;
                }
                this.concerner = {
                    ...this.concerner, type_piece: this.concerner.type_piece.toString(),
                    telephone: prefix+this.concerner.telephone.slice(1,this.concerner.telephone.length) 
                };
    
                
                let res = await this.http.put<any>(environment.pension_concerners + '/' + this.concerner.concernees_id, this.concerner, valuesys.httpAuthOptions()).toPromise();
                console.log('Response :', this.concerner);
                if (res.code === 201) {
                    this.toastr.success(res.msg, this.__('msg.success'));
                    this.close();
                    this.getUrlDatatable(this.endPOint);
                } else if (res.code === 400) {
                    this.concerner_error = <concerner>this.helper.errorForm(res.data);
                    this.toastr.warning(res.msg, this.__('msg.warning'));
                }
                //--error
                else {
                    let doublon = res.data.indexOf('Duplicate');
                    if (doublon > -1) {
                        this.toastr.error(this.__('msg.error_doublon'), this.__('msg.error'));
                    } else {
                        this.toastr.error(res.msg, this.__('msg.error'));
                    }
                }
            }
        // }else {
        //     this.toastr.error("Le formulaire est invalide", this.__('msg.error'));
        // }
        this.isValid = false;
        this.submitted = false;
    }

    async addExcelForm() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        //--add Logo
        this.concerner.documents = this.documentLogo.name;
        this.concerner_error = new concerner();
        this.isValid = true;
        //--- Add User
        if (this.isAdd) {
            let res: any = await this.http.post<any>(environment.pension_concerners, this.concerner, valuesys.httpAuthOptions()).toPromise();
            console.log(res);
            //--success
            if (res.code === 201) {
                this.toastr.success(res.msg, this.__('msg.success'));
                this.close();
                this.getUrlDatatable(this.endPOint);
            } else if (res.code === 400) {
                for (let item in res.data) {
                    this.concerner_error[item] = res.data[item][0];
                }
                this.concerner_error = <concerner>this.helper.errorForm(res.data);
                this.toastr.warning(res.msg, this.__('msg.warning'));
            } else {
                this.toastr.error(res.msg, this.__('msg.error'));
            }
        }

        this.isValid = false;
    }

    handleInputChange(e) {
        e.stopPropagation();
        console.log(e);
        var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        if (!files) {
            return;
        }

        this.filename = files[0].name;
        for (var i = 0; i < files.length; i++) {
            if (files[i].size > 1000 * 1000 * 100) {
                this.toastr.error('Taille de l\'image trop grande', this.__('msg.error'));
                $('#document').val('');
                return;
            }
            var reader = new FileReader();
            if (!files[i].type.match('application/vnd.ms-excel|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
                this.filename = '';
                this.registerFormImporExcel.get('fichier').setValue('');
                this.toastr.error(this.__('import_excel.text_invalid'), this.__('msg.error'));
                return;
            }
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(files[i]);
        }
    }


    _handleReaderLoaded(e) {
        let reader = e.target;
        let d: any = [];
        d.name = reader.result;
        if (this.documents.length > 0) {
            this.documents = [];
            this.documents.push(d);
        }
        if (this.documents.length == 0) {
            this.documents.push(d);
        }

        console.log('DOCUMENTS : ' + this.documents);
        //this.partenaire.logo = reader.result;
    }


    async AddImportExcel() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.registerFormImporExcel.invalid) {
            return;
        }
        this.savedDocument = this.documents.filter((_) => _.id == null);

        this.etab_c_id = this.etab_id;
        this.cat_c_id = this.cat_id;

        console.log('YYYYYYYYYYYY', this.etab_c_id, this.cat_c_id);

        let objectConcerner = {
            'categorie_concerne_id': this.cat_c_id,
            'etablissement_id': this.etab_c_id,
            'fileXls': this.savedDocument[0].name
        };
        console.log('object', objectConcerner);

        let res = await this.http.post<any>(environment.pension_improt_excel, objectConcerner, valuesys.httpAuthOptions()).toPromise();
        console.log('Response after post :', res);
        //--success
        if (res.code === 201) {
            this.tabbenefshow = true;
            this.totalparcourue = res.data.ligne_parcourue;
            this.totalvalid = res.data.total_valid;
            this.totalinvalid = this.totalparcourue - this.totalvalid;
            this.isImported = true;
          
            this.allBV = [...res.data.insered];
            this.beneficiaire_verif = res.data.insered;

            this.ligne_parcourues = res.data.ligne_parcourues;
            this.ligne_traitees = res.data.ligne_traitees;
            this.ligne_echouees = res.data.ligne_echouees;
            this.filename = '';
        } else if (res.code === 400) {
            this.concerner_error = <concerner>this.helper.errorForm(res.data);
            this.toastr.warning(res.msg, this.__('msg.warning'));
        }
        //--error
        else {
            this.toastr.error(res.data, this.__('msg.error'));
        }
        this.disableEtabInput = true;
        this.disableCatInput = true;
        this.disableExcelInput = true;
        this.submitted = false;

    }

    async validerAddBenef() {
        let objectConcerner = {
            'categorie_concerne_id': this.cat_c_id,
            'etablissement_id': this.etab_c_id,
            'fileXls': this.savedDocument[0].name
        };
        console.log('object a inserer : ', objectConcerner);

        let res = await this.http.post<any>(environment.pension_concerners + '/insertDataFile', objectConcerner, valuesys.httpAuthOptions()).toPromise();

        if (res.code == 201) {
            if (res.data.total_insert > 0) {
                this.toastr.success(res.msg + ' : ' + res.data.total_insert + ' inserée(s)', this.__('msg.success'));
                this.close();
                this.getUrlDatatable(this.endPOint);
            } else {
                this.toastr.error(this.__('msg.error_verify_excel_benef'), this.__('msg.error'));
            }

        } else {
            this.toastr.error(res.msg, this.__('msg.error'));
        }

    }

    async reimporterExcel() {
        this.beneficiaire_verif = [];
        this.tabbenefshow = false;
        this.disableEtabInput = false;
        this.disableCatInput = false;
        this.disableExcelInput = false;
        this.savedDocument = [];
        this.registerFormImporExcel.get('fichier').setValue('');
    }

    async openModalDetails(template: TemplateRef<any>, currentConcerner: concerner = null, elm) {
        elm.disabled = true;
        this.titleModal = this.__('label_details');

        this.concerner = currentConcerner;
        console.log('xxex', this.concerner);

        this.modalService.open(template, {size: 'xl', backdrop: 'static'});
        elm.disabled = false;
    }


    /*telephone */
    objetPhone: any;

    onCountryChange(m) {
        let lastDiall = this.objetPhone.dialCode;
        let newDiall = m.dialCode;
        console.log(`LAST = ${lastDiall} ; NEW ${newDiall}`, m);
        this.objetPhone = m;
        /*Changed*/
        this.concerner.telephone = this.element.value;
        /*Changed*/
    }

    telInputObject(m) {
        this.objetPhone = m.s;
    }

    element: any;
    currenCode: string = 'mg';

    controle(element) {
        this.isFocused = true;
        this.objetPhone.dialCode = '03';
        this.element = element;
        console.log(this.objetPhone.dialCode);
        element.value = element.value.replace(/[^0-9]/gi, '');
        if ((element.value || '').substr(0, this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) == -1) {
            if (element.value.length <= this.objetPhone.dialCode.length && (element.value || '').substr(this.objetPhone.dialCode.length).length < 1) {
                element.value = this.objetPhone.dialCode;
            } else {
                if ((element.value || '').substr(0, this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) !== -1) {
                    element.value = element.value;
                } else {
                    element.value = this.objetPhone.dialCode + element.value;
                }
            }
        }
        /*Changed*/
        this.concerner.telephone = element.value;
        /*Changed*/
    }

    /*telephone */


    goAffectation(idBeneficiaire) {
        this.router.navigateByUrl('/dashboard/BNF/plateforme/concernee/affectation-carte', {state: {idBeneficiaire: idBeneficiaire}});
        this.close();
    }

    addImage(documentFile: HTMLInputElement) {

        documentFile.click();
    }

    importExcel(documentFile: HTMLInputElement) {

        documentFile.click();
    }


    handleInputLogo(e) {
        var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        if (!files) {
            return;
        }
        //this.etablissement.logo = files[0].name;

        for (var i = 0; i < files.length; i++) {
            if (files[i].size > 1000 * 1000 * 100) {
                this.toastr.error('taille image trop grande', 'Error');
                $('#document').val('');
                return;
            }
            var reader = new FileReader();
            var pattern = /image-*/;

            if (!files[i].type.match(pattern)) {
                this.toastr.error('invalid format', 'Error');
                return;
            }
            reader.onload = this._handleReaderLogoLoaded.bind(this);
            reader.readAsDataURL(files[i]);
        }

    }

    _handleReaderLogoLoaded(e) {
        let reader = e.target;
        this.documentLogo.name = reader.result;
        this.profileImage = reader.result;
        // this.update = true;
    }

    resetForm() {
        this.registerForm.reset();
        this.registerForm.markAllAsTouched();
        this.registerForm.markAsUntouched();
        this.registerForm.updateValueAndValidity();
        this.registerForm.markAsPristine();
    }

    async exportBeneficiareToExcel(fileName) {

        this.helper.exportExcel(this.printPaiement(this.concernerArray), this.__('print.list') + this.__('client.clients')).then(
            (response: any) => {
                console.log('respons beee', response);
                let a = document.createElement('a');
                a.href = response.data;
                a.download = `${fileName}.xlsx`;
                a.click();
            },
            (error: any) => {
                console.log(error);
            }
        );
    }

    printPaiement(ordre_paiement: any[]) {
        return ordre_paiement.map((ordre_paiement) => {
            let t = {};
            t['Ministère'] = ordre_paiement.etablissement_name;
            t['N° matricule'] = ordre_paiement.matricule;
            t['Nom'] = ordre_paiement.nom;
            t['Prénom'] = ordre_paiement.prenom;
            t['Téléphone'] = ordre_paiement.telephone;
            t['Numéro pièce(cin/passport)'] = ordre_paiement.numero_piece;
            t['N° compte'] = (ordre_paiement.num_compte != null) ? ordre_paiement.num_compte : this.__('carte.nondefini');
            t[this.__('carte.type_compte')] = this.getTypeCompte(ordre_paiement.type_compte);
            t['Catégorie'] = ordre_paiement.categorie;
            return t;
        }) || [];

    }
    printEdutiant(etudiants: any[],type="pdf") {
        return etudiants.map((e) => {
            let t = {};
            t['Matricule'] = e.matricule;
            t['Nom'] = e.nom;
            t['Prénom'] = e.prenom;
            t['Téléphone'] = e.telephone;
            t["Email"] = e.email
            t['Date de naissance'] = e.date_naissance;
            t['Sexe'] = e.sexe;
            t['Type pièce'] = e.type_piece == 0 ? 'CIN' : 'PASSPORT';
            t['Numéro pièce(cin/passport)'] = e.numero_piece;
            t['Etat'] = this.errorMessage(e,type);
            return t;
        }) || [];

    }
    errorMessage(bv:any,type="pdf") {
        if(bv.valid == 0) {
            const errorList =  [
                bv?.telephone_invalid == 1 ? this.__('concerner.tel_invalid') : null,
                bv?.telephone_existant == 1 ? this.__('concerner.tel_existant') : '',
                bv?.bv?.error_dateLivrance && bv?.error_dateLivrance.length != 0 ? bv?.error_dateLivrance : null,
                bv?.pas_de_matricule == 1 ? "Matricule obligatoire" : null,
                bv?.pas_de_nom == 1 ? "Nom obligatoire" : null,
                bv?.piece_invalid == 1 ? "Numéro pièce invalide" : null,
                bv?.date_invalid == 1 ? "Date invalide" : null,
                bv?.telephone_redendant == 1 ? "Téléphone doublons" : null,
                bv?.existant_base == 1 ? this.__('concerner.deja_existant') : null,

            ]
            let text = '';
            for (let index = 0; index < errorList.length; index++) {
                if(errorList[index] && type == 'pdf') text +="<p>"+errorList[index]+"</p>";
                if(errorList[index] && type == 'excel') text +=","+errorList[index];
                else text +='';
            }
            return text.replace(",","");
            
        } else return 'Valide'
    }



    async exportEtudiantPDF() {
        let filename = ('liste_edutiant_'+ this.filtreName != '' ? this.filtreName : '' as string).replaceAll(' ','_').toLocaleLowerCase() || 'liste_etudiant' ;
        await this.helper.exportPdf(this.printEdutiant(this.beneficiaire_verif,'pdf'),`Listes étudiants ${ this.filtreName ? '- '+this.filtreName : '' }`,'',filename);
    }
    async exportEtudiantEXCEL() {
        let filename = ('liste_edutiant_'+this.filtreName  !=  '' ? this.filtreName : '' as string).replaceAll(' ','_').toLocaleLowerCase() || 'liste_etudiant';
         this.helper.exportExcel(this.printEdutiant(this.beneficiaire_verif,'excel'),`Listes étudiants ${ this.filtreName ? '- '+this.filtreName : '' }`).then(
            (response: any)=>{
              console.log('respons beee',response)
                  let a = document.createElement("a"); 
                  a.href = response.data;
                  a.download = `${filename}.xlsx`;
                  a.click(); 
            },
            (error:any)=>{
              console.log(error)
            }
          );
    }
    
    getTypeCompte(type): string {
        switch (type) {
            case -1:
                return this.__('carte.type_d');
                break;
            case 0:
                return this.__('carte.type_0');
                break;
            case 1:
                return this.__('carte.type_1');
                break;
            default:
                break;
        }
    }

    async exportPdf(btnPdf) {
        await this.helper.exportPdf(this.printPaiement(this.concernerArray), 'Listes bénéficiaires', '', 'liste_beneficiaire');
    }

    validateMatricule() {
        setInputFilter(document.getElementById("matriculeElement"), function (value) {
            return /^\d*$/.test(value) && (value === "" || value.length <= 6)
        }, "Le matricule doit contenir 6 nombres");
    }
    async downloadCanva(element: any) {
        this.http.get<any>(environment.base_url_crud+'concernee/CanvaExportConcerner').subscribe(
            response => {
                this.sourcePath = response;
                setTimeout(() => {
                    element.click()
                }, 1000);  
            },
            (error) => {
                this.toastr.error(error.message, this.__('msg.error'));

            }
        )
        
    }   
    
    handleFilter(id: any) {
        console.log('change',this.beneficiaire_verif)
        this.filtreName = this.filterInsertList.find(f => f.id == id).text;

        /**
         * 1 : existant dans la base
         * 2: phone existant
         * 3: lignes invalides
         * 4: Lignes valides
         * 5: Tous
         */

          /**
             * ajout filtre par telephone_existant && valid == 0 invalid
             */
        
        switch (id) {
          
            case 1: 
                this.beneficiaire_verif = this.allBV.filter(bv => bv.existant_base == 1);
                console.log(this.beneficiaire_verif ,'xxxxx')
                break;
            case 2:
                this.beneficiaire_verif = this.allBV.filter(bv => bv.telephone_existant == 1);
                console.log(this.beneficiaire_verif ,'xxxxx')

                break;
            case 3:
                this.beneficiaire_verif = this.allBV.filter(bv => bv.pas_de_matricule == 1)
                console.log(this.beneficiaire_verif ,'xxxxx')

                break;
            case 4:
                this.beneficiaire_verif = this.allBV.filter(bv => bv.valid == 0)
                console.log(this.beneficiaire_verif ,'xxxxx')

                break;
            case 5:
                this.beneficiaire_verif = this.allBV.filter(bv => bv.valid == 1)
                console.log(this.beneficiaire_verif ,'xxxxx')

                break;
            case  6:
                this.beneficiaire_verif = this.allBV;
                console.log(this.beneficiaire_verif ,'xxxxx')

                break;
            case 7:
                this.beneficiaire_verif = this.allBV.filter(bv => bv.telephone_redendant == 1);
                console.log(this.beneficiaire_verif ,'xxxxx')

                break;
            default:
                console.log("default",id)
                break;
        }
    }
    async getCategorieByIdEtab(id,element) {
        this.isImported = false;
        this.categories = [];
        element.clearModel();
        let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+id, valuesys.httpAuthOptions()).toPromise();
        if(res.code == 200) {
            this.categories = res.data;
            // if(this.categories.length == 0) element.clearModel();
        }
    }
    async openModalEdutiantBousier(template_3: TemplateRef<any>) {
        this.etablissements = await this.helper.getSelectList(environment.etablissement, ['name']);
        if (this.superAdmin == 0) {
            const type_etablissement = this.auth.account().etablissement.type_etablissement;
            if(type_etablissement == 1) {
                this.insertBoursierConcerner.etablissement_id = this.auth.account().info.etablissement_id;
            }

            // let res: any = await this.http.get<any>(environment.categorie_etablissement+"?where=categorie_etablissement.etablissement_id|e|"+this.etab_id, valuesys.httpAuthOptions()).toPromise();
            // if(res.code == 200) {
            //     this.categories = res.data;
            // }
        }
        let resNiveau: any = await this.http.get<any>(environment.niveau, valuesys.httpAuthOptions()).toPromise();
        if(resNiveau.code == 200) {
            this.niveauList = resNiveau.data;
            console.log(resNiveau,'resniveau')
        }
        this.concerner_error = new concerner();
        this.isAdd = false;
        this.titleModal = this.__('concerner.ajout_etudiant_boursier');
        this.modalService.open(template_3, {windowClass: 'glady', backdrop: 'static', modalDialogClass: 'glady'});
    }
    
    async getEdutiantConcenant() {
        // const currentEtab = this.etablissements.find(e => e.id == this.insertBoursierConcerner.etablissement_id);
        // console.log(currentEtab,"currentetable")
        let resEtudiant: any = await this.http.post<any>(environment.edutic_concerne,{etablissement_id: this.insertBoursierConcerner.etablissement_id,niveau_id: this.insertBoursierConcerner.niveau_id}, valuesys.httpAuthOptions()).toPromise();
        if (resEtudiant.code === 201) {
            this.tabbenefshow = true;
            this.totalparcourue = resEtudiant.data.ligne_parcourue;
            this.totalvalid = resEtudiant.data.total_valid;
            this.totalinvalid = this.totalparcourue - this.totalvalid;
            this.isImported = true;
          
            this.allBV = [...resEtudiant.data.insered];
            this.beneficiaire_verif = resEtudiant.data.insered;

            this.ligne_parcourues = resEtudiant.data.ligne_parcourues;
            this.ligne_traitees = resEtudiant.data.ligne_traitees;
            this.ligne_echouees = resEtudiant.data.ligne_echouees;
            this.filename = '';
        } else if (resEtudiant.code === 400) {
            this.concerner_error = <concerner>this.helper.errorForm(resEtudiant.data);
            this.toastr.warning(resEtudiant.msg, this.__('msg.warning'));
        }
        //--error
        else {
            this.toastr.error(resEtudiant.data, this.__('msg.error'));
        }
    }
    async submitEtudiantBoursier() {
        console.log(environment.CATEGORIE_ID_ETUDIANT,"categ")
        this.insertBoursierConcerner.categorie_concerne_id = environment.CATEGORIE_ID_ETUDIANT;
        let res = await this.http.post<any>(environment.edutic_insert_concerne , this.insertBoursierConcerner, valuesys.httpAuthOptions()).toPromise();

        if (res.code == 201) {
                this.toastr.success(res.msg + ' : ' + res.data.total_insert + ' inserée(s)', this.__('msg.success'));
                this.close();
                this.getUrlDatatable(this.endPOint);
        } else {
            this.toastr.error(res.msg, this.__('msg.error'));
        }

    }
    

 }
