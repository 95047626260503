<br><div class="repMenu">
      <div class="row  d-flex  justify-content-between">
        
            
              <div class="breadcrumb flat">
                <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
                <a [routerLink]="['/dashboard/BNF/plateforme/concernee']"  routerLinkActive="active">{{__('menu.beneficiaire')}}</a>
                <a [routerLink]="['/dashboard/BNF/plateforme/categorie_concerner']"  routerLinkActive="active"> Liste des catégories bénéficiaire</a>
                <!-- <a href="#">Terminer</a> -->
              </div>
          <!-- <div class="col-lg-8"><span class="ariare" [routerLink]="['/app']" style="cursor: pointer;" >{{__('menu.accueil')}} </span>  > <span class="ariare" [routerLink]="['/app/module/BNF']" style="cursor: pointer;"  >{{__('menu.beneficiaire')}} </span>  > <span class="ariare"> {{__('concerner.title')}}</span></div> -->
          
          <!-- <div class="div-add pt-2 d-md-none d-sm-none"  > 
            <button class="button-control" *ngIf="autority('BNF_2')"  #addBtnImport (click)="openModalImportExcel(addImportExcel,null,addBtnImport)" ><i class="fa fa-file-excel-o"></i> {{__('import_excel.import')}}</button>

            <button class="button-control" *ngIf="autority('BNF_2')"  #addBtnUser (click)="openModalAdd(addConcerner,null,addBtnUser)" >{{__('concerner.add')}}</button>
          </div> -->

      </div>
</div> 
<div  class="bg-white px-3  repMenu mt-2 d-flex justify-content-between align-items-center">

  <div class="export" >
   <button class="px-2" (click)="exportBeneficiareToExcel('listes_beneficiares')">
    Export excel
    <img class="mb-1" src="assets/img/excel.png" alt="">
    </button>
    <button (click)="exportPdf('listes_beneficiares')" class="ml-3 px-2">
      Export Pdf
      <img   class="mb-1" src="assets/img/pdf-file.png" alt="">

      </button>
  </div>
  <div class="div-add pt-2 "  > 
    <button class="button-control" *ngIf="autority('BNF_2')"  #addBtnImport (click)="openModalImportExcel(addImportExcel,null,addBtnImport)" ><i class="fa fa-file-excel-o"></i> {{__('import_excel.import')}}</button>
    <button class="button-control" *ngIf="autority('BNF_9')"  #addBtnEdutiant (click)="openModalEdutiantBousier(refModalEdutiantBoursier,null,addBtnEdutiant)" ><i class="fa fa-user"></i> {{__('concerner.etudiant_boursier')}}</button>

    <button class="button-control" *ngIf="autority('BNF_2')"  #addBtnUser (click)="openModalAdd(addConcerner,null,addBtnUser,selectDropdownAddConcerner)" ><i class="fa fa-plus"></i> {{__('concerner.add')}}</button>
    <a
    #canvaBeneficiaire
    [href]="sourcePath"
    [download]="'modele_import_beneficiaire.xlsx'"
    [attr.disabled]="dwld.busy"
    #dwld="wmDownload"
    class="d-none"
   
  >
    download
  </a>
  <label class="custom-file-upload ml-4 border-green" (click)="downloadCanva(canvaBeneficiaire)">
  
  
  <i class="fa fa-download"></i> {{__("ordre_paiement.download_fichier")}}
  </label>
  
  </div>
</div> 

<div class="row">
  <div class="col-lg-12">
    <br>
    <div class="card">
      <div class="card-body">
            <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label >{{__('carte.ministere')}} (<span class="required-red" >{{__('label.require')}}</span>)

                            </label>
                            <ng-select placeholder="{{__('carte.ministere')}}"
                                       [(ngModel)]="etablissement_id"
                                       class="form-control"
                                       [readonly]="superAdmin == 0 && activeEtabId != 1"
                                       (change)="getCategorieByIdEtab($event,categorieSearch)"
                            >
                                <ng-option  *ngFor="let etab of etablissements" [value]="etab?.id ">{{ etab?.name }}</ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                            <label >{{__('concerner.categorie_concerne_id')}} <!--(<span class="required-red" >{{__('label.require')}}</span>)-->

                            </label>
                            <ng-select placeholder="{{__('concerner.categorie_concerne_id')}}"
                                       [(ngModel)]="categorie_concernees_id"
                                       class="form-control"
                                       #categorieSearch
                            >
                                <ng-option  *ngFor="let categorie of categories" [value]="categorie?.id ">{{ categorie?.name }}</ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                            <label >{{__('concerner.situation')}}

                            </label>
                            <ng-select placeholder="{{__('concerner.situation')}}"
                                       class="form-control"
                                       [(ngModel)]="situation_cart_id"
                            >
                                <ng-option  *ngFor="let type of types" [value]="type?.id ">{{ type?.typ }}</ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                            <label >{{__('carte.type_compte')}}

                            </label>
                            <ng-select placeholder="{{__('carte.type_compte')}}"
                                       [(ngModel)]="type_compte_id"
                                       class="form-control"
                            >
                                <ng-option  *ngFor="let c of compte" [value]="c?.id ">{{ c?.comptes }}</ng-option>
                            </ng-select>
                        </div>
                    </div>

                    <div class="col-lg-2 d-flex align-items-center mt-1" >
                        <button (click)="addSelectForm()" style="height: 44px;" type="submit" name="button" class="btn button-control" >{{__('btn.filtre')}}</button>
                    </div>
            </div>
      </div>
    </div>
  </div>
</div> 

<div class="row">

    <div class="col-lg-12">
      <br>
      <div class="card">
        <div class="card-body">
          <div class="row footer-paginate" >
            <div class="col-lg-4"> 
              <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span> 
              <select name="" id="" [(ngModel)]="filtreSelect" (change)="getFiltreSelect()" class="search-input" >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="5000">5000</option>
                <option value="10000">10000</option>
              </select> 
              <span style="color: #666 !important;" > {{__('datatable.element')}}  </span> 
            </div>
            <div class="col-lg-8 btn-paginate">
              <span style="color: #666 !important;" > {{__('datatable.rechercher')}} : </span><input type="text" [(ngModel)]="searchInput" (keyup.enter)="getSearchInput()" class="search-input">
            </div> 

          </div>
            <table style="width: 100%"  class="table table-striped table-hover " >
            <thead>
              <tr>

                <th class="text-left">{{__('concerner.etablissement_id')}}
                  <br>
                </th>


                <th class="text-left">{{__('concerner.matricule')}}

                  <br>
                    <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.matricule','asc')" *ngIf="tri=='concernees.matricule' && order=='desc'" ></i> 
                    <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.matricule ','desc')"  *ngIf="tri=='concernees.matricule' && order=='asc'"  ></i> 
                    <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.matricule','asc')" *ngIf="tri!='concernees.matricule'" ></i> 
                    <input type="text" #matricule class="inputCol" name="matricule" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.matricule',matricule, false)" id="">
                </th>
                

                <th class="text-left">{{__('concerner.nom')}}
                  <br>
                  <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.nom','asc')" *ngIf="tri=='concernees.nom' && order=='desc'" ></i> 
                  <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.nom','desc')"  *ngIf="tri=='concernees.nom' && order=='asc'"  ></i> 
                  <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.nom','_asc')" *ngIf="tri!='concernees.nom'" ></i> 
                  <input type="text" #nom class="inputCol" name="nom" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.nom',nom, false)" id="">
                </th>

                <th class="text-left">{{__('concerner.prenom')}}
                  <br>
                  <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.prenom','asc')" *ngIf="tri=='concernees.prenom' && order=='desc'" ></i> 
                  <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.prenom','desc')"  *ngIf="tri=='concernees.prenom' && order=='asc'"  ></i> 
                  <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.prenom','_asc')" *ngIf="tri!='concernees.prenom'" ></i> 
                  <input type="text" #prenom class="inputCol" name="prenom" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.prenom',prenom, false)" id="">
                </th>

                <th class="text-left">{{__('carte.numcompte')}}
                  <br>
                  <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.num_compte','asc')" *ngIf="tri=='concernees.num_compte' && order=='desc'" ></i> 
                  <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.num_compte','desc')"  *ngIf="tri=='concernees.num_compte' && order=='asc'"  ></i> 
                  <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.num_compte','_asc')" *ngIf="tri!='concernees.num_compte'" ></i> 
                  <input type="text" #num_compte class="inputCol" name="prenom" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.num_compte',num_compte, false)" id="">
                </th>

                <th class="text-left">{{__('ordre_paiement.categorie')}}
                  <br>
                </th>

                  
                  <th class="text-left">{{__('carte.sitation')}}
                    <br>
                </th>
                  <th class="text-left">{{__('carte.type_compte')}}
                      <br>
                  </th>
                <th class=" text-center">{{__('table.action')}}</th>
              </tr>
            </thead>
            <tbody *ngIf="concernerArray?.length != 0" >
                <tr *ngFor="let concerner of concernerArray">
                    <td class="text-left">{{ concerner?.etablissement_name }}</td>
                    <td class="text-left">{{ concerner?.matricule }}</td>    
                    <td class="text-left">{{ concerner?.nom}}</td> 
                    <td class="text-left">{{ concerner?.prenom}}</td> 
                    <td class="text-left" >
                      <span *ngIf="concerner?.num_compte == null" style="color: #858584;"> {{__('carte.nondefini')}}</span>
                      <span *ngIf="concerner?.num_compte !== null" style="color: #858584;"> {{concerner?.num_compte}}</span>
                    </td> 
                    <td class="text-left">{{ concerner?.categorie }}</td>
                    
                    <td class="text-left" >
                        <span *ngIf="concerner?.carte_situation == 0" style="color: #39b391;" > {{__('carte.situation_0')}}</span>
                        <span *ngIf="concerner?.carte_situation == 1" style="color: #d9d951;" > {{__('carte.situation_1')}}</span>
                        <span *ngIf="concerner?.carte_situation == 2" style="color: #68ad36;" > {{__('carte.situation_2')}}</span>
                        <span *ngIf="concerner?.carte_situation == 3" style="color: #e28b49;" > {{__('carte.situation_3')}}</span>
                        <span *ngIf="concerner?.carte_situation == 4" style="color: #29bfbf;" > {{__('carte.situation_4')}}</span>
                        <span *ngIf="concerner?.carte_situation == null" style="color: #858584;"> {{__('carte.situation_vi')}}</span>
                    </td>
                    <td class="text-left" >
                        <span *ngIf="concerner?.type_compte == -1" style="color: #dc2d0f;" > {{__('carte.type_d')}}</span>
                        <span *ngIf="concerner?.type_compte == 0" style="color: #126d12;" > {{__('carte.type_0')}}</span>
                        <span *ngIf="concerner?.type_compte == 1" style="color: #093072;" > {{__('carte.type_1')}}</span>
                    </td>
                    <td class="text-center">
                        <i class="fa fa-eye icon-table vert" *ngIf="autority('BNF_5') && concerner.state == 1" (click)="goAffectation(concerner.concernees_id)" ></i>
                        <i class="fa fa-edit icon-table" *ngIf="autority('BNF_3')" ngbTooltip="{{__('tooltip.edite')}}"  #openModalBtnEdit (click)="openModal(EditConcerner,concerner,openModalBtnEdit)" ></i>
                        
                        <i class="fa fa-toggle-on icon-table vert" ngbTooltip="{{__('tooltip.active')}}" #buttonElement (click)="toggleState(concerner,0,buttonElement)" *ngIf="concerner.state == 1 && autority('BNF_6')" ></i>

                        <i class="fa fa-toggle-off icon-table vert"  ngbTooltip="{{__('tooltip.desactiver')}}" #buttonElement (click)="toggleState(concerner,1,buttonElement)" *ngIf="concerner.state == 0 && autority('BNF_6')" ></i>

                        <i class="fa fa-trash icon-table red" *ngIf="autority('BNF_5')" ngbTooltip="{{__('tooltip.delete')}}" #openModalBtnDelete (click)="delete(concerner,openModalBtnDelete)"></i>
                    </td>
              </tr>
            </tbody>
            <tbody *ngIf="concernerArray?.length == 0">
            <tr>
              <td colspan="6" >{{__('label_no_data')}}</td>
            </tr>
          </tbody> 
          </table>

          <div class="row footer-paginate pt-2" >
            <div class="col-lg-4"> 
              <span class="three-point" style="padding-left: 0px;" >{{infoData}}</span> 
            </div>
            <div class="col-lg-8 btn-paginate" >
              <button *ngIf="currentPage != 1" (click)="getUrlDatatable(firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
              <button *ngIf="currentPage != 1" (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
              <button *ngIf="currentPage > 3" class="btn-pagination-hr"  (click)="getUrlDatatable(firstPage)" > 1 </button>
              <span *ngIf="currentPage > 3" class="three-point" > ... </span>
              <button *ngIf="cPage_less_2 > 0 && currentPage < 4" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_less_2)" > {{cPage_less_2}} </button>
              <button *ngIf="cPage_less_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_less_1)" > {{cPage_less_1}} </button>
              <button (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{currentPage}} </button>
              <button *ngIf="cPage_more_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_more_1)" > {{cPage_more_1}} </button>
              <button *ngIf="(cPage_more_2 > 0 && cPage_more_2 > lastNumPage && cPage_more_1 > lastNumPage && currentPage > lastNumPage) || cPage_more_2 == lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_more_2)" > {{cPage_more_2}} </button>
              <span *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="three-point" > ... </span>
              <button *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(lastPage)" > {{lastNumPage}} </button>
              <button *ngIf="currentPage < lastNumPage" (click)="getUrlDatatable(nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
              <button *ngIf="currentPage < lastNumPage" (click)="getUrlDatatable(lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

<ng-template #addConcerner style="background-color: red; width: auto;">

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="row">
    <div class="col-md-6">
      <form ngNativeValidate [formGroup]="registerForm" (ngSubmit)="addtab_concernee()">

        <div class="modal-body">
          <br>
          <div>
            
              <!-- INFORMATION PERSONNEL -->
              <div class="row">
                     
                     <!-- NOM -->
                     <div class="col-lg-6">
                       <div class="form-group">
                           <label >{{__('concerner.nom')}} (<span class="required-red" >{{__('label.require')}}</span>)
                             <span *ngIf="submitted && f.nom.errors" class="invalid-form-feedback" >                      
                               <span *ngIf="f.nom.errors.required"> / Ce champ est obligatoire</span>
                             </span>
                           </label>
                           <input formControlName="nom"  type="text" class="form-control" [(ngModel)]="concerner.nom"  placeholder="{{__('concerner.nom')}}" >
                       </div>
                     </div>
  
                     <!-- PRENOM -->
                     <div class="col-lg-6">
                       <div class="form-group">
                         <label >{{__('concerner.prenom')}} (<span class="required-red" >{{__('label.require')}}</span>)
                           <span *ngIf="submitted && f.prenom.errors" class="invalid-form-feedback" >                      
                             <span *ngIf="f.prenom.errors.required"> / Ce champ est obligatoire</span>
                           </span>
                         </label>
                           <input formControlName="prenom"  type="text" class="form-control" [(ngModel)]="concerner.prenom"  placeholder="{{__('concerner.prenom')}}" >
                         </div>
                     </div>
  
                     <!-- SEXE -->
                     <div class="col-lg-6">
                       <div class="form-group">
                         <label for="exampleInputEmail1">{{__('etudiant.sexe')}} (<span class="required-red" >{{__('label.require')}}</span>)</label>
                         <span *ngIf="submitted && f.sexe.errors" class="invalid-form-feedback">
                                           <span *ngIf="f.sexe.errors.required"> / Ce champ est obligatoire</span>
                                       </span> <br>
                         <div class="form-check form-check-inline mr-1" >
                           <input class="form-check-input" type="radio" [(ngModel)]="concerner.sexe" name="sexe" value="M" formControlName="sexe" 
                           [ngClass]="{ 'invalid-form-input-validate': submitted && f.sexe.errors }"> 
                           <label class="form-check-label" >{{__('etudiant.homme')}}</label>
                         </div>
                         <div class="form-check form-check-inline mr-1">
                           <input class="form-check-input" [(ngModel)]="concerner.sexe" type="radio" name="sexe" value="F" formControlName="sexe" 
                           [ngClass]="{ 'invalid-form-input-validate': submitted && f.sexe.errors }"  >
                           <label class="form-check-label" >{{__('etudiant.femme')}}</label>
                         </div>
                       </div>
                     </div>
  
                     <div class="col-lg-6">
                       <div class="form-group">
                           <label >{{__('concerner.date_naissance')}} (<span class="required-red" >{{__('label.require')}}</span>)
                             <span *ngIf="submitted && f.date_naissance.errors" class="invalid-form-feedback" >                      
                               <span *ngIf="f.date_naissance.errors.required"> / Ce champ est obligatoire</span>
                             </span>
                           </label>
                           <input formControlName="date_naissance"  type="date" class="form-control" [(ngModel)]="concerner.date_naissance"  placeholder="{{__('concerner.date_naissance')}}" >
                       </div>
                     </div>
  
              </div>
              <!-- INFORMATION PERSONNEL -->
  
              <hr>
              <!-- INFORMATION IDENTIFIANT NATIONAL -->
              <div class="row">
                   <div class="col-lg-4">
                     <div class="form-group">
                       <label >{{__('concerner.type_piece')}} (<span class="required-red" >{{__('label.require')}}</span>)
                         <span *ngIf="submitted && f.type_piece.errors" class="invalid-form-feedback" >                      
                           <span *ngIf="f.type_piece.errors.required"> / Ce champ est obligatoire</span>
                         </span>
                       </label>
  
                       <ng-select placeholder="{{__('concerner.type_piece')}}"
                       aria-readonly=""
                           formControlName="type_piece" 
                           [(ngModel)]="concerner.type_piece" 
                           class="form-control"
                           [ngClass]="{ 'invalid-form-input-validate': submitted && f.type_piece.errors }"
                           (change)="changeTypePiece($event)"
                           >
                           <ng-option  *ngFor="let type of type_pieces" [value]="type?.id ">{{ type?.type }}</ng-option>
                       </ng-select> 
                       </div>
                   </div>
  
                   <div class="col-lg-4">
                     <div class="form-group">
                       <label >{{__('concerner.numero_piece')}} (<span class="required-red" >{{__('label.require')}}</span>)
                         <span *ngIf="submitted && f.numero_piece.errors" class="invalid-form-feedback" >                      
                           <span *ngIf="f.numero_piece.errors.required"> / Ce champ est obligatoire</span>
                         </span>
                         <span *ngIf="pieceLengthError" class="invalid-form-feedback" >                      
                          <span > / {{concerner.type_piece == 0 ? 'Le CIN doit avoir 12 nombres' : '' }}</span>
                        </span>
                       </label>
                         <input minlength="{{concerner.type_piece == 0 ? 12 : 1}}" maxlength="{{concerner.type_piece == 0 ? 12 : 9}}" formControlName="numero_piece" #element_numero_piece (input)="controleNumero_piece(element_numero_piece)" type="text" class="form-control" [(ngModel)]="concerner.numero_piece"  placeholder="{{__('concerner.numero_piece')}}" >
                       </div>
                   </div>
  
                   <div class="col-lg-4">
                     <div class="form-group">
                         <label >{{__('concerner.date_livrance')}} (<span class="required-red" >{{__('label.require')}}</span>)
                           <span *ngIf="submitted && f.date_livrance.errors" class="invalid-form-feedback" >                      
                             <span *ngIf="f.date_livrance.errors.required"> / Ce champ est obligatoire</span>
                           </span>
                         </label>
                         <input formControlName="date_livrance"  type="date" class="form-control" [(ngModel)]="concerner.date_livrance"  placeholder="{{__('concerner.date_livrance')}}" >
                     </div>
                   </div>
  
              </div>
              <!-- INFORMATION IDENTIFIANT NATIONAL -->
              <hr>
              <!--INFORMATION POSTE MINISTERIELLE -->
              <div class="row">
                   <div class="col-lg-12">
                     <div class="form-group">
                         <label >{{__('concerner.etablissement_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                           <span *ngIf="submitted && f.etablissement_id.errors" class="invalid-form-feedback" >                      
                             <span *ngIf="f.etablissement_id.errors.required"> / Ce champ est obligatoire</span>
                           </span>
                         </label>
                         <ng-select placeholder="{{__('concerner.etablissement_id')}}"
                         aria-readonly=""
                             formControlName="etablissement_id" 
                             [(ngModel)]="concerner.etablissement_id" 
                             class="form-control"
                             [ngClass]="{ 'invalid-form-input-validate': submitted && f.etablissement_id.errors }"
                             (change)="getEtablissemenNameSelected(selectDropdownAddConcerner)"
                             [readonly]="superAdmin == 0 && activeEtabId != 1"
                             >
                             <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
                         </ng-select>
  
                        <!--  <input formControlName="etablissement_id"  type="number" class="form-control" [(ngModel)]="concerner.etablissement_id" id="etablissement_id" placeholder="{{__('concerner.etablissement_id')}}" > -->
                     </div>
                   </div>
  
                   <div class="col-lg-6">
                     <div class="form-group">
                       <label >{{__('concerner.categorie_concerne_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                         <span *ngIf="submitted && f.categorie_concerne_id.errors" class="invalid-form-feedback" >                      
                           <span *ngIf="f.categorie_concerne_id.errors.required"> / Ce champ est obligatoire</span>
                         </span>
                       </label>
                       <ng-select placeholder="{{__('concerner.categorie_concerne_id')}}"
                       aria-readonly=""
                           formControlName="categorie_concerne_id" 
                           [(ngModel)]="concerner.categorie_concerne_id" 
                           class="form-control"
                           [ngClass]="{ 'invalid-form-input-validate': submitted && f.categorie_concerne_id.errors }"
                           (change)="getConcerneNameSelected()"
                           #selectDropdownAddConcerner
                           >
                           <ng-option  *ngFor="let categorie of categories" [value]="categorie?.id ">{{ categorie?.name }}</ng-option>
                       </ng-select> 
                       </div>
                   </div>
  
                   <div class="col-lg-6">
                     <div class="form-group">
                         <label >{{__('concerner.matricule')}} (<span class="required-red" >{{__('label.require')}}</span>)
                           <span *ngIf="submitted && f.matricule.errors" class="invalid-form-feedback" >                      
                             <span *ngIf="f.matricule.errors.required">  / Ce champ est obligatoire</span>
                           </span>
                           <!-- <span *ngIf="matrculeLengthError || f.matricule.errors.maxlength" class="invalid-form-feedback" >                      
                            <span >  / Le matricule doit contenir 6 nombres</span>
                          </span> -->
                         </label>
                         <input formControlName="matricule"  #element_matricul (input)="controleMatricule(element_matricul)"  type="text" class="form-control" [(ngModel)]="concerner.matricule"  placeholder="{{__('concerner.matricule')}}" >
                     </div>
                   </div>
  
              </div>
              <!--INFORMATION POSTE MINISTERIELLE -->
              <hr>
              <!--INFORMATION CONTACT-->
              <div class="row">
                   
                  <div class="col-lg-6">
                     <div class="form-group">
                         <label >
                             {{__('civil.phone')}} 
                             <span *ngIf="submitted && f.telephone.errors" class="invalid-form-feedback">
                                 <span *ngIf="f.telephone.errors.required"> / Ce champ est obligatoire</span>
                             </span>
                         </label>
                         <input 
                             formControlName="telephone" 
                             #element type="text" 
                             ng2TelInput   
                             onKeyPress="if(this.value.length==10) return false;"
                             (intlTelInputObject)="telInputObject($event)" 
                             (countryChange)="onCountryChange($event)"
                             [ng2TelInputOptions]="{initialCountry: currenCode,preferredCountries:['mg','sn']}"
                             [(ngModel)]="concerner.telephone"  
                             (focus)="controle(element)" 
                             (input)="controle(element)"     
                             name="telephone" 
                             id="telephone" 
                             class="form-control" 
                             placeholder="03X XX XXX XX" 
                             [ngClass]="{ 'invalid-form-input-validate': submitted && f.telephone.errors }"
                         >
                     </div>
                  </div>
  
                  <div class="col-lg-6">
                    <div class="form-group">
                        <label >{{__('concerner.email')}}(<span class="required-red" >{{__('label.require')}}</span>)
                          <span *ngIf="submitted && f.email.errors" class="invalid-form-feedback" >                      
                            <span *ngIf="f.email.errors.required"> / Ce champ est obligatoire</span>
                          </span>
                        </label>
                        <input formControlName="email"  type="email" class="form-control" [(ngModel)]="concerner.email"  placeholder="{{__('concerner.email')}}" >
                    </div>
                  </div>
  
              </div>
              <!--INFORMATION CONTACT-->
              <hr>
              <!-- INFORMATION ADDRESSE -->
              <div class="row">
                  
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label >{{__('concerner.adresse')}} (<span class="required-red" >{{__('label.require')}}</span>)
                        <span *ngIf="submitted && f.adresse.errors" class="invalid-form-feedback" >                      
                          <span *ngIf="f.adresse.errors.required"> / Ce champ est obligatoire</span>
                        </span>
                      </label>
                        <input formControlName="adresse"  type="text" class="form-control" [(ngModel)]="concerner.adresse"  placeholder="{{__('concerner.adresse')}}" >
                      </div>
                  </div>
  
                  <!-- <div class="col-lg-4">
                    <div class="form-group">
                      <label >{{__('concerner.province_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                        <span *ngIf="submitted && f.province_id.errors" class="invalid-form-feedback" >                      
                          <span *ngIf="f.province_id.errors.required"> / Ce champ est obligatoire</span>
                        </span>
                      </label>
                      <ng-select placeholder="{{__('concerner.province_id')}}"
                      aria-readonly=""
                          formControlName="province_id" 
                          [(ngModel)]="concerner.province_id" 
                          class="form-control"
                          [ngClass]="{ 'invalid-form-input-validate': submitted && f.province_id.errors }"
                          [readonly]="superAdmin == 0"
                          (change)="getProvinceNameSelected()"
                          >
                          <ng-option  *ngFor="let province of provinces" [value]="province?.id ">{{ province?.name }}</ng-option>
                      </ng-select> 
                      </div>
                  </div> -->
  
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label >{{__('concerner.code_postal')}} 
                        <!-- <span *ngIf="submitted && f.code_postal.errors" class="invalid-form-feedback" >                      
                          <span *ngIf="f.code_postal.errors.required"> / Ce champ est obligatoire</span>
                        </span> -->
                      </label>
                        <input formControlName="code_postal"  type="number" min="0" class="form-control" [(ngModel)]="concerner.code_postal"  placeholder="{{__('concerner.code_postal')}}" >
                      </div>
                  </div>
  
              </div>
              <div class="col-lg-4" style="text-align: center!important;">
       
                <span class="imgUpload" >
                  <img  [src]="profileImage"style="width:85% !important; max-height:300px;" >
                </span>
                <hr style="border: 1px solid #44a1a0;">
                <span 
                  class="imgAdd"
                  (click)="documentLogo.length <= 4 ? addImage(documentFileLogo) : false"  >
                  Ajouter photo  <i class="fa fa-plus" style="padding-left: 20px; font-size: 17px;"></i>
                </span> 
                <br><br>
                <button type="button" name="button" *ngIf="update == true" (click) ="updateImg()" style="float:none" class="btn mb-4 button-control"> {{__('tooltip.enreg')}}</button>            
                 <input accept="image/*" (change)="handleInputLogo($event)" #documentFileLogo style="display: none"  id="documentlogo" name="documentlogo" type="file" class="file" multiple > 
              </div>
              <!-- INFORMATION ADDRESSE -->
  
              <div class="row">
                <div class="col-lg-12">
  
                  <button type="submit" [disabled]="registerForm.invalid"  name="button" class="btn mb-4 button-control" *ngIf="isAdd == true"   >{{__('concerner.Add_tab_concerne')}}</button>    
  
                  <!--   <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == true"   >{{__('tooltip.enreg')}}</button>   -->     
                  <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false"  > {{__('tooltip.edite')}}</button>
                  <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>            
                </div>
              </div>
  
          </div>
          
  
        
        </div>
       
    </form>
    </div>
    <div class="col-md-6 border-left" style="padding-right: 20px!important;">
      <table    style="width: 100%"  class="table table-striped table-hover border-top-none pr-0" >
        <thead>
          <tr>
            <th class="text-left">Matricule
            
            </th>
            <th class="text-left">{{__('concerner.nom')}}
            
            </th>

            <th class="text-left">{{__('concerner.prenom')}}
     
            </th>

            <th class="text-left">{{__('concerner.telephone')}}
       
            </th>

            <th class="text-left">Numéro de pièce
             
            </th>
           
            <th class=" text-center">{{__('table.action')}}</th>
          </tr>
        </thead>
        <tbody *ngIf="tab_concernee?.length != 0" >
            <tr *ngFor="let concerner of tab_concernee">
              <td class="text-left">{{ concerner?.matricule }}</td> 
              <td class="text-left">{{ concerner?.nom}}</td> 
              <td class="text-left">{{ concerner?.prenom}}</td> 
              <td class="text-left">{{ concerner?.telephone }}</td>  
                <td class="text-left">{{ concerner?.numero_piece }}</td>
                <!-- <td class="text-left">{{ concerner?.categorie }}</td>   -->
                <td class="text-center">
                    <!-- <i class="fa fa-eye icon-table vert" *ngIf="autority('BNF_5')" (click)="goAffectation(concerner.concernees_id)" ></i>
                    <i class="fa fa-edit icon-table" *ngIf="autority('BNF_3')" ngbTooltip="{{__('tooltip.edite')}}"  #openModalBtnEdit (click)="openModal(addConcerner,concerner,openModalBtnEdit)" ></i>
                    <i class="fa fa-toggle-on icon-table vert" ngbTooltip="{{__('tooltip.active')}}" #buttonElement (click)="toggleState(concerner,0,buttonElement)" *ngIf="concerner.state == 1 && autority('BNF_6')" ></i> -->
                    <i class="fa fa-trash icon-table red" *ngIf="autority('BNF_5')" ngbTooltip="{{__('tooltip.delete')}}" #openModalBtnDelete (click)="removeConcerne(concerner.matricule)"></i>
                </td>
          </tr>
        </tbody>
        
        <tr  *ngIf="tab_concernee?.length == 0" >
          <td colspan="6">
            <div class="row d-flex flex-column justify-content-center align-items-center">
              <img src="assets/img/empty-set.png" style="width: 5%;" alt="">
              <span style="font-weight: 500;" class="mt-2">Il n'y pas encore de beneficiaire ajouté!</span>
            </div>
          </td>
        </tr>
      </table>
      
      <div class="row justify-content-center">
        <!-- <div class="col-lg-6">     
            <button type="button" (click)="backForm()" name="button" class="btn mb-4 button-cancel" >Précédent</button>        
        </div> -->
       
            <button type="button" (click)="addConcernerForm()" name="button" class="btn mb-4 button-control" *ngIf="isAdd == true && tab_concernee.length > 0" >{{__('tooltip.enreg')}}</button>        
      </div>
    </div>
  </div>

</ng-template> 

<ng-template #EditConcerner style="background-color: red;  width: auto;">
    <div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>

  <div class="row">
    <div class="col-md-12">
      <form ngNativeValidate [formGroup]="registerForm" (ngSubmit)="addConcernerForm()">

        <div class="modal-body">
          <br>
          <div>
            
              <!-- INFORMATION PERSONNEL -->
              <div class="row">
                     
                     <!-- NOM -->
                     <div class="col-lg-3">
                       <div class="form-group">
                           <label >{{__('concerner.nom')}} (<span class="required-red" >{{__('label.require')}}</span>)
                             <span *ngIf="submitted && f.nom.errors" class="invalid-form-feedback" >                      
                               <span *ngIf="f.nom.errors.required"> / Ce champ est obligatoire</span>
                             </span>
                           </label>
                           <input formControlName="nom"  type="text" class="form-control" [(ngModel)]="concerner.nom"  placeholder="{{__('concerner.nom')}}" >
                       </div>
                     </div>
  
                     <!-- PRENOM -->
                     <div class="col-lg-3">
                       <div class="form-group">
                         <label >{{__('concerner.prenom')}} (<span class="required-red" >{{__('label.require')}}</span>)
                           <span *ngIf="submitted && f.prenom.errors" class="invalid-form-feedback" >                      
                             <span *ngIf="f.prenom.errors.required"> / Ce champ est obligatoire</span>
                           </span>
                         </label>
                           <input formControlName="prenom"  type="text" class="form-control" [(ngModel)]="concerner.prenom"  placeholder="{{__('concerner.prenom')}}" >
                         </div>
                     </div>

                     <div class="col-lg-3">
                      <div class="form-group">
                          <label >{{__('concerner.date_naissance')}} (<span class="required-red" >{{__('label.require')}}</span>)
                            <span *ngIf="submitted && f.date_naissance.errors" class="invalid-form-feedback" >                      
                              <span *ngIf="f.date_naissance.errors.required"> / Ce champ est obligatoire</span>
                            </span>
                          </label>
                          <input formControlName="date_naissance"  type="date" class="form-control" [(ngModel)]="concerner.date_naissance"  placeholder="{{__('concerner.date_naissance')}}" >
                      </div>
                    </div>
                     <!-- SEXE -->
                     <div class="col-lg-3">
                       <div class="form-group">
                         <label for="exampleInputEmail1">{{__('etudiant.sexe')}} (<span class="required-red" >{{__('label.require')}}</span>)</label>
                         <span *ngIf="submitted && f.sexe.errors" class="invalid-form-feedback">
                                           <span *ngIf="f.sexe.errors.required"> / Ce champ est obligatoire</span>
                                       </span> <br>
                         <div class="form-check form-check-inline mr-1" >
                           <input class="form-check-input" type="radio" [(ngModel)]="concerner.sexe" name="sexe" value="M" formControlName="sexe" 
                           [ngClass]="{ 'invalid-form-input-validate': submitted && f.sexe.errors }"> 
                           <label class="form-check-label" >{{__('etudiant.homme')}}</label>
                         </div>
                         <div class="form-check form-check-inline mr-1">
                           <input class="form-check-input" [(ngModel)]="concerner.sexe" type="radio" name="sexe" value="F" formControlName="sexe" 
                           [ngClass]="{ 'invalid-form-input-validate': submitted && f.sexe.errors }"  >
                           <label class="form-check-label" >{{__('etudiant.femme')}}</label>
                         </div>
                       </div>
                     </div>
              </div>
              <!-- INFORMATION PERSONNEL -->
  
              <hr>
              <!-- INFORMATION IDENTIFIANT NATIONAL -->
              <div class="row">
                   <div class="col-lg-6">
                     <div class="form-group">
                       <label >{{__('concerner.type_piece')}} (<span class="required-red" >{{__('label.require')}}</span>)
                         <span *ngIf="submitted && f.numero_piece.errors" class="invalid-form-feedback" >                      
                           <span *ngIf="f.type_piece.errors.required"> / Ce champ est obligatoire</span>
                         </span>
                       </label>
  
                       <ng-select placeholder="{{__('concerner.type_piece')}}"
                       aria-readonly=""
                           formControlName="type_piece" 
                           [(ngModel)]="concerner.type_piece" 
                           class="form-control"
                           [ngClass]="{ 'invalid-form-input-validate': submitted && f.type_piece.errors }"
                           (change)="changeTypePiece($event)"
                          
                           >
                           <ng-option  *ngFor="let type of type_pieces" [value]="type?.id ">{{ type?.type }}</ng-option>
                       </ng-select> 
                       </div>
                   </div>
  
                   <div class="col-lg-6">
                     <div class="form-group">
                       <label >{{__('concerner.numero_piece')}} (<span class="required-red" >{{__('label.require')}}</span>)
                         <span *ngIf="submitted && f.numero_piece.errors" class="invalid-form-feedback" >                      
                           <span *ngIf="f.numero_piece.errors.required"> / Ce champ est obligatoire</span>
                         </span>
                       </label>
                         <input  [minLength]="lengthPiece" [maxLength]="lengthPiece" formControlName="numero_piece"  type="{{concerner.numero_piece == 0 ? 'number' : 'text'}}" class="form-control" [(ngModel)]="concerner.numero_piece"  placeholder="{{__('concerner.numero_piece')}}" >
                       </div>
                   </div>
  
                   <div class="col-lg-6">
                     <div class="form-group">
                         <label >{{__('concerner.date_livrance')}} (<span class="required-red" >{{__('label.require')}}</span>)
                           <span *ngIf="submitted && f.date_livrance.errors" class="invalid-form-feedback" >                      
                             <span *ngIf="f.date_livrance.errors.required"> / Ce champ est obligatoire</span>
                           </span>
                         </label>
                         <input formControlName="date_livrance"  type="date" class="form-control" [(ngModel)]="concerner.date_livrance"  placeholder="{{__('concerner.date_livrance')}}" >
                     </div>
                   </div>

                   <div class="col-lg-6">
                    <div class="form-group">
                        <label >{{__('concerner.etablissement_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                          <span *ngIf="submitted && f.etablissement_id.errors" class="invalid-form-feedback" >                      
                            <span *ngIf="f.etablissement_id.errors.required"> / Ce champ est obligatoire</span>
                          </span>
                        </label>
                        <ng-select placeholder="{{__('concerner.etablissement_id')}}"
                        aria-readonly=""
                            formControlName="etablissement_id" 
                            [(ngModel)]="concerner.etablissement_id" 
                            class="form-control"
                            [ngClass]="{ 'invalid-form-input-validate': submitted && f.etablissement_id.errors }"
                            (change)="getEtablissemenNameSelected(selectDropdownAddConcerner)"
                            [readonly]="superAdmin == 0 && activeEtabId != 1"
                            >
                            <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
                        </ng-select>
 
                       <!--  <input formControlName="etablissement_id"  type="number" class="form-control" [(ngModel)]="concerner.etablissement_id" id="etablissement_id" placeholder="{{__('concerner.etablissement_id')}}" > -->
                    </div>
                  </div>
  
              </div>
              <!-- INFORMATION IDENTIFIANT NATIONAL -->
              <hr>
              <!--INFORMATION POSTE MINISTERIELLE -->
              <div class="row">
                   <div class="col-lg-6">
                     <div class="form-group">
                       <label >{{__('concerner.categorie_concerne_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                         <span *ngIf="submitted && f.categorie_concerne_id.errors" class="invalid-form-feedback" >                      
                           <span *ngIf="f.categorie_concerne_id.errors.required"> / Ce champ est obligatoire</span>
                         </span>
                       </label>
                       <ng-select placeholder="{{__('concerner.categorie_concerne_id')}}"
                       aria-readonly=""
                           formControlName="categorie_concerne_id" 
                           [(ngModel)]="concerner.categorie_concerne_id" 
                           class="form-control"
                           [ngClass]="{ 'invalid-form-input-validate': submitted && f.categorie_concerne_id.errors }"
                           (change)="getConcerneNameSelected()"
                           #selectDropdownAddConcerner
                           >
                           <ng-option  *ngFor="let categorie of categories" [value]="categorie?.id ">{{ categorie?.name }}</ng-option>
                       </ng-select> 
  
                          <!-- <input formControlName="categorie_concerne_id"  type="number" class="form-control" [(ngModel)]="concerner.categorie_concerne_id"  placeholder="{{__('concerner.categorie_concerne_id')}}" >  -->
                       </div>
                   </div>
  
                   <div class="col-lg-6">
                     <div class="form-group">
                         <label >{{__('concerner.matricule')}} (<span class="required-red" >{{__('label.require')}}</span>)
                           <span *ngIf="submitted && f.matricule.errors" class="invalid-form-feedback" >                      
                             <span *ngIf="f.matricule.errors.required"> / Ce champ est obligatoire</span>
                           </span>
                         </label>
                         <input formControlName="matricule"  #element_matricul (input)="controleMatricule(element_matricul)"   type="text" class="form-control" [(ngModel)]="concerner.matricule"  placeholder="{{__('concerner.matricule')}}" >
                     </div>
                   </div>

                   <div class="col-lg-6">
                    <div class="form-group">
                        <label >
                            {{__('civil.phone')}} (<span class="required-red" >{{__('label.require')}}</span>)
                            <span *ngIf="submitted && f.telephone.errors" class="invalid-form-feedback">
                                <span *ngIf="f.telephone.errors.required"> / Ce champ est obligatoire</span>
                            </span>
                        </label>
                        <input 
                            formControlName="telephone" 
                            #element type="text" 
                            ng2TelInput   
                            onKeyPress="if(this.value.length==10) return false;"
                            (intlTelInputObject)="telInputObject($event)" 
                            (countryChange)="onCountryChange($event)"
                            [ng2TelInputOptions]="{initialCountry: currenCode,preferredCountries:['mg','sn']}"
                            [(ngModel)]="concerner.telephone"  
                            (focus)="controle(element)" 
                            (input)="controle(element)"     
                            name="telephone" 
                            id="telephone" 
                            class="form-control" 
                            placeholder="03X XX XXX XX" 
                            [ngClass]="{ 'invalid-form-input-validate': submitted && f.telephone.errors }"
                        >
                    </div>
                   </div>

                   <div class="col-lg-6">
                    <div class="form-group">
                        <label >{{__('concerner.email')}} 
                          <!-- <span *ngIf="submitted && f.email.errors" class="invalid-form-feedback" >                      
                            <span *ngIf="f.email.errors.required"> / Ce champ est obligatoire</span>
                          </span> -->
                        </label>
                        <input formControlName="email"  type="email" class="form-control" [(ngModel)]="concerner.email"  placeholder="{{__('concerner.email')}}" >
                    </div>
                  </div>
  
  
              </div>
              <!--INFORMATION POSTE MINISTERIELLE -->
              <hr>
              <!-- INFORMATION ADDRESSE -->
              <div class="row">
                  
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label >{{__('concerner.adresse')}} (<span class="required-red" >{{__('label.require')}}</span>)
                        <span *ngIf="submitted && f.adresse.errors" class="invalid-form-feedback" >                      
                          <span *ngIf="f.adresse.errors.required"> / Ce champ est obligatoire</span>
                        </span>
                      </label>
                        <input formControlName="adresse"  type="text" class="form-control" [(ngModel)]="concerner.adresse"  placeholder="{{__('concerner.adresse')}}" >
                      </div>
                  </div>
  
                  <!-- <div class="col-lg-4">
                    <div class="form-group">
                      <label >{{__('concerner.province_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                        <span *ngIf="submitted && f.province_id.errors" class="invalid-form-feedback" >                      
                          <span *ngIf="f.province_id.errors.required"> / Ce champ est obligatoire</span>
                        </span>
                      </label>
                      <ng-select placeholder="{{__('concerner.province_id')}}"
                      aria-readonly=""
                          formControlName="province_id" 
                          [(ngModel)]="concerner.province_id" 
                          class="form-control"
                          [ngClass]="{ 'invalid-form-input-validate': submitted && f.province_id.errors }"
                          [readonly]="superAdmin == 0"
                          (change)="getProvinceNameSelected()"
                          >
                          <ng-option  *ngFor="let province of provinces" [value]="province?.id ">{{ province?.name }}</ng-option>
                      </ng-select> 
                      </div>
                  </div> -->
  
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label >{{__('concerner.code_postal')}} 
                        <!-- <span *ngIf="submitted && f.code_postal.errors" class="invalid-form-feedback" >                      
                          <span *ngIf="f.code_postal.errors.required"> / Ce champ est obligatoire</span>
                        </span> -->
                      </label>
                        <input formControlName="code_postal"  type="number" min="0" class="form-control" [(ngModel)]="concerner.code_postal"  placeholder="{{__('concerner.code_postal')}}" >
                      </div>
                  </div>
  
              </div>
              <div class="col-lg-4" style="text-align: center!important;">
       
                <span class="imgUpload" >
                  <img  [src]="profileImage"style="width:85% !important; max-height:300px;" >
                </span>
                <hr style="border: 1px solid #44a1a0;">
                <span 
                  class="imgAdd"
                  (click)="documentLogo.length <= 4 ? addImage(documentFileLogo) : false"  >
                  Ajouter photo  <i class="fa fa-plus" style="padding-left: 20px; font-size: 17px;"></i>
                </span> 
                <br><br>
                <button type="button" name="button" *ngIf="update == true" (click) ="updateImg()" style="float:none" class="btn mb-4 button-control"> {{__('tooltip.enreg')}}</button>            
                 <input accept="image/*" (change)="handleInputLogo($event)" #documentFileLogo style="display: none"  id="documentlogo" name="documentlogo" type="file" class="file" multiple > 
              </div>
              <!-- INFORMATION ADDRESSE -->
  
              <div class="row">
                <div class="col-lg-12">
  
                  <button type="button" name="button" (click)="addtab_concernee()"  class="btn mb-4 button-control" *ngIf="isAdd == true"   >{{__('concerner.Add_tab_concerne')}}</button>     
                  <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false"  > {{__('tooltip.edite')}}</button>
                  <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>            
                </div>
              </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
</ng-template> 

<ng-template #addImportExcel style="background-color: red; width: auto;">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form ngNativeValidate [formGroup]="registerFormImporExcel"  (ngSubmit)="AddImportExcel()">

    <div class="modal-body">
      <br>
      
      <div class="row"> 
        <div class="col-lg-4">
          <div class="form-group">
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
          </div>
        </div>

      </div>
 
      <div class="row">
         
        <div class="col-lg-6">
          <div class="form-group">
              <label >{{__('concerner.etablissement_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
             <span *ngIf="submitted && f_import.etab_id.errors" class="invalid-form-feedback" >                      
                  <span *ngIf="f_import.etab_id.errors.required"> / Ce champ est obligatoire</span>

                </span> 

              </label>
              <ng-select placeholder="{{__('concerner.etablissement_id')}}" required
                  aria-readonly=""
                  formControlName="etab_id"
                  [ngClass]="{ 'invalid-form-input-validate': submitted && f_import.etab_id.errors }"
                  [(ngModel)]="etab_id" 
                  class="form-control"
                  [readonly]="(superAdmin == 0 || disableEtabInput) && activeEtabId != 1 "
                  (change)="getCategorieByIdEtab($event,categorieEl)"
                  >
                  <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
              </ng-select>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="form-group">
            <label >{{__('concerner.categorie_concerne_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
             <span *ngIf="submitted && f_import.cat_id.errors" class="invalid-form-feedback" >                      
                <span *ngIf="f_import.cat_id.errors.required"> / Ce champ est obligatoire</span>

              

              </span> 
            </label>
            <ng-select placeholder="{{__('concerner.categorie_concerne_id')}}"
                [ngClass]="{ 'invalid-form-input-validate': submitted && f_import.cat_id.errors }"
                formControlName="cat_id"
                [(ngModel)]="cat_id" 
                class="form-control"  
                #categorieEl              
                >
                <ng-option  *ngFor="let categorie of categories" [value]="categorie?.id ">{{ categorie?.name }}</ng-option>
            </ng-select> 

            </div>
        </div> 

      </div>

      <div class="row">
             
        <div class="col-lg-6">
            <label >
              <span *ngIf="submitted && f_import.fichier.errors" class="invalid-form-feedback">
                  <span *ngIf="f_import.fichier.errors.required"><i class="fa fa-times-circle"></i> Ce champ est obligatoire</span>
              </span>
            </label>
            <br>
            <input 
            (change)="handleInputChange($event)" 
            #documentExcel 
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            id="documentFileImport" 
            formControlName="fichier"
            name="document" 
            type="file" 
            [disabled]="disableExcelInput"
            [ngClass]="{'cursor-interdit':disableExcelInput}"
            >
            <label class="custom-file-upload" (click)="importExcel(documentExcel)">
              <i class="fa fa-cloud-upload"></i> {{filename != "" ? filename : __('etudiant.fichier_importer') }}
          </label>
          
        </div>
      </div>
      <div class="row" *ngIf="!tabbenefshow">
        <div class="col-lg-12">
        
         <button type="submit" name="button" class="btn mb-4 button-control"  >{{__('concerner.add_infos')}}</button>   

          <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>       

        </div>
      </div>



    </div>
  </form>
  <hr>
  
  <div *ngIf="isImported"  class="col-md-12" style="padding: 0 3rem 2rem 3rem;height: 300px;overflow: scroll;">
    <hr>
    <div class="row">
      <div class="col-md-4 text-center">
        <label> {{__('carte.nb_ligne_parcourues')}} : <b>{{totalparcourue}}</b></label>
      </div>
      <div class="col-md-4 text-center">
        <label> {{__('carte.nb_ligne_valides')}} : <b class="bv_valide">{{totalvalid}}</b></label>
      </div>
      <div class="col-md-4 text-center">
        <label> {{__('carte.nb_ligne_invalides')}} : <b class="bv_invalide">{{totalinvalid}}</b></label>
      </div>
    </div>
    <div class="d-flex justify-content-end my-3">
      <div class="col-md-3">
        <ng-select 
          placeholder="Séléctionner un filtre" 
          class="form-control"
          (change)="handleFilter($event)" 
          
        >
          <ng-option  *ngFor="let filter of filterInsertList" [value]="filter?.id ">{{ filter?.text }}</ng-option>
      </ng-select>
      </div>
    </div>
    
    <div [ngClass]="{'height-scroll':beneficiaire_verif?.length>15 ,'border-top': true}" >
      <table style="width: 100%"  class="table table-striped table-hover " >
      <thead>
        <tr>
          <th class="text-left">{{__('concerner.matricule')}}</th>
          <th class="text-left">{{__('concerner.nom')}}</th>
          <th class="text-left">{{__('concerner.prenom')}}</th> 
          <th class="text-left">{{__('concerner.type_piece')}}</th>
          <th class="text-left">{{__('concerner.numero_piece')}}</th>
          <th class="text-left">{{__('concerner.date_livrance')}}</th>
          <th class="text-left">{{__('concerner.telephone')}}</th>
          <th class="text-left">{{__('concerner.email')}}</th>
          <th class="text-left">{{__('concerner.date_naissance')}}</th>
          <th class="text-left">{{__('concerner.code_postal')}}</th>
          <th class="text-left">{{__('concerner.sexe')}}</th>
          <!-- <th class="text-left">{{__('concerner.adresse')}}</th> -->
          <th class="text-left">{{__('carte.etat')}}</th>
        </tr>
      </thead>
      <tbody *ngIf="beneficiaire_verif?.length != 0" >
          <tr *ngFor="let bv of beneficiaire_verif">
            <td class="text-left">{{ bv?.matricule}}</td> 
              <td class="text-left">{{ bv?.nom }}</td>
              <td class="text-left">{{ bv?.prenom }}</td>    
              <td class="text-left" *ngIf="bv?.type_piece==0">CIN</td> 
              <td class="text-left" *ngIf="bv?.type_piece==1">Passeport</td>  
              <td class="text-left" *ngIf="bv?.type_piece==2">Autre</td> 
              <td class="text-left">{{ bv?.numero_piece}}</td> 
              <td class="text-left">{{ bv?.date_livrance }}</td>
              <td class="text-left">{{ bv?.telephone }}</td>
              <td class="text-left">{{ bv?.email }}</td>    
              <td class="text-left">{{ bv?.date_naissance}}</td> 
              <td class="text-left">{{ bv?.code_postal}}</td> 
              <td class="text-left">{{ bv?.sexe }}</td>  
              <!-- <td class="text-left">{{ bv?.adresse }}</td> -->
              <td class="text-left" style="width:15%">
                <ng-container  *ngIf="bv?.valid==0" >
                  <p *ngIf="bv?.telephone_invalid==1" class="bv_invalide">
                    <i  class="fa fa-times-circle"></i>&nbsp;{{__('concerner.tel_invalid')}}
                  </p>
                  <p *ngIf="bv?.telephone_existant==1"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;{{__('concerner.tel_existant')}}
                  </p>
                  <p *ngIf="bv?.error_dateLivrance"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;{{bv.error_dateLivrance}}
                  </p>
                  <p *ngIf="bv?.pas_de_matricule"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;Matricule obligatoire
                  </p>
                  <p *ngIf="bv?.pas_de_nom == 1"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;Nom obligatoire
                  </p>
                  <p *ngIf="bv?.piece_invalid == 1"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;Numéro pièce invalide
                  </p>
                  <p *ngIf="bv?.date_invalid == 1"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;Date invalide
                  </p>
              
                  
                  <p *ngIf="bv?.existant_base==1" class="bv_invalide">
                    <i  class="fa fa-times-circle"></i>&nbsp;{{__('concerner.deja_existant')}}
                </p>
                </ng-container>
              
                
                <p *ngIf="bv?.valid == 1" class="bv_valide">
                  <i class="fa fa-check-circle"></i>&nbsp;{{__('carte.valide')}}
                </p>

              </td>
    
        </tr>
      </tbody>
      <div class="d-flex w-100 justify-content-center" *ngIf="beneficiaire_verif?.length == 0">
        <p>Aucun donneé disponible</p>
      </div>
    
    </table>
    </div>
    <div class="row" *ngIf="beneficiaire_verif?.length != 0">
      <div class="col-lg-12 mt-2">
      
       <button type="button" name="button" [disabled]="totalvalid==0" (click)="validerAddBenef()" class="btn mb-4 button-control"  >{{__('btn.valide')}}</button>   

        <button type="button" name="cancel" (click)="reimporterExcel()" class="btn mb-4 button-cancel"> {{__('concerner.reimporter_excel')}}</button>       

      </div>
    </div>
  </div>


  
</ng-template> 

<ng-template #showDetail>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{__('label_details')}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <tabset>
      <tab heading="{{__( 'categorie_concerner.d_concerner')}}">
        <table style="width: 100%;">

          <tr>
            <td class="tdd tab-detail-title" nowrap>{{__('concerner.etablissement_id')}}</td>
            <td  class="tab-detail" > {{concerner?.etablissement_name}} </td>

            <td class="tdd tab-detail-title" nowrap>{{__('concerner.province_id')}}</td>
            <td  class="tab-detail" > {{concerner?.province}} </td>


            <td class="tdd tab-detail-title" nowrap>{{__('concerner.categorie_concerne_id')}}</td>
            <td  class="tab-detail" > {{concerner?.categorie}} </td>
          </tr>

          <tr>
            <td class="tdd tab-detail-title" nowrap>{{__('concerner.nom')}}</td>
            <td  class="tab-detail" > {{concerner?.nom}} </td>

            <td class="tdd tab-detail-title" nowrap>{{__('concerner.prenom')}}</td>
            <td  class="tab-detail" > {{concerner?.prenom}} </td>

            <td class="tdd tab-detail-title" nowrap>{{__('concerner.sexe')}}</td>
            <td  class="tab-detail" > {{concerner?.sexe}} </td>
          </tr>

          <tr>
            <td class="tdd tab-detail-title" nowrap>{{__('concerner.date_naissance')}}</td>
            <td  class="tab-detail" > {{concerner?.date_naissance}} </td>

            <td class="tdd tab-detail-title" nowrap>{{__('concerner.telephone')}}</td>
            <td  class="tab-detail" > {{concerner?.telephone}} </td>

            <td class="tdd tab-detail-title" nowrap>{{__('concerner.email')}}</td>
            <td  class="tab-detail" > {{concerner?.email}} </td>
          </tr>

          <tr>
            <td class="tdd tab-detail-title" nowrap>{{__('concerner.matricule')}}</td>
            <td  class="tab-detail" > {{concerner?.matricule}} </td>

            <td class="tdd tab-detail-title" nowrap>{{__('concerner.date_livrance')}}</td>
            <td  class="tab-detail" > {{concerner?.date_livrance}} </td>

            <td class="tdd tab-detail-title" nowrap>{{__('concerner.code_postal')}}</td>
            <td  class="tab-detail" > {{concerner?.code_postal}} </td>
          </tr>

          <tr>
            <td class="tdd tab-detail-title" nowrap>{{__('concerner.numero_piece')}}</td>
            <td  class="tab-detail" > {{concerner?.numero_piece}} </td>
            
            <td class="tdd tab-detail-title" nowrap>{{__('concerner.type_piece')}}</td>
            <td  class="tab-detail" *ngIf="concerner.type_piece == 0"> CIN </td>
            <td  class="tab-detail" *ngIf="concerner.type_piece == 1"> Passeport </td>
            <td  class="tab-detail" *ngIf="concerner.type_piece == 2"> Autre </td>

            <td class="tdd tab-detail-title" nowrap>{{__('concerner.carte')}}</td>
            <td  class="tab-detail" *ngIf="concerner.carte_numero_serie != null"> {{concerner?.carte_numero_serie}} </td>
            <td  class="tab-detail" *ngIf="concerner.carte_numero_serie == null">
                <button type="button" class="btn btn-primary" (click)="goAffectation(concerner.concernees_id)">{{ __('concerner.affecte') }}</button>
            </td>

          </tr>

        </table>
      </tab>
    </tabset>
  </div>
</ng-template>

 


<ng-template #refModalEdutiantBoursier style="background-color: red; width: auto;">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form ngNativeValidate [formGroup]="registerFormEtudiantBoursier"  (ngSubmit)="getEdutiantConcenant()">

    <div class="modal-body">
      <br>
      
      <div class="row"> 
        <div class="col-lg-4">
          <div class="form-group">
          </div>
        </div>

        <div class="col-lg-4">
          <div class="form-group">
          </div>
        </div>

      </div>
 
      <div class="row">
         
        <div class="col-lg-6">
          <div class="form-group">
              <label >{{__('concerner.etablissement_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
             <span *ngIf="submitted && f_boursier_etudiant.etab_id.errors" class="invalid-form-feedback" >                      
                  <span *ngIf="f_boursier_etudiant.etab_id.errors.required"> / Ce champ est obligatoire</span>

                </span> 

              </label>
              <ng-select placeholder="{{__('concerner.etablissement_id')}}" required
                  aria-readonly=""
                  formControlName="etab_id"
                  [ngClass]="{ 'invalid-form-input-validate': submitted && f_boursier_etudiant.etab_id.errors }"
                  [(ngModel)]="insertBoursierConcerner.etablissement_id" 
                  class="form-control"
                  [readonly]="(superAdmin == 0 || disableEtabInput ) && activeEtabId != 1"
                  (change)="getCategorieByIdEtab($event,categorieEl)"
                  >
                  <ng-container *ngFor="let etablissement of etablissements" >
                      <ng-option *ngIf="etablissement.type_etablissement == 1"  (change)="test()" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
                  </ng-container>
              </ng-select>
          </div>
        </div>

        <!-- <div class="col-lg-6">
          <div class="form-group">
            <label >{{__('concerner.categorie_concerne_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
             <span *ngIf="submitted && f_boursier_etudiant.cat_id.errors" class="invalid-form-feedback" >                      
                <span *ngIf="f_boursier_etudiant.cat_id.errors.required"> / Ce champ est obligatoire</span>

              

              </span> 
            </label>
            <ng-select placeholder="{{__('concerner.categorie_concerne_id')}}"
                [ngClass]="{ 'invalid-form-input-validate': submitted && f_boursier_etudiant.cat_id.errors }"
                formControlName="cat_id"
                [(ngModel)]="insertBoursierConcerner.categorie_concerne_id" 
                class="form-control"  
                #categorieEl      
                >
                <ng-option  *ngFor="let categorie of categories" [value]="categorie?.categorie_concernee_id ">{{ categorie?.name }}</ng-option>
            </ng-select> 

            </div>
        </div>  -->
        <div class="col-lg-6">
          <div class="form-group">
            <label >{{__('concerner.niveau')}} (<span class="required-red" >{{__('label.require')}}</span>)
             <span *ngIf="submitted && f_boursier_etudiant.niveau_id.errors" class="invalid-form-feedback" >                      
                <span *ngIf="f_boursier_etudiant.niveau_id.errors.required"> / Ce champ est obligatoire</span>

              

              </span> 
            </label>
            <ng-select placeholder="{{__('concerner.niveau')}}"
                [ngClass]="{ 'invalid-form-input-validate': submitted && f_boursier_etudiant.niveau_id.errors }"
                formControlName="niveau_id"
                [(ngModel)]="insertBoursierConcerner.niveau_id" 
                class="form-control"  
                >
                <ng-option  *ngFor="let niveau of niveauList" [value]="niveau?.id ">{{ niveau?.name }}</ng-option>
            </ng-select> 

            </div>
        </div> 

      </div>

      <div class="row">
        <div class="col-lg-12">
        
         <button type="submit" name="button" class="btn mb-4 button-control"  >{{__('concerner.get_edutiant')}}</button>   

          <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>       

        </div>
      </div>



    </div>
  </form>
  <hr>
  
  <div *ngIf="isImported"  class="col-md-12" style="padding: 0 3rem 2rem 3rem;height: 300px;overflow: scroll;">
    <hr>
    <div class="row">
      <div class="col-md-4 text-center">
        <label> {{__('carte.nb_ligne_parcourues')}} : <b>{{totalparcourue}}</b></label>
      </div>
      <div class="col-md-4 text-center">
        <label> {{__('carte.nb_ligne_valides')}} : <b class="bv_valide">{{totalvalid}}</b></label>
      </div>
      <div class="col-md-4 text-center">
        <label> {{__('carte.nb_ligne_invalides')}} : <b class="bv_invalide">{{totalinvalid}}</b></label>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center py-2 my-3">
      <div class="export">
        <button class="px-2" (click)="exportEtudiantPDF()">
          {{__('ordre_paiement.export_pdf')}}  
          <img   class="mb-1" src="assets/img/pdf-file.png" alt=""> 
         </button>
         <button (click)="exportEtudiantEXCEL()" class="ml-3 px-2">
          {{__('ordre_paiement.export_excel')}}
          <img class="mb-1" src="assets/img/excel.png" alt="">
          </button>
       </div>
      <div class="col-md-3">
        <ng-select 
          placeholder="Séléctionner un filtre" 
          class="form-control"
          (change)="handleFilter($event)" 
          
        >
          <ng-option  *ngFor="let filter of filterInsertList" [value]="filter?.id ">{{ filter?.text }}</ng-option>
      </ng-select>
      </div>
    </div>
    
    <div [ngClass]="{'height-scroll':beneficiaire_verif?.length>15 ,'border-top': true}" >
      <table style="width: 100%"  class="table table-striped table-hover " >
      <thead>
        <tr>
          <th class="text-left">{{__('concerner.matricule')}}</th>
          <th class="text-left">{{__('concerner.nom')}}</th>
          <th class="text-left">{{__('concerner.prenom')}}</th>
          <th class="text-left">{{__('concerner.type_piece')}}</th>
          <th class="text-left">{{__('concerner.numero_piece')}}</th>
          
          <!-- <th class="text-left">{{__('concerner.date_livrance')}}</th> -->
          <th class="text-left">{{__('concerner.telephone')}}</th>
          <th class="text-left">{{__('concerner.email')}}</th>
          <th class="text-left">{{__('concerner.date_naissance')}}</th>
          <!-- <th class="text-left">{{__('concerner.code_postal')}}</th> -->
          <th class="text-left">{{__('concerner.sexe')}}</th>
          <!-- <th class="text-left">{{__('concerner.adresse')}}</th> -->
          <th class="text-left">{{__('carte.etat')}}</th>
        </tr>
      </thead>
      <tbody *ngIf="beneficiaire_verif?.length != 0" >
          <tr *ngFor="let bv of beneficiaire_verif">
            <td class="text-left">{{ bv?.matricule}}</td> 
              <td class="text-left">{{ bv?.nom }}</td>
              <td class="text-left">{{ bv?.prenom }}</td>    
              <td class="text-left" *ngIf="bv?.type_piece==0">CIN</td> 
              <td class="text-left" *ngIf="bv?.type_piece==1">Passeport</td>  
              <td class="text-left" *ngIf="bv?.type_piece==2">Autre</td> 
              <td class="text-left">{{ bv?.numero_piece}}</td> 
              <!-- <td class="text-left">{{ bv?.date_livrance }}</td> -->
              <td class="text-left">{{ bv?.telephone }}</td>
              <td class="text-left">{{ bv?.email || '' }}</td>    
              <td class="text-left">{{ bv?.date_naissance}}</td> 
              <!-- <td class="text-left">{{ bv?.code_postal}}</td>  -->
              <td class="text-left">{{ bv?.sexe }}</td>  
              <!-- <td class="text-left">{{ bv?.adresse }}</td> -->
              <td class="text-left">
                <ng-container  *ngIf="bv?.valid==0" >
                  <p *ngIf="bv?.telephone_invalid==1" class="bv_invalide">
                    <i  class="fa fa-times-circle"></i>&nbsp;{{__('concerner.tel_invalid')}}
                  </p>
                  <p *ngIf="bv?.telephone_existant==1"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;{{__('concerner.tel_existant')}}
                  </p>
                  <p *ngIf="bv?.error_dateLivrance"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;{{bv.error_dateLivrance}}
                  </p>
                  <p *ngIf="bv?.pas_de_matricule"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;Matricule obligatoire
                  </p>
                  <p *ngIf="bv?.pas_de_nom == 1"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;Nom obligatoire
                  </p>
                  <p *ngIf="bv?.piece_invalid == 1"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;Numéro pièce invalide
                  </p>
                  <p *ngIf="bv?.date_invalid == 1"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;Date invalide
                  </p>
                  <p *ngIf="bv?.telephone_redendant == 1"  class="bv_invalide">
                    <i class="fa fa-times-circle"></i>&nbsp;Téléphone doublons
                  </p>
              
                  
                  <p *ngIf="bv?.existant_base==1" class="bv_invalide">
                    <i  class="fa fa-times-circle"></i>&nbsp;{{__('concerner.deja_existant')}}
                  </p>
                </ng-container>
              
                
                <p *ngIf="bv?.valid == 1" class="bv_valide">
                  <i class="fa fa-check-circle"></i>&nbsp;{{__('carte.valide')}}
                </p>

              </td>
    
        </tr>
      </tbody>
      <div class="d-flex w-100 justify-content-center" *ngIf="beneficiaire_verif?.length == 0">
        <p>Aucun donneé disponible</p>
      </div>
    
    </table>
    </div>
    <div class="row" *ngIf="beneficiaire_verif?.length != 0">
      <div class="col-lg-12 mt-2">
      
       <button type="button" name="button" [disabled]="totalvalid==0" (click)="submitEtudiantBoursier()" class="btn mb-4 button-control"  >{{__('btn.valide')}}</button>   

        <!-- <button type="button" name="cancel" (click)="reimporterExcel()" class="btn mb-4 button-cancel"> {{__('concerner.reimporter_excel')}}</button>        -->

      </div>
    </div>
  </div>


  
</ng-template> 



