import { Component, OnInit, TemplateRef } from '@angular/core';
import { Translatable } from '../..//../../service/Translatable';
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {HelperService} from "../../../../service/helper.service";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from "@angular/common/http";
import {user, profil, etablissement, type_etablissement, type_user} from "../../../../model/db";
import {valuesys} from "../../../../../../options";
import {environment} from "../../../../../../environments/environment";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {DataTableDirective} from "angular-datatables";
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import { idText } from 'typescript';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-utilisateur',
  templateUrl: './utilisateur.component.html',
  styleUrls: ['./utilisateur.component.scss']
})
export class UtilisateurComponent extends Translatable implements OnInit {

  dtOptions: DataTables.Settings = {};
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  title:string ;
  etabId:any ;
  public users :user[] = [] ;
  public user:user = new user();
  public profils:profil [] = [];
  public etablissements:etablissement [] = [];
  public type_etablissements:type_etablissement [] = [];
  public type_users:type_user [] = [];
  public user_details: user;
  public user_error : user ;
  public superAdmin : number ;
  closeResult: string = '';
  selectedPays: string = '';
  documentLogo: any = [];
  activeEtabId: number;

  /*
    FORM VALIDATOR
  */
  registerForm : FormGroup;
  submitted    = false;


  constructor(             
            private modalService: NgbModal,   
            private auth : AuthServiceService,  
            private toastr: ToastrService,          
            private helper: HelperService,
            private http: HttpClient,
            private formBuilder: FormBuilder,
            private sanitizer: DomSanitizer
            ) { 
    super()
  }

  async ngOnInit() {
    this.auth.initAutority(['ETA','USR','PRFL','MDL','SMDL','PRV']);
    let userinfo = this.auth.account()
    this.etabId = userinfo.info.etablissement_id;
    this.activeEtabId = userinfo.info.etablissement_id;
    this.superAdmin = userinfo.info.admin;

    this.initDatatable();


    this.registerForm = this.formBuilder.group({
        etablissment:       ['', Validators.required],
        profil:             ['', Validators.required],
        prenom:             [''],
        nom:                ['', Validators.required],
        phone:              ['', Validators.required],
        photo:              [''],
        email:              ['', Validators.required],
        adress:             [''],
    });
    this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);
    let res = await this.http.get<any>(environment.profil,valuesys.httpAuthOptions()).toPromise();
    if(res.code == 200) {
      this.profils = res.data;
    }else {
      this.profils = []
    }
    




  }

  // GET REGISTER FORM VALIDATE
  get f() { return this.registerForm.controls; }

  initDatatable(){
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "f_name"
      },
      {
        "data": "l_name"
      },
      {
        "data": "phone"
      },
      {
        "data": "email"
      },

      {
        "data": "etablissement.name",
        "name": "etablissement"
      },
  
    ];





    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      let where : any;
    
      if(this.superAdmin == 0 && this.etabId != 1){
         where = "&where=etablissement_id|e|"+this.etabId;
      }else{
         where = "";
      }
      this.http
        .get<DataTablesResponse>(
          environment.user+"?child=etablissement,profil"+ where +"&"+ $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        
        console.log("List users : ", resp.data);
        this.users = resp.data;

       /*  this.users = resp.data.map((val) => {
          return {
            ...val,
            etablissement: {name: this.etablissements.filter((et) => et.id == val.etablissement_id)[0].name},
            profil: this.profils.filter((p) => p.id == val.profil_id)[0]
          }
        }) as [] */
        
        console.log("List users : ", this.users);
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    } 

  
  }
  

  async openModal(template:TemplateRef<any>,currenUser:user =null,elem) {
    this.user_error = new user()
    this.etablissements = await this.helper.getSelectList(environment.etablissement,['name']);
    if(currenUser == null){
      this.isAdd = true ;
      this.user = new user() ;
      if(this.superAdmin == 0) {
        this.user.etablissement_id = this.etabId 
      }
      this.titleModal = this.__('label_user_new') ;
    }else {
      this.isAdd = false ;
      this.titleModal = this.__('label_user_edit') ;
      this.user = currenUser ;
      let res = await this.http.get<any>(environment.user + "?where=id|e|" + currenUser.id,valuesys.httpAuthOptions()).toPromise();
      this.user.type_etablissement_id = res.data[0].type_etablissement_id;
    } 
    this.modalService.open(template, { size: 'xl', backdrop:"static"  });
  } 


  async toggleState(user:user,state:number,btn:any){
    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.user+`/${user.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      user.state = toogle.data.state
    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }

  //--fermeture du modal
  close(){
    this.modalService.dismissAll()
    this.isValid = false ;
    this.user = new user()
    this.titleModal = this.__('label_user_edit') ;
    this.user_error = new user();
  }

  //--Add or Update
  async addUserForm(){

    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    //--add Logo
    this.user.photo = this.documentLogo.name;
    this.user_error = new user()
    this.isValid = true ;
    this.user.plateforme_id = 1;
    //--- Add User
    if(this.isAdd ){
      let res:any =  await this.http.post<any>(environment.user,this.user,valuesys.httpAuthOptions()).toPromise();
      console.log(res);
        //--success
        if(res.code === 201){
          this.toastr.success(res.msg,this.__("msg.success")) ;

          const resultSpecialite= this.etablissements.filter(word => word.id == this.user.etablissement_id);
          Object.assign(res.data, {"etablissement": {"name" : resultSpecialite[0]?.name}});

          res.data.profil = this.profils.find( _ => _.id == this.user.profil_id) ;
          this.users.push( res.data);
          console.log(this.users,"userss")
          this.close()
        //--Warning : Donnée invalide, Session expiré
        }else if(res.code === 400){
          for (let item in res.data){
            this.user_error[item] = res.data[item][0] ;
          }
          this.user_error = <user> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        //--error
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
    //--- Update User
    }else {
        console.log(this.user);
        let res =  await this.http.put<any>( environment.user + '/'+this.user.id,this.user,valuesys.httpAuthOptions()).toPromise();
        //--success
        if(res.code === 201){

          const resultSpecialite= this.etablissements.filter(word => word.id == this.user.etablissement_id);
          this.user.etablissement.name = resultSpecialite[0]?.name;

          this.user.profil = this.profils.find((_) =>_.id == this.user.profil_id);
          this.toastr.success(res.msg,this.__("msg.success")) ;
          this.close()
        //--Warning : Donnée invalide, Session expiré
        }else if(res.code === 400){
          this.user_error = <user> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        //--error
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
    }
    this.isValid = false ;
  }





  delete(deletedUser:user,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.user+"/" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.users= this.users.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
            {
              icon: 'success',
              title: this.__('msg.deleted'),
              confirmButtonColor: '#44A1A0',
              text: sup.msg,
            }
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          {
            icon: 'error',
            title: this.__('msg.canceled'),
            confirmButtonColor: '#44A1A0',
            text: this.__('msg.canceled_delete'),
          }
        )
      }
    })
  }


  /*telephone */
  objetPhone : any ;
  onCountryChange(m){
    let lastDiall =  this.objetPhone .dialCode ;
    let newDiall = m.dialCode
    console.log(`LAST = ${lastDiall} ; NEW ${newDiall}`,m);
  //  this.element.value = this.element.value.replace(lastDiall+'',newDiall+'');
    this.objetPhone = m ;
    /*Changed*/
    this.user.phone = this.element.value
    /*Changed*/
  }
  telInputObject(m){
    this.objetPhone = m.s ;
  }

  element : any ;
  currenCode :string ="mg";
  controle(element){
      this.objetPhone.dialCode = "03"
     this.element = element ;
     console.log(this.objetPhone.dialCode)
     element.value = element.value.replace(/[^0-9]/gi,'');
     if((element.value || "").substr(0,this.objetPhone.dialCode.length).indexOf(this.objetPhone.dialCode.toString()) == -1){
       if(element.value.length <=  this.objetPhone.dialCode.length  && (element.value || "").substr( this.objetPhone.dialCode.length ).length < 1)
       {
         element.value =  this.objetPhone.dialCode;
       }
       else
       {
         if((element.value || "").substr(0,this.objetPhone.dialCode.length ).indexOf( this.objetPhone.dialCode.toString()) !== -1){
           element.value =  element.value;
         }
         else {
           element.value = this.objetPhone.dialCode +  element.value;
         }
       }
     } 
    /*Changed*/
    this.user.phone = element.value;
    /*Changed*/
  }
  /*telephone */


  async getEtablissement(id: number) {
    let res = await this.http.get<any>(environment.etablissement + "?where=type_etablissement_id|e|" + id,valuesys.httpAuthOptions()).toPromise();
    if(res.code = 200){
      this.etablissements = res.data;
    }

  }

  



  async refreshPassword(user: user, elm) {
    elm.disabled = true
    console.log(user)
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_regener_password'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: this.__('msg.confirmed__regener_password'),
      cancelButtonText: this.__('msg.cancel_regener_password')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.get<any>(environment.userRefreshPwd + '/' + user.id ,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 201){
          Swal.fire(
            {
              icon: 'success',
              title: this.__('msg.regered_password'),
              confirmButtonColor: '#44A1A0',
              text: sup.msg,
            }
        
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(

          {
            icon: 'error',
            title: this.__('msg.canceled'),
            confirmButtonColor: '#44A1A0',
            text: this.__('msg.canceled_regered_password'),
          }
    
        )
        elm.disabled = false
      }
    })

  }


  /*IMAGE ADD*/
  // sanitizer:DomSanitizer =DomSanitizer.;
  getUrl(url:string)
  {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

   addImage(documentFile: HTMLInputElement) {
    documentFile.click()
  }

 
  handleInputLogo(e) {
    var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    if (!files) {
      return;
    }
    //this.etablissement.logo = files[0].name;

    for (var i = 0; i < files.length; i++) {
      if (files[i].size > 1000*1000 * 100) {
        this.toastr.error("taille image trop grande", "Error");
        $("#document").val("");
        return;
      }
      var reader = new FileReader();
      var pattern = /image-*/;

      if (!files[i].type.match(pattern)) {
        this.toastr.error("invalid format", "Error");
        return;
      }
      reader.onload = this._handleReaderLogoLoaded.bind(this);
      reader.readAsDataURL(files[i]);
    }

  }

  _handleReaderLogoLoaded(e) {
    let reader = e.target;
    this.documentLogo.name = reader.result;
    /*if (this.documentLogo.length < 2)
      this.documentLogo.push(d) */

      console.log(this.documentLogo);
    //this.partenaire.logo = reader.result;
  }

/*  async updateImg(){
    //--add Logo
    this.user.avatar = this.documentLogo.name;
    let res =  await this.http.put<any>( environment.user + '/'+this.user.user.id,this.concerner,valuesys.httpAuthOptions()).toPromise();
    //--success
    if(res.code === 201){
      this.toastr.success(res.msg,this.__("msg.success")) ;
      //--Warning : Donnée invalide, Session expiré
    }else if(res.code === 400){
      this.toastr.warning(res.msg,this.__("msg.warning")) ;
    }
    //--error
    else {
      this.toastr.error(res.msg,this.__("msg.error")) ;
    }

  }*/

}


