/*const BASE_URL = "https://gateway.inclusive-admin.com/" ;
const BASE_URL_CRUD = "https://ms-admin.inclusive-admin.com/api/v1/"*/

const BASE_URL = "https://preprod-gateway.inclusive-admin.com/" ;
const BASE_URL_CRUD = "https://preprod-ms-admin.inclusive-admin.com/api/v1/"

/*const BASE_URL = "https://gateway-pensions.numherit-dev.com/" ;
const BASE_URL_CRUD = "https://crud-pensions.numherit-dev.com/api/v1/"*/

export const environment = {
  production: true,
  baseUrl : BASE_URL,
  authItemName:'__token_api_gate_way_pensionnaire',
  userItemName:'__user_api_gate_way_pensionnaire',
  login:'auth/login',
  userAuth:'auth/me',
  me:'auth/me',
  profil : BASE_URL + "administration/profil",
  profilage : BASE_URL + "administration/profilage",
  etablissement : BASE_URL + "plateforme/etablissement",
  plateforme : BASE_URL + "administration/settings/plateforme",
  type_etablissement : BASE_URL + "plateforme/typeetablissement",
  admin_etablissement : BASE_URL + "plateforme/adminetablissement",
  type_user : BASE_URL + "plateforme/typeuser",
  module : BASE_URL + "administration/module",
  sous_module : BASE_URL + "administration/sousmodule",
  ping:'auth/ping',
  refresh:'auth/refresh',
  refreshPassword:'auth/refreshPassword',
  user : BASE_URL + "administration/user",
  action : BASE_URL + "administration/settings/action",
  updateAction : BASE_URL + "administration/settings/appendroute",
  genererAction : BASE_URL + 'administration/settings/generateroute',
  document  : BASE_URL + "plateforme/document",
  province : BASE_URL + "plateforme/province",
  niveau : BASE_URL + "plateforme/niveau",
  exportPdf: BASE_URL + "export/pdf",
  exportExcel: BASE_URL + "export/excel",
  userRefreshPwd : BASE_URL + "administration/user/refreshPwd",
  ordre_paiement  : BASE_URL + "plateforme/ordrepaiement",
  categorie : BASE_URL + "plateforme/categorie",
  pension_categories: BASE_URL + "plateforme/categorie_concerner",
  pension_concerners: BASE_URL + "plateforme/concernee",
  pension_improt_excel: BASE_URL + "plateforme/concernee/importExcel",
  pension_cartes: BASE_URL + "plateforme/carte",
  carte_concerner : BASE_URL + "plateforme/carte_concerner",
  concerne_with_carte_active: BASE_URL + "plateforme/concernee/getConcernneesWithCarteActive",
  pension_improt_excel_carte: BASE_URL + "plateforme/carte/importExcel",
  pension_active_carte: BASE_URL + "plateforme/carte/activateCarte",
  pension_desactiv_carte: BASE_URL + "plateforme/carte/desactivateCarte",
  pension_retirer_carte: BASE_URL + "plateforme/carte_concerner/retirerCarte",
  pension_journal_bord : BASE_URL + "plateforme/journalbord",
  pension_detail_ordre_paiement: BASE_URL + "plateforme/detailordrepaiement/getInformationDetail",
  detail_ordre_paiement: BASE_URL + "plateforme/detailordrepaiement",
  detail_ordre_paiement_detail: BASE_URL + "plateforme/detailordrepaiement/",
  pension_detail_dashboard_listecarte: BASE_URL + "plateforme/dashboard/recap",
  pension_detail_dashboard_listecarte_by_cat_id: BASE_URL + "plateforme/dashboard/situationconcernees",
  pension_list_carte_par_ministere: BASE_URL + "plateforme/listeCarteParMinistere",
  pension_ordre_import_excel: BASE_URL + "plateforme/ordrepaiement/readXlsxPaiementBill",
  pension_detailPaiementConcernees: BASE_URL + "plateforme/concernee/detailPaiementConcernees",
  detail_ordre_envoiee: BASE_URL + "plateforme/ordrepaiement/envoie",
  pension_val_creer: BASE_URL + "plateforme/ordrepaiement",
  pension_val_rejete: BASE_URL + "plateforme/ordrepaiement",
  concerne_ready_for_bill: BASE_URL + "plateforme/concernee/getConcerneReadyForBill",
  base_url_crud: BASE_URL_CRUD,
  demande_enrolement: BASE_URL + "plateforme/demandeenrolement",
  demande_wallet: BASE_URL + "plateforme/demandewallet",
  affectationCategoryEtablissement : BASE_URL + "plateforme/categorie_etablissement",
  categorie_etablissement: BASE_URL+ "plateforme/categorie_etablissement",
  edutic_concerne: BASE_URL + "/plateforme/concernee/consultEduticConcerned",
  edutic_insert_concerne: BASE_URL + "/plateforme/concernee/insertEduticConcerned",
  edutic_niveau: BASE_URL + "/plateforme/niveau",
  CATEGORIE_ID_ETUDIANT: 2,
  mois_ordre_paiement: BASE_URL + "/plateforme/mois_ordre_paiement",
  etudiant_ready_for_bill: BASE_URL + "plateforme/concernee/getEtudiantReadyForBill",
  etatResumeBourseUniversite: BASE_URL + "plateforme/historiquecashflow/etatResumeBourseUniversite",
  etatResumeBourse: BASE_URL + "plateforme/historiquecashflow/etatResumeBourse",
  detailPaiement: BASE_URL + "plateforme/historiquecashflow/detailPaiement"
};
