import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {DataTableDirective} from "angular-datatables";
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import { environment } from '../../../../../../../environments/environment';
import { valuesys } from '../../../../../../../options';
import { DataTablesResponse } from '../../../../../model/DataTablesResponse';
import { etablissement } from '../../../../../model/db';
import { categorie } from '../../../../../model/pension';

import { AuthServiceService } from '../../../../../service/auth-service.service';
import { HelperService } from '../../../../../service/helper.service';
import { Translatable } from '../../../../../service/Translatable';

@Component({
  selector: 'app-categorie-concerner',
  templateUrl: './categorie-concerner.component.html',
  styleUrls: ['./categorie-concerner.component.scss']
})
export class CategorieConcernerComponent extends Translatable implements OnInit  {

  dtOptions: DataTables.Settings = {};
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  title:string ;
  public categories :categorie[] = [] ;
  public categorie:categorie = new categorie();
  public categorie_error : categorie ;
  closeResult: string = '';
  selectedPays: string = '';
  etabId:any ;
  public superAdmin : number ;
  where : any= "";
  // public etablissements: etablissement [] = [];

  constructor(             
            private modalService: NgbModal,   
            private auth : AuthServiceService,  
            private toastr: ToastrService,          
            private helper: HelperService,
            private http: HttpClient) { 
    super()
  }

  async ngOnInit() {
    this.auth.initAutority("CACO");
    this.initDatatable()

    // let userinfo = this.auth.account()
    // this.etabId = userinfo.info.etablissement_id;    
    // this.superAdmin = userinfo.info.admin;

    //   if(this.superAdmin == 0){
    //     this.where = "&where=etablissement_id|e|"+this.etabId;
    //   }else{
    //     this.where = "";
    //   }
  }

  initDatatable(){
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "name"
      },
    ];


    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      // let where : any;
      // if(this.superAdmin == 0){
      //   where = "&where=etablissement_id|e|"+this.etabId;
      // }else{
      //   where = "";
      // }
      this.http
        .get<DataTablesResponse>(
          environment.pension_categories+"?" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.categories = resp.data;
        console.log(this.categories);
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    } 

  
  }
  

  async openModal(template:TemplateRef<any>,currenUser:categorie =null,elem) {
    console.log(currenUser);
    // this.etablissements = await this.helper.getSelectList(environment.etablissement, ['name']);
    this.categorie_error = new categorie()
    //elem.disabled = true ;
  
   // this.plateforme = await this.helper.getSelectList( environment.plateforme,['name']);
    if(currenUser == null){
      this.isAdd = true ;
      this.categorie = new categorie() ;
      this.titleModal = this.__('type_user.add_cat') ;
    }else {
      /*TEL*/
      //this.currenCode = this.helper.getCodePaysByNum(currenUser.phone.substr(0,3));
      /*TEL*/
      this.isAdd = false ;
      this.titleModal = this.__('type_user.edit_cat') ;
      this.categorie = currenUser ;

    } 
    //elem.disabled = false ;
    //this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});

    this.modalService.open(template, { size: 'lg', backdrop:"static"  });

  } 


  async toggleState(categorie:categorie,state:number,btn:any){
    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.pension_categories+`/${categorie.id}/state/${state}`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      categorie.state = toogle.data.state
  
    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }
 
  //--fermeture du modal
  close(){
    this.modalService.dismissAll()
    this.isValid = false ;
    this.categorie = new categorie()
    this.titleModal = this.__('label_user_edit') ;
    this.categorie_error = new categorie();
  }

  //--Add or Update
  async addCategorieForm(){
    this.categorie_error = new categorie()
    this.isValid = true ;
    //--- Add type_user
    if(this.isAdd ){
      let res:any =  await this.http.post<any>(environment.pension_categories,this.categorie,valuesys.httpAuthOptions()).toPromise();
      console.log(res);
        //--success
        if(res.code === 201){
          this.toastr.success(res.msg,this.__("msg.success")) ;
          // const resultEtablissement= this.etablissements.filter(word => word.id == this.categorie.etablissement_id as any);
          // Object.assign(res.data, {"etablissement": {"name" : resultEtablissement[0]?.name}});
          this.categories.push(res.data);
          this.close()
        //--Warning : Donnée invalide, Session expiré
        }else if(res.code === 400){
          for (let item in res.data){
            this.categorie_error[item] = res.data[item][0] ;
          }
          this.categorie_error = <categorie> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        //--error
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
    //--- Update 
    }else {
        console.log(this.categorie);
        let res =  await this.http.put<any>( environment.pension_categories + '/'+this.categorie.id,this.categorie,valuesys.httpAuthOptions()).toPromise();
        //--success
        if(res.code === 201){
          this.toastr.success(res.msg,this.__("msg.success")) ;
          // let resUp = this.etablissements.filter(_=>_.id == this.categorie.etablissement_id);
          // this.categorie.etablissement.name = resUp[0].name;
          // console.log(resUp);
          
          this.close()
        //--Warning : Donnée invalide, Session expiré
        }else if(res.code === 400){
          this.categorie_error = <categorie> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        //--error
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        } 
    }
    this.isValid = false ;
  }



 

  delete(deletedUser:categorie,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.pension_categories+"/" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.categories= this.categories.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
            {
              icon: 'success',
              title: this.__('msg.deleted'),
              confirmButtonColor: '#44A1A0',
              text: sup.msg,
            }
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          {
            icon: 'error',
            title: this.__('msg.canceled'),
            confirmButtonColor: '#44A1A0',
            text: this.__('msg.canceled_delete'),
          }
        )
      }
    })
  }

}
