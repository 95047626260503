import { Component, OnInit, TemplateRef } from '@angular/core';
import { Translatable } from '../..//../../service/Translatable';
import {DataTablesResponse} from "../../../../model/DataTablesResponse";
import {HelperService} from "../../../../service/helper.service";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from "@angular/common/http";
import {module} from "../../../../model/db";
import {valuesys} from "../../../../../../options";
import {environment} from "../../../../../../environments/environment";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {DataTableDirective} from "angular-datatables";
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";


@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss']
})
export class ModuleComponent  extends Translatable implements OnInit {

  dtOptions: DataTables.Settings = {};
  titleModal:string ;
  isAdd : boolean = true ;
  isValid : boolean = false ;
  title:string ;
  public modules :module[] = [] ;
  public module:module = new module();
  public module_error : module ;
  closeResult: string = '';
  selectedPays: string = '';

  constructor(             
            private modalService: NgbModal,   
            private auth : AuthServiceService,  
            private toastr: ToastrService,          
            private helper: HelperService,
            private http: HttpClient) { 
    super()
  }

  async ngOnInit() {
    this.auth.initAutority(['ETA','USR','PRFL','MDL','SMDL','PRV']);
    this.initDatatable()
  }

  initDatatable(){
    this.isAdd = true ;
    this.isValid = false ;
    this.title = this.__("lebel_users");
    this.dtOptions = this.helper.dataTableOption();
    this.dtOptions.columns = [
      {
        "data": "name"
      },
      {
        "data": "code"
      },
      {
        "data": "icone"
      },
    
    ];


    this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
      this.http
        .get<DataTablesResponse>(
          environment.module+"?" + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
        ).subscribe(resp => {
        this.modules = resp.data;
        console.log(this.modules);
        callback({
          recordsTotal: resp.recordsTotal,
          recordsFiltered: resp.recordsFiltered,
          data:[]
        });
      });
    } 

  
  }
  

  async openModal(template:TemplateRef<any>,currenUser:module =null,elem) {
    this.module_error = new module()
    //elem.disabled = true ;
  
   // this.plateforme = await this.helper.getSelectList( environment.plateforme,['name']);
    if(currenUser == null){
      this.isAdd = true ;
      this.module = new module() ;
      this.titleModal = this.__('module.add') ;
    }else {
      /*TEL*/
      //this.currenCode = this.helper.getCodePaysByNum(currenUser.phone.substr(0,3));
      /*TEL*/
      this.isAdd = false ;
      this.titleModal = this.__('module.edit') ;
      this.module = currenUser ;

    } 
    //elem.disabled = false ;
    //this.modalRef = this.modalService.show(template,{class:"modal-md",ignoreBackdropClick:true});

    this.modalService.open(template, { size: 'xl', backdrop:"static" });

  } 


  async toggleState(module:module,state:number,btn:any){
    btn.disabled = true;
    let toogle = await this.http.get<any>(environment.module+`/${module.id}/${state}/state`,valuesys.httpAuthOptions()).toPromise();
    if(toogle.code == 201){
      this.toastr.success(toogle.msg,this.__("msg.success"));
      module.state = toogle.data.state
  
    }else {
      this.toastr.error(toogle.msg,this.__("msg.error"));
    }
    btn.disabled = false;
  }

  //--fermeture du modal
  close(){

    this.modalService.dismissAll()
    this.isValid = false ;
    this.module = new module()
    this.titleModal = this.__('label_user_edit') ;
    this.module_error = new module();
  }

  //--Add or Update
  async addModuleForm(){
    this.module_error = new module()
    this.isValid = true ;
    //--- Add Module
    if(this.isAdd ){
      let res:any =  await this.http.post<any>(environment.module,this.module,valuesys.httpAuthOptions()).toPromise();
        //--success
        if(res.code === 201){
          this.toastr.success(res.msg,this.__("msg.success")) ;
          this.module.state = 1;
          this.modules.push(this.module);
          this.close()
        //--Warning : Donnée invalide, Session expiré
        }else if(res.code === 400){
          for (let item in res.data){
            this.module_error[item] = res.data[item][0] ;
          }
          this.module_error = <module> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        //--error
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
    //--- Update Module
    }else {
        let res =  await this.http.put<any>( environment.module + '/'+this.module.id,this.module,valuesys.httpAuthOptions()).toPromise();
        console.log(res);
        //--success
        if(res.code === 201){
          this.toastr.success(res.msg,this.__("msg.success")) ;
          this.close()
        //--Warning : Donnée invalide, Session expiré
        }else if(res.code === 400){
          this.module_error = <module> this.helper.errorForm(res.data);
          this.toastr.warning(res.msg,this.__("msg.warning")) ;
        }
        //--error
        else {
          this.toastr.error(res.msg,this.__("msg.error")) ;
        }
    }
    this.isValid = false ;
  }





  delete(deletedUser:module,elm){
    elm.disabled = true
    Swal.fire({
      title: this.__('msg.confirm'),
      text: this.__('msg.warning_delete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#44A1A0',
      cancelButtonColor: '#FF7467',
      reverseButtons : true,
      confirmButtonText: this.__('msg.confirmed_delete'),
      cancelButtonText: this.__('msg.cancel_delete')
    }).then(async (result) => {
      if (result.value) {
        let sup = await this.http.delete<any>(environment.module+"/" + deletedUser.id,valuesys.httpAuthOptions()).toPromise();
        // this.rerender();
        if(sup.code == 204){
          this.modules= this.modules.filter(function (item,index) {
            return item.id != deletedUser.id
          })
          Swal.fire(
            {
              icon: 'success',
              title: this.__('msg.deleted'),
              confirmButtonColor: '#44A1A0',
              text: sup.msg,
            }
          )
        }else {
          this.toastr.error(sup.msg,this.__("msg.error"));
        }
        elm.disabled = false

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        elm.disabled = false
        Swal.fire(
          {
            icon: 'error',
            title: this.__('msg.canceled'),
            confirmButtonColor: '#44A1A0',
            text: this.__('msg.canceled_delete'),
          }
        )
      }
    })
  }



}
