

import formatNumber from 'number-handler'
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChartType } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { environment } from '../../../../../../../environments/environment';
import { valuesys } from '../../../../../../../options';
import { etablissement, ordre_paiement } from '../../../../../model/db';
import { categorie } from '../../../../../model/pension';
import { AuthServiceService } from '../../../../../service/auth-service.service';
import { HelperService } from '../../../../../service/helper.service';
import { Translatable } from '../../../../../service/Translatable';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resume-bourse',
  templateUrl: './resume-bourse.component.html',
  styleUrls: ['./resume-bourse.component.scss']
})
export class ResumeBourseComponent extends  Translatable implements OnInit {


  formatNumber : any = formatNumber; 

  dtOptions: DataTables.Settings = {};
  titleModal:string ;
  title:string ;
  public categories: categorie [] = [];
  
  etabId:any ;
  public module_code = window['module'];
  public superAdmin : number ;
  registerForm : FormGroup;
  submitted    = false;

  searchInput : any= "";
  filtreSelect : any= 10;
  where : any= "";
  search : any= "";
  child : any= "";
  tri : any= "";
  order : any= "";
  endPOint : any= "";
  public searchColumn: string;
  public column: string;
  public childCol: string;

   //--paginate
   public paginateListe : any = [];
   public nextPage : any = [];
   public prevPage : any = [];
   public lastPage : any = [];
   public firstPage : any = [];
   public currentPage : any = [];
   public total : any = [];
   public path : any = [];
   public lastNumPage : any = [];
   public infoData : any = [];
   public cPage_less_2 : number;
   public path_cPage_less_2 : any;
   public cPage_less_1 : number;
   public path_cPage_less_1 : any;
   public cPage_more_1 : number;
   public path_cPage_more_1 : any;
   public cPage_more_2 : number;  
   public path_cPage_more_2 : any;
  //--end-paginate

  public etablissements:etablissement [] = [];

  filtre_etablissement_id : any;
  filtre_categorie_concernees_id: any;
   

  public ordre_paiements :ordre_paiement[] = [] ;
  dateDebut: string = this.formatDateYMD(new Date());
  dateFin: string = this.formatDateYMD(new Date());
  submitFiltreDate: boolean = false;

  // Chart Pour genre
  doughnutChartLabels: Label[] = [];
  doughnutChartData: MultiDataSet = [];
  doughnutChartType: ChartType = 'doughnut';
  public chartOptions: any = {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 80
  };
  public chartColors: Array<any> = [
      { // first color
        backgroundColor: ['#834090', '#cb2a7c','#bf96e8','#99185f','#d2418a','#ef5fa7']
      }
  ];

  
  // Chart Pour nationalité 
  doughnutChartLabelsNationalite: Label[] = [];
  doughnutChartDataNationalite: MultiDataSet = [];
  doughnutChartTypeNationalite: ChartType = 'doughnut';
  public chartOptionsNationalite: any = {
      responsive: true,
      maintainAspectRatio: false,
      cutoutPercentage: 80
  };
  public chartColorsNationalite: Array<any> = [
      { // first color
        backgroundColor: ['#834090', '#cb2a7c','#bf96e8','#99185f','#d2418a','#ef5fa7']
      }
  ];

  public detail_dashboard :any = [] ;
  public nombre_etudiant_inscrit_par_mention :any = [] ;
  public recapitulatif_preinscris_a_valider_par_etablissement :any = [] ;
  public recapitulatif_preinscription_a_valider_par_genre :any = [] ;
  public recapitulatif_etudiant_inscris_par_code_redoublement :any = [] ;
  public recapitulatif_etudiant_inscris_nationalite :any = [] ;
  etatResumeBourseList: any[] = [];
  constructor(private helper: HelperService,             
              private http: HttpClient,
              private auth : AuthServiceService,  
              private formBuilder: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
               
    ) 
    {
      super();
    }

  async ngOnInit() {
    let where = "";
    let userinfo = this.auth.account();

    this.etabId = userinfo.info.etablissement_id;
    this.superAdmin = userinfo.info.admin;
    let resp:any = await this.http.get<any>(`${environment.etatResumeBourse}?date_debut=${this.dateDebut}&date_fin=${this.dateFin}`, valuesys.httpAuthOptions()).toPromise();
    if(resp.code == 200) {
      this.etatResumeBourseList = resp.data;
    } else {
      this.toastr.error(resp.msg,this.__("msg.error"));
      this.etatResumeBourseList = [];
    }

  
    }




 

  async getSearchInput(){
    let where = "";
    let date = ""
    if(this.dateFin && this.dateDebut) date = `&date_debut=${this.dateDebut}&date_fin=${this.dateFin}`;
    if(this.superAdmin == 0) where += "&where=etablissement.id|e|"+this.etabId
    this.endPOint = environment.pension_detail_dashboard_listecarte+"?page=1"+where+date;
    this.getUrlDatatable(this.endPOint )
  }

  async getFiltreSelect(){   
    let where = "";
    let date = ""
    if(this.dateFin && this.dateDebut) date = `&date_debut=${this.dateDebut}&date_fin=${this.dateFin}`;
    if(this.superAdmin == 0) where += "&where=etablissement.id|e|"+this.etabId
    this.endPOint = environment.pension_detail_dashboard_listecarte+"?page=1"+where+date;
    this.getUrlDatatable(this.endPOint )
  }


  async getSearch(){
    this.getUrlDatatable(this.endPOint )
  }
  async triTable(col, order){
    this.tri = col;
    this.order = order;
    this.getUrlDatatable(this.endPOint )
  }

  async getUrlDatatable(url,col = "", refElement?: any, child?: any, searchMulti?:any){


          this.where = "";

          if(refElement){
          this.searchColumn = refElement.value
          this.column = col
          }

       
          if(col != "" || this.searchColumn ){
            if(this.searchColumn !="" && this.searchColumn !="undefined"){
              if(this.superAdmin==1)
              this.search = this.column+"|l|"+this.searchColumn;
              else
              this.search = ","+this.column+"|l|"+this.searchColumn;
            }else if(this.searchColumn == "") {
              this.search = "";
            }
          } 

          else {

            if(this.searchInput !="" && this.searchInput !="undefined"){
              this.where = ""
              this.search = "&where_or=etablissement.name|l|%"+this.searchInput+ "%"
            }else if(this.searchInput == "") {
              this.search = "";
            }
          }
    

    let filtre : any= "";
    if(this.filtreSelect !="" && this.filtreSelect !="undefined"){
      filtre = "&per_page=" + this.filtreSelect;
    }

    let triage : any= "";
    if(this.tri !="" && this.tri !="undefined"){
      triage = "&__order__="+ this.order+","+ this.tri;
    }
    console.log("search", this.search)

    let toogle = await this.http.get<any>(url + this.where + this.search  + filtre + triage,valuesys.httpAuthOptions()).toPromise();
   
    this.ordre_paiements = toogle.data.data;

    console.log("Togle data :",  this.ordre_paiements)
  }



 
  async filtreByDate(){
    
    let resp:any = await this.http.get<any>(`${environment.etatResumeBourse}?date_debut=${this.dateDebut}&date_fin=${this.dateFin}`, valuesys.httpAuthOptions()).toPromise();
    if(resp.code == 200) {
      this.etatResumeBourseList = resp.data;

    }else {
      this.toastr.error(resp.msg,this.__("msg.error"));
      this.etatResumeBourseList = [];
    }

    
  }

  async exportToExcel() {

    this.helper.exportExcel(this.printPaiement(this.etatResumeBourseList),'Listes etats résumé bourse date du '+this.dateDebut+" au "+this.dateFin).then(
      (response: any)=>{
        console.log('respons beee',response)
            let a = document.createElement("a"); 
            a.href = response.data;
            a.download = `etat_resume_bourse.xlsx`;
            a.click(); 
      },
      (error:any)=>{
        console.log(error)
      }
    );
  }
  printPaiement(ordre_paiement:any[]){
    return  ordre_paiement.map((ordre_paiement)=>{
      let t = {}
      t[this.__('resume.t1')] = ordre_paiement.name
      t[this.__('resume.t2')] = ordre_paiement.nb_boursier
      t[this.__('resume.t3')] = ordre_paiement.nb_boursier_carte
      t[this.__('resume.t4')] = ordre_paiement.nb_boursier_wallet
      t[this.__('resume.t5')] = ordre_paiement.nb_paiment
      t[this.__('resume.t6')] = ordre_paiement.nb_compte_no_credite
      t[this.__('resume.t7')] = ordre_paiement.montan_paiment
      t[this.__('resume.t8')] = ordre_paiement.nb_retrait_epoketra_PAOMA
      t[this.__('resume.t9')] = ordre_paiement.montant_retrait_epoketra_PAOMA
      t[this.__('resume.t10')] = ordre_paiement.nb_retrait_paositra_PAOMA
      t[this.__('resume.t11')] = ordre_paiement.montant_retrait_paositra_PAOMA
      t[this.__('resume.t12')] = ordre_paiement.nb_retrait_epoketra_GAB
      t[this.__('resume.t12')] = ordre_paiement.montant_retrait_epoketra_GAB
      return t ;
    })  || [];

  }
  async exportPdf() {
    await this.helper.exportPdf(this.printPaiement(this.etatResumeBourseList),'Listes etats résumé bourse date du '+this.dateDebut+" au "+this.dateFin,'','etat_resume_bourse');
  }
  

 
  formatDateYMD(date: Date) {
    return date.toISOString().split('T')[0];
  }

  goToDetailuniv(idUniv: number) {
    this.router.navigate(['dashboard','DAS','plateforme','historiquecashflow','etatResumeBourseUniversite'],{ queryParams: { idUniv,date_debut: this.dateDebut,date_fin: this.dateFin}})
  }

  addSpace(number,separator = " ") {
    const format = (num) => String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
    return format(number).toString().split(',').join(separator) || '';
  }

}
 