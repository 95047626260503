


<nav class="navbar navbar-expand-lg p-3 navbar-light bg-light fixed-top bg-white" style="height: 86px;">
  <div class="padding" >
    <ul class="navbar-nav mr-auto" >
      <li class="nav-item">
        <img class="logo1" src="../../../../assets/img/Logo-Madagascar-republic.png" alt="">
      </li>
      <li class="nav-item">
        <img class="logo2" src="../../../../assets/img/mptdn.png" style="width:130px;position: relative;
        bottom: 10px;" alt="">
      </li> 
    </ul>
  </div>
</nav>





<main class="d-flex align-items-center min-vh-100 py-3 py-md-0 main-login">
  <div class="container" style="color:rgb(21, 60, 80);" >
      <div class="card login-card">
        <div class="row no-gutters">
          <div class="col-lg-6 img_cl height-666px" >
            <h1 id="plateforme" style="margin-top: 40px;font-weight: bold; font-size: 52px;  margin-left:60px; ">
              {{__('default.bienvenu')}}
            </h1>
          </div>
          <div class="col-lg-6  height-666px" >
            <div class="img-right">
              <!-- <img src="assets/img/idea.png" class="height-400px"> -->
            </div>
            <div class="card-body">
              <!-- <p class="connexion-title">{{__('login.connexion')}}</p> -->
              <div class="d-flex flex-column justify-content-center align-items-center">
                <img src="assets/img/loginicon.png" alt="" style="width:100px;">
                <p class="connexion-title">{{__('login.connexion')}}</p>
                <span class="line"></span>
              </div>
              <br>
              <div>
              <form ngNativeValidate style="margin:auto;"autocomplete="off" (ngSubmit)="loginSubmit()">
                  <div class="form-group">
                    <label for="email">{{__('login.email')}}</label>
                    <input type="email" name="email" id="email" class="form-control" [(ngModel)]="login.email" placeholder="{{__('login.placeholder_mail')}}" required="true">
                  </div>
                  <div class="form-group mb-4">
                    <label for="password">{{__('login.mdp')}}</label>
                    <div class="pswd"> 
                      <input  [type]="password" name="password" id="password" [(ngModel)]="login.password" minlength="8" class="form-control input-pswd" placeholder="{{__('login.placeholer_mdp')}}" required="true">        
                      <span (click)="showPassword()" class="eye">
                        <i class="fa fa-eye eye-psd" aria-hidden="true" *ngIf="!show"></i>
                        <i class="fa fa-eye-slash eye-psd" aria-hidden="true" *ngIf="show"></i>
                      </span>
                    </div>
                  </div>
                  <button type="submit" name="button" class="btn btn-block login-btn mb-4" ><i class="fa fa-sign-in"></i> {{__('login.se_connecter')}}</button>
              </form>
              </div>
              
            </div>
            
          </div>
        </div>
      </div>
  </div>
</main>
  

<app-footer class="fixed-bottom login-footer">
  <span><a href=""></a><small><b style="font-size: 0.8rem;font-weight: 300;">{{__('default.copyright')}}</b></small></span>
  <span class="ml-auto"><img src="assets/img/mptdn.png" style="width: 140px;"  class="img-avatar" /></span>
</app-footer>
 
  