<br><div class="repMenu">
    <!-- <div class="row">
      <div class="col-lg-8"><span class="ariare" [routerLink]="['/app']" style="cursor: pointer;" >{{__('menu.accueil')}} </span>  > <span class="ariare" [routerLink]="['/app/module/CAR']" style="cursor: pointer;"  >{{__('menu.carte')}} </span>  > <span class="ariare"> {{__('carte.title')}}</span></div>

        <div class="col-lg-4 div-add pt-2"  > 
          <button class="button-control" *ngIf="autority('CAR_2')"  #addBtnImport (click)="openModal(addImportExcel,null,addBtnImport)" >{{__('import_excel.import')}}</button>

          <button class="button-control" *ngIf="autority('CAR_2')"  #addBtnUser (click)="openModal(addConcerner,null,addBtnUser)" >{{__('carte.ajout')}}</button>
        </div>

    </div> -->
    <div class="row  d-flex  justify-content-between">
        
            
      <div class="breadcrumb flat">
        <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
        <a [routerLink]="['/app/module/CAR']"  routerLinkActive="active">{{__('menu.carte')}}</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/carte']" routerLinkActive="active" *ngIf="autority('GCA_1')">{{__('carte.title')}}</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/listeCarteParMinistere']"  routerLinkActive="active" *ngIf="autority('DCA_1')">Disponibiliter des cartes</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/affecterCarte']"  routerLinkActive="active" *ngIf="autority('AFC_1')">Affecter carte</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/ActiverDesactive']"  routerLinkActive="active" *ngIf="autority('ACD_1')">Activer / Desactiver carte</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/EnrolerCarte']"  routerLinkActive="active" *ngIf="autority('ENC_1')">Enroler carte</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/planifier_creation_compte']"  routerLinkActive="active" *ngIf="autority('CRC_1')">Création de compte</a>
        <a [routerLink]="['/dashboard/CAR/plateforme/demandeenrolement']"  routerLinkActive="active" *ngIf="autority('DEN_1')">Demande enrolement</a> 
        <a [routerLink]="['/dashboard/CAR/plateforme/demandewallet']"  routerLinkActive="active" *ngIf="autority('DEW_1')">Demande création compte</a>
     
      </div>

  <!-- <div class="col-lg-8"><span class="ariare" [routerLink]="['/app']" style="cursor: pointer;" >{{__('menu.accueil')}} </span>  > <span class="ariare" [routerLink]="['/app/module/BNF']" style="cursor: pointer;"  >{{__('menu.beneficiaire')}} </span>  > <span class="ariare"> {{__('concerner.title')}}</span></div> -->
  
 

</div>
</div> 
<div  class="bg-white px-3  repMenu mt-2 d-flex justify-content-between align-items-center">
  
  <div class="export">
   <button class="px-2" (click)="exportCarteToExcel()">
    Export excel
    <img class="mb-1" src="assets/img/excel.png" alt="">


    </button>
    <button (click)="exportPdf()" class="ml-3 px-2">
      Export Pdf
      <img   class="mb-1" src="assets/img/pdf-file.png" alt="">

      </button>
  </div>
  <div class="div-add pt-2"  > 
    <button class="button-control" *ngIf="autority('CAR_2')"  #addBtnImport (click)="openModal(addImportExcel,null,addBtnImport,'import')" ><i class="fa fa-file-excel-o"></i> {{__('import_excel.import')}}</button>

    <button class="button-control" *ngIf="autority('CAR_2')"  #addBtnUser (click)="openModal(addConcerner,null,addBtnUser,'simple')" ><i class="fa fa-plus"></i>{{__('carte.ajout')}}</button>
    <a
    #canvaCarte
    [href]="sourcePath"
    [download]="'modele_import_carte.xlsx'"
    [attr.disabled]="dwld.busy"
    #dwld="wmDownload"
    class="d-none"
   
  >
    download
  </a>
  <label class="custom-file-upload ml-4 border-green" (click)="downloadCanva(canvaCarte)">
  
  
  <i class="fa fa-download"></i> {{__("ordre_paiement.download_fichier")}}
  </label>
  </div>
</div> 

<div class="row">
  <div class="col-lg-12">
    <br>
    <div class="card">
      <div class="card-body">
            <div class="row">
          
              <div class="col-lg-4">
                <div class="form-group">
                  <label >{{__('bachelier.etablissement')}} (<span class="required-red" >{{__('label.require')}}</span>)
                  
                  </label>
                  <ng-select placeholder="{{__('concerner.etablissement_id')}}" 
                      [(ngModel)]="etablissement_id" 
                      class="form-control"
                      [readonly]="superAdmin == 0 && activeEtabId != 1"
                      name="etab"
                      >
                      <ng-option  *ngFor="let etab of etablissements" [value]="etab?.id ">{{ etab?.name }}</ng-option>
                  </ng-select> 
                  </div>
              </div>
          
              <div class="col-lg-3">
                <div class="form-group">
                  <label >{{__('carte.niveau')}} (<span class="required-red" >{{__('label.require')}}</span>)
          
                  </label>
                  <ng-select placeholder="{{__('carte.niveau')}}"
                      [(ngModel)]="niveau_id" 
                      class="form-control"
                      >
                      <ng-option  *ngFor="let niv of niveaux" [value]="niv?.niveau ">{{ niv?.niveau }}</ng-option>
                  </ng-select> 
                  </div>
              </div> 
          
              <div class="col-lg-3">
                <div class="form-group">
                  <label >{{__('concerner.situation')}} (<span class="required-red" >{{__('label.require')}}</span>)
                
                  </label>
                  <ng-select placeholder="{{__('concerner.situation')}}"
                      class="form-control"
                      [(ngModel)]="situation_cart_id" 
                      >
                      <ng-option  *ngFor="let sit_cart of situation_cartes" [value]="sit_cart?.id ">{{ sit_cart?.sit }}</ng-option>
                  </ng-select> 
                  </div>
              </div> 
          
              <div class="col-lg-2 d-flex align-items-center mt-1" >
                  <button (click)="addSelectForm()" style="height: 44px;" type="submit" name="button" class="btn button-control" >{{__('btn.filtre')}}</button>       
              </div>
          
            </div>
      </div>
    </div>
  </div>
</div> 


<div class="row">
    <div class="col-lg-12">
      <br>
      <div class="card">
        <div class="card-body">
          <div class="row footer-paginate" >
            <div class="col-lg-4"> 
              <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span> 
              <select name="" id="" [(ngModel)]="filtreSelect" (change)="getFiltreSelect()" class="search-input" >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
                <option value="5000">5000</option>
                <option value="10000">10000</option>
              </select> 
              <span style="color: #666 !important;" > {{__('datatable.element')}}  </span> 
            </div>
            <div class="col-lg-8 btn-paginate">
              <span style="color: #666 !important;" > {{__('datatable.rechercher')}} : </span><input type="text" [(ngModel)]="searchInput" (keyup.enter)="getSearchInput()" class="search-input">
            </div> 

          </div>
            <table datatable  [dtOptions]="dtOptions" style="width: 100%"  class="table table-striped table-hover " >
            <thead>
              <tr>
                <th class="text-left">N° DE SERIE

                  <br>
                    <i class="fa fa-caret-down icon-tri" (click)="triTable('carte.numero_serie ','asc')" *ngIf="tri=='carte.numero_serie ' && order=='desc'" ></i> 
                    <i class="fa fa-caret-up icon-tri" (click)="triTable('carte.numero_serie ','desc')"  *ngIf="tri=='carte.numero_serie ' && order=='asc'"  ></i> 
                    <i class="fa fa-caret-left icon-tri" (click)="triTable('carte.numero_serie ','asc')" *ngIf="tri!='carte.numero_serie '" ></i> 
                    <input [(ngModel)]="numSerieFilter" type="text" #numero_serie class="inputCol" name="numero_serie" (keyup.enter)="getUrlDatatable(endPOint, 'carte.numero_serie',numero_serie, false)" id="">
                </th>

                <th class="text-left">{{__('carte.ministere')}}
<!-- 
                  <br>
                    <i class="fa fa-caret-down icon-tri" (click)="triTable('etablissement.name','asc')" *ngIf="tri=='etablissement.name ' && order=='desc'" ></i> 
                    <i class="fa fa-caret-up icon-tri" (click)="triTable('etablissement.name ','desc')"  *ngIf="tri=='etablissement.name ' && order=='asc'"  ></i> 
                    <i class="fa fa-caret-left icon-tri" (click)="triTable('etablissement.name ','asc')" *ngIf="tri!='etablissement.name '" ></i>  -->
                    <!-- <input type="text" #name class="inputCol" name="name" (keyup.enter)="getUrlDatatable(endPOint, 'etablissement.name',name, false)" id=""> -->
                </th>

                <th class="text-left">{{__('carte.niveau')}}
                  <!-- <br>
                  <i class="fa fa-caret-down icon-tri" (click)="triTable('carte.niveau','asc')" *ngIf="tri=='carte.niveau' && order=='desc'" ></i> 
                  <i class="fa fa-caret-up icon-tri" (click)="triTable('carte.niveau','desc')"  *ngIf="tri=='carte.niveau' && order=='asc'"  ></i> 
                  <i class="fa fa-caret-left icon-tri" (click)="triTable('carte.niveau','_asc')" *ngIf="tri!='carte.niveau'" ></i>  -->
                  <!-- <input type="text" #niveau class="inputCol" name="niveau" (keyup.enter)="getUrlDatatable(endPOint, 'carte.niveau',niveau, false)" id=""> -->
                </th>
              
                <th class="text-left">Situation Carte
                  <!-- <br>
                  <i class="fa fa-caret-down icon-tri" (click)="triTable('carte.situation','asc')" *ngIf="tri=='carte.situation' && order=='desc'" ></i>
                  <i class="fa fa-caret-up icon-tri" (click)="triTable('carte.situation','desc')"  *ngIf="tri=='carte.situation' && order=='asc'"  ></i>
                  <i class="fa fa-caret-left icon-tri" (click)="triTable('carte.situation','_asc')" *ngIf="tri!='carte.situation'" ></i> -->
                  <!-- <input type="text" #situation class="inputCol" name="situation" (keyup.enter)="getUrlDatatable(endPOint, 'carte.situation',situation, false)" id=""> -->
                </th>
                  <th class="text-left">Affectation
                      <!-- <br>
                      <i class="fa fa-caret-down icon-tri" (click)="triTable('carte.situation','asc')" *ngIf="tri=='carte.situation' && order=='desc'" ></i>
                      <i class="fa fa-caret-up icon-tri" (click)="triTable('carte.situation','desc')"  *ngIf="tri=='carte.situation' && order=='asc'"  ></i>
                      <i class="fa fa-caret-left icon-tri" (click)="triTable('carte.situation','_asc')" *ngIf="tri!='carte.situation'" ></i> -->
                      <!-- <input type="hidden" #affected class="inputCol" name="situation" (keyup.enter)="getUrlDatatable(endPOint, 'carte.situation',affected, false)" id=""> -->
                  </th>
                  <th class="text-left">Status
                    <br>
                    <!-- <i class="fa fa-caret-down icon-tri" (click)="triTable('carte.active','asc')" *ngIf="tri=='carte.active' && order=='desc'" ></i> 
                    <i class="fa fa-caret-up icon-tri" (click)="triTable('carte.active','desc')"  *ngIf="tri=='carte.active' && order=='asc'"  ></i> 
                    <i class="fa fa-caret-left icon-tri" (click)="triTable('carte.active','_asc')" *ngIf="tri!='carte.niveau'" ></i> 
                    <input placeholder="0: inactif | 1: actif" type="text" #active class="inputCol" name="active" (keyup.enter)="getUrlDatatable(endPOint, 'carte.active',active, false)" id=""> -->
                  </th>
                <th class=" text-center">{{__('table.action')}}</th>
              </tr>
            </thead>
            <tbody *ngIf="carteArray?.length != 0" >
                <tr *ngFor="let carte of carteArray">
                <td class="text-left">{{ carte?.numero_serie }}</td>  
                <td class="text-left">{{ carte?.name }}</td>  
                <td class="text-left">{{ carte?.niveau }}</td>
                
                 <td class="text-center">
                    <span *ngIf="carte?.situation == 0" style="color: #a7aaa7;" > {{__('carte.situation_0')}}</span>
                    <span *ngIf="carte?.situation == 1" style="color: #d9d951;" > {{__('carte.situation_1')}}</span>
                    <span *ngIf="carte?.situation == 2" style="color: #68ad36;" > {{__('carte.situation_2')}}</span>
                    <span *ngIf="carte?.situation == 3" style="color: #20c3e5;" > {{__('carte.situation_3')}}</span>
                    <span *ngIf="carte?.situation == 4" style="color: #29bfbf;" > {{__('carte.situation_4')}}</span>
                    <span *ngIf="carte?.situation == null" style="color: #858584;"> {{__('carte.situation_vi')}}</span>
                    <!--<span *ngIf="concerner?.carte_active == 0" style="color: #d92222;"> {{__('tooltip.desactiver')}}</span>
                    <span *ngIf="concerner?.carte_active == 1" style="color: #51e2e2;"> {{__('tooltip.active')}}</span>-->
                  </td>
                    <td class="text-left">
                        <span *ngIf="carte?.affected == 0" style="color: #a7aaa7;" > non affectée </span>
                        <span *ngIf="carte?.affected == 1" style="color: #45a523;" > affectée à {{carte?.nom}} {{carte?.prenom}}</span>
                       </td>
                       <td>{{ carte?.active == 1 ? 'Active': 'Inactive' }}</td>
                <td class="text-center">
                    
                    <i class="fa fa-toggle-on icon-table vert" ngbTooltip="{{__('tooltip.active')}}" #buttonElement (click)="toggleStateActive(carte,buttonElement)" *ngIf="carte.active == 1 && autority('CAR_6')" ></i>

                    <i class="fa fa-toggle-off icon-table vert"  ngbTooltip="{{__('tooltip.desactiver')}}" #buttonElement (click)="toggleStateDesactive(carte,buttonElement)" *ngIf="carte.active == 0 && autority('CAR_6')" ></i>&nbsp;

                    <!-- <i class="fa fa-credit-card-alt vert"  ngbTooltip="{{__('tooltip.retirer_carte')}}" #buttonElement (click)="toggleRetirerCarte(carte,buttonElement)" *ngIf="autority('CAR_6')" style="color: green;" ></i> -->

                   <!--  <i class="fa fa-credit-card-alt vert"  ngbTooltip="{{__('tooltip.retirer_carte')}}" #buttonElement (click)="toggleRetirerCarte(carte,buttonElement)" *ngIf="autority('CAR_6')"></i> -->

                   <!--  <i class="fa fa-trash icon-table red" *ngIf="autority('CAR_5')" ngbTooltip="{{__('tooltip.delete')}}" #openModalBtnDelete (click)="delete(carte,openModalBtnDelete)"></i>
                   -->

                </td>

              </tr>
            </tbody>
            <tbody *ngIf="carteArray?.length == 0">
            <tr>
              <td colspan="6" >{{__('label_no_data')}}</td>
            </tr>
          </tbody> 
          </table>

          <div class="row footer-paginate pt-2" >
            <div class="col-lg-4"> 
              <span class="three-point" style="padding-left: 0px;" >{{infoData}}</span> 
            </div>
            <div class="col-lg-8 btn-paginate" >
              <button *ngIf="currentPage != 1" (click)="getUrlDatatable(firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
              <button *ngIf="currentPage != 1" (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
              <button *ngIf="currentPage > 3" class="btn-pagination-hr"  (click)="getUrlDatatable(firstPage)" > 1 </button>
              <span *ngIf="currentPage > 3" class="three-point" > ... </span>
              <button *ngIf="cPage_less_2 > 0 && currentPage < 4" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_less_2)" > {{cPage_less_2}} </button>
              <button *ngIf="cPage_less_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_less_1)" > {{cPage_less_1}} </button>
              <button (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{currentPage}} </button>
              <button *ngIf="cPage_more_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_more_1)" > {{cPage_more_1}} </button>
              <button *ngIf="(cPage_more_2 > 0 && cPage_more_2 > lastNumPage && cPage_more_1 > lastNumPage && currentPage > lastNumPage) || cPage_more_2 == lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_more_2)" > {{cPage_more_2}} </button>
              <span *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="three-point" > ... </span>
              <button *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(lastPage)" > {{lastNumPage}} </button>
              <button *ngIf="currentPage < lastNumPage" (click)="getUrlDatatable(nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
              <button *ngIf="currentPage < lastNumPage" (click)="getUrlDatatable(lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

<ng-template #addConcerner  let-modal style="background-color: red">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form ngNativeValidate [formGroup]="registerForm" (ngSubmit)="addCarteForm()">

      <div class="modal-body">
        <br>

        <div class="row"> 
          <div class="col-lg-6">
            <div class="form-group">
                <label >{{__('concerner.etablissement_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                  <span *ngIf="submitted && f.etablissement_id.errors" class="invalid-form-feedback" >                      
                    <span *ngIf="f.etablissement_id.errors.required"> / Ce champ est obligatoire</span>
                  </span>
                </label>
                <ng-select placeholder="{{__('concerner.etablissement_id')}}"
                aria-readonly=""
                    formControlName="etablissement_id" 
                    [(ngModel)]="carte.etablissement_id" 
                    class="form-control"
                    [ngClass]="{ 'invalid-form-input-validate': submitted && f.etablissement_id.errors }"
                    [readonly]="superAdmin == 0 && activeEtabId != 1"
                    >
                    <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
                </ng-select>
            </div>
          </div>

          <div class="col-lg-6">
              <div class="form-group">
                  <label >{{__('carte.num_serie')}} (<span class="required-red" >{{__('label.require')}}</span>)
                    <span *ngIf="submitted && f.numero_serie.errors" class="invalid-form-feedback" >                      
                      <span *ngIf="f.numero_serie.errors.required"> / Ce champ est obligatoire</span>
                    </span>
                  </label>
                    <input formControlName="numero_serie" type="number" min="0" maxlength="10" class="form-control" [(ngModel)]="carte.numero_serie"  placeholder="{{__('carte.num_serie')}}" >
                  </div>
            </div>

          </div>

        <div class="row">
          <div class="col-lg-12">

            <button type="button" name="button" (click)="addtab_carte()"  class="btn mb-4 button-control" *ngIf="isAdd == true"   >{{__('concerner.Add_tab_concerne')}}</button>       
            <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == false"  > {{__('tooltip.edite')}}</button>
            <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>            
          </div>
        </div>

        <div *ngIf="tab_concernee.length > 0" class="confirm-form-add">
          <p>{{__('carte.confirm_save')}}</p>
          <p>{{__('carte.num_serie')}}: <b>{{ tab_concernee[0].numero_serie }}</b></p>
          <button type="submit" name="button" class="btn mb-4 button-control" style="float:none" *ngIf="isAdd == true && tab_concernee.length > 0" >{{__('tooltip.enreg')}}</button>  
        </div>
      </div>
  </form>

</ng-template> 


<ng-template #addImportExcel  let-modal style="background-color: red; width: auto;">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{titleModal}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form ngNativeValidate [formGroup]="registerFormImport" (ngSubmit)="AddImportExcel()">

    <div class="modal-body">
      <br>

      <div class="row"> 
        <div class="col-lg-6">
          <div class="form-group">
              <label >{{__('concerner.etablissement_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                <span *ngIf="submitted && f_import.etab_id.errors" class="invalid-form-feedback" >                      
                  <span *ngIf="f_import.etab_id.errors.required"> / Ce champ est obligatoire</span>
                </span>
              </label>
              <ng-select placeholder="{{__('concerner.etablissement_id')}}"
              aria-readonly=""
                  formControlName="etab_id" 
                  [(ngModel)]="carte.etablissement_id" 
                  class="form-control"
                  [ngClass]="{ 'invalid-form-input-validate': submitted && f_import.etab_id.errors }"
                  [readonly]="(superAdmin == 0 || disableEtabInput) && activeEtabId != 1"
                  >
                  <ng-option  *ngFor="let etablissement of etablissements" [value]="etablissement?.id ">{{ etablissement?.name }}</ng-option>
              </ng-select>
          </div>
        </div>

        <div class="col-lg-6">
          <label class="mb-0" >            
            {{__('etudiant.fichier_importer')}} (<span class="required-red" >{{__('label.require')}}</span>)
            <span *ngIf="submitted && f_import.fichier.errors" class="invalid-form-feedback">
              <span *ngIf="f_import.fichier.errors.required"> / Ce champ est obligatoire</span>
            </span>
          </label>
          <br>
          <input 
          (change)="handleInputChange($event)" 
          formControlName="fichier"
          #documentFile 
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          id="documentFileImport" 
          name="document" 
          type="file" 
          class="file form-control"
          [readonly]="disableExcelInput" >
      </div>
   
      </div>

      <div class="row mt-3" *ngIf="!tabcarteshow">
        <div class="col-lg-12">
        
         <button type="submit" name="button" class="btn mb-4 button-control" *ngIf="isAdd == true"   >{{__('concerner.add_infos')}}</button>   

          <button type="button" name="cancel" (click)="close()" class="btn mb-4 button-cancel"> {{__('tooltip.cancel')}}</button>       

        </div>
      </div>

    </div>
</form>

<div *ngIf="carte_verif?.length != 0" class="col-md-12" style="padding: 0 3rem 2rem 3rem;">
  <hr>
  <div class="row">
    <div class="col-md-4 text-center">
      <label> {{__('carte.nb_ligne_parcourues')}} : <b>{{totalparcourue}}</b></label>
    </div>
    <div class="col-md-4 text-center">
      <label> {{__('carte.nb_ligne_valides')}} : <b class="bv_valide">{{totalvalid}}</b></label>
    </div>
    <div class="col-md-4 text-center">
      <label> {{__('carte.nb_ligne_invalides')}} : <b class="bv_invalide">{{totalinvalid}}</b></label>
    </div>
  </div>



  
  <table style="width: 100%"  class="table table-striped table-hover " >
    <thead>
      <tr>
        <th class="text-left">{{__('carte.n_serie')}}</th>
        <th class="text-left">{{__('etudiant.niveau')}}</th> 
        <th class="text-left">{{__('carte.etat')}}</th>     
      </tr>
    </thead>
    <tbody *ngIf="carte_verif?.length != 0" >
        <tr *ngFor="let bv of carte_verif">
            <td class="text-left">{{ bv?.numero_serie }}</td>
            <td class="text-left">{{ bv?.niveau }}</td>            
            <td class="text-left" style="width:30%">
              <p *ngIf="bv?.numero_serie_existe==1" class="bv_invalide">
                <i  class="fa fa-times-circle"></i>&nbsp;{{__('carte.num_serie_existant')}}
              </p>
              <p *ngIf="bv?.numero_serie_existe==0" class="bv_valide">
                <i class="fa fa-check-circle"></i>&nbsp;{{__('carte.valide')}}
              </p>

            </td>
  
      </tr>
    </tbody>
  
  </table>

  <div class="row">
    <div class="col-lg-12">
    
     <button type="button" name="button" [disabled]="totalvalid==0" (click)="validerAddCarte()" class="btn mb-4 button-control"  >{{__('btn.valide')}}</button>   

      <button type="button" name="cancel" (click)="reimporterExcel()" class="btn mb-4 button-cancel"> {{__('concerner.reimporter_excel')}}</button>       

    </div>
  </div>
</div>
  
</ng-template> 


 






