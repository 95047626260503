<br>
<div class="repMenu">
	   <div class="row  d-flex  justify-content-between">
              <div class="breadcrumb flat">
                <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
                <a [routerLink]="['/app/module/BNF']"  routerLinkActive="active">{{__('menu.beneficiaire')}}</a>
                <a routerLinkActive="active">{{__('concerner.title')}}</a>
                <!-- <a href="#">Terminer</a> -->
              </div>
          <!-- <div class="col-lg-8"><span class="ariare" [routerLink]="['/app']" style="cursor: pointer;" >{{__('menu.accueil')}} </span>  > <span class="ariare" [routerLink]="['/app/module/BNF']" style="cursor: pointer;"  >{{__('menu.beneficiaire')}} </span>  > <span class="ariare"> {{__('concerner.title')}}</span></div> -->
          <div class="div-add pt-2"  > 
            <button class="button-control" *ngIf="autority('BNF_2')"  #addBtnImport (click)="openModal(addImportExcel,null,addBtnImport)" >{{__('import_excel.import')}}</button>

            <button class="button-control" *ngIf="autority('BNF_2')"  #addBtnUser (click)="openModalAdd(addConcerner,null,addBtnUser)" >{{__('concerner.add')}}</button>
          </div>
      </div>
	<!-- <div class="row">
	  <div class="col-lg-8"><span class="ariare" [routerLink]="['/app']" style="cursor: pointer;" >{{__('menu.accueil')}} </span>  > <span class="ariare" [routerLink]="['/app/module/BNF']" style="cursor: pointer;"  >{{__('menu.beneficiaire')}} </span>  > <span class="ariare" [routerLink]="['/dashboard/BNF/plateforme/concernee']"> {{__('concerner.title')}}</span> > <span class="ariare"> {{__('concerner.detail')}}</span></div>
	</div> -->
</div>

<br>

<!-- TABS -->
<mat-tab-group style="background-color:white" [selectedIndex]="tabIndexActive">
  	<mat-tab label="Information générale" style="margin-left:1rem;margin-right:1rem">
  		<div class="row" style="margin-right:0;margin-left:0;margin-top: 2rem;margin-bottom: 2rem;">
  			<div class="col-lg-4" style="text-align:center;margin-top: 4rem;">
  				<div class="row">
					<div class="col-md-12 my-2">
						<!-- <img [src]="concerner.photo" class="photo" alt=""> -->
						<div class="row d-flex flex-column">
							<span class="imgUpload" >
								<img  [src]="concerner.photo" class="photo" >
							  </span>
							  <!-- <hr style="border: 1px solid #44a1a0;"> -->
							  <span 
								class="imgAdd my-3"
								(click)="documentLogo.length <= 0 ? addImage(documentFileLogo) : false"  >
								{{__('me.pdp')}} <i class="fa fa-plus" style="padding-left: 20px; font-size: 17px;"></i>
							  </span> 
						</div>
						  
						   <input accept="image/*" (change)="handleInputLogo($event)" #documentFileLogo style="display: none"  id="documentlogo" name="documentlogo" type="file" class="file" multiple >
					</div>
					<div class="col-md-12 d-flex justify-content-center">
						<button type="button" name="button" *ngIf="update" (click) ="updateImg()" style="float:none" class="btn mb-4 button-control"> Modifier la photo</button>            
					</div>
				</div>
  				<h3 class="mt-2" style="color: #524f4f;">{{ concerner?.prenom }} {{ concerner?.nom }}</h3>
  			</div>
  			<div class="col-lg-6">
  				<div class="row" >
  					<div class="col-lg-4">
  						<h6>Ministère</h6>
  						<p class="data-info">{{ concerner?.etablissement_name }}</p>
  					</div>
  					<div class="col-lg-4">
  						<h6>N° Matricule</h6>
  						<p class="data-info">{{ concerner?.matricule }}</p>
  					</div>
  					<div class="col-lg-4">
  						<h6>Catégorie</h6>
  						<p class="data-info">{{ concerner?.categorie }}</p>
  					</div>
  				</div>
  				<hr>
  				<div class="row">
  					<div class="col-lg-4">
  						<h6>Sexe</h6>
  						<p class="data-info">{{ concerner?.sexe }}</p>
  					</div>
  					<div class="col-lg-4">
  						<h6>Date de naissance</h6>
  						<p class="data-info">{{ concerner?.date_naissance }}</p>
  					</div>
					<div class="col-lg-4">
						<h6>Date de livrance</h6>
						<p class="data-info">{{ concerner?.date_livrance }}</p>
					</div>
  				</div>
  				<hr>
  				<div class="row">
  					<div class="col-lg-4">
  						<h6>Type de pièce</h6>
  						<p class="data-info" *ngIf="concerner?.type_piece == 0">CIN</p>
  						<p class="data-info" *ngIf="concerner?.type_piece == 1">Passeport</p>
  					</div>
  					<div class="col-lg-4">
  						<h6>N° de pièce</h6>
  						<p class="data-info">{{ concerner?.numero_piece }}</p>
  					</div>
					<div class="col-lg-4">
						<h6>Téléphone</h6>
						<p class="data-info">{{ concerner?.telephone }}</p>
					</div>
  				</div>
  				<hr>
  				<div class="row">
					<div class="col-lg-4">
						<h6>Email</h6>
						<p class="data-info">{{ concerner?.email }}</p>
					</div>
					<div class="col-lg-4">
						<h6>Adresse</h6>
						<p class="data-info">{{ concerner?.adresse }}</p>
					</div>
					<div class="col-lg-4">
						<h6>Code postal</h6>
						<p class="data-info">{{ concerner?.code_postal }}</p>
					</div>
  				</div>
  				<hr>
  				<div class="row">
					<div class="col-lg-4">
						<h6>Numéro de compte</h6>
						<p class="data-info">
							<span *ngIf="concerner?.num_compte == null" style="color: #858584;"> {{__('carte.nondefini')}}</span>
                      		<span *ngIf="concerner?.num_compte !== null" style="color: #858584;"> {{concerner?.num_compte}}</span>
						</p>
					</div>
  				</div>

  			</div>
  		</div>
  		
  	</mat-tab>
  	<mat-tab label="Cartes" >
  		<div class="row" style="margin-left:1rem;margin-right:1rem">
  		    <div class="col-lg-12">
  		      	<br>
  		      	<div *ngIf="havecard == false">
  		      		<div  class="row">
  		      			<div class="col-12" style="text-align:center;padding: 1.5rem;">
  		      				<p class="data-info">Ce bénéficiaire ne dispose pas encore de carte.</p>
  		      				<div style="width:100%">
  		      					<button class="button-control ng-star-inserted" style="float:none" (click)="goToAffectation()">Affecter une carte</button>
  		      				
							</div>
  		      				
  		      			</div>
  		      			
  		      		</div>
  		      		
  		
  		      	</div>
				<!-- situation carte -->

			

				<!-- end -->
  		      	<div *ngIf="havecard == true" style="text-align: center;">
  		      		<div class="row" style="text-align:center">
  		      			<div class="col-12">
  		      				<i class="fa fa-credit-card" style="font-size:4rem;color: #45a1a1;"></i>
  		      				<h3>Information sur la carte</h3>
  		      			</div>
  		      		</div>
  		      		<hr>
  		      		<div class="row">
  		      			<div class="col-12" style="text-align:center;">
  		      				<button ngbTooltip="Vous devez desactiver la carte avant de la retirer!" [ngClass]="{'not-allowed': concerner?.carte_active == 1,'px-3': true}" style="background-color:#fc7f7f;border: none;height: 50px;" (click)="retirerCarte()" [disabled]="concerner?.carte_active == 1">
  		      					<i class="fa fa-exclamation-triangle" style="font-size: 1.1rem;color: white;"></i> 
  		      					<span style="color:white"> Retirer de ce bénéficiaire </span>
  		      				</button>
  		      			</div>
  		      			
  		      		</div>
					<hr/>
  		      		<div class="row mt-4">
  		      			<div class="col-lg-3">
  		      				<h6 class="title-info">Numéro de série</h6>
  		      				<p class="data-info">{{ concerner?.carte_numero_serie }}</p>
  		      			</div>
  		      			<div class="col-lg-3">
  		      				<h6 class="title-info">Date affectation</h6>
  		      				<p class="data-info">{{ concerner?.date_livrance | date: 'dd/MM/yyyy' }}</p>
  		      			</div>
  		      			<div class="col-lg-3">
  		      				<h6 class="title-info">Status</h6>
  		      				<p class="data-info" *ngIf="concerner?.carte_active == 0" style="color:red">
  		      					Désactivé
  		      				</p>
  		      				<p class="data-info" *ngIf="concerner?.carte_active == 1" style="color:green">
  		      					Activé
  		      				</p>
  		      			</div>
						<div class="col-lg-3">
							<h6 class="title-info">Situation Carte</h6>
							<p class="data-info" *ngIf="concerner?.carte_situation == 0" style="color:red">
								non personnalisée
							</p>
							<p class="data-info" *ngIf="concerner?.carte_situation == 1" style="color:green">
								réservé
							</p>
							<p class="data-info" *ngIf="concerner?.carte_situation == 2" style="color:green">
								en cours de personnalisation
							</p>
							<p class="data-info" *ngIf="concerner?.carte_situation == 3" style="color:green">
								personnalisée
							</p>
							<p class="data-info" *ngIf="concerner?.carte_situation == 4" style="color:green">
								personnalisée enrôler
							</p>
							<p class="data-info" *ngIf="concerner?.carte_situation == null" style="color:green">
								Pas de carte
							</p>
						</div>
  		      		</div>
  		      		<hr>
  		      		<div class="row mt-2 py-3">
						<div class="col-12 d-flex justify-content-center">
							<!-- 0: Reserver une carte -->
							<button *ngIf="concerner?.carte_situation == 0" ngbTooltip="Résérver la carte!" class="button-control" (click)="gestionCarte(concerner?.carte_numero_serie,0)">
								<!-- <i class="fa fa-exclamation-triangle" style="font-size: 1.1rem;color: white;"></i>  -->
								<span style="color:white"> Résérver la carte</span>
							</button>
							<!-- 3: enroler une carte -->
							<button *ngIf="concerner?.carte_situation == 2" ngbTooltip="Confirmer la personnalisation de la carte!" class="button-control" (click)="gestionCarte(concerner?.carte_numero_serie,4)">
								<!-- <i class="fa fa-exclamation-triangle" style="font-size: 1.1rem;color: white;"></i>  -->
								<span style="color:white"> Confirmer la personnalisation de la carte</span>
							</button>
							<button *ngIf="concerner?.carte_situation == 3" ngbTooltip="Enroler la carte!" class="button-control" (click)="gestionCarte(concerner?.carte_numero_serie,3)">
								<!-- <i class="fa fa-exclamation-triangle" style="font-size: 1.1rem;color: white;"></i>  -->
								<span style="color:white"> Enroler la carte</span>
							</button>
						</div>
						<div class="col-12 d-flex justify-content-center">
							<!-- 1: Libérer une carte pcq 1 est carte reservé -->
							<button *ngIf="concerner?.carte_situation == 1" ngbTooltip="Libérer la carte!" class="px-2 button-control text-white mr-2" style="background-color:#23282c!important;" (click)="gestionCarte(concerner?.carte_numero_serie,1)">
								<!-- <i class="fa fa-exclamation-triangle" style="font-size: 1.1rem;color: white;"></i>  -->
								<span style="color:white"> Libérer la carte</span>
							</button>
							<!-- 2: personnalisé une carte pcq 1 est carte reservé -->
							<button *ngIf="concerner?.carte_situation == 1" ngbTooltip="Personnaliser la carte!" class="button-control ml-2" (click)="gestionCarte(concerner?.carte_numero_serie,2)">
								<!-- <i class="fa fa-exclamation-triangle" style="font-size: 1.1rem;color: white;"></i>  -->
								<span style="color:white"> Pérsonnalisé la carte</span>
							</button>
							
						</div>
						
						
					</div>
  		      	</div>
  		    </div>
  		</div>
  	</mat-tab>

  	<mat-tab label="Paiements">
			<br>

			<div class="row">
				<div class="col-lg-12">
				  
				  <br><br>

				  <div class="card">
					
					<div class="card-body">


					  <div class="row footer-paginate" >
						<div class="col-lg-4"> 
						  <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span> 
						  <select name="" id="" [(ngModel)]="filtreSelect" (change)="getFiltreSelect()" class="search-input" >
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="200">200</option>
							<option value="500">500</option>
							<option value="1000">1000</option>
							<option value="2000">2000</option>
							<option value="5000">5000</option>
							<option value="10000">10000</option>
						  </select> 
						  <span style="color: #666 !important;" > {{__('datatable.element')}}  </span> 
						</div>
						<div class="col-lg-8 btn-paginate">
						  <span style="color: #666 !important;" > {{__('datatable.rechercher')}} : </span><input type="text" [(ngModel)]="searchInput" (keyup.enter)="getSearchInput()" class="search-input">
						</div>

					  </div>

					  <table class="table table-hover table-striped" style="width: 100%;" >
						<thead>
						  <tr>
							<th>{{__('ordre_paiement.libelle')}} <br>
								<i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.nom','asc')" *ngIf="tri=='concernees.nom' && order=='desc'" ></i> 
								<i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.nom','desc')"  *ngIf="tri=='concernees.nom' && order=='asc'"  ></i> 
								<i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.nom','asc')" *ngIf="tri!='concernees.nom'" ></i> 
								<input type="text" #nom class="inputCol" name="nom" (keyup.enter)="getUrlDatatable(endPOint, 'concernees.nom',nom, 'ordre_paiement')" id="">
			  
							</th>
							<th>{{__('ordre_paiement.date_debut_paiement')}} <br>
								<i class="fa fa-caret-down icon-tri" (click)="triTable('ordre_paiement.date_debut_paiement','asc')" *ngIf="tri=='ordre_paiement.date_debut_paiement' && order=='desc'" ></i> 
								<i class="fa fa-caret-up icon-tri" (click)="triTable('ordre_paiement.date_debut_paiement','desc')"  *ngIf="tri=='ordre_paiement.date_debut_paiement' && order=='asc'"  ></i> 
								<i class="fa fa-caret-left icon-tri" (click)="triTable('ordre_paiement.date_debut_paiement','asc')" *ngIf="tri!='ordre_paiement.date_debut_paiement'" ></i> 
								<input type="date" #date_debut_paiement class="inputCol" name="date_debut_paiement" (keyup.enter)="getUrlDatatable(endPOint, 'ordre_paiement.date_debut_paiement',date_debut_paiement, 'ordre_paiement')" id="">
							 
							</th>
							<th>{{__('ordre_paiement.date_fin_paiement')}} <br>
								<i class="fa fa-caret-down icon-tri" (click)="triTable('ordre_paiement.date_fin_paiement','asc')" *ngIf="tri=='ordre_paiement.date_fin_paiement' && order=='desc'" ></i> 
								<i class="fa fa-caret-up icon-tri" (click)="triTable('ordre_paiement.date_fin_paiement','desc')"  *ngIf="tri=='ordre_paiement.date_fin_paiement' && order=='asc'"  ></i> 
								<i class="fa fa-caret-left icon-tri" (click)="triTable('ordre_paiement.date_fin_paiement','asc')" *ngIf="tri!='ordre_paiement.date_fin_paiement'" ></i> 
								<input type="date" #date_fin_paiement class="inputCol" name="date_fin_paiement" (keyup.enter)="getUrlDatatable(endPOint, 'ordre_paiement.date_fin_paiement',date_fin_paiement, 'ordre_paiement')" id="">
							</th>

							<th>{{__('ordre_paiment.date_paiement')}} <br>
								<i class="fa fa-caret-down icon-tri" (click)="triTable('detail_ordre_paiement.date_paiement','asc')" *ngIf="tri=='detail_ordre_paiement.date_paiement' && order=='desc'" ></i> 
								<i class="fa fa-caret-up icon-tri" (click)="triTable('detail_ordre_paiement.date_paiement','desc')"  *ngIf="tri=='detail_ordre_paiement.date_paiement' && order=='asc'"  ></i> 
								<i class="fa fa-caret-left icon-tri" (click)="triTable('detail_ordre_paiement.date_paiement','asc')" *ngIf="tri!='detail_ordre_paiement.date_paiement'" ></i> 
								<input type="date" #date_paiement class="inputCol" name="date_paiement" (keyup.enter)="getUrlDatatable(endPOint, 'detail_ordre_paiement.date_paiement',date_paiement, 'ordre_paiement')" id="">
							  
							</th>

							<th>{{__('ordre_paiment.montant')}}  <br>
								<i class="fa fa-caret-down icon-tri" (click)="triTable('detail_ordre_paiement.montant','asc')" *ngIf="tri=='detail_ordre_paiement.montant' && order=='desc'" ></i> 
								<i class="fa fa-caret-up icon-tri" (click)="triTable('detail_ordre_paiement.montant','desc')"  *ngIf="tri=='detail_ordre_paiement.montant' && order=='asc'"  ></i> 
								<i class="fa fa-caret-left icon-tri" (click)="triTable('detail_ordre_paiement.montant','asc')" *ngIf="tri!='detail_ordre_paiement.montant'" ></i> 
								<input type="number" #montant class="inputCol" name="montant" (keyup.enter)="getUrlDatatable(endPOint, 'detail_ordre_paiement.montant',montant, 'ordre_paiement')" id="">
							 
							</th>

							<th>{{__('ordre_paiment.status_paiement')}}  <br>
							 
							</th>
						   
							<th>{{__('ordre_paiment.situation_paiement')}}  <br>
							 
							</th>
						  </tr>
						
					
						</thead>
						<tbody *ngIf="this.ordre_paiements?.length != 0" class="body-paginate-hr" >
						   <tr *ngFor="let ordre_paiement of ordre_paiements">
							<td class="text-left">{{ ordre_paiement?.nom }}</td>
							<td class="text-left">{{ ordre_paiement?.date_debut_paiement | date: 'dd-MM-yyyy' }}</td>
							<td class="text-left">{{ ordre_paiement?.date_fin_paiement | date: 'dd-MM-yyyy' }}</td>
							<td class="text-left">{{ ordre_paiement?.date_paiement | date: 'dd-MM-yyyy' }}</td> 
							<td class="text-left">{{ ordre_paiement?.montant }}</td>

							<td class="text-left" *ngIf="ordre_paiement?.ordre_paiement_statut == -1">Brouillon</td>

							<td class="text-left" *ngIf="ordre_paiement?.ordre_paiement_statut == 0">Crée</td>

							<td class="text-left" *ngIf="ordre_paiement?.ordre_paiement_statut == 1">Validé</td>

							<td class="text-left" *ngIf="ordre_paiement?.ordre_paiement_statut == 2">Rejeté</td>

							<td class="text-left" *ngIf="ordre_paiement?.ordre_paiement_statut == 3">Envoyé</td>

							<td class="text-left" *ngIf="ordre_paiement?.situation_paiement == -1">Echec</td>

							<td class="text-left" *ngIf="ordre_paiement?.situation_paiement == 0">En attente</td>

							<td class="text-left" *ngIf="ordre_paiement?.situation_paiement == 1">Payé</td> 

						  </tr>
						</tbody>
						<tbody *ngIf="ordre_paiements?.length == 0">
						<tr>
							<td colspan="9" class="text-center" >{{__('label_no_data')}}</td>
						</tr>
					  </tbody> 
					
					  </table>
					  <div class="row footer-paginate" >
						<div class="col-lg-4"> 
						  <span class="three-point" style="padding-left: 0px;" >{{infoData}}</span> 
						</div>
						<div class="col-lg-8 btn-paginate">
						  <button (click)="getUrlDatatable(firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
						  <button (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
						  <button *ngIf="currentPage > 3" class="btn-pagination-hr"  (click)="getUrlDatatable(firstPage)" > 1 </button>
						  <span *ngIf="currentPage > 3" class="three-point" > ... </span>
						  <button *ngIf="cPage_less_2 > 0 && currentPage < 4" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_less_2)" > {{cPage_less_2}} </button>
						  <button *ngIf="cPage_less_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_less_1)" > {{cPage_less_1}} </button>
						  <button (click)="getUrlDatatable(prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{currentPage}} </button>
						  <button *ngIf="cPage_more_1 > 0" class="btn-pagination-hr" (click)="getUrlDatatable(path_cPage_more_1)" > {{cPage_more_1}} </button>
						  <button *ngIf="(cPage_more_2 > 0 && cPage_more_2 > lastNumPage && cPage_more_1 > lastNumPage && currentPage > lastNumPage) || cPage_more_2 == lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(path_cPage_more_2)" > {{cPage_more_2}} </button>
						  <span *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="three-point" > ... </span>
						  <button *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="btn-pagination-hr"  (click)="getUrlDatatable(lastPage)" > {{lastNumPage}} </button>
						  <button (click)="getUrlDatatable(nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
						  <button (click)="getUrlDatatable(lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
						</div>

					  </div>


					</div>
				  
				  </div>
				</div>

			</div>

			  
			<hr>
	</mat-tab>
</mat-tab-group>
<!-- TABS -->




