import {HttpHeaders} from "@angular/common/http";
import {environment} from '../../../environments/environment';
// @ts-ignore

export const constante = {
  headers: () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': "Bearer " + localStorage.getItem(environment.authItemName) || '',
        'lang': localStorage.getItem('lang') || "fr"
      }),
    }
  },
  headersProcess : () => {
    return {
      headers: new HttpHeaders({
        'Content-Type':  'application/json; charset=utf-8',
        'Authorization' :"Bearer " + localStorage.getItem(environment.authItemName) || '',
        'lang':  localStorage.getItem('lang') || "fr",
        'process': "1"

      }),
    }
  },
  CURREN_CODE_COUNTRY :"mg",
  authRefreshInterval: (5 * 60 * 1000 ),//5 mm,
  timeTokenName: "_jirama_time_token"
};
