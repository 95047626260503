import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms'; 
import { Routes, RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';
import { NgStepperModule } from 'angular-ng-stepper';
import {CdkStepperModule} from '@angular/cdk/stepper';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';


const APP_CONTAINERS = [
  DefaultLayoutComponent,
  StaticLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { ChartsModule } from 'ng2-charts';
import { LoginComponent } from './administration/components/fragment/auth/login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { UtilisateurComponent } from './administration/components/fragment/admin/utilisateur/utilisateur.component';
import { MeComponent } from './administration/components/fragment/auth/me/me.component';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { ToastrModule } from 'ngx-toastr';
import { HomeModuleComponent } from './containers/home-module/home-module.component';
import { ModuleComponent } from './administration/components/fragment/admin/module/module.component';
import { LogoutComponent } from './administration/components/fragment/auth/logout/logout.component';
import { SousModuleComponent } from './administration/components/fragment/admin/sous-module/sous-module.component';
import { PlateformeComponent } from './administration/components/fragment/parametre/plateforme/plateforme.component';
import { ActionComponent } from './administration/components/fragment/parametre/action/action.component';
import { ProfileComponent } from './administration/components/fragment/admin/profile/profile.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { InterceptorService } from './administration/service/interceptor.service';
import { AuthInterceptorService } from './administration/service/auth-interceptor.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { HomeSousModuleComponent } from './containers/home-sous-module/home-sous-module.component';
import { StaticLayoutComponent } from './containers/static-layout/static-layout.component';
import { DatePipe, registerLocaleData } from '@angular/common';
import * as fr from '@angular/common/locales/fr';
import { ConcernerComponent } from './administration/components/fragment/parametre/plateforme/concerner/concerner.component';
import { CategorieConcernerComponent } from './administration/components/fragment/parametre/plateforme/categorie-concerner/categorie-concerner.component';
import { CarteComponent } from './administration/components/fragment/parametre/plateforme/carte/carte.component';
import { OrdrePaiementComponent } from './administration/components/fragment/parametre/plateforme/ordre-paiement/ordre-paiement.component';
import { EtablissementComponent } from './administration/components/fragment/admin/etablissement/etablissement.component';
import { LogUtilisateurComponent } from './administration/components/fragment/parametre/plateforme/log-utilisateur/log-utilisateur.component';
import { CashFlowComponent } from './administration/components/fragment/parametre/plateforme/cash-flow/cash-flow.component';
import { ProvinceComponent } from './administration/components/fragment/admin/province/province.component';
import { AffectationCarteComponent } from './administration/components/fragment/parametre/plateforme/affectation-carte/affectation-carte.component';
import { TableauBordComponent } from './administration/components/fragment/parametre/plateforme/tableau-bord/tableau-bord.component';
import { ListeCarteParMinistereComponent } from './administration/components/fragment/parametre/plateforme/liste-carte-par-ministere/liste-carte-par-ministere.component';
import { DefaultForMeComponent } from './containers/default-for-me/default-for-me.component';
import { AffectCarteComponent } from './administration/components/fragment/parametre/plateforme/affect-carte/affect-carte.component';
import { ActiveDesactiveCarteComponent } from './administration/components/fragment/parametre/plateforme/active-desactive-carte/active-desactive-carte.component';
import { EnrolerCarteComponent } from './administration/components/fragment/parametre/plateforme/enroler-carte/enroler-carte.component';
import { CreationCompteComponent } from './administration/components/fragment/parametre/plateforme/creation-compte/creation-compte.component';
import { DemandeEnroulementComponent } from './administration/components/fragment/parametre/plateforme/demande-enroulement/demande-enroulement.component';
import { DownloadDirective } from './administration/helpers/directives/wm-download.directive';
import { DemandewalletComponent } from './administration/components/fragment/parametre/plateforme/demandewallet/demandewallet.component';
import { ResumeBourseComponent } from './administration/components/fragment/parametre/plateforme/resume-bourse/resume-bourse.component';
import { ResumeBourseUnivComponent } from './administration/components/fragment/parametre/plateforme/resume-bourse-univ/resume-bourse-univ.component';




@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    NgbModule,
    DataTablesModule.forRoot(),
    HttpClientModule,
    ShowHidePasswordModule,
    NgSelectModule,
    Ng2TelInputModule,
    FormsModule,
    ToastrModule.forRoot(),
    AccordionModule.forRoot(),
    TabsModule.forRoot(),
    NgxSpinnerModule,
    NgStepperModule,
    CdkStepperModule,
    ReactiveFormsModule,
    RouterModule,
    MatTabsModule
   
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
    UtilisateurComponent,
    MeComponent,
    HomeModuleComponent,
    ModuleComponent,
    LogoutComponent,
    SousModuleComponent,
    PlateformeComponent,
    ActionComponent,
    ProfileComponent,
    HomeSousModuleComponent,
    StaticLayoutComponent,
    CategorieConcernerComponent,
    ConcernerComponent,
    CarteComponent,
    OrdrePaiementComponent,
    EtablissementComponent,
    
    LogUtilisateurComponent,
    CashFlowComponent,
    ProvinceComponent,
    AffectationCarteComponent,
    TableauBordComponent,
    ListeCarteParMinistereComponent,
    DefaultForMeComponent,
    AffectCarteComponent,
    ActiveDesactiveCarteComponent,
    EnrolerCarteComponent,
    CreationCompteComponent,
    DemandeEnroulementComponent,
    DownloadDirective,
    DemandewalletComponent,
    ResumeBourseComponent,
    ResumeBourseUnivComponent,
    
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService,multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR'},DatePipe,
    IconSetService,
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { 
  constructor() {
    registerLocaleData(fr.default);
  }
}
