<br>
<div class="repMenu">
  <div class="row  d-flex  justify-content-between">     
      <div class="breadcrumb flat">
          <a [routerLink]="['/app']" routerLinkActive="active">{{__('menu.accueil')}}</a>
          <a [routerLink]="['/app/module/CAR']"  routerLinkActive="active">{{__('menu.carte')}}</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/carte']" routerLinkActive="active" *ngIf="autority('GCA_1')">{{__('carte.title')}}</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/listeCarteParMinistere']"  routerLinkActive="active" *ngIf="autority('DCA_1')">Disponibiliter des cartes</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/affecterCarte']"  routerLinkActive="active" *ngIf="autority('AFC_1')">Affecter carte</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/ActiverDesactive']"  routerLinkActive="active" *ngIf="autority('ACD_1')">Activer / Desactiver carte</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/EnrolerCarte']"  routerLinkActive="active" *ngIf="autority('ENC_1')">Enroler carte</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/planifier_creation_compte']"  routerLinkActive="active" *ngIf="autority('CRC_1')">Création de compte</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/demandeenrolement']"  routerLinkActive="active" *ngIf="autority('DEN_1')">Demande enrolement</a>
          <a [routerLink]="['/dashboard/CAR/plateforme/demandewallet']"  routerLinkActive="active" *ngIf="autority('DEW_1')">Demande création compte</a>
      </div>
  </div>
</div> 


<div class="card">
    <div class="card-body">
      <div class="row" > 
        <br>
                                  
        
          <div class="col-md-12" >
            <div class="card">
              <div class="card-body">
                    <div class="row d-flex justify-content-center">
                      <div class="col-md-4 d-flex justify-content-center align-items-center pt-3" >
                        <div class="form-group" style="text-align: center;" >
                          <div class="form-check form-check-inline mr-1" >
                            <input class="form-check-input" type="radio" [(ngModel)]="statusEnroler"  name="filtreStatus" (click)="getFiltreStatus('A')" id="inlineRadio1" value="A" >
                            <label class="form-check-label"  >En attente </label>
                          </div> &nbsp;&nbsp;&nbsp;

                          <div class="form-check form-check-inline mr-1" >
                            <input class="form-check-input" type="radio" [(ngModel)]="statusEnroler"  name="filtreStatus" (click)="getFiltreStatus('R')" id="inlineRadio1" value="R" >
                            <label class="form-check-label"  >Enroler</label>
                          </div>&nbsp;&nbsp;&nbsp;

                          <div class="form-check form-check-inline mr-1" >
                            <input class="form-check-input" type="radio" [(ngModel)]="statusEnroler"  name="filtreStatus" (click)="getFiltreStatus('E')" id="inlineRadio1" value="E" >
                            <label class="form-check-label"  >Enrolement échoué </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6" >
                        <div class="form-group">
                          <label >{{__('bachelier.etablissement')}}
                          </label>

                          <ng-select placeholder="{{__('concerner.etablissement_id')}}"
                              [(ngModel)]="etabId"
                              class="form-control"
                              name="etablissement"
                              [readonly]="superAdmin == 0 && activeEtabId != 1"
                              (change)="filtreByEtablissement()"
                              >
                              <ng-option  *ngFor="let etab of etablissements" [value]="etab?.id ">{{ etab?.name }}</ng-option>
                          </ng-select>
                          </div>
                      </div>


                      <!-- <div class="col-lg-5">
                        <div class="form-group">
                          <label >{{__('concerner.categorie_concerne_id')}} (<span class="required-red" >{{__('label.require')}}</span>)
                            <span class="required-red" *ngIf="!categorieId && submitFiltre">/ Ce champ est obligatoire</span>
                          </label>
                          <ng-select placeholder="{{__('concerner.categorie_concerne_id')}}"
                              [(ngModel)]="categorieId"
                              class="form-control"
                              >
                              <ng-option  *ngFor="let categ of categories" [value]="categ?.id ">{{ categ?.name }}</ng-option>
                          </ng-select>
                          </div>
                      </div>  -->


                      <!-- <div class="col-lg-2 d-flex align-items-center mt-1" >
                          <button [disabled]="!etablissmentIdModal || !categorieId" (click)="getResultFiltred()" style="height: 44px;" name="button" class="btn button-control" >{{__('btn.filtre')}}</button>
                      </div> -->

                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <br>
                        <div class="card">
                          <div class="card-body">
                                <div class="row d-flex justify-content-center">
                                    <div class="col-md-3">
                                      <label >{{__('journalbord.date_debut')}}</label>
                                      <input [(ngModel)]="dateDebut" type="date" class="form-control">
                  
                                    </div>
                                    <div class="col-md-3">
                                      <label >{{__('journalbord.date_fin')}}</label>
                                      <input [(ngModel)]="dateFin" type="date" class="form-control">
                  
                                    </div>
                                        
                                   <div class="pt-1">
                                      <button  style="height: 44px;" type="submit" name="button" (click)="getListDemandeEnrolementEnAttent(endPOint, '','','',true)" class="mt-4 btn button-control" >{{__('btn.filtre')}}</button>
                  
                                   </div>
                                </div>
                          </div>
                        </div>
                      </div>
                    </div> 
              </div>
            </div>
          </div>
      </div>


      <div class="row">
          <div class="col-lg-12">
              <br>
              <div class="row footer-paginate" >
                  <div class="col-lg-4"> 
                      <span style="color: #666 !important;" > {{__('datatable.afficher')}}  : </span> 
                      <select name="" id="" [(ngModel)]="filtreSelect" (change)="getFiltreSelect()" class="search-input" >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                      <option value="2000">2000</option>
                      <option value="5000">5000</option>
                      <option value="10000">10000</option>
                      </select> 
                      <span style="color: #666 !important;" > {{__('datatable.element')}}  </span> 
                  </div>
                  <div class="col-lg-8 btn-paginate">
                      <span style="color: #666 !important;" > {{__('datatable.rechercher')}} : </span><input type="text" [(ngModel)]="searchInput" (keyup.enter)="getSearchInput()" class="search-input">
                  </div> 

              </div>
                  <table style="width: 100%"  class="table table-striped table-hover " >
                    <thead>
                        <tr>
                          <th class="text-left">{{__('concerner.matricule')}}
                              <br>
                              <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.matricule','asc')" *ngIf="tri=='concernees.matricule' && order=='desc'" ></i> 
                              <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.matricule ','desc')"  *ngIf="tri=='concernees.matricule' && order=='asc'"  ></i> 
                              <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.matricule','asc')" *ngIf="tri!='concernees.matricule'" ></i> 
                              <!-- <input type="text" #matricule class="inputCol" name="matricule" (keyup.enter)="getListDemandeEnrolementEnAttent(endPOint,'','concernees.matricule',matricule, false)" id=""> -->
                              <input type="text" #matricule class="inputCol" name="matricule" (keyup.enter)="getListDemandeEnrolementEnAttent(endPOint,'', 'concernees.matricule',matricule, false)" id="">
                          </th>
                          <th class="text-left">{{__('concerner.nom')}}
                              <br>
                              <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.nom','asc')" *ngIf="tri=='concernees.nom' && order=='desc'" ></i> 
                              <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.nom','desc')"  *ngIf="tri=='concernees.nom' && order=='asc'"  ></i> 
                              <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.nom','_asc')" *ngIf="tri!='concernees.nom'" ></i> 
                              <input type="text" #nom class="inputCol" name="nom" (keyup.enter)="getListDemandeEnrolementEnAttent(endPOint, 'concernees.nom',nom, false)" id="">
                          </th>
                          <th class="text-left">{{__('concerner.prenom')}}
                              <br>
                              <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.prenom','asc')" *ngIf="tri=='concernees.prenom' && order=='desc'" ></i> 
                              <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.prenom','desc')"  *ngIf="tri=='concernees.prenom' && order=='asc'"  ></i> 
                              <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.prenom','_asc')" *ngIf="tri!='concernees.prenom'" ></i> 
                              <input type="text" #prenom class="inputCol" name="prenom" (keyup.enter)="getListDemandeEnrolementEnAttent(endPOint, '','concernees.prenom',prenom, false)" id="">
                          </th>
                          <th class="text-left">{{__('concerner.telephone')}}
                              <br>
                              <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.telephone','asc')" *ngIf="tri=='concernees.telephone' && order=='desc'" ></i> 
                              <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.telephone','desc')"  *ngIf="tri=='concernees.telephone' && order=='asc'"  ></i> 
                              <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.telephone','asc')" *ngIf="tri!='concernees.telephone'" ></i> 
                              <input type="text" #telephone class="inputCol" name="email" (keyup.enter)="getListDemandeEnrolementEnAttent(endPOint,'', 'concernees.telephone',telephone, false)" id="">
                          </th>
                          <th class="text-left">{{__('concerner.email')}}
                            <br>
                            <i class="fa fa-caret-down icon-tri" (click)="triTable('concernees.email','asc')" *ngIf="tri=='concernees.telephone' && order=='desc'" ></i> 
                            <i class="fa fa-caret-up icon-tri" (click)="triTable('concernees.email','desc')"  *ngIf="tri=='concernees.email' && order=='asc'"  ></i> 
                            <i class="fa fa-caret-left icon-tri" (click)="triTable('concernees.email','asc')" *ngIf="tri!='concernees.email'" ></i> 
                            <input type="text" #email class="inputCol" name="email" (keyup.enter)="getListDemandeEnrolementEnAttent(endPOint, '','concernees.email',email, false)" id="">
                          </th>
                          <th class="text-left">{{__('etudiant.numCarte')}}
                            <br>
                            <i class="fa fa-caret-down icon-tri" (click)="triTable('carte.numero_serie','asc')" *ngIf="tri=='carte.numero_serie' && order=='desc'" ></i> 
                            <i class="fa fa-caret-up icon-tri" (click)="triTable('carte.numero_serie','desc')"  *ngIf="tri=='carte.numero_serie' && order=='asc'"  ></i> 
                            <i class="fa fa-caret-left icon-tri" (click)="triTable('carte.numero_serie','asc')" *ngIf="tri!='carte.numero_serie'" ></i> 
                            <input type="text" #numero_serie class="inputCol" name="email" (keyup.enter)="getListDemandeEnrolementEnAttent(endPOint,'', 'carte.numero_serie',numero_serie, false)" id="">
                          </th>
                          <th  >{{__('civil.etablissement')}} </th>
                          <th *ngIf="statusEnroler == 'A'" class="" nowrap  >{{__('carte.date_demande')}}
                           
                          </th>
                          <th *ngIf="statusEnroler == 'R' || statusEnroler == 'E' " class="" nowrap  >{{__('carte.date_enrolement')}}
                            
                          <th class="actionTab" nowrap *ngIf="statusEnroler == 'E'" >{{__('concerner.message')}} </th>
                        
                      </tr>
                    </thead>
                    <tbody *ngIf="listDemandeEnrollementEnAttent?.length != 0" >
                          <tr *ngFor="let concerner of listDemandeEnrollementEnAttent">
                              <td class="text-left">{{ concerner?.matricule }}</td>    
                              <td class="text-left">{{ concerner?.nom}}</td> 
                              <td class="text-left">{{ concerner?.prenom}}</td> 
                              <td class="text-left">{{ concerner?.telephone }}</td>  
                              <td class="text-left">{{ concerner?.email }}</td>  
                              <td class="text-left">{{ concerner?.numero_serie }}</td>  
                              <!--<td class="text-left">
                                <span *ngIf="concerner.type_compte == 0" > {{__('tooltip.compte_paositra')}}</span>
                                <span *ngIf="concerner.type_compte == 1"> {{__('tooltip.compte_e_poketra')}}</span>
                            </td>-->
                              <td class="text-left">{{ concerner?.etablissement_name }}</td>
                              <td  class="text-left">{{ statusEnroler == 'A' ?  concerner?.date_demande : concerner?.date_enrolement }}</td>

                              <td class="text-left" *ngIf="statusEnroler == 'E'">{{ concerner?.message }}</td>
                              
                          </tr>
                    </tbody>
                    <tbody *ngIf="listDemandeEnrollementEnAttent?.length == 0">
                      <tr class="text-center">
                          <td colspan="8" >{{__('label_no_data')}}</td>
                      </tr>
                    </tbody> 
                  </table>

                  <div class="row footer-paginate pt-2" *ngIf="listDemandeEnrollementEnAttent.length != 0">
                      <div class="col-lg-4"> 
                          <span class="three-point" style="padding-left: 0px;" >{{infoData}}</span> 
                      </div>
                      <div class="col-lg-8 btn-paginate" >
                          <button *ngIf="currentPage != 1" (click)="getListDemandeEnrolementEnAttent(firstPage)" class="btn-pagination-hr" > {{__('datatable.first')}} </button>
                          <button *ngIf="currentPage != 1" (click)="getListDemandeEnrolementEnAttent(prevPage)" class="btn-pagination-hr" > {{__('datatable.previous')}} </button>
                          <button *ngIf="currentPage > 3" class="btn-pagination-hr"  (click)="getListDemandeEnrolementEnAttent(firstPage)" > 1 </button>
                          <span *ngIf="currentPage > 3" class="three-point" > ... </span>
                          <button *ngIf="cPage_less_2 > 0 && currentPage < 4" class="btn-pagination-hr"  (click)="getListDemandeEnrolementEnAttent(path_cPage_less_2)" > {{cPage_less_2}} </button>
                          <button *ngIf="cPage_less_1 > 0" class="btn-pagination-hr" (click)="getListDemandeEnrolementEnAttent(path_cPage_less_1)" > {{cPage_less_1}} </button>
                          <button (click)="getListDemandeEnrolementEnAttent(prevPage)" class="btn-pagination-hr current" style="background-color: red;" > {{currentPage}} </button>
                          <button *ngIf="cPage_more_1 > 0" class="btn-pagination-hr" (click)="getListDemandeEnrolementEnAttent(path_cPage_more_1)" > {{cPage_more_1}} </button>
                          <button *ngIf="(cPage_more_2 > 0 && cPage_more_2 > lastNumPage && cPage_more_1 > lastNumPage && currentPage > lastNumPage) || cPage_more_2 == lastNumPage" class="btn-pagination-hr"  (click)="getListDemandeEnrolementEnAttent(path_cPage_more_2)" > {{cPage_more_2}} </button>
                          <span *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="three-point" > ... </span>
                          <button *ngIf="cPage_more_2 < lastNumPage && cPage_more_1 < lastNumPage && currentPage < lastNumPage" class="btn-pagination-hr"  (click)="getListDemandeEnrolementEnAttent(lastPage)" > {{lastNumPage}} </button>
                          <button *ngIf="currentPage < lastNumPage" (click)="getListDemandeEnrolementEnAttent(nextPage)" class="btn-pagination-hr"  > {{__('datatable.next')}} </button>
                          <button *ngIf="currentPage < lastNumPage" (click)="getListDemandeEnrolementEnAttent(lastPage)" class="btn-pagination-hr"  > {{__('datatable.last')}} </button>
                      </div>
                  </div>
              </div>
      </div>
    </div>
</div>





