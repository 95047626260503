import { Component, OnInit,ViewChild  } from '@angular/core';
import { Translatable } from '../..//../../service/Translatable';
import {DataTablesResponse, HttpApiResponse} from "../../../../model/DataTablesResponse";
import {HelperService} from "../../../../service/helper.service";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from "@angular/common/http";
import {action,Auth} from "../../../../model/db";
import {valuesys} from "../../../../../../options";
import {environment} from "../../../../../../environments/environment";
import {AuthServiceService} from "../../../../service/auth-service.service";
import {DataTableDirective} from "angular-datatables";
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import {Subject} from "rxjs";


@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent extends Translatable implements OnInit {

    /*REFRESH*/
    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;
    // @ts-ignore
    dtTrigger: Subject = new Subject();
    ngAfterViewInit(): void {
      this.dtTrigger.next();
    }
  
    ngOnDestroy(): void {
      this.dtTrigger.unsubscribe();
    }
    rerender(): void {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
    }
    /*REFRESH*/
    title:string ;
    actions:action [] =[];
    etabId:any ;
    superAdmin:any ;
    dtOptions: DataTables.Settings = {};
    constructor(
                private auth : AuthServiceService,
                private http: HttpClient,
                private toastr: ToastrService,
                private helper: HelperService,
    ) {
      super();
    }
    user: Auth;
  
    async ngOnInit() {
    
      this.user =<Auth> this.auth.account();
    this.etabId = this.user.info.etablissement_id;    
    this.superAdmin = this.user.info.admin;
      this.initDatatable()
    }
  
    initDatatable() {
      this.title = this.__("action.action");
      this.dtOptions = this.helper.dataTableOption();
      this.dtOptions.columns = [
        {
          "data": "name"
        },
        {
          "data": "code"
        },
      
        {
          "data": "url"
        },
        {
          "data": "method"
        },
        {
          "data": "typeaction",
          "name": "typeaction.name",
        },
        {
          "data": "sousmodule",
          "name": "sousmodule.name",
        }
      ];
      this.dtOptions.ajax = (dataTablesParameters: any, callback) => {
        this.http
          .get<DataTablesResponse>(
            environment.action +"?child=sousmodule,typeaction&"  + $.param(dataTablesParameters), valuesys.httpAuthOptionsProcess()
          ).subscribe(resp => {
          this.actions = resp.data;
          console.log(this.actions)
          callback({
            recordsTotal: resp.recordsTotal,
            recordsFiltered: resp.recordsFiltered,
            data: []
          });
        });
      }
    }


    async genererAction(btn:any){
      btn.disabled = true;
      Swal.fire({
        title: this.__('swal.confirmTitle'),
        text: this.__('swal.confirmMsg'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#44A1A0',
        cancelButtonColor: '#FF7467',
        confirmButtonText: this.__('swal.yes'),
        cancelButtonText: this.__('swal.no'),
        reverseButtons : true
      }).then(async (result) => {
   
        if (result.value) {
          let {code,msg} = await this.http.get<HttpApiResponse>(environment.genererAction,valuesys.httpAuthOptions()).toPromise();
          console.log(code)
          if(code === 200){
            this.toastr.success(msg,this.__("msg.success"))
            this.rerender();
          }else {
            this.toastr.success(msg,this.__("msg.error"))
          }
          btn.disabled = false;
        }
        btn.disabled = false;
      })
   
     }
    async addNewAction(btn:any){
      btn.disabled = true;
      Swal.fire({
        title: this.__('swal.confirmTitle'),
        text: this.__('swal.confirmMsg'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#44A1A0',
        cancelButtonColor: '#FF7467',
        confirmButtonText: this.__('swal.yes'),
        cancelButtonText: this.__('swal.no'),
        reverseButtons : true
      }).then(async (result) => {
   
        if (result.value) {
          let {code,data,msg} = await this.http.get<HttpApiResponse>(environment.updateAction,valuesys.httpAuthOptions()).toPromise();
          if(code === 200){
            this.toastr.success(msg,this.__("msg.success"))
            this.rerender();
          }else {
            this.toastr.success(data,this.__("msg.error"))
          }
          btn.disabled = false;
        }
        btn.disabled = false;
      })
   
     }
  

}
